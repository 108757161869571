import React from "react";
import type {ReactNode} from "react"; 

const SectionCardContext = React.createContext(undefined);
type TProps = {
  children: ReactNode,
  value: any
}

function SectionCardProvider({ children, value }: TProps) {
  return (
    <SectionCardContext.Provider value={value}>{children}</SectionCardContext.Provider>
  );
}

function useSectionCardContext() {
  const context = React.useContext(SectionCardContext);
  if (context === undefined) {
    throw new Error("useSectionCardContext must be used within a SectionCardProvider");
  }
  return context;
}

export { SectionCardProvider, useSectionCardContext };
