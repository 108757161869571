import React from "react";
import type {ReactNode} from "react"; 
import { Item, Image } from "semantic-ui-react";
import {useHvacItemContext} from "../useHvacItemContext";
import type { IAwair, IErvCtrl } from "@interfaces";
import styled from "styled-components";

type TProps = {
  children: ReactNode
}

function Label({children}: TProps) {
  const  { ervCtrl } : {ervCtrl: IErvCtrl} = useHvacItemContext();
  return (
    <Item.Header>
      {children || 'NO NAME'}
      {ervCtrl && <StyledImage src="/logo_maximum.png" verticalAlign="middle" />}
    </Item.Header>
  )
}
export { Label };

const StyledImage = styled(Image)`
  width: 20px;
  margin-left:3px;
`

       