import React from "react";
import { Segment } from "semantic-ui-react";
import styled from "styled-components";

type TProps = {
  children: any;
};

function Col({ children }: TProps) {
  return <StyledSegmentGroup horizontal>{children}</StyledSegmentGroup>;
}
export { Col };

const StyledSegmentGroup = styled(Segment.Group)`
  margin: 0 !important;
  border-radius: 0 !important;
`;
