import React from "react";
import moment from "moment";
import { Grid, Segment } from "semantic-ui-react";
import Charts from "Components/Common/Charts";
import styled from "styled-components";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

function ConsultingPrintArea({
  name,
  reportAreaRef,
  startDate,
  endDate,
  averageData,
  generalTag1,
  generalTag2,
  generalTag3,
  generalTag4,
  graphData,
  minMaxData,
  generalComment,
  dateList,
  minDesc,
  minMaxHourData,
  reviewerNames,
  reviewerKey,
}) {
  const getStatusText = (target, value) => {
    if (target === "awairScore") {
      if (value >= 95) return "매우 좋음";
      else if (value >= 86) return "좋음";
      else if (value >= 80) return "보통";
      else return "나쁨";
    }

    if (target === "ico2") {
      if (value <= 600) return "매우 좋음";
      else if (value <= 999) return "좋음";
      else if (value <= 1499) return "미흡";
      else if (value <= 2499) return "나쁨";
      else return "매우 나쁨";
    }

    if (target === "ivoc") {
      if (value <= 399) return "매우 좋음";
      else if (value <= 599) return "좋음";
      else if (value <= 999) return "미흡";
      else if (value <= 2500) return "나쁨";
      else return "매우 나쁨";
    }

    if (target === "ipm25") {
      if (value <= 15) return "매우 좋음";
      else if (value <= 35) return "좋음";
      else if (value <= 55) return "미흡";
      else if (value <= 75) return "나쁨";
      else return "매우 나쁨";
    }
  };

  const getStatusColor = (target, value) => {
    if (target === "ico2") {
      if (value <= 999) return "#92d050";
      else if (value <= 1499) return "#ffc000";
      else return "#ff7c5d";
    }

    if (target === "ivoc") {
      if (value <= 599) return "#92d050";
      else if (value <= 999) return "#ffc000";
      else return "#ff7c5d";
    }

    if (target === "ipm25") {
      if (value <= 35) return "#92d050";
      else if (value <= 55) return "#ffc000";
      else return "#ff7c5d";
    }
  };

  const getHourText = (hour) => {
    const startHour = hour - 3;
    const endHour = hour + 3;
    let startHourText = "";
    let endHourText = "";
    if (startHour < 0) return `오전 ${hour}시 전`;
    if (endHour > 24) return `오후 ${hour - 12}시 이후`;
    if (startHour <= 12) startHourText = `오전 ${startHour}시와 `;
    if (startHour > 12) startHourText = `오후 ${startHour - 12}시와 `;
    if (startHour <= 12 && endHour <= 12) endHourText = `${endHour}시 사이에`;
    if (startHour <= 12 && endHour > 12) endHourText = `오후 ${endHour - 12}시 사이에`;
    if (startHour > 12 && endHour > 12) endHourText = `${endHour - 12}시 사이에`;

    return `${startHourText} ${endHourText} `;
  };

  const getPieChartDescText = (data) => {
    return data.map((cur) => {
      if (cur[1] === 0) return;
      return (
        <div key={cur[0]}>
          공기상태 {cur[0]}은 <span>{cur[1]}시간</span> 입니다.
        </div>
      );
    });
  };

  const getDescText = (target, hour, value) => {
    switch (target) {
      case "ico2":
        if (hour >= 7 && hour < 21) {
          if (value >= 1000) return "적절한 환기를 통해 이산화탄소 관리에 조금 더 신경을 쓴다면 쾌적하고 활기찬 거주 공간이 될 수 있습니다.";
          if (value < 1000) return "이산화탄소 수치가 적절하게 유지되고 있습니다. 양호한 수치를 유지하기 위해 규칙적인 환기가 필요합니다.";
        } else {
          if (value >= 1000) return "적절한 환기를 통해 이산화탄소 관리에 조금 더 신경을 쓴다면 더욱 깊은 수면을 취할 수 있습니다.";
          if (value < 1000) return "이산화탄소 수치가 적절하게 유지되고 있습니다. 깊은 수면이 방해받지 않을 정도의 수치입니다.";
        }
        break;

      case "ivoc":
        if (value >= 600) return "화학물질이 인체에 흡수되어 체내에 지속적으로 쌓이게 된다면 새집증후군을 유발할 수 있습니다.";
        if (value < 600) return "자연적으로 배출되는 정도의 수치로 건강을 해치지 않습니다. 좋은 상태의 공기질 관리를 위해 꾸준한 환기가 필요합니다.";
        break;

      case "ipm25":
        if (value >= 36)
          return "미세먼지가 체내에 쌓이게 되면 기관지에 흡착하여 코막힘을 유발하고 호흡기를 건조하게 만듭니다. 환기장치 내 미세먼지 필터는 주기적으로 관리해주는 것이 좋습니다.";
        if (value < 36) return "외부로부터 들어온 먼지 혹은 요리 시 배출될 수 있는 수준으로 적절한 공기청정기의 사용과 환기가 필요합니다.";
        break;

      default:
        return "";
        break;
    }
  };

  return (
    <>
      <StyledSegment>
        <PrintArea ref={reportAreaRef}>
          <PrintPage>
            <Head>
              <h2>
                <span>{name} 님</span> 실내 공기상태 종합 리포트
                <img src="/logo_horizontal_white.png" alt="logo" />
              </h2>
            </Head>
            <Body>
              <Section>
                <Title>
                  <h2>종합 의견</h2>
                  <p className="date">
                    {startDate.format("YYYY. MM. DD")} ~ {endDate.format("YYYY. MM. DD")}
                  </p>
                </Title>
                <General>
                  <Grid className="general-box">
                    <Grid.Column width={3}>
                      <h4 style={{ textAlign: "center" }}>SCORE</h4>
                      <b>{averageData.awairScore.toFixed(0)}</b>
                    </Grid.Column>
                    <Grid.Column width={3} className="reviewer">
                      <img src={`/member/member_${reviewerKey}.jpg`} />
                      <p>
                        모니터링 담당자
                        <br />
                        {reviewerNames[reviewerKey]}
                      </p>
                    </Grid.Column>
                    <Grid.Column width={10}>
                      <h4>종합 의견</h4>
                      <p className="main-msg">
                        &ldquo;기간 내 평균 공기질 점수는 {averageData.awairScore.toFixed(0)}점으로{" "}
                        {getStatusText("awairScore", averageData.awairScore.toFixed(0))}의 공기질을 유지하였습니다. {generalComment}
                        &rdquo;
                      </p>
                      <div className="tags">
                        <h4>주요 키워드</h4>
                        {generalTag1 && <div className="tag">{generalTag1}</div>}
                        {generalTag2 && <div className="tag">{generalTag2}</div>}
                        {generalTag3 && <div className="tag tag-outer">{generalTag3}</div>}
                        {generalTag4 && <div className="tag tag-outer">{generalTag4}</div>}
                      </div>
                    </Grid.Column>
                  </Grid>
                  <p className="additional-msg">* 공기질 점수를 구성하는 항목은 총 3가지로 ①이산화탄소, ②휘발성 유기화합물, ③미세먼지 입니다.</p>
                </General>
              </Section>
              <Section>
                <Title>
                  <h2>기간 내 공기 상태 평가</h2>
                </Title>
                <Grid>
                  <Grid.Column width={8} className="pie-chart-grid">
                    {/* <p>336시간</p> */}
                    <Charts kind="awairScorePieChart" data={graphData.allHourlyAwairScore} />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <AllHourBox>
                      {/* <small>2주(336시간) 간</small> <br /> */}
                      <small>{moment(endDate).diff(startDate, "hours") + 1}시간 동안의</small> <br />
                      {getPieChartDescText(graphData.allHourlyAwairScore[0]["data"])}
                    </AllHourBox>
                  </Grid.Column>
                </Grid>
              </Section>
              <Section>
                <Title>
                  <h2>일자 별 공기 상태</h2>
                </Title>
                <Charts kind="dailyAwairScore" data={graphData.dailyAwairScore} xAxisCategory={dateList} minMaxData={minMaxData} />
                <MinMaxBox>
                  <Grid>
                    <Grid.Column width={3} className="left">
                      <h4>최고 점수 {minMaxData.max.awairScore.toFixed(0)}점 </h4>
                      {minMaxData.max.date}
                    </Grid.Column>
                    <Grid.Column width={13} className="right">
                      <p>
                        공기질 점수 최고점을 기록하였습니다. 청정지역에 가까웠네요!
                        <br />
                        앞으로도 적절한 환기와 생활습관으로 깨끗한 공기를 마셔요.
                      </p>
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    <Grid.Column width={3} className="left">
                      <h4 className="minTitle">최저 점수 {minMaxData.min.awairScore.toFixed(0)}점 </h4>
                      {minMaxData.min.date}
                    </Grid.Column>
                    <Grid.Column width={13} className="right" style={{ whiteSpace: "pre-line" }}>
                      <p>{minDesc}</p>
                    </Grid.Column>
                  </Grid>
                </MinMaxBox>
              </Section>
            </Body>
          </PrintPage>
          <PrintPage>
            <Head>
              <h2>
                <span>{name} 님</span> 실내 공기상태 종합 리포트
                <img src="/logo_horizontal_white.png" alt="logo" />
              </h2>
            </Head>
            <Body>
              <Section className="section--last">
                <Title style={{ marginBottom: 10 }}>
                  <h2>
                    공기질 오염 물질 분석 <span className="desc1">(이산화탄소, 휘발성 유기화합물, 미세먼지)</span>
                  </h2>
                  <p className="desc2">컨설팅 기간 동안 측정된 공기질 요소의 평균치입니다.</p>
                </Title>
                <StyledGrid>
                  <Grid.Column width={4} className="col-left">
                    <img src="/cloud.png" alt="co2" />
                    <p>
                      이산화탄소(ppm)
                      <b>{averageData.ico2.toFixed(0)}</b>
                    </p>
                  </Grid.Column>
                  <Grid.Column width={12} className="col-right">
                    <h3>
                      기간 내 고객님의 이산화탄소 평균 수치는 {averageData.ico2.toFixed(0)}ppm으로{" "}
                      <span style={{ color: getStatusColor("ico2", averageData.ico2.toFixed(0)) }}>
                        {getStatusText("ico2", averageData.ico2.toFixed(0))}에 해당됩니다.
                      </span>
                    </h3>
                    이산화탄소는 사람의 호흡 과정에서 발생하는 물질로서, 실내 공간에 사람들이 모여서 생활할 경우 자연스럽게 농도가 상승하는 물질입니다. 자연
                    상태에서는 평균적으로 400~500 ppm 정도의 농도로 분포합니다. 그러나 실내 공기 중 이산화탄소의 농도가 안전 기준치(1000ppm)를 기준으로,
                    높아질수록 졸음 또는 두통이 오거나 산소부족으로 인해 피로감이 쌓일 수 있습니다.
                  </Grid.Column>
                </StyledGrid>

                <StyledGrid>
                  <Grid.Column width={10} className="chart-column">
                    <Charts
                      kind="hourlyCo2"
                      data={graphData.hourlyCo2}
                      maxHour={minMaxHourData.ico2.hour}
                      maxValue={minMaxHourData.ico2.maxValue}
                      minValue={minMaxHourData.ico2.minValue}
                    />
                  </Grid.Column>
                  <Grid.Column width={6} className="col-right2">
                    <div className="h4-wrap">
                      <h4>시간 별 CO2</h4>
                    </div>
                    <p>
                      이산화탄소가 {getHourText(minMaxHourData.ico2.hour)} 최고 수치를 나타내고 있습니다.
                      {getDescText("ico2", minMaxHourData.ico2.hour, minMaxHourData.ico2.maxValue)}
                    </p>
                  </Grid.Column>
                </StyledGrid>

                <StyledGrid>
                  <Grid.Column width={4} className="col-left">
                    <img src="/virus.png" alt="voc" />
                    <p>
                      휘발성 유기화합물(ppb)
                      <b>{averageData.ivoc.toFixed(0)}</b>
                    </p>
                  </Grid.Column>
                  <Grid.Column width={12} className="col-right">
                    <h3>
                      기간 내 고객님의 휘발성 유기화합물 평균 수치는 {averageData.ivoc.toFixed(0)}ppb로{" "}
                      <span style={{ color: getStatusColor("ivoc", averageData.ivoc.toFixed(0)) }}>
                        {getStatusText("ivoc", averageData.ivoc.toFixed(0))}에 해당됩니다.
                      </span>
                    </h3>
                    휘발성 유기화합물은 대기 중에 휘발되어 악취나 오존을 발생시키는 탄화수소 화합물을 뜻합니다. 대표적으로 벤젠, 포름알데히드, 톨루엔, 자일렌
                    등이 있습니다. 이러한 물질은 특정 농도에서 특유의 냄새를 뿜고, 피부 접촉이나 호흡기 흡입을 통해 신체에 들어와 신경계에 장애를 일으킬 수
                    있습니다. 특히 장기간 체내에 흡수되면 천식, 아토피, 그리고 알러지 증상이 심해질 수 있습니다.
                  </Grid.Column>
                </StyledGrid>

                <StyledGrid>
                  <Grid.Column width={10} className="chart-column">
                    <Charts
                      kind="hourlyVoc"
                      data={graphData.hourlyVoc}
                      maxHour={minMaxHourData.ivoc.hour}
                      maxValue={minMaxHourData.ivoc.maxValue}
                      minValue={minMaxHourData.ivoc.minValue}
                    />
                  </Grid.Column>
                  <Grid.Column width={6} className="col-right2">
                    <div className="h4-wrap">
                      <h4>시간 별 VOCs</h4>
                    </div>
                    <p>
                      화학물질이 {getHourText(minMaxHourData.ivoc.hour)} 최고 수치를 나타내고 있습니다.{" "}
                      {getDescText("ivoc", minMaxHourData.ivoc.hour, minMaxHourData.ivoc.maxValue)}
                    </p>
                  </Grid.Column>
                </StyledGrid>

                <StyledGrid>
                  <Grid.Column width={4} className="col-left">
                    <img src="/sand.png" alt="pm25" />
                    <p>
                      미세먼지(㎍/m³)
                      <b>{averageData.ipm25.toFixed(1)}</b>
                    </p>
                  </Grid.Column>
                  <Grid.Column width={12} className="col-right">
                    <h3>
                      기간 내 고객님의 미세먼지 평균 수치는 {averageData.ipm25.toFixed(1)}㎍/m³로{" "}
                      <span style={{ color: getStatusColor("ipm25", averageData.ipm25.toFixed(0)) }}>
                        {getStatusText("ipm25", averageData.ipm25.toFixed(1))}에 해당됩니다.
                      </span>
                    </h3>
                    미세먼지는 최근 가장 심각히 건강을 위협하는 물질로 인식되고 있습니다. 크기가 작아 일반적인 먼지와는 달리 인체에 들어올 경우 잘 걸러지지
                    않습니다. 미세먼지 관련 질환은 인체 면역력으로 퇴치할 수 없으며 미세먼지가 몸에 침투하면 강제로 배출할 방법도 없어 각별한 주의를 요합니다.
                    미세먼지는 호흡기 질환부터 심혈관 질환까지 각종 질환을 유발할 수 있습니다.
                  </Grid.Column>
                </StyledGrid>

                <StyledGrid style={{ position: "relative" }}>
                  <Grid.Column width={10} className="chart-column chart-column--last">
                    <Charts
                      kind="hourlyPm25"
                      data={graphData.hourlyPm25}
                      maxHour={minMaxHourData.ipm25.hour}
                      maxValue={minMaxHourData.ipm25.maxValue}
                      minValue={minMaxHourData.ipm25.minValue}
                    />
                  </Grid.Column>
                  <Grid.Column width={6} className="col-right2">
                    <div className="h4-wrap">
                      <h4>시간 별 PM2.5</h4>
                    </div>
                    <p>
                      미세먼지가 {getHourText(minMaxHourData.ipm25.hour)} 최고 수치를 나타내고 있습니다.{" "}
                      {getDescText("ipm25", minMaxHourData.ipm25.hour, minMaxHourData.ipm25.maxValue)}
                    </p>
                  </Grid.Column>
                  <IconDesc>Icon made by Freepik</IconDesc>
                </StyledGrid>
              </Section>
            </Body>
          </PrintPage>
        </PrintArea>
      </StyledSegment>
    </>
  );
}

export default ConsultingPrintArea;

const StyledSegment = styled(Segment)`
  padding: 0 !important;
  overflow: hidden;
  border: none !important;
`;

const PrintArea = styled.div`
  /* padding-top: 16px;  */
  /* height: 1120px;  */
  background-color: #203864 !important;
`;

const PrintPage = styled.div`
  padding-top: 16px; /*프린트 상단잘림 여백 */
  height: 1120px; /* 프린트를 위한 고정 높이 */
`;

const Head = styled.div`
  padding: 10px 20px;
  background-color: #203864;
  h2 {
    font-size: 16px;
    color: #fff;
    font-weight: 300;
    margin-left: 6px;
    span {
      background-color: #fff;
      color: #203864;
      border-radius: 10px;
      padding: 0px 8px;
    }
  }
  img {
    display: block;
    margin-top: 2px;
    width: 140px;
    float: right;
  }
  &:after {
    content: "";
    display: block;
    clear: both;
  }
`;

const Body = styled.div`
  padding: 15px 20px 10px 20px;
  color: #7a7a7a;
  background-color: #fff;

  .chartContainer {
    box-shadow: none;
  }

  .col-left {
    color: #fff;
    padding: 20px;
    line-height: 1.4em;
    background-color: #203864;
    position: relative;

    img {
      height: 50px;
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    p {
      text-align: center;
      position: absolute;
      font-size: 12px;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      b {
        display: block;
        margin-top: 6px;
        font-size: 24px;
        font-weight: normal;
      }
    }

    &.col-left--2 {
      background-color: #a6a1a1;
    }
  }

  .col-right {
    background-color: #e6e4e4;
    font-size: 10px;
    h3 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
      span {
        color: #ee6516;
      }
    }
  }

  .col-right2 {
    margin-top: 12px;
    font-size: 12px;
    .h4-wrap {
      border-bottom: 2px solid #e5e5e5;
      margin-bottom: 14px;
    }

    h4 {
      border-left: 5px solid #203864;
      padding-left: 12px;
      margin-bottom: 2px;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .pie-chart-grid {
    position: relative;
    p {
      position: absolute;
      font-size: 20px;
      font-weight: 500;
      top: 26px;
      left: 46px;
      z-index: 10;
    }
  }
`;

const Title = styled.div`
  border-bottom: 2px solid #e5e5e5;
  position: relative;
  margin-bottom: 14px;
  h2 {
    border-left: 5px solid #203864;
    padding-left: 16px;
    margin-bottom: 2px;
    font-weight: 400;
    font-size: 16px;
  }
  .date {
    position: absolute;
    right: 0px;
    bottom: 0px;
    font-weight: 600;
    font-size: 16px;
  }

  .desc1 {
    font-size: 12px;
  }

  .desc2 {
    position: absolute;
    right: 0px;
    bottom: 0px;
    font-size: 10px;
  }
  &:after {
    content: "";
    display: block;
    clear: both;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;
  &.section--last {
    margin-bottom: 12px;
  }
`;

const General = styled.div`
  margin: 0 14px;
  .general-box {
    background-color: #e6e4e4;
    margin-bottom: 14px;
  }
  h4 {
    font-weight: 600;
    margin-bottom: 6px;
    font-size: 12px;
  }
  b {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 60px;
  }
  p {
    &.main-msg {
      white-space: pre-line;

      font-size: 12px;
      line-height: 1.5;
      border-left: 1px solid #e5e5e5;
      padding-left: 20px;
      margin-left: -20px;
      height: 110px;
      overflow: hidden;
    }

    &.additional-msg {
      font-size: 9px;
    }
  }
  .tags {
    margin-top: 12px;
  }
  .tag {
    display: inline;
    color: #fff;
    background-color: #f98901;
    margin-right: 4px;
    border-radius: 10px;
    padding: 2px 16px;
    font-size: 12px;

    &.tag-outer {
      background-color: #feb358;
    }
  }

  .reviewer {
    img {
      width: 113px;
      height: 113px;
      border-radius: 100%;
      border: 1px solid #ddd;
    }
    p {
      font-size: 12px;
      text-align: center;
    }
  }
`;

const AllHourBox = styled.div`
  background-color: #e6e4e4;
  padding: 20px;
  margin-top: 20px;
  border-radius: 20px;
  display: inline-block;
  line-height: 2;
  font-size: 18px;
  small {
    font-size: 0.8em;
  }
  span {
    background-color: #fff;
    padding: 0px 10px;
    border-radius: 10px;
  }
`;

const MinMaxBox = styled.div`
  background-color: #e6e4e4;
  padding-right: 10px;
  .left {
    text-align: right;
    font-size: 14px;
    h4 {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 2px;
      color: #92d050;
      &.minTitle {
        color: #ff7c5d;
      }
    }
  }
  .right {
    p {
      font-size: 10px;
      height: 40px;
      overflow: hidden;
    }
  }
`;

const StyledGrid = styled(Grid)`
  margin: 0px !important;
  margin-bottom: 14px !important;
  &.grid-margin-bottom {
    margin-bottom: 10px !important;
  }
  .column {
    padding: 6px 10px !important;
  }

  .column.chart-column {
    padding: 0px !important;

    .chartContainer {
      margin: 5px 0px 24px 0px;
    }
  }

  .chart-column--last .chartContainer {
    margin-bottom: 0px !important;
  }
`;

const IconDesc = styled.p`
  position: absolute;
  color: #9c9c9c;
  font-size: 8px;
  right: 0;
  bottom: -18px;
`;
