import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { Dropdown, Segment, Icon, Header, Confirm, Button } from "semantic-ui-react";
import { ManageBtns, ToolBtns } from "@styles/common";
import Breadcrumbs from "@Components/Common/Breadcrumbs";
import ManualAppPushModal from "@Components/Modals/ManualAppPushModal";
import { toastSuccess, toastError, getUserStatusColor } from "@util";
import { INIT_USER_PAGE } from "@graphql/query";
import { UPDATE_USER, REMOVE_USER, RESET_AND_INVITE_USER_ACCOUNT_FOR_USER, SET_USER_STEP } from "@graphql/mutation";
import { useMutation } from "@apollo/react-hooks";
import { IUser } from "@interfaces";
import SelfContext from "@selfContext";
import { useModal } from "@Hooks/useModal";
import { UserForm } from "@Components/Modals";

function UserSegment({ user, orgId, confrimContent }: { user: IUser; orgId: string; confrimContent: {} }) {
  const { self } = useContext(SelfContext);
  const [inviteConfirmOpen, setInviteConfirmOpen] = useState(false);
  const [removeConfirmOpen, setRemoveConfirmOpen] = useState(false);
  const [accountId, setAccountId] = useState();
  const history = useHistory();
  const { ModalPortal : UserModalPortal, openModal : openUserModal, closeModal : closeUserModal } =  useModal();
  
  useEffect(() => {
    if (!self || !self.user) return;
    if (self.user._id === user._id) {
      setAccountId(self._id);
    }
  }, [self]);

  // user 수정
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted(data) {
      toastSuccess("user 수정", `name : ${data.updateUser.name}`);
    },
    onError(error) {
      console.error("error", error);
      toastError("user 수정 실패.", error.message);
    },
    refetchQueries: [{ query: INIT_USER_PAGE, variables: { userId: user._id } }],
  });

  // user 수정 제출 
  const userSubmit = (data: any) => {
    updateUser({ variables: { userId: user._id, data: data } }); 
    closeUserModal();
  }

  // user 삭제
  const [removeUser] = useMutation(REMOVE_USER, {
    onCompleted(data) {
      toastSuccess("user 삭제", `name : ${data.removeUser.name}`);
    },
    onError(error) {
      console.error("error", error);
      toastError("user 삭제 실패.", error.message);
    },
  });

  async function handleUserRemove() {
    await removeUser({ variables: { userId: user._id, organizationId: orgId } });
    history.push(`/orgs/${orgId}/setting`);
  }

  // user status 수정
  const [setUserStep] = useMutation(SET_USER_STEP, {
    onCompleted(data) {
      toastSuccess("status 지정", `${data.setUserStep.status}`);
    },
    onError(error) {
      console.error("error", error);
      toastError("status 지정 실패.", error.message);
    },
    refetchQueries: [{ query: INIT_USER_PAGE, variables: { userId: user._id } }],
  });

  // 로그인 정보 발송
  const [inviteLoading, setInviteLoading] = useState(false);

  const [resetAndInviteUserAccountForUser] = useMutation(RESET_AND_INVITE_USER_ACCOUNT_FOR_USER, {
    onCompleted(data) {
      toastSuccess("로그인 정보 발송 성공");
      setInviteLoading(false);
      setInviteConfirmOpen(false);
    },
    onError(error) {
      console.error("error", error);
      toastError("로그인 정보 발송 실패", error.message);
      setInviteLoading(false);
      setInviteConfirmOpen(false);
    },
    refetchQueries: [{ query: INIT_USER_PAGE, variables: { userId: user._id } }],
  });

  async function handleInvite() {
    setInviteLoading(true);
    resetAndInviteUserAccountForUser({ variables: { userId: user._id } });
  }

  return (
    <>
      <Segment color={getUserStatusColor(user.status)}>
        <Header as="h2">
          {user.name}
          <Header.Subheader>
            <Breadcrumbs />
          </Header.Subheader>
        </Header>
        <Icon name="mail" />
        {user.email} {!!user?.mailList?.length && ` (${user.mailList.join(", ")})`}
        <br />
        <Icon name="phone" />
        {user.phone}
        <br />
        <Icon name="calendar" />
        {moment(user.createdAt).format("YYYY-MM-DD, h:mm a")}
        <br />
        status :{" "}
        <Dropdown
          inline
          options={[
            { key: "active", value: "active", text: "active" },
            { key: "new", value: "new", text: "new" },
            { key: "consulting", value: "consulting", text: "consulting" },
            { key: "filter", value: "filter", text: "filter" },
            { key: "paid", value: "paid", text: "paid" },
            { key: "outlier", value: "outlier", text: "outlier" },
            { key: "sponsor", value: "sponsor", text: "sponsor" },
          ]}
          value={user.status}
          onChange={(e, onChangeData) => {
            setUserStep({ variables: { userId: user._id, status: onChangeData.value } });
          }}
        />
        {user.statusUpdatedAt && <span>(변경일: {moment(user.statusUpdatedAt).format("YYYY-MM-DD, h:mm a")})</span>}
        <br />
        <ManageBtns>
          <Button size="mini" onClick={openUserModal}>
            <Icon name="edit" />
            Modify
          </Button>
          <Button size="mini" onClick={() => setRemoveConfirmOpen(true)}>
            <Icon name="trash" />
            Delete
          </Button>
          <Button size="mini" onClick={() => setInviteConfirmOpen(true)} disabled={user.status === "filter" ? true : false}>
            <Icon name="mail" />
            로그인 정보 발송 {user.lastInvitedTime && <span>(발송됨)</span>}
          </Button>
          <ManualAppPushModal type="user" name={user.name} id={user._id} status={user.status} />
        </ManageBtns>
        <Confirm
          open={inviteConfirmOpen}
          header={`로그인 정보 발송 (앱/유저대시보드)`}
          content={confrimContent}
          confirmButton={
            <Button primary loading={inviteLoading}>
              메일전송
            </Button>
          }
          cancelButton={<Button disabled={inviteLoading}>취소</Button>}
          onCancel={() => setInviteConfirmOpen(false)}
          onConfirm={handleInvite}
        />
        <Confirm
          open={removeConfirmOpen}
          header={`삭제`}
          content={`정말로 삭제하시겠습니까?`}
          cancelButton="취소"
          confirmButton={{
            content: "삭제",
            negative: true,
          }}
          onCancel={() => setRemoveConfirmOpen(false)}
          onConfirm={handleUserRemove}
        />
        <ToolBtns>
          <Link to={`/orgs/${orgId}/users/${user._id}/contracts`}>
            <Button size="mini">
              <Icon name="money bill alternate" />
              Contracts
            </Button>
          </Link>
        </ToolBtns>
      </Segment>
      <UserModalPortal title="User">
        <UserForm onSubmit={userSubmit} user={user} />
      </UserModalPortal>
    </>
  );
}

export default UserSegment;
