export type TKind = "awairScore" | "CO2" | "VOC" | "PM25" | "temp" | "humid";

const titles = {
  awairScore: "AwairScore",
  CO2: "CO2",
  VOC: "VOC",
  PM25: "PM25",
  temp: "Temperature",
  humid: "Humidity",
};

const getSeries = (kind: TKind, graphData: any, selectedSectionIDs: string, selectedErvIDs: string, isShowErvLog: boolean) => {
  const seriesAqi = JSON.parse(JSON.stringify(graphData[kind])).filter((cur: any) => selectedSectionIDs.includes(cur.sectionId));
  const seriesPredictionAqi = JSON.parse(JSON.stringify(graphData[`${kind}Prediction`])).filter((cur: any) => selectedSectionIDs.includes(cur.sectionId));

  const seriesERV = JSON.parse(JSON.stringify([...graphData["vsp"], ...graphData["fanspeed"]])).filter((cur: any) => selectedErvIDs.includes(cur.ervId));

  if (!isShowErvLog) {
    for (let i = 0; i < seriesERV.length; i++) {
      seriesERV[i].data = [];
    }
  }

  return [...seriesAqi, ...seriesPredictionAqi, ...seriesERV];
};

export const getChartOption = (kind: TKind, graphData: any, selectedSectionIDs: string, selectedErvIDs: string, isShowErvLog: boolean) => {
  const isVsp = graphData?.vsp?.length;
  const yAxis: any = [
    {
      title: {
        text: "",
      },
      labels: {
        style: {
          color: "#2abb9b",
        },
      },
      startOnTick: false,
    },
  ];

  if (isVsp) {
    yAxis.push({
      title: "vsp",
      labels: {
        style: {
          color: "#fabe58",
        },
      },
      max: 100,
      min: 0,
      gridLineColor: "transparent",
      opposite: true,
    });
  } else {
    yAxis.push({
      title: "fanSpeed",
      labels: {
        style: {
          color: "#fabe58",
        },
      },
      max: 3,
      min: 0,
      gridLineColor: "transparent",
      opposite: true,
    });
    yAxis.push({
      title: "ventLevel",
      labels: {
        style: {
          color: "#ffd215",
        },
      },
      max: 100,
      min: 0,
      gridLineColor: "transparent",
      opposite: true,
    });
  }

  const chartOptions: any = {
    chart: { type: "line", zoomType: "x" },
    title: {
      text: titles[kind],
      style: {
        "font-weight": "bold",
        fontSize: "18px",
      },
    },
    legend: {
      align: "left",
      layout: "horizontal",
      verticalAlign: "top",
    },
    yAxis: yAxis,
    xAxis: {
      type: "datetime",
      labels: {},
    },
    time: {
      timezoneOffset: 9,
      useUTC: false,
    },
    series: getSeries(kind, graphData, selectedSectionIDs, selectedErvIDs, isShowErvLog),
    // series : graphData[kind]
  };
  return chartOptions;
};
