import React from "react";
import type { ReactNode } from "react";
import { HvacItemProvider } from "./useHvacItemContext";
import { Label, Option, Description, CheckBox, ErvCtrl, Marker, Detail } from "./layouts";
import type { IErvCtrl, IFloor, IUser } from "@interfaces";
import { Item } from "semantic-ui-react";
import { useHvacItem } from "./useHvacItem";
import { HvacForm, ErvCtrlSelectorForm, ErvCtrlForm } from "@Components/Modals";

type TProps = {
  children: ReactNode;
  ervCtrl?: IErvCtrl;
  hvac?: any;
  user?: IUser;
  floor?: IFloor;
  onClick?: any;
};

function HvacItem({ children, ervCtrl, hvac, user, floor, onClick }: TProps) {
  const {
    hvacModifySubmit,
    HvacModalPortal,
    openHvacModal,
    handleRemove,
    handleUnallocate,
    ErvCtrlSelectorModalPortal,
    openErvCtrlSelector,
    ervCtrlSelectorSubmit,
    handleResetErvCtrl,
    ervCtrlModifySubmit,
    onControlInfoUpdateSubmit,
    ErvCtrlModalPortal,
    openErvCtrl,
    isOpenDetail,
    setIsOpenDetail,
    aiPerformance,
  } = useHvacItem({ ervCtrl, user, hvac, floor });
  return (
    <>
      <HvacItemProvider
        value={{
          ervCtrl,
          hvac,
          openHvacModal,
          handleRemove,
          handleUnallocate,
          openErvCtrlSelector,
          handleResetErvCtrl,
          openErvCtrl,
          isOpenDetail,
          setIsOpenDetail,
          aiPerformance,
        }}
      >
        <Item onClick={onClick}>
          <Item.Content>{children}</Item.Content>
        </Item>
      </HvacItemProvider>
      <HvacModalPortal title="HVAC">
        <HvacForm onSubmit={hvacModifySubmit} hvac={hvac} userName={user?.name} location={floor?.location} />
      </HvacModalPortal>
      <ErvCtrlSelectorModalPortal title="HVAC에 erv controller를 할당">
        <ErvCtrlSelectorForm onSubmit={ervCtrlSelectorSubmit} />
      </ErvCtrlSelectorModalPortal>
      <ErvCtrlModalPortal title={`ERV Controller (${hvac?.name} / ${ervCtrl?.id})`}>
        <ErvCtrlForm ervCtrl={ervCtrl} onSubmit={ervCtrlModifySubmit} onControlInfoUpdate={onControlInfoUpdateSubmit} />
      </ErvCtrlModalPortal>
    </>
  );
}

HvacItem.Label = Label;
HvacItem.Option = Option;
HvacItem.Description = Description;
HvacItem.CheckBox = CheckBox;
HvacItem.ErvCtrl = ErvCtrl;
HvacItem.Marker = Marker;
HvacItem.Detail = Detail;

export { HvacItem };
