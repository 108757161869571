export const getGradientStops = (boundaryNum: any, minValue: number, maxValue: number, isInverse = false) => {
  // 두개의 경계값으로 상,중,하 로 나눠짐
  let stopColors = ["#ff7c5d", "#ffc000", "#92d050"];
  let stopCenterColors = ["#FF9E2F", "#C9C828"];
  if (isInverse) {
    stopColors = ["#92d050", "#ffc000", "#ff7c5d"];
    stopCenterColors = ["#C9C828", "#FF9E2F"];
  }
  const stops = [];
  const boundaryCenter = (boundaryNum[1] + boundaryNum[0]) / 2;
  if (maxValue > boundaryNum[1]) {
    stops.push([0, stopColors[0]]);
    if (minValue > boundaryNum[1]) {
      //최대: 상, 최소: 상
      stops.push([1, stopColors[0]]);
    } else if (minValue > boundaryNum[0]) {
      //최대: 상, 최소: 중
      const stopVal = (boundaryNum[1] - minValue) / (maxValue - minValue);
      stops.push([stopVal, stopCenterColors[0]]);
      stops.push([1, stopColors[1]]);
    } else {
      //최대: 상, 최소: 하
      const stopVal = (boundaryCenter - minValue) / (maxValue - minValue);
      stops.push([stopVal, stopColors[1]]);
      stops.push([1, stopColors[2]]);
    }
  } else if (maxValue > boundaryNum[0]) {
    stops.push([0, stopColors[1]]);
    if (minValue > boundaryNum[0]) {
      //최대: 중, 최소: 중
      stops.push([1, stopColors[1]]);
    } else {
      //최대: 중, 최소: 하
      const stopVal = (boundaryNum[0] - minValue) / (maxValue - minValue);
      stops.push([stopVal, stopCenterColors[1]]);
      stops.push([1, stopColors[2]]);
    }
  } else {
    //최대: 하, 최소: 하
    stops.push([0, stopColors[2]]);
    stops.push([1, stopColors[2]]);
  }
  return stops;
};

export const getPlotBandsData = (kind: "awairScore" | "CO2" | "VOC" | "PM25" | "temperature" | "humidity", isShowPlotBands: any) => {
  if (!isShowPlotBands) {
    return [];
  }

  if (kind === "awairScore") {
    return [
      { color: "rgb(255, 124, 93, 0.5)", from: 0, to: 60 },
      { color: "rgb(255, 192, 0, 0.5)", from: 60, to: 80 },
      { color: "rgb(146, 208, 80, 0.5)", from: 80, to: 100 },
    ];
  } else if (kind === "CO2") {
    return [
      { color: "rgb(255, 124, 93, 0.5)", from: 1500, to: 9999 },
      { color: "rgb(255, 192, 0, 0.5)", from: 600, to: 1500 },
      { color: "rgb(146, 208, 80, 0.5)", from: 0, to: 600 },
    ];
  } else if (kind === "VOC") {
    return [
      { color: "rgb(255, 124, 93, 0.5)", from: 1000, to: 9999 },
      { color: "rgb(255, 192, 0, 0.5)", from: 600, to: 1000 },
      { color: "rgb(146, 208, 80, 0.5)", from: 0, to: 600 },
    ];
  } else if (kind === "PM25") {
    return [
      { color: "rgb(255, 124, 93, 0.5)", from: 55, to: 999 },
      { color: "rgb(255, 192, 0, 0.5)", from: 35, to: 55 },
      { color: "rgb(146, 208, 80, 0.5)", from: 0, to: 35 },
    ];
  } else if (kind === "temperature") {
    return [
      { color: "rgb(255, 124, 93, 0.5)", from: 0, to: 21 },
      { color: "rgb(255, 192, 0, 0.5)", from: 21, to: 24 },
      { color: "rgb(146, 208, 80, 0.5)", from: 24, to: 27 },
      { color: "rgb(255, 192, 0, 0.5)", from: 27, to: 30 },
      { color: "rgb(255, 124, 93, 0.5)", from: 30, to: 40 },
    ];
  } else if (kind === "humidity") {
    return [
      { color: "rgb(255, 124, 93, 0.5)", from: 0, to: 30 },
      { color: "rgb(255, 192, 0, 0.5)", from: 30, to: 40 },
      { color: "rgb(146, 208, 80, 0.5)", from: 40, to: 60 },
      { color: "rgb(255, 192, 0, 0.5)", from: 60, to: 80 },
      { color: "rgb(255, 124, 93, 0.5)", from: 80, to: 140 },
    ];
  }
};
