import moment from "moment";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Button, Header, Icon, Segment, Table, Confirm, Menu, Input } from "semantic-ui-react";
import { EmptyMessage } from "@styles/common";
import { INIT_EVENT_DASHBOARD_PAGE } from "@graphql/query";
import { FINISH_VISIT_REQUEST, UPDATE_VISIT_REQUEST } from "@graphql/mutation";
import { useMutation } from "@apollo/react-hooks";
import { toastSuccess, toastError } from "@util";
import SelfContext from "@selfContext";
import styled from "styled-components";
import mediaQueries from "@styles/mediaQueries";

type TDeliveryEventList = {
  deliveryEvents: any;
  orgId: any;
  managerOptions: any;
  managerItems: any;
};

function DeliveryEventList({ deliveryEvents, orgId, managerOptions, managerItems }: TDeliveryEventList) {
  const { self } = useContext(SelfContext);
  const [selectedEventsType, setSelectedEventsType] = useState("notFinished");

  return (
    <Segment>
      <Header as="h3">배송 목록</Header>
      <Table celled compact striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>분류</Table.HeaderCell>
            <Table.HeaderCell>신청자</Table.HeaderCell>
            <Table.HeaderCell>장소</Table.HeaderCell>
            {/* <Table.HeaderCell>매니저</Table.HeaderCell> */}
            <Table.HeaderCell>상태</Table.HeaderCell>
            <Table.HeaderCell>신청일자</Table.HeaderCell>
            {/* <Table.HeaderCell>배송일자</Table.HeaderCell> */}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {deliveryEvents.length ? (
            deliveryEvents.map((cur: any, index: any) => {
              return (
                <Table.Row key={index}>
                  <Table.Cell>{cur.type}</Table.Cell>
                  <Table.Cell>
                    <Link to={`/orgs/${orgId}/users/${cur.userId}`}>{cur.userName}</Link>
                  </Table.Cell>
                  <Table.Cell>{`${cur.kaptName} ${cur.dong} ${cur.ho}`}</Table.Cell>
                  <Table.Cell>{cur.status}</Table.Cell>
                  <Table.Cell>{moment(cur.createdAt).locale("ko").format("MM-DD (dd) a hh:mm")}</Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan="8">
                <EmptyMessage>Empty</EmptyMessage>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Segment>
  );
}

export default DeliveryEventList;

const SearchInput = styled(Input)`
  position: absolute !important;
  top: 6px;
  right: 14px;
  width: 160px;
  input {
    padding: 0 0;
    font-size: 1rem;
  }
  ${mediaQueries("sm")`
     position: relative !important;
    margin: 4px 14px;
    width: 100%;
  `}
`;
