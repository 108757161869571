import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Grid, Segment, Icon, Header, Button, Loader, Sticky, Rail, Ref } from "semantic-ui-react";
import DefaultLayout from "@Layouts/DefaultLayout";
import { toastSuccess, toastError } from "@util";
import { Helmet } from "react-helmet";
import SankeyDiagram from "@Components/Common/Charts/SankeyDiagram";
import WeightChangeControl from "@Components/Floor/WeightChangeControl";
import { INIT_FLOOR_PAGE } from "@graphql/query";
import { SET_DEFAULT_SYSTEM_GROUP, UPDATE_SYSTEM_GROUP } from "@graphql/mutation";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { cloneDeep } from "lodash";
import SectionSegment from "@Components/User/SectionsSegment";
import HvacsSegment from "@Components/User/HvacsSegment";
import Breadcrumbs from "@Components/Common/Breadcrumbs";
import styled from "styled-components";
import { Scores, useScores } from "@Components/Scores";

function Floor() {
  const { floorId, userId }: { floorId: string; userId: string } = useParams();
  const [systemGroup, setSystemGroup] = useState();
  const [relations, setRelations] = useState<any>();
  const [nodes, setNodes] = useState();
  const [changeRowList, setChangeRowList] = useState<any>([]);
  const [links, setLinks] = useState<any>();
  const scores = useScores({ floorId });
  const stickyRef = useRef();

  let chart;

  const { loading, data } = useQuery(INIT_FLOOR_PAGE, {
    variables: { floorId: floorId, userId: userId },
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!data) return;
    data.floor?.systemGroup?.nodes && setNodes(data.floor.systemGroup.nodes);
    data.floor?.systemGroup?.relations && setRelations(data.floor.systemGroup.relations);
  }, [data]);

  useEffect(() => {
    if (!relations) return;
    const newLinks = [["From", "To", "Weight", { type: "string", role: "style" }, "relationType", "subIndex"]];
    const relationTypes = Object.keys(relations);
    const idx = relationTypes.findIndex((cur) => cur === "__typename");
    relationTypes.splice(idx, 1); // __typename 제거
    for (const relationType of relationTypes) {
      const [prevNodeGroup, nextNodeGroup] = [relationType.split("To")[0].toLowerCase(), relationType.split("To")[1].toLowerCase()];

      if (!relations[relationType]) continue;
      newLinks.push(
        ...relations[relationType].links.map((link: any, idx: Number) => {
          const fromName = link.from.nodeModel === "location" ? link.from.node?.kaptInfo?.kaptName : link.from.node.name;
          const toName = link.to.nodeModel === "location" ? link.to.node?.kaptInfo?.kaptName : link.to.node.name;
          return [`${prevNodeGroup}-${fromName}`, `${nextNodeGroup}-${toName}`, link.weight, "opacity:0.2", relationType, idx];
        })
      );
    }

    (async () => {
      setLinks(await labelActivatedLinks(newLinks, changeRowList));
    })();
  }, [relations]);

  // Set Default
  const [setDefaultSystemGroup, { loading: defaultLoading }] = useMutation(SET_DEFAULT_SYSTEM_GROUP, {
    onCompleted(data) {
      setSystemGroup(data.systemGroup);
      toastSuccess("systemGroup 초기화");
    },
    onError(error) {
      console.error("error", error);
      toastError("systemGroup 초기화 실패.", error.message);
    },
    refetchQueries: [{ query: INIT_FLOOR_PAGE, variables: { floorId: floorId, userId: userId } }],
  });

  async function handleSetDefaultSystemGroup() {
    setDefaultSystemGroup({ variables: { floorId: floorId } });
  }

  // 슬라이더 이동시
  function handleChangeRowList(value: Number, idx: any) {
    const newRowList = changeRowList;
    Object.assign(newRowList[idx], { value: value });
    setChangeRowList([...newRowList]);
    handleApplyRelations();
  }
  function handleApplyRelations() {
    const newRelations = cloneDeep(relations);
    changeRowList.map((list: any) => {
      const link = links[list.row];

      newRelations[link[4]].links[link[5]].weight = list.value;
    });
    setRelations(newRelations);
  }

  // updateSystemGroup
  // 챠트 선택시
  function handleOnLinksSelected(rowList: any, chartWrapper: any) {
    // setChangeRowList([...rowList]);
    const newRows: any = [];
    rowList.forEach((rowItem: any) => {
      if (!changeRowList.find((cur: any) => cur.row === rowItem.row)) {
        newRows.push(rowItem);
      }
    });
    setChangeRowList([...changeRowList, ...newRows]);
    if (chartWrapper) chart = chartWrapper;
  }

  async function labelActivatedLinks(links: any, rowList: any) {
    if (!links) return;
    const newLinks = links;
    await newLinks.map((link: any, idx: any) => {
      if (idx === 0) return link;
      link[3] = "opacity:0.2";
      return link;
    });
    await rowList.map((list: any) => {
      newLinks[list.row][3] = "opacity:1";
    });
    return [...newLinks];
  }

  const [updateSystemGroup] = useMutation(UPDATE_SYSTEM_GROUP, {
    onCompleted(data) {
      toastSuccess("systemGroup 변경 반영");
    },
    onError(error) {
      console.error("error", error);
      toastError("systemGroup update 실패", error.message);
    },
  });

  async function handleSubmit() {
    setChangeRowList([]);
    updateSystemGroup({ variables: { systemGroupId: data.floor.systemGroup._id, data: { relations: relations } } });
  }

  if (loading)
    return (
      <DefaultLayout>
        <Loader active />
      </DefaultLayout>
    );

  if (!nodes || !relations) {
    return (
      <DefaultLayout>
        <>systemGroup 정보가 없습니다.</>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <>
        <Helmet>
          <title>(floor)</title>
        </Helmet>
        <Segment>
          <Header as="h2">
            System Group
            <Header.Subheader>
              <Breadcrumbs />
            </Header.Subheader>
          </Header>
          {data && (
            <div>
              <Icon name="user" /> {data.user.name}
            </div>
          )}
          <div>floor: {data.floor?.name}</div>
        </Segment>

        <Scores>
          <Scores.Col>
            <Scores.Item label="Temperature">{scores.temperature}</Scores.Item>
            <Scores.Item label="Humidity">{scores.humidity}</Scores.Item>
            <Scores.Item label="CO2">{scores.co2}</Scores.Item>
            <Scores.Item label="TVOC">{scores.voc}</Scores.Item>
            <Scores.Item label="PM25">{scores.pm25}</Scores.Item>
          </Scores.Col>
        </Scores>

        <Ref innerRef={stickyRef}>
          <Segment>
            {links && <SankeyDiagram links={links} onLinksSelected={handleOnLinksSelected} />}
            <Rail internal position="right">
              <Sticky context={stickyRef}>
                <Panner>
                  <Button className="default-btn" disabled={defaultLoading} size="mini" onClick={handleSetDefaultSystemGroup}>
                    <Icon name="repeat" />
                    Set Default
                  </Button>

                  {links &&
                    changeRowList.map((row: any, idx: any) => (
                      <WeightChangeControl key={idx} link={[...links[row.row]]} row={row} idx={idx} onChange={handleChangeRowList} />
                    ))}
                  {!!changeRowList.length && (
                    <Button primary size="mini" onClick={handleSubmit}>
                      <Icon name="expand arrows alternate" />
                      Apply
                    </Button>
                  )}
                </Panner>
              </Sticky>
            </Rail>
          </Segment>
        </Ref>
        <Grid>
          <Grid.Column mobile={16} computer={8}>
            <SectionSegment curFloor={data.floor} user={data.user} />
          </Grid.Column>
          <Grid.Column mobile={16} computer={8}>
            <HvacsSegment curFloor={data.floor} user={data.user} />
          </Grid.Column>
        </Grid>
      </>
    </DefaultLayout>
  );
}
export default Floor;

const Panner = styled.div`
  margin-top: 20px;
  text-align: right;

  .default-btn.button {
    margin-bottom: 10px;
  }
`;
