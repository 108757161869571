import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import DefaultLayout from "@Layouts/DefaultLayout";
import Breadcrumbs from "@Components/Common/Breadcrumbs";
import moment from "moment";
import ConsultingItem from "@Components/ConsultingReport/ConsultingItem";
import { Segment, Header, Loader } from "semantic-ui-react";
import { INIT_CONSULTING_REPORT_PAGE } from "@graphql/query";
import { useLazyQuery } from "@apollo/react-hooks";

function ConsultingReport() {
  const { userId, floorId } = useParams();
  const [startDate, setStartDate] = useState(moment().subtract(7, "days").startOf("day"));
  const [endDate, setEndDate] = useState(moment().subtract(1, "days").endOf("day"));

  const [initConsultingReportPage, { loading, data }] = useLazyQuery(INIT_CONSULTING_REPORT_PAGE);

  useEffect(() => {
    initConsultingReportPage({ variables: { userId: userId, from: startDate.valueOf(), to: endDate.valueOf() } });
    // }, [startDate]);
  }, [startDate, endDate]);

  const handleStartDateChange = (date) => {
    setStartDate(moment(date).startOf("day"));
    // setEndDate(moment(date).add(14, "days").endOf("day"));
  };

  const handleEndDateChange = (date) => {
    // setStartDate(moment(date).subtract(14, "days").startOf("day"));
    setEndDate(moment(date).endOf("day"));
  };

  if (loading)
    return (
      <DefaultLayout>
        <Loader active />
      </DefaultLayout>
    );

  if (!data) return <DefaultLayout></DefaultLayout>;

  return (
    <DefaultLayout isFixedWidth={true}>
      <Helmet>
        <title>{data.user.name}(Consulting Report)</title>
      </Helmet>
      <Segment>
        <Header as="h2">
          {data.user.name}
          <Header.Subheader>
            <Breadcrumbs />
          </Header.Subheader>
        </Header>
      </Segment>
      {data.user.floors.filter((floor) => floor && floor._id === floorId).length &&
        data.user.floors
          .filter((floor) => floor && floor._id === floorId)[0]
          .sections.map((section) => (
            <div key={section._id}>
              <ConsultingItem
                section={section}
                startDate={startDate}
                endDate={endDate}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
                name={data.user.name}
              />
            </div>
          ))}
    </DefaultLayout>
  );
}

export default ConsultingReport;
