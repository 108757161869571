import React from "react";
import { useHistory } from "react-router-dom";
import SignupForm from "@Components/Signup/SignupForm";
import { SUPERADMIN_SIGNUP_KEY } from "@constants";
import AuthLayout from "@Layouts/AuthLayout";
import { toastError, toastSuccess, validateEmail } from "@util";
import { Helmet } from "react-helmet";
import { SIGNUP_SUPERADMIN } from "@graphql/mutation";
import { useMutation } from "@apollo/react-hooks";

function Signup() {
  const history = useHistory();

  // super admin 가입
  const [signupSuperAdmin] = useMutation(SIGNUP_SUPERADMIN, {
    onCompleted(data) {
      toastSuccess("회원가입이 되었습니다.", `관리자 승인후 로그인이 가능합니다. 회원 ID : ${data.signupSuperAdmin.id}`);
      history.push("/login");
    },
    onError(error) {
      console.error(error);
      toastError("회원가입 실패.", error.message);
    },
  });

  const onSubmit = async (data: onSubmitProps) => {
    const phoneRole = /^\d{3}-\d{3,4}-\d{4}$/;
    if (!phoneRole.test(data.phone)) {
      toastError("입력오류", "휴대폰 번호가 유효하지 않습니다.");
      return false;
    }

    if (!validateEmail(data.email)) {
      toastError("입력오류", "이메일이 유효하지 않습니다.");
      return false;
    }

    signupSuperAdmin({ variables: { key: SUPERADMIN_SIGNUP_KEY, data: data } });
  };

  return (
    <AuthLayout>
      <Helmet>
        <title>Sign up</title>
      </Helmet>
      <SignupForm onSubmit={onSubmit} />
      <a href="/login">Login</a>
    </AuthLayout>
  );
}

export default Signup;

type onSubmitProps = {
  id: string;
  password: string;
  name: string;
  phone: string;
  email: string;
};
