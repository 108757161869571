import React from "react";
import { Table } from "semantic-ui-react";
import FilterItem from "@Components/Common/FilterItem";
import { EmptyMessage } from "@styles/common";
import { IFilter } from "@interfaces";

type TFilterTable = {
  medium: string;
  filters: IFilter[];
  handleFilterCart?: Function;
};

function FilterTable({ medium, filters, handleFilterCart }: TFilterTable) {
  return (
    <Table compact="very" celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>index</Table.HeaderCell>
          <Table.HeaderCell>specification</Table.HeaderCell>
          <Table.HeaderCell>location</Table.HeaderCell>
          <Table.HeaderCell>stock</Table.HeaderCell>
          {/* <Table.HeaderCell>new stock</Table.HeaderCell> */}
          {/* <Table.HeaderCell>cart</Table.HeaderCell> */}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {filters && filters.length > 0 ? (
          filters.map((filter: IFilter, index: number) =>
            filter.medium === medium || medium === "any" ? (
              // <FilterItem key={filter._id} index={index} filter={filter} handleSubmit={handleFilterCart} />
              <FilterItem key={filter._id} index={index} filter={filter} />
            ) : null
          )
        ) : (
          <Table.Row>
            <Table.Cell colSpan="4">
              <EmptyMessage>Empty</EmptyMessage>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
}

export default FilterTable;
