import React, { useState } from "react";
import { Rating, Select, Button, Form, Input, Modal, Icon, Label } from "semantic-ui-react";
import { validateEmail } from "@util";
import InputRange from "react-input-range";
import styled from "styled-components";
import ModifyPasswordModal from "./ModifyPasswordModal";
import { IUser } from "@interfaces";

type TProps = {
  user?: IUser;
  accountId? : string;
  onSubmit: Function;
};

export const UserForm = ({ onSubmit, user, accountId}: TProps) => {
  const [name, setName] = useState(user?.name);
  const [phone, setPhone] = useState(user?.phone);
  const [email, setEmail] = useState(user?.email);
  const [currentMailListItem, setCurrentMailListItem] = useState("");
  const [mailList, setMailList] = useState<string[]>(user?.mailList || []);

  const [sleepTime, setSleepTime] = useState<any>( user?.profile?.sleepTime || { from: 23, to: 7 });
  const [numOfFamilyMembers, setNumOfFamilyMembers] = useState( user?.profile?.numOfFamilyMembers || 3);
  const [aqiSensitivity, setAqiSensitivity] = useState<any>( user?.profile?.aqiSensitivity || "mid" );
  const [noiseSensitivity, setNoiseSensitivity] = useState<any>( user?.profile?.noiseSensitivity || "mid" );
  const [windowOpenFrequency, setWindowOpenFrequency] = useState<any>( user?.profile?.windowOpenFrequency || "mid" );
  const [needs, setNeeds] = useState( user?.profile?.needs );
  const ratings = ["very low", "low", "mid", "high", "very high"];

  function addMailList(e: any) {
    e.preventDefault();
    if (!validateEmail(currentMailListItem)) return;
    if (mailList.includes(currentMailListItem)) return;
    setMailList([...mailList, currentMailListItem]);
    setCurrentMailListItem("");
  }

  function handleMailListItemRemove(email: string) {
    const newMailList = mailList.filter((cur) => cur !== email);
    setMailList(newMailList);
  }

  const onChangePhone = (e: any) => {
    const strOriginal = e.target.value.replace(/[^0-9]/g, "");
    setPhone(strOriginal.replace(/(^01.{1})([0-9]+)([0-9]{4})/, "$1-$2-$3"));
  };

  return (
      <Modal.Content>
        <Form
          onSubmit={() =>
            onSubmit({
              name,
              phone,
              email,
              mailList,
              profile: { sleepTime, numOfFamilyMembers, aqiSensitivity, noiseSensitivity, windowOpenFrequency, needs },
            })
          }
        >
          <Form.Group>
            <Form.Field required>
              <label>Name</label>
              <Input type="name" placeholder="name" value={name} onChange={(e) => setName(e.target.value)} required={true} />
            </Form.Field>
            <Form.Field required>
              <label>Phone</label>
              <Input type="phone" placeholder="phone" value={phone} onChange={onChangePhone} maxLength={13} />
            </Form.Field>

            <Form.Field required>
              <label>Email</label>
              <Input type="email" placeholder="email" value={email} onChange={(e) => setEmail(e.target.value)} required={true} disabled={!!user}/>
            </Form.Field>
            {accountId && (
              <Form.Field style={{ paddingTop: 10, minHeight: 40 }}>
                <ModifyPasswordModal accountId={accountId} />
              </Form.Field>
            )}
          </Form.Group>

          <Form.Field>
            <label>mailList</label>
            <Input
              type="email"
              placeholder="추가 이메일 등록"
              value={currentMailListItem}
              onChange={(e) => setCurrentMailListItem(e.target.value)}
              required={false}
              action={{ icon: "plus", onClick: (e: any) => addMailList(e) }}
            />
            <div style={{ margin: "20px 0" }}>
              {mailList.map((email, idx) => (
                <Label key={idx} style={{ marginBottom: 4 }}>
                  {email}
                  <Icon name="delete" onClick={() => handleMailListItemRemove(email)} />
                </Label>
              ))}
            </div>
          </Form.Field>

          <Form.Group>
            <Form.Field style={{ width: "100%" }}>
              <label>기상/취침 시간</label>
              <InputRangeWrap>
                <InputRange
                  minValue={0}
                  maxValue={24}
                  value={{ min: sleepTime.to, max: sleepTime.from }}
                  onChange={(value: any) => setSleepTime({ from: value.max, to: value.min })}
                />
              </InputRangeWrap>
            </Form.Field>
            <Form.Field>
              <label>가족 구성원 수</label>
              <Select
                value={numOfFamilyMembers}
                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((opt) => {
                  return { key: opt, text: opt + "명", value: opt };
                })}
                onChange={(e, data: any) => setNumOfFamilyMembers(data.value)}
              />
            </Form.Field>
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Field>
              <label>공기질 민감도: {aqiSensitivity}</label>
              <Rating
                icon="heart"
                rating={ratings.findIndex((val) => val === aqiSensitivity) + 1}
                maxRating={5}
                onRate={(e, data: any) => {
                  setAqiSensitivity(ratings[data.rating - 1]);
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>소음 민감도 {noiseSensitivity}</label>
              <Rating
                icon="heart"
                rating={ratings.findIndex((val) => val === noiseSensitivity) + 1}
                maxRating={5}
                onRate={(e, data: any) => {
                  setNoiseSensitivity(ratings[data.rating - 1]);
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>창문환기 빈도 {windowOpenFrequency}</label>
              <Rating
                icon="heart"
                rating={ratings.findIndex((val) => val === windowOpenFrequency) + 1}
                maxRating={5}
                onRate={(e, data: any) => {
                  setWindowOpenFrequency(ratings[data.rating - 1]);
                }}
              />
            </Form.Field>
          </Form.Group>

          <Button type="submit" fluid>
            Submit
          </Button>
        </Form>
      </Modal.Content>
  );
}

const InputRangeWrap = styled.div`
  width: 90%;
  padding-top: 12px;
  margin-left: 10px;
  margin-right: 20px;
  .input-range__track--active,
  .input-range__slider {
    background: #2185d0;
  }
  .input-range__slider {
    border: 1px solid #2185d0;
  }
`;
