import React from "react";
import styled from "styled-components";
import { WideContainer } from "@styles/common";
import mediaQueries from "@styles/mediaQueries";

function Footer() {
  return (
    <StyledFooter>
      <WideContainer>
        법인명 : 퍼핀플래닛 주식회사 | 대표자 : 선강민 | 사업자 등록번호 : 237-87-01276
        <br />
        사업장 소재지 : 대전광역시 유성구 대학로 291 8층
        <br />
        이메일 : hello@puffinplace.com | 연락처 : 02-508-5225
        <br />
        통신판매업신고번호 : 2020-대전유성-0726 | 개인정보관리책임자 : 선강민
      </WideContainer>
    </StyledFooter>
  );
}

export default Footer;

const StyledFooter = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #666a73;
  color: #fff;
  padding: 3rem 0;
  ${mediaQueries("sm")`
    font-size: 0.7rem;
    padding: 3rem 1rem;
  `}
`;
