import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Segment, Icon, Header, Item, Loader, Card, Button } from "semantic-ui-react";
import DefaultLayout from "@Layouts/DefaultLayout";
import { Helmet } from "react-helmet";
import { INIT_POSITION_PAGE } from "@graphql/query";
import { useQuery } from "@apollo/react-hooks";
import Breadcrumbs from "@Components/Common/Breadcrumbs";
import styled from "styled-components";
import { Scores, useScores } from "@Components/Scores";
import ImageMarker, { Marker } from "react-image-marker";
import { SectionCard } from "@Components/SectionCard";
import { ISection, IErv } from "@interfaces";
import { HvacItem } from "@Components/HvacItem";
import { ADD_IMAGE, UPDATE_FLOOR } from "@graphql/mutation";
import { useMutation } from "@apollo/react-hooks";
import { toastSuccess, toastError } from "@util";
import { EmptyMessage } from "@styles/common";

function Position() {
  const { floorId, userId }: { floorId: string; userId: string } = useParams();
  const scores = useScores({ floorId });
  const [markers, setMarkers] = useState<any>([]);
  const [selectedSectionId, setSelectedSectionId] = useState<String>();
  const [selectedHvacId, setSelectedHvacId] = useState<String>();

  const [updateFloor] = useMutation(UPDATE_FLOOR, {
    onError(error) {
      console.error("error", error);
      toastError("floor 수정 실패.", error.message);
    },
    refetchQueries: [{ query: INIT_POSITION_PAGE, variables: { floorId: floorId, userId: userId } }],
  });

  const [addImage] = useMutation(ADD_IMAGE, {
    onCompleted(data) {
      console.log("data", data);
      if (!data.addImage) return;
      updateFloor({ variables: { floorId: floorId, data: { image: data.addImage._id } } });
    },
    onError(error) {
      toastError(`이미지 등록에 실패했습니다.`);
      console.error(error.message);
    },
  });

  const { loading, data } = useQuery(INIT_POSITION_PAGE, {
    variables: { floorId: floorId, userId: userId },
    onCompleted(data) {
      setMarkers(
        data?.floor?.positions?.map((cur: any) => ({
          status: "active",
          id: cur.node,
          type: cur.nodeModel,
          left: cur.left,
          top: cur.top,
        })) || []
      );
    },
    onError(error) {
      console.error(error);
    },
  });

  const getMarkersForDB = (markers: any) => {
    return (
      markers.map((cur: any) => ({
        nodeModel: cur.type,
        node: cur.id,
        left: cur.left,
        top: cur.top,
      })) || []
    );
  };

  const selectMarker = (type: "section" | "erv", id: String) => {
    const readyMarker = markers.find((cur: any) => cur.status === "ready");
    if (!readyMarker) return;
    readyMarker.status = "active";
    readyMarker.type = type;
    readyMarker.id = id;

    const currentMarkers = markers.filter((cur: any) => {
      return cur.status !== "ready" && cur?.id !== id;
    });

    setMarkers([...currentMarkers, readyMarker]);

    const currentPositions = getMarkersForDB(currentMarkers);

    // 일단 floor.positions 추가
    const newPosition = {
      nodeModel: readyMarker.type,
      node: readyMarker.id,
      left: readyMarker.left,
      top: readyMarker.top,
    };
    console.log("newPosition", newPosition);
    updateFloor({ variables: { floorId: floorId, data: { positions: [...currentPositions, newPosition] } } });
  };

  const closeMarkerSelector = () => {
    setMarkers(markers.filter((cur: any) => cur.status !== "ready"));
  };

  const activeMarker = (type: "section" | "erv", id: String) => {
    setMarkers(markers.filter((cur: any) => cur.status !== "ready"));
    type === "section" && setSelectedSectionId(id);
    type === "erv" && setSelectedHvacId(id);
  };

  const checkIsActiveMarker = (type: "section" | "erv", id: string) => {
    if (type === "section" && id === selectedSectionId) return true;
    if (type === "erv" && id === selectedHvacId) return true;
    return false;
  };

  const getCurrentItemNumber = (type: "section" | "erv", id: string) => {
    const currentMarkerIndex = markers.findIndex((cur: any) => cur.id === id);
    if (currentMarkerIndex === -1) return null;
    return currentMarkerIndex;
  };

  const removeMarker = (id: string) => {
    const removedMarkers = markers.filter((cur: any) => cur.id !== id);
    const removedPositions = getMarkersForDB(removedMarkers);

    setMarkers(removedMarkers);
    updateFloor({ variables: { floorId: floorId, data: { positions: removedPositions } } });
  };

  const CustomMarker = (props: any) => {
    if (props.status === "ready") {
      // sections
      const Sections = data.floor.sections
        .filter((cur: any) => cur.status === "active")
        .map((cur: any) => {
          return (
            <SelectorItem key={cur._id} onClick={() => selectMarker("section", cur._id)}>
              {cur.name}
            </SelectorItem>
          );
        });

      //HVACs
      const Hvacs = data.floor.ervs
        .filter((cur: any) => cur.status === "active")
        .map((cur: any) => {
          return (
            <SelectorItem key={cur._id} onClick={() => selectMarker("erv", cur._id)}>
              {cur.name}
            </SelectorItem>
          );
        });

      return (
        <Card>
          <Card.Content>
            <CloseBtn onClick={closeMarkerSelector}>
              <Icon name="close" size="large" />
            </CloseBtn>
          </Card.Content>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <SelectorHeader>Sections</SelectorHeader>
                {Sections}
              </Grid.Column>
              <Grid.Column>
                <SelectorHeader>HVACs</SelectorHeader>
                {Hvacs}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card>
      );
    }
    if (props.status === "active" && props.type === "section") {
      return (
        <StyledMarker onClick={() => activeMarker(props.type, props.id)} className={selectedSectionId === props.id ? "section active" : "section"}>
          {props.itemNumber}
        </StyledMarker>
      );
    }
    if (props.status === "active" && props.type === "erv") {
      return (
        <StyledMarker onClick={() => activeMarker(props.type, props.id)} className={selectedHvacId === props.id ? "hvac active" : "hvac"}>
          {props.itemNumber}
        </StyledMarker>
      );
    }
    return null;
  };

  const addMarker = (marker: Marker) => {
    setMarkers([...markers.filter((cur: any) => cur.status !== "ready"), { ...marker, status: "ready" }]);
  };

  const handleImageUpload = async (e: any) => {
    const files = e.target.files;
    if (!files || files.length !== 1) return;
    const file = files[0];
    await addImage({ variables: { adminId: userId, file: file } });
  };

  if (loading)
    return (
      <DefaultLayout>
        <Loader active />
      </DefaultLayout>
    );

  return (
    <DefaultLayout>
      <Helmet>
        <title>(position)</title>
      </Helmet>
      <Segment>
        <Header as="h2">
          Position
          <Header.Subheader>
            <Breadcrumbs />
          </Header.Subheader>
        </Header>
        {data && (
          <div>
            <Icon name="user" /> {data.user.name}
          </div>
        )}
        <div>floor: {data.floor?.name}</div>
      </Segment>

      <Scores>
        <Scores.Col>
          <Scores.Item label="AQI Accuracy">{scores.aqiAccuracy}</Scores.Item>
          <Scores.Item label="AQI Prediction">{scores.aqiPrediction}</Scores.Item>
          <Scores.Item label="PM Score">{scores.pmScore}</Scores.Item>
          <Scores.Item label="AQI Score">{scores.aqiScore}</Scores.Item>
          <Scores.Item label="Energy Score">{scores.energyScore}</Scores.Item>
        </Scores.Col>
        <Scores.Col>
          <Scores.Item label="Temperature">{scores.temperature}</Scores.Item>
          <Scores.Item label="Humidity">{scores.humidity}</Scores.Item>
          <Scores.Item label="CO2">{scores.co2}</Scores.Item>
          <Scores.Item label="TVOC">{scores.voc}</Scores.Item>
          <Scores.Item label="PM25">{scores.pm25}</Scores.Item>
        </Scores.Col>
        <Scores.Col>
          <Scores.Item label="Temperature<br />Prediction">{scores.temperaturePrediction}</Scores.Item>
          <Scores.Item label="Humidity<br />Prediction">{scores.humidityPrediction}</Scores.Item>
          <Scores.Item label="CO2<br />Prediction">{scores.co2Prediction}</Scores.Item>
          <Scores.Item label="TVOC<br />Prediction">{scores.vocPrediction}</Scores.Item>
          <Scores.Item label="PM25<br />Prediction">{scores.pm25Prediction}</Scores.Item>
        </Scores.Col>
      </Scores>

      <Segment>
        <UploadImage>
          {data?.floor?.image?.url ? (
            <Button as="label" htmlFor="input-file">
              이미지 수정
            </Button>
          ) : (
            <Button as="label" htmlFor="input-file">
              이미지 등록
            </Button>
          )}
          <input type="file" id="input-file" onChange={(e) => handleImageUpload(e)} />
        </UploadImage>
        {/* <MapImage src="/sample_map.jpg" alt="sample_map" /> */}
        {data?.floor?.image?.url && <ImageMarker src={data.floor.image.url} markers={markers} onAddMarker={addMarker} markerComponent={CustomMarker} />}
      </Segment>

      <Grid>
        <Grid.Column mobile={16} computer={8}>
          <Segment>
            <Header>Sections</Header>
            {!data.floor?.sections?.length ? (
              <EmptyMessage>Empty</EmptyMessage>
            ) : (
              <Card.Group itemsPerRow={2}>
                {data.floor?.sections?.map((section: ISection) => (
                  <SectionCard key={section._id} awairs={section.awairs} section={section} user={data.user} floor={data.floor}>
                    <Card.Content>
                      <SectionCard.Label>{section.name}</SectionCard.Label>
                      <SectionCard.Awair noEdit={true} />
                      <SectionCard.Description profile={section.profile} />
                      <SectionCard.Marker
                        isActive={checkIsActiveMarker("section", section._id)}
                        itemNumber={getCurrentItemNumber("section", section._id)}
                        removeMarker={() => removeMarker(section._id)}
                      />
                    </Card.Content>
                    <Card.Content extra>
                      <SectionCard.DetailBtns />
                      <SectionCard.Detail />
                    </Card.Content>
                  </SectionCard>
                ))}
              </Card.Group>
            )}
          </Segment>
        </Grid.Column>
        <Grid.Column mobile={16} computer={8}>
          <Segment>
            <Header>HVACs</Header>
            {!data.floor?.ervs?.length ? (
              <EmptyMessage>Empty</EmptyMessage>
            ) : (
              <Item.Group divided>
                {data.floor?.ervs?.map((erv: IErv) => (
                  <HvacItem key={erv._id} ervCtrl={erv.ervCtrl} hvac={erv} user={data?.user} floor={data.floor}>
                    <Grid>
                      <Grid.Column mobile={16} computer={7}>
                        <HvacItem.Label>{erv.name}</HvacItem.Label>
                        <HvacItem.ErvCtrl noEdit={true} />
                        {/* <HvacItem.Marker /> */}
                        <HvacItem.Marker
                          isActive={checkIsActiveMarker("erv", erv._id)}
                          itemNumber={getCurrentItemNumber("erv", erv._id)}
                          removeMarker={() => removeMarker(erv._id)}
                        />
                      </Grid.Column>
                      <Grid.Column mobile={16} computer={9}>
                        <HvacItem.Description />
                      </Grid.Column>
                    </Grid>
                    <HvacItem.Detail />
                  </HvacItem>
                ))}
              </Item.Group>
            )}
          </Segment>
        </Grid.Column>
      </Grid>
    </DefaultLayout>
  );
}

export default Position;

const MapImage = styled.img`
  width: 100%;
`;

const StyledMarker = styled.div`
  &.section {
    background-color: #2abb9b;
  }
  &.hvac {
    background-color: #e87e04;
  }
  &.active {
    text-decoration: underline;
  }
  width: 50px;
  height: 50px;
  border-radius: 25px;
  transform: translate(-25%, -25%);
  line-height: 50px;
  text-align: center;
  font-size: 14px;
  color: white;
`;

const SelectorHeader = styled.p`
  color: #666666;
  padding: 0px 10px;
`;
const SelectorItem = styled.div`
  margin-bottom: 4px;
  cursor: pointer;
  padding: 0px 10px;
`;

const CloseBtn = styled.div`
  float: right;
  display: inline;
`;

const UploadImage = styled.div`
  input {
    display: none;
  }

  text-align: right;

  span {
    margin-right: 10px;
    color: #666;
  }
`;
