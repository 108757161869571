import React, { useState, useEffect, useRef } from "react";
import Charts from "Components/Common/Charts";
import Breadcrumbs from "@Components/Common/Breadcrumbs";
import { Segment, Loader, Header, Icon, Dropdown, Grid, Checkbox } from "semantic-ui-react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { DateRangePicker } from "react-dates";
import styled from "styled-components";
import DefaultLayout from "@Layouts/DefaultLayout";
import mediaQueries from "@styles/mediaQueries";
import { Helmet } from "react-helmet";
import { INIT_DASHBOARD_PAGE } from "@graphql/query";
import { useLazyQuery } from "@apollo/react-hooks";
import { toastError } from "@util";

function Dashboard() {
  const { userId, floorId } = useParams();
  const [startDate, setStartDate] = useState(moment(Date.now()).startOf("day"));
  const [focusedInput, setFocusedInput] = useState(null);
  const [endDate, setEndDate] = useState(moment());
  const [oneDaySample, setOneDaySample] = useState(288);
  const [graphData, setGraphData] = useState(null);
  const [isShowPlotBands, setIsShowPlotBands] = useState(false);
  const [floorName, setFloorName] = useState("");
  const yAxisIndexForHide = useRef();

  const [initDashboardPage, { data, loading }] = useLazyQuery(INIT_DASHBOARD_PAGE, {
    onCompleted(data) {
      let newGraphData = {
        temp: [],
        humid: [],
        CO2: [],
        VOC: [],
        PM25: [],
        awairScore: [],
        fanspeed: [],
        vsp: [],
      };

      let fanspeedDatas = [];
      let vspDatas = [];
      let aqiDatas = {};

      const floor = data.user.floors.find((cur) => cur._id === floorId);
      setFloorName(floor.name);

      // for (const floor of data.user.floors) {
      // 팬스피드 데이터 가공
      for (const erv of floor.ervs) {
        if (!erv.ervLogsByDate || !erv.ervLogsByDate.actions.length) continue;
        const [basic, pro] = formatFanspeedDataForGraph(erv.ervLogsByDate.isVsp, erv.ervLogsByDate.actions);

        if (erv.ervLogsByDate.isVsp === true) {
          vspDatas.push({ name: `ventLevel[erv:${erv.name}]`, data: pro.ventLevel });
          vspDatas.push({ name: `vspIn[erv:${erv.name}]`, data: pro.vspIn });
          vspDatas.push({ name: `vspOut[erv:${erv.name}]`, data: pro.vspOut });
          yAxisIndexForHide.current = 0;
        } else {
          fanspeedDatas.push({ name: `fanspeed[erv:${erv.name}]`, data: basic.fanspeed });
          fanspeedDatas.push({ name: `ventLevel[erv:${erv.name}]`, data: basic.ventLevel, yAxis: 1 });
          yAxisIndexForHide.current = 1;
        }

        // basic.length > 0 ? fanspeedDatas.push({ name: `fanspeed[ erv:${erv.name} / floor:${floor.name} ]`, data: basic }) : (yAxisIndexForHide.current = 0);
        // pro.length > 0
        //   ? fanspeedDatas.push({ name: `VSP[ erv:${erv.name} / floor:${floor.name} ]`, data: pro, yAxis: 1, color: "#4080B0" })
        //   : (yAxisIndexForHide.current = 1);
      }

      // innerAQI 데이터 가공
      for (const section of floor.sections) {
        if (!section.indoorAtmLogsByDate || !section.indoorAtmLogsByDate.measurements) continue;

        let thisName;
        let i_temp = [];
        let i_humid = [];
        let co2 = [];
        let co2_15 = [];
        let co2_90 = [];
        let voc = [];
        let voc_15 = [];
        let voc_90 = [];
        let i_pm25 = [];
        let awairScores = [];

        for (const item of section.indoorAtmLogsByDate.measurements) {
          const ts = moment(item.logtime).valueOf();
          item.temp && i_temp.push([ts, item.temp]);
          item.humid && i_humid.push([ts, item.humid]);
          item.co2 && co2.push([ts, item.co2]);
          item.co2_15 && co2_15.push([ts, item.co2_15]);
          item.co2_90 && co2_90.push([ts, item.co2_90]);
          item.voc && voc.push([ts, item.voc]);
          item.voc_15 && voc_15.push([ts, item.voc_15]);
          item.voc_90 && voc_90.push([ts, item.voc_90]);
          item.pm25 && i_pm25.push([ts, item.pm25]);
          item.awairScore && awairScores.push([ts, item.awairScore]);
        }

        thisName = section.name;
        newGraphData.temp.push({ name: thisName, data: i_temp });
        newGraphData.humid.push({ name: thisName, data: i_humid });
        newGraphData.CO2.push({ type: "line", name: thisName, data: co2 });
        newGraphData.CO2.push({
          type: "line",
          name: `${thisName} (15min)`,
          data: co2_15,
        });
        newGraphData.CO2.push({
          type: "line",
          name: `${thisName} (90min)`,
          data: co2_90,
        });
        newGraphData.VOC.push({ type: "line", name: thisName, data: voc });
        newGraphData.VOC.push({
          type: "line",
          name: `${thisName} (15min)`,
          data: voc_15,
        });
        newGraphData.VOC.push({
          type: "line",
          name: `${thisName} (90min)`,
          data: voc_90,
        });
        newGraphData.PM25.push({ name: thisName, data: i_pm25 });
        newGraphData.awairScore.push({ name: thisName, data: awairScores });
      }

      // outerAQI 데이터 가공
      if (floor.location && floor.location.outdoorAtmLogsByDate && floor.location.outdoorAtmLogsByDate.measurements) {
        let o_temp = [];
        let o_humid = [];
        let o_pm25 = [];

        for (const item of floor.location.outdoorAtmLogsByDate.measurements) {
          const ts = moment(item.logtime).valueOf();
          item.temp && o_temp.push([ts, item.temp]);
          item.humid && o_humid.push([ts, item.humid]);
          item.pm25 && o_pm25.push([ts, item.pm25]);
        }

        newGraphData.temp.push({ name: `Outside`, data: o_temp });
        newGraphData.humid.push({ name: `Outside`, data: o_humid });
        newGraphData.PM25.push({ name: `Outside`, data: o_pm25 });
      }

      newGraphData.fanspeed = fanspeedDatas;
      newGraphData.vsp = vspDatas;
      setGraphData(newGraphData);
    },
    onError(error) {
      console.error(error);
      toastError(`조회 실패.`, error.message);
    },
  });

  useEffect(() => {
    const sample = Math.min(Math.floor(oneDaySample * (moment(endDate).diff(moment(startDate), "minutes") / (24 * 60))), 4000); //최대 4000
    initDashboardPage({ variables: { userId: userId, from: startDate.valueOf(), to: endDate.valueOf(), sample: sample } });
  }, [startDate, endDate, oneDaySample]);

  const formatFanspeedDataForGraph = (isVsp, rawData) => {
    const proFanspeedData = { ventLevel: [], vspIn: [], vspOut: [] };
    const basicFanspeedData = { fanspeed: [], ventLevel: [] };
    rawData.forEach((row) => {
      if (isVsp) {
        proFanspeedData.vspIn.push([moment(row.logtime).valueOf(), row.vspIn]);
        proFanspeedData.vspOut.push([moment(row.logtime).valueOf(), row.vspOut]);
        proFanspeedData.ventLevel.push([moment(row.logtime).valueOf(), row.ventLevel]);
      } else {
        basicFanspeedData.fanspeed.push([moment(row.logtime).valueOf(), row.fanspeed]);
        basicFanspeedData.ventLevel.push([moment(row.logtime).valueOf(), row.ventLevel]);
      }
    });
    return [basicFanspeedData, proFanspeedData];
  };

  const handleDatesChange = (dates) => {
    dates.startDate && setStartDate(moment(dates.startDate).startOf("day"));
    dates.endDate && setEndDate(moment(dates.endDate).endOf("day"));
    setGraphData(null);
  };

  return (
    <DefaultLayout>
      {data && (
        <Helmet>
          <title>{data.user.name}(Dashboard)</title>
        </Helmet>
      )}
      <Segment>
        <Header as="h2">
          Dashboard
          <Header.Subheader>
            <Breadcrumbs />
          </Header.Subheader>
        </Header>
        {data && (
          <div>
            <Icon name="user" /> {data.user.name}
          </div>
        )}
        {floorName && <div>floor: {floorName}</div>}
        {/* {floor && (
          <div>
            {" "}
            <Icon name="map marker" /> {floor.name}{" "}
          </div>
        )} */}
      </Segment>
      {/* {awairDatas && (
        <Segment>
          <Header as="h4">Awairs</Header>
          {awairDatas.map((awair) => {
            return (
              <Link key={awair._id} to={`/orgs/${orgId}/awair/${awair._id}`}>
                <Button size="mini">{awair.name}</Button>
              </Link>
            );
          })}
        </Segment>
      )} */}
      <DateRangePicker
        minimumNights={0}
        showDefaultInputIcon
        isOutsideRange={(date) => (date > moment().endOf("day") ? true : false)}
        startDateId="startDate"
        endDateId="endDate"
        displayFormat="YYYY-MM-DD"
        startDate={startDate}
        endDate={endDate}
        onDatesChange={(dates) => handleDatesChange(dates)}
        focusedInput={focusedInput}
        onFocusChange={(input) => {
          setFocusedInput(input);
        }}
      />
      <PeriodSelecter>
        {" "}
        데이터 조회 간격{" "}
        <Dropdown
          inline
          value={oneDaySample}
          onChange={(e, data) => setOneDaySample(data.value)}
          options={[
            { key: 1, text: "1m (1440 sample/day)", value: 1440 },
            { key: 5, text: "5m (288 sample/day)", value: 288 },
            { key: 15, text: "15m (97 sample/day)", value: 97 },
          ]}
        />
      </PeriodSelecter>
      <ShowPlotBandsCheckbox
        toggle
        label="기준영역표시"
        checked={isShowPlotBands}
        onChange={(e, { checked }) => {
          setIsShowPlotBands(!isShowPlotBands);
        }}
      />
      {loading && <StyledLoader active size="massive" />}
      {graphData && (
        <Grid>
          <Grid.Column mobile={16} computer={8}>
            <Charts kind="awairScore" data={graphData.awairScore} isShowPlotBands={isShowPlotBands} />
          </Grid.Column>
          {graphData.fanspeed.length > 0 && (
            <Grid.Column mobile={16} computer={8}>
              <Charts kind="fanSpeed" data={graphData.fanspeed} />
            </Grid.Column>
          )}

          {graphData.vsp.length > 0 && (
            <Grid.Column mobile={16} computer={8}>
              <Charts kind="vsp" data={graphData.vsp} />
            </Grid.Column>
          )}
          <Grid.Column mobile={16} computer={8}>
            <Charts kind="CO2" data={graphData.CO2} isShowPlotBands={isShowPlotBands} />
          </Grid.Column>
          <Grid.Column mobile={16} computer={8}>
            <Charts kind="VOC" data={graphData.VOC} isShowPlotBands={isShowPlotBands} />
          </Grid.Column>
          <Grid.Column mobile={16} computer={8}>
            <Charts kind="PM25" data={graphData.PM25} isShowPlotBands={isShowPlotBands} />
          </Grid.Column>
          <Grid.Column mobile={16} computer={8}>
            <Charts kind="temperature" data={graphData.temp} isShowPlotBands={isShowPlotBands} />
          </Grid.Column>
          <Grid.Column mobile={16} computer={8}>
            <Charts kind="humidity" data={graphData.humid} isShowPlotBands={isShowPlotBands} />
          </Grid.Column>
        </Grid>
      )}
    </DefaultLayout>
  );
}

export default Dashboard;

const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
`;

const PeriodSelecter = styled.span`
  ${mediaQueries("sm")`
    display: block;
    margin: 20px 0;
  `}
`;

const ShowPlotBandsCheckbox = styled(Checkbox)`
  float: right;
  margin-top: 13px;
  ${mediaQueries("sm")`
    float: none;
  `}
`;
