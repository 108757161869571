import React, { useState } from "react";
import type { IFloor, IUser } from "@interfaces";
import { useMutation } from "@apollo/react-hooks";
import { toastSuccess, toastError } from "@util";
import {
  REMOVE_ERV,
  UPDATE_ERV,
  ALLOCATE_ERVCTRL_TO_ERV,
  UNALLOCATED_ERVCTRL_TO_ERV,
  SET_ERVCONTROL_INFO,
  UPDATE_ERV_CTRL,
  FACTORY_RESET_ERV_CTRL,
} from "@graphql/mutation";
import { INIT_USER_PAGE } from "@graphql/query";
import { useModal } from "@Hooks/useModal";

type TProps = {
  hvac?: any;
  user?: IUser;
  floor?: IFloor;
  ervCtrl?: any;
};

function useHvacItem({ hvac, user, floor, ervCtrl }: TProps) {
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const { ModalPortal: HvacModalPortal, openModal: openHvacModal, closeModal: closeHvacModal } = useModal();
  const { ModalPortal: ErvCtrlSelectorModalPortal, openModal: openErvCtrlSelector, closeModal: closeErvCtrlSelector } = useModal();
  const { ModalPortal: ErvCtrlModalPortal, openModal: openErvCtrl, closeModal: closeErvCtrl } = useModal();

  // erv 수정
  const [updateErv] = useMutation(UPDATE_ERV, {
    onCompleted(data) {
      toastSuccess("HVAC 수정", `name : ${data.updateErv.name}`);
    },
    onError(error) {
      console.error("error", error);
      toastError("HVAC 수정 실패.", error.message);
    },
    refetchQueries: [{ query: INIT_USER_PAGE, variables: { userId: user?._id } }],
  });

  // erv 삭제
  const [removeErv] = useMutation(REMOVE_ERV, {
    onCompleted(data) {
      toastSuccess("HVAC 삭제", `name : ${data.removeErv.name}`);
    },
    onError(error) {
      console.error("error", error);
      toastError("HVAC 삭제 실패.", error.message);
    },
    refetchQueries: [{ query: INIT_USER_PAGE, variables: { userId: user?._id } }],
  });

  //HVAC에 ervCtrl 할당
  const [allocateErvCtrlToErv] = useMutation(ALLOCATE_ERVCTRL_TO_ERV, {
    onCompleted(data) {
      toastSuccess("Erv Ctrl 할당", `name : ${data.allocateErvCtrlToErv.name}`);
    },
    onError(error) {
      console.error("error", error);
      toastError("Erv Ctrl 할당 실패.", error.message);
    },
    refetchQueries: [{ query: INIT_USER_PAGE, variables: { userId: user?._id } }],
  });

  //HVAC에 ervCtrl 할당 해제
  const [unallocateErvCtrlToErv] = useMutation(UNALLOCATED_ERVCTRL_TO_ERV, {
    onCompleted(data) {
      toastSuccess("Erv Ctrl 할당 해제", `name : ${data.unallocateErvCtrlToErv.name}`);
    },
    onError(error) {
      console.error("error", error);
      toastError("Erv Ctrl 할당 해제 실패.", error.message);
    },
    refetchQueries: [{ query: INIT_USER_PAGE, variables: { userId: user?._id } }],
  });

  // control info update
  const [setErvControlInfo] = useMutation(SET_ERVCONTROL_INFO, {
    onCompleted(data) {
      toastSuccess("Erv Control 정보 업데이트", `name : ${data.setErvControlInfo.id}`);
    },
    onError(error) {
      console.error("error", error);
      toastError("Erv Control 정보 업데이트 실패", error.message);
    },
    refetchQueries: [{ query: INIT_USER_PAGE, variables: { userId: user?._id } }],
  });

  // ervCtrl 수정
  const [updateErvCtrl] = useMutation(UPDATE_ERV_CTRL, {
    onCompleted(data) {
      toastSuccess("Erv Control 수정", `name : ${data.updateErvCtrl.id}`);
    },
    onError(error) {
      console.error("error", error);
      toastError("Erv Control 수정 실패", error.message);
    },
    refetchQueries: [{ query: INIT_USER_PAGE, variables: { userId: user?._id } }],
  });

  // ervCtrl 공장 초기화
  const [factoryResetErvCtrl] = useMutation(FACTORY_RESET_ERV_CTRL, {
    onCompleted(data) {
      toastSuccess("공장 초기화 성공");
    },
    onError(error) {
      console.error("error", error);
      toastError("공장 초기화 실패", error.message);
    },
    refetchQueries: [{ query: INIT_USER_PAGE, variables: { userId: user?._id } }],
  });

  // hvac 수정 제출
  const hvacModifySubmit = (data: any) => {
    updateErv({ variables: { ervId: hvac?._id, data: data } });
    closeHvacModal();
  };

  const handleRemove = async () => {
    await removeErv({ variables: { floorId: floor?._id, ervId: hvac?._id } });
  };

  const handleUnallocate = async () => {
    await unallocateErvCtrlToErv({ variables: { ervId: hvac?._id, ervCtrlId: ervCtrl?._id } });
  };

  // ervCtrl selector 제출
  const ervCtrlSelectorSubmit = (data: any) => {
    allocateErvCtrlToErv({ variables: { ervId: hvac?._id, ervCtrlId: data.ervCtrlId } });
    closeErvCtrlSelector();
  };

  const handleResetErvCtrl = async () => {
    await factoryResetErvCtrl({ variables: { ervCtrlId: ervCtrl?._id, factoryReset: true } });
  };

  // ervCtrl 수정 제출
  const ervCtrlModifySubmit = (onSubmitData: any) => {
    updateErvCtrl({ variables: { ervCtrlId: onSubmitData.ervCtrlId, data: onSubmitData.data } });
    closeErvCtrl();
  };

  // control info update 제출
  const onControlInfoUpdateSubmit = (ervCtrlId: string) => {
    setErvControlInfo({ variables: { ervCtrlId: ervCtrlId } });
  };

  return {
    hvacModifySubmit,
    HvacModalPortal,
    openHvacModal,
    closeHvacModal,
    handleRemove,
    handleUnallocate,
    ErvCtrlSelectorModalPortal,
    openErvCtrlSelector,
    ervCtrlSelectorSubmit,
    handleResetErvCtrl,
    ervCtrlModifySubmit,
    onControlInfoUpdateSubmit,
    ErvCtrlModalPortal,
    openErvCtrl,
    isOpenDetail,
    setIsOpenDetail,
    aiPerformance: floor?.aiPerformance,
  };
}

export { useHvacItem };
