import React from "react";
import type { ReactNode } from "react";
import { Item, Col } from "./layouts";
import styled from "styled-components";

type TProps = {
  children: ReactNode;
};

function Scores({ children }: TProps) {
  return <Container>{children}</Container>;
}

Scores.Item = Item;
Scores.Col = Col;
export { Scores };

const Container = styled.div`
  margin: 10px 0;
`;
