import React, { useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Link } from "react-router-dom";
import DefaultLayout from "@Layouts/DefaultLayout";
import { Segment, Header, Loader } from "semantic-ui-react";
import { INIT_REPORT_PAGE } from "@graphql/query";
import { useQuery } from "@apollo/react-hooks";
import { Button, Grid } from "semantic-ui-react";
import { IUser, IOrganization } from "@interfaces";
import DailyReportArea, { TDailyReportStatus } from "@Components/Reports/DailyReportArea";

function DailyReports() {
  const DEFAULT_ORG_ID = "5dd1fad2d6be7915873bb9cc";

  const { loading, data } = useQuery<{ organization: IOrganization }, { organizationId: string }>(INIT_REPORT_PAGE, {
    variables: {
      organizationId: DEFAULT_ORG_ID,
    },
  });

  const [userId, setUserId] = useState<string>();
  const [floorId, setFloorId] = useState<string>();
  const [status, setStatus] = useState<TDailyReportStatus>();

  const onClickItem = (userId: string, floorId: string, status: TDailyReportStatus) => {
    setUserId(userId);
    setFloorId(floorId);
    setStatus(status);
  };

  const isActive = (curUserId: string, curFloorId: string, curStatus: TDailyReportStatus) => {
    if (curUserId === userId && curFloorId === floorId && curStatus === status) {
      return true;
    } else {
      return false;
    }
  };

  if (loading)
    return (
      <DefaultLayout>
        <Loader active />
      </DefaultLayout>
    );

  return (
    <DefaultLayout>
      <Helmet>
        <title>Reports</title>
      </Helmet>

      <Grid columns="equal">
        <Grid.Column>
          <Segment>
            <Header as="h3">일간 리포트 대상</Header>
            {data && data.organization.users ? (
              data.organization.users
                .filter((user: IUser) => {
                  return user.status === "paid" || user.status === "outlier" || user.status === "sponsor";
                })
                .map((user: IUser) => (
                  <div key={user._id} style={{ marginBottom: 20 }}>
                    <h4 style={{ marginBottom: 4 }}>
                      {user.name} (status: {user.status})
                    </h4>
                    {user.floors &&
                      user.floors.map(
                        (floor) =>
                          floor && (
                            <div key={floor._id} style={{ marginLeft: 10 }}>
                              {floor.name}
                              <div>
                                <ButtonWrap>
                                  <Button
                                    size="mini"
                                    onClick={() => onClickItem(user._id, floor._id, "co2_good_1")}
                                    active={isActive(user._id, floor._id, "co2_good_1")}
                                  >
                                    이산화탄소좋음1
                                  </Button>
                                  <Link to={`/reports/daily/users/${user._id}/floors/${floor._id}/status/co2_good_1`} target="_blank">
                                    새창
                                  </Link>
                                </ButtonWrap>
                                <ButtonWrap>
                                  <Button
                                    size="mini"
                                    onClick={() => onClickItem(user._id, floor._id, "co2_good_2")}
                                    active={isActive(user._id, floor._id, "co2_good_2")}
                                  >
                                    이산화탄소좋음2
                                  </Button>
                                  <Link to={`/reports/daily/users/${user._id}/floors/${floor._id}/status/co2_good_2`} target="_blank">
                                    새창
                                  </Link>
                                </ButtonWrap>
                                <ButtonWrap>
                                  <Button
                                    size="mini"
                                    onClick={() => onClickItem(user._id, floor._id, "co2_good_3")}
                                    active={isActive(user._id, floor._id, "co2_good_3")}
                                  >
                                    이산화탄소좋음3
                                  </Button>
                                  <Link to={`/reports/daily/users/${user._id}/floors/${floor._id}/status/co2_good_3`} target="_blank">
                                    새창
                                  </Link>
                                </ButtonWrap>
                                <ButtonWrap>
                                  <Button
                                    size="mini"
                                    onClick={() => onClickItem(user._id, floor._id, "voc_bad")}
                                    active={isActive(user._id, floor._id, "voc_bad")}
                                  >
                                    화학물질
                                  </Button>
                                  <Link to={`/reports/daily/users/${user._id}/floors/${floor._id}/status/voc_bad`} target="_blank">
                                    새창
                                  </Link>
                                </ButtonWrap>
                                <ButtonWrap>
                                  <Button
                                    size="mini"
                                    onClick={() => onClickItem(user._id, floor._id, "pm25_bad")}
                                    active={isActive(user._id, floor._id, "pm25_bad")}
                                  >
                                    미세먼지나쁨
                                  </Button>
                                  <Link to={`/reports/daily/users/${user._id}/floors/${floor._id}/status/pm25_bad`} target="_blank">
                                    새창
                                  </Link>
                                </ButtonWrap>
                                <ButtonWrap>
                                  <Button
                                    size="mini"
                                    onClick={() => onClickItem(user._id, floor._id, "pm25_good")}
                                    active={isActive(user._id, floor._id, "pm25_good")}
                                  >
                                    미세먼지좋음
                                  </Button>
                                  <Link to={`/reports/daily/users/${user._id}/floors/${floor._id}/status/pm25_good`} target="_blank">
                                    새창
                                  </Link>
                                </ButtonWrap>
                              </div>
                            </div>
                          )
                      )}

                    {user.floors && user.floors.length === 0 && <p style={{ marginLeft: 10 }}>floor가 없습니다.</p>}
                  </div>
                ))
            ) : (
              <p style={{ marginLeft: 10 }}>일간 리포트 대상자가 없습니다.</p>
            )}
          </Segment>
        </Grid.Column>
        <Grid.Column style={{ postion: "relative" }}>
          {/* {userId && floorId && <WeeklyReportArea userId={userId} floorId={floorId} />} */}
          {userId && floorId && status && (
            <ReportAreaWarp>
              <DailyReportArea userId={userId} floorId={floorId} status={status} />
            </ReportAreaWarp>
          )}
        </Grid.Column>
      </Grid>
    </DefaultLayout>
  );
}

export default DailyReports;

const ButtonWrap = styled.div`
  margin-bottom: 4px;
  a {
    margin-left: 10px;
    font-size: 12px;
  }
`;

const ReportAreaWarp = styled.div`
  position: fixed;
`;
