import React, { useState } from "react";
import Breadcrumbs from "@Components/Common/Breadcrumbs";
import { Segment, Loader, Header, Icon, Card, Grid, Checkbox, Item } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import DefaultLayout from "@Layouts/DefaultLayout";
import mediaQueries from "@styles/mediaQueries";
import { Helmet } from "react-helmet";
import { SectionCard } from "@Components/SectionCard";
import { DashboardChart, useDashboardChart } from "@Components/DashboardChart";
import { ISection, IErv } from "@interfaces";
import { HvacItem } from "@Components/HvacItem";
import { Scores, useScores } from "@Components/Scores";
import { EmptyMessage } from "@styles/common";

function Dashboard() {
  const { userId, floorId } = useParams<any>();
  const [oneDaySample, setOneDaySample] = useState(288);
  const [isShowErvLog, setIsShowErvLog] = useState(true);
  const [selectedGraph, setSelectedGraph] = useState<"temp" | "humid" | "VOC" | "CO2" | "PM25" | "awairScore">("awairScore");
  const [selectedSectionIDs, setSelectedSectionIDs] = useState<String[]>([]);
  const [selectedErvIDs, setSelectedErvIDs] = useState<String[]>([]);

  const {
    data,
    loading,
    startDate,
    endDate,
    startDateInput,
    endDateInput,
    graphData,
    getCurFloor,
    handleDatesChange,
    submitDates,
    checkIsActiveAwair,
    checkIsActiveErv,
  } = useDashboardChart({ userId, floorId, oneDaySample, setSelectedSectionIDs, setSelectedErvIDs });
  const scores = useScores({ floorId });
  const onClickSectionCard = (sectionId: string) => {
    if (!checkIsActiveAwair(sectionId)) return;
    if (selectedSectionIDs.includes(sectionId)) {
      setSelectedSectionIDs((prevSectionIDs) => prevSectionIDs.filter((cur) => cur !== sectionId));
    } else {
      setSelectedSectionIDs((prevSectionIDs) => [...prevSectionIDs, sectionId]);
    }
  };

  const onClickHvacItem = (ervId: string) => {
    if (!checkIsActiveErv(ervId)) return;
    if (selectedErvIDs.includes(ervId)) {
      setSelectedErvIDs((prevErvIDs) => prevErvIDs.filter((cur) => cur !== ervId));
    } else {
      setSelectedErvIDs((prevErvIDs) => [...prevErvIDs, ervId]);
    }
  };

  return (
    <DefaultLayout>
      {data && (
        <Helmet>
          <title>{data.user.name}(Dashboard)</title>
        </Helmet>
      )}
      <Segment>
        <Header as="h2">
          Dashboard
          <Header.Subheader>
            <Breadcrumbs />
          </Header.Subheader>
        </Header>
        {data && (
          <div>
            <Icon name="user" /> {data.user.name}
          </div>
        )}
        {data?.user?.floors && <div>floor: {getCurFloor(data?.user?.floors).name}</div>}
      </Segment>

      <Scores>
        <Scores.Col>
          <Scores.Item label="AQI Accuracy">{scores.aqiAccuracy}</Scores.Item>
          <Scores.Item label="AQI Prediction">{scores.aqiPrediction}</Scores.Item>
          <Scores.Item label="PM Score">{scores.pmScore}</Scores.Item>
          <Scores.Item label="AQI Score">{scores.aqiScore}</Scores.Item>
          <Scores.Item label="Energy Score">{scores.energyScore}</Scores.Item>
        </Scores.Col>
      </Scores>

      <>
        <DashboardChart graphData={graphData} selectedSectionIDs={selectedSectionIDs} selectedErvIDs={selectedErvIDs} isShowErvLog={isShowErvLog}>
          <DashboardChart.CurrentDate startDate={startDate} endDate={endDate} />
          <DashboardChart.SelectDate
            startDateInput={startDateInput}
            endDateInput={endDateInput}
            handleDatesChange={handleDatesChange}
            submitDates={submitDates}
          />
          <DashboardChart.SelectSample oneDaySample={oneDaySample} setOneDaySample={setOneDaySample} />
          <DashboardChart.SelectChartBtns
            selectedGraph={selectedGraph}
            setSelectedGraph={setSelectedGraph}
            isShowErvLog={isShowErvLog}
            setIsShowErvLog={setIsShowErvLog}
          />
          {loading ? (
            <StyledLoader active size="massive" />
          ) : (
            <>
              {selectedGraph === "awairScore" && <DashboardChart.Chart kind="awairScore" />}
              {selectedGraph === "CO2" && <DashboardChart.Chart kind="CO2" />}
              {selectedGraph === "VOC" && <DashboardChart.Chart kind="VOC" />}
              {selectedGraph === "PM25" && <DashboardChart.Chart kind="PM25" />}
              {selectedGraph === "temp" && <DashboardChart.Chart kind="temp" />}
              {selectedGraph === "humid" && <DashboardChart.Chart kind="humid" />}
            </>
          )}
        </DashboardChart>
        {!loading && (
          <Grid>
            <Grid.Column mobile={16} computer={8}>
              <Segment>
                <Header>Sections</Header>
                {!getCurFloor(data?.user?.floors)?.sections?.length ? (
                  <EmptyMessage>Empty</EmptyMessage>
                ) : (
                  <Card.Group itemsPerRow={2}>
                    {getCurFloor(data?.user?.floors)?.sections?.map((section: ISection) => (
                      <SectionCard key={section._id} awairs={section.awairs} section={section} user={data?.user} floor={getCurFloor(data?.user?.floors)}>
                        <Card.Content onClick={() => onClickSectionCard(section._id)}>
                          <SectionCard.Label>{section.name}</SectionCard.Label>
                          <SectionCard.Awair noEdit={true} />
                          <SectionCard.Description profile={section.profile} />
                          {checkIsActiveAwair(section._id) && (
                            <SectionCard.CheckBox checked={selectedSectionIDs.includes(section._id)} isActiveAwair={checkIsActiveAwair(section._id)} />
                          )}
                        </Card.Content>
                        <Card.Content extra>
                          <SectionCard.DetailBtns />
                          <SectionCard.Detail />
                        </Card.Content>
                      </SectionCard>
                    ))}
                  </Card.Group>
                )}
              </Segment>
            </Grid.Column>
            <Grid.Column mobile={16} computer={8}>
              <Segment>
                <Header>HVACs</Header>
                {!getCurFloor(data?.user?.floors)?.ervs?.length ? (
                  <EmptyMessage>Empty</EmptyMessage>
                ) : (
                  <Item.Group divided>
                    {getCurFloor(data?.user?.floors)?.ervs?.map((hvac: IErv) => (
                      <HvacItem
                        key={hvac._id}
                        ervCtrl={hvac.ervCtrl}
                        hvac={hvac}
                        user={data?.user}
                        floor={getCurFloor(data?.user?.floors)}
                        onClick={() => onClickHvacItem(hvac._id)}
                      >
                        <Grid>
                          <Grid.Column mobile={16} computer={7}>
                            <HvacItem.Label>{hvac.name}</HvacItem.Label>
                            <HvacItem.ErvCtrl noEdit={true} />
                          </Grid.Column>
                          <Grid.Column mobile={16} computer={9}>
                            <HvacItem.Description />
                            {checkIsActiveErv(hvac._id) && <HvacItem.CheckBox checked={selectedErvIDs.includes(hvac._id)} />}
                          </Grid.Column>
                        </Grid>
                        <HvacItem.Detail />
                      </HvacItem>
                    ))}
                  </Item.Group>
                )}
              </Segment>
            </Grid.Column>
          </Grid>
        )}
      </>
    </DefaultLayout>
  );
}

export default Dashboard;

const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
`;

const ShowPlotBandsCheckbox = styled(Checkbox)`
  float: right;
  margin-top: 13px;
  ${mediaQueries("sm")`
    float: none;
  `}
`;
