import React from "react";
import { Icon, Label } from "semantic-ui-react";

function StatusLabel({ bool, text }: { bool: boolean; text: string }) {
  return (
    <Label size="tiny" style={{ marginLeft: 4, marginBottom: 2, padding: 4 }}>
      <Icon name="circle" style={{ color: bool ? "#67BF63" : "#F2A950" }} />
      {text}
    </Label>
  );
}

export default StatusLabel;
