import React, { useState, useEffect } from "react";
import { Label } from "semantic-ui-react";
import InputRange from "react-input-range";
import styled from "styled-components";

interface WeightChangeControl {
  idx: number;
  link: any;
  row: any;
  onChange: Function;
}

function WeightChangeControl({ idx, link, row, onChange }: WeightChangeControl) {
  const [weight, setWeight] = useState();
  useEffect(() => {
    setWeight(link[2]);
  },[link]);

  function handleChangeWeight(value: any) {
    setWeight(value);
    onChange(value, idx);
  }
  return (
    <Container key={row.row}>
      <P>Link <Label>{link[0]}</Label> → <Label>{link[1]}</Label></P>
      <RangeSliderContainer color="#555">
        <InputRange minValue={0.01} maxValue={1} step={0.01} value={weight || 0.01} onChange={(value) => handleChangeWeight(value)} />
      </RangeSliderContainer>
    </Container>
  );
}

export default WeightChangeControl;


const Container = styled.div`
  margin-bottom: 22px;
`

const P = styled.div`
  font-size:10px;
  margin-bottom:12px;
`  
const RangeSliderContainer = styled.div`
  .input-range__track--active,
  .input-range__slider {
    background: ${props => props.color}; 
    border-color: ${props => props.color};
  }
`;