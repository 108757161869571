import React, { useState, useEffect } from "react";
import Highcharts, { reduce } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import defaultChartOps from "./config";
import * as utills from "./utills";
import styled from "styled-components";

function Charts({ kind, maxHour, xAxisCategory, minMaxData, maxValue, minValue, isShowPlotBands, data }) {
  const [optionData, setOptionData] = useState({
    options: {
      ...defaultChartOps.common,
    },
    longListCondition: null,
  });

  useEffect(() => {
    const newOptionData = { ...optionData };
    if (newOptionData.options) {
      newOptionData.options.yAxis = {};
      newOptionData.options.yAxis.plotBands = [];
    }
    newOptionData.options.yAxis.plotBands = utills.getPlotBandsData(kind, isShowPlotBands);
    setOptionData(newOptionData);
  }, [isShowPlotBands]);

  useEffect(() => {
    let title;
    // Chart kind specific options

    let options = defaultChartOps[kind];

    let kindSlice = kind.slice(0, 6);

    if (kind === "fanSpeed") {
      title = "Fan Speed";
      // if (!isNaN(yAxisIndexForHide)) {
      //   const yAxisHideOpt = {
      //     labels: {
      //       enabled: false,
      //     },
      //     title: {
      //       text: null,
      //     },
      //   };
      //   options.yAxis[yAxisIndexForHide] = yAxisHideOpt;
      //   if (yAxisIndexForHide === 0) {
      //     options.yAxis[2] = yAxisHideOpt;
      //   }
      // }
    } else if (kind === "awairScore" || kind === "CO2" || kind === "VOC" || kind === "PM25" || kind === "temperature" || kind === "humidity") {
      if (kind === "awairScore") title = "Awair Score";
      options.yAxis.plotBands = utills.getPlotBandsData(kind, isShowPlotBands);
    } else if (kindSlice === "hourly") {
      let stops = [];
      if (kind === "hourlyCo2") {
        stops = utills.getGradientStops([999, 1499], minValue, maxValue);
      } else if (kind === "hourlyVoc") {
        stops = utills.getGradientStops([599, 999], minValue, maxValue);
      } else if (kind === "hourlyPm25") {
        stops = utills.getGradientStops([35, 55], minValue, maxValue);
      }
      options.xAxis.plotBands.from = Math.max(maxHour - 3, 0);
      options.xAxis.plotBands.to = Math.min(maxHour + 3, 24);
      options.plotOptions.series.color = {};
      options.plotOptions.series.color.linearGradient = { x1: 0, x2: 0, y1: 0, y2: 1 };
      options.plotOptions.series.color.stops = stops;
    } else if (kind === "dailyAwairScore") {
      options.xAxis.categories = xAxisCategory;
      options.chart.events = {
        load: function () {
          var chart = this,
            points = chart.series[0].points,
            maxValue,
            minValue,
            chosenMaxPoint,
            chosenMinPoint;

          points.forEach(function (point, index) {
            if (!maxValue || maxValue < point.y) {
              maxValue = point.y;
              chosenMaxPoint = point;
            }
            if (!minValue || minValue > point.y) {
              minValue = point.y;
              chosenMinPoint = point;
            }
          });

          chosenMaxPoint.update({
            marker: {
              enabled: true,
              symbol: "circle",
              fillColor: "#92d050",
              radius: 10,
            },
            dataLabels: {
              enabled: true,
              format: `<div>이산화탄소 ${minMaxData.max.ico2.toFixed(0)}ppm<br />화학물질 ${minMaxData.max.ivoc.toFixed(0)}ppb</div>`,
              allowOverlap: true,
              backgroundColor: "#fafafa",
              padding: 10,
              borderRadius: 10,
              shadow: true,
              color: "#7a7a7a",
              y: 40,
              x: -80,
            },
          });

          chosenMinPoint.update({
            marker: {
              enabled: true,
              symbol: "circle",
              fillColor: "#ff7c5d",
              radius: 10,
            },
            dataLabels: {
              enabled: true,
              format: `<div>이산화탄소 ${minMaxData.min.ico2.toFixed(0)}ppm<br />화학물질 ${minMaxData.min.ivoc.toFixed(0)}ppb</div>`,
              allowOverlap: true,
              backgroundColor: "#fafafa",
              padding: 10,
              borderRadius: 10,
              shadow: true,
              color: "#7a7a7a",
              y: 20,
              x: -80,
            },
          });
          const xAxis = this.xAxis[0];
          xAxis.update({
            plotLines: [
              {
                color: "#92d050",
                width: 1,
                value: chosenMaxPoint.index,
              },
              {
                color: "#ff7c5d",
                width: 1,
                value: chosenMinPoint.index,
              },
            ],
          });
        },
      };
    } else {
      title = kind[0].toUpperCase() + kind.slice(1);
    }

    setOptionData({
      options: { ...optionData.options, ...options, series: [...data] },
    });
  }, [data]);

  return (
    <ChartContainer className="long-list">
      {optionData && optionData.options.series !== undefined && (
        <HighchartsReact className="xoxo" highcharts={Highcharts} options={optionData.options} updateArgs={[true]} />
      )}
    </ChartContainer>
  );
}

export default Charts;


const ChartContainer = styled.div`
  color: #333;
  width: 48%;
  padding: 0;
  margin: 3% 0;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  &.long-list {
    width: 100%;
  }
  .xoxo {
    display: flex;
    align-items: center;
    padding: 0;
  }
  &.right {
    margin-right: 1%;
  }
`
