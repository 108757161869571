import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Card, Icon, Label } from "semantic-ui-react";
import CardItemActions from "@Components/Common/CardItemActions";
import { IUser } from "@interfaces";
import { getUserStatusColor } from "@util";
import { useModal } from "@Hooks/useModal";
import { UserForm, UserStatusForm } from "@Components/Modals";

type TUserItem = {
  user: IUser;
  to: string;
  orgId: string;
  updateUser: Function;
  removeUser: Function;
  setUserStep: Function;
  canModify: boolean;
  canRemove: boolean;
};

type TUserInput = {
  name: String;
  phone: String;
  email: String;
  mailList?: String[];
  profile?: Object;
  isMarketingAgreed?: Boolean;
};

function UserItem({ user, to, orgId, updateUser, removeUser, setUserStep, canModify, canRemove }: TUserItem) {
  const { ModalPortal : UserModalPortal, openModal : openUserModal, closeModal : closeUserModal } =  useModal();
  const { ModalPortal : UserStatusModalPortal, openModal : openUserStatusModal, closeModal : closeUserStatusModal } =  useModal();

  // user 수정 제출 
  const userSubmit = (data: any) => {
    updateUser({ variables: { userId: user._id, data: data } }); 
    closeUserModal();
  }

  async function handleRemove() {
    removeUser({ variables: { userId: user._id, organizationId: orgId } });
  }

  // userStatus 수정 제출 
  const userStatusSubmit = (data: any) => {
    setUserStep({ variables: { userId: data.userId, status: data.status } });
    closeUserStatusModal();
  }

  const getLocationsStr = (floors: any) => {
    const floorNamesArr = floors.filter((floor: any) => floor).map((floor: any) => floor?.location?.kaptInfo?.kaptName);
    if (!floorNamesArr.length) return "미지정";
    return floorNamesArr.join();
  };

  return (
    <>
      <Card color={getUserStatusColor(user.status) as "yellow" | "olive" | "red" | "blue" | "grey"}>
        <Card.Content style={{ overflow: "hidden" }}>
          <CardItemActions canModify={canModify} onModify={openUserModal} canRemove={canRemove} onRemove={handleRemove} onModifyState={openUserStatusModal} />
          <Card.Header>
            <Link to={to}>{user.name}</Link>
          </Card.Header>
          <Card.Meta>
            user | <span style={{ color: getUserStatusColor(user.status, true) }}>{user.status}</span>
          </Card.Meta>
          <Card.Description>
            <Icon name="mail" />
            {user.email} {user.mailList && <MailListNum mailList={user.mailList} />}
            <br />
            <Icon name="phone" />
            {user.phone}
            <br />
            <Icon name="map" />
            {getLocationsStr(user.floors)}
            <br />
            <Icon name="calendar" />
            {moment(user.createdAt).format("YYYY-MM-DD, h:mm a")}
          </Card.Description>

          {/*       
          <ItemNumGrid>
            {showManagerAllocationAction && (
              <ManagerAction>
                <Checkbox
                  onClick={(e, { checked }) => (checked ? addManagerToUser() : removeManagerFromUser())}
                  label={isManaged ? "관리 대상 입니다." : "관리 대상으로 지정합니다."}
                  checked={isManaged}
                />
              </ManagerAction>
            )}

            <Grid.Row columns={4}>
              <ItemNumGridCol>
                Locations
                <ItemNum>{user.locationCnt}</ItemNum>
              </ItemNumGridCol>

              <ItemNumGridCol>
                Managers
                <ItemNum>{user.managers.length}</ItemNum>
              </ItemNumGridCol> 

              <ItemNumGridCol>
                ERV Ctrls
                <ItemNum>{user.ervCnt}</ItemNum>
              </ItemNumGridCol>

              <ItemNumGridCol>
                Awairs
                <ItemNum>{user.awairCnt}</ItemNum>
              </ItemNumGridCol>
            </Grid.Row>
          </ItemNumGrid> */}
        </Card.Content>
      </Card>
      <UserModalPortal title="User">
        <UserForm onSubmit={userSubmit} user={user} />
      </UserModalPortal>
      <UserStatusModalPortal title="User의 Status 수동 지정">
        <UserStatusForm onSubmit={userStatusSubmit} userId={user._id} status={user.status}/>
      </UserStatusModalPortal> 
    </>
  );
}
export default UserItem;

// const ManagerAction = styled(Form.Field)`
//   width: 100%;
//   height: 40px;
//   padding: 10px;
//   background-color: #ccc;
// `;

const MailListNum = ({ mailList }: { mailList: string[] }) => {
  if (mailList.length === 0) return null;
  return (
    <Label size="mini">
      {" "}
      <Icon name="plus" />
      {mailList.length}
    </Label>
  );
};
