import React from "react";
import styled from "styled-components";
import mediaQueries from "@styles/mediaQueries";
import { Dropdown } from "semantic-ui-react";

type TProps = {
  oneDaySample: number,
  setOneDaySample: any
}

function SelectSample({oneDaySample, setOneDaySample}: TProps) {
  return (
    <PeriodSelecter>
    {" "}
    데이터 조회 간격{" "}
    <Dropdown
      inline
      value={oneDaySample}
      onChange={(e, data) => setOneDaySample(data.value)}
      options={[
        { key: 1, text: "1m (1440 sample/day)", value: 1440 },
        { key: 5, text: "5m (288 sample/day)", value: 288 },
        { key: 15, text: "15m (97 sample/day)", value: 97 },
      ]}
    />
  </PeriodSelecter>
  )
}
export { SelectSample };

const PeriodSelecter = styled.span`
  ${mediaQueries("sm")`
    display: block;
    margin: 20px 0;
  `}
`;