import React, { useState } from "react";
import { Button, Form, Input, Modal, Label, Checkbox, Dropdown, Grid } from "semantic-ui-react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import styled from "styled-components";
import ErvTypeSelector from "@Components/User/ErvTypeSelector";
import FilterManagement from "@Components/User/FilterManagement";

type TProps = {
  hvac?: any;
  userName? : String;
  location?: any;
  onSubmit?: any;
};

export const HvacForm = ({onSubmit, hvac, userName, location} : TProps) => {
  const [name, setName] = useState(hvac?.name || `${userName}님의 환기장치`);
  const [isDefaultErv, setIsDefaultErv] = useState<any>(location?.ervType?._id === hvac?.ervType?._id || !hvac?.ervType );
  const [ervTypeId, setErvTypeId] = useState<any>(hvac?.ervType?._id);
  const [ervId, setErvId] = useState(hvac?._id);
  const [board, setBoard] = useState<string>(hvac?.board || "default");
  // const [autoControlOn, setAutoControlOn] = useState(false);
  const [physicalVspMin, setPhysicalVspMin] = useState<any>( (board !== "default" && hvac?.limitation?.physicalVspLimit.min) || 20 );
  const [physicalVspMax, setPhysicalVspMax] = useState<any>( (board !== "default" && hvac?.limitation?.physicalVspLimit.max) || 100 );
  const [dailyOperationSchedules, setDailyOperationSchedules] = useState<any>(hvac?.limitation?.dailyOperationSchedules || []);
  const [consumables, setConsumables] = useState<any>(hvac?.consumables);
 
  async function handleSubmit() {
    try {
      const submitObj = {
        name,
        ervType: isDefaultErv ? location?.ervType?._id : ervTypeId,
        board,
        limitation: {
          dailyOperationSchedules,
          physicalVspLimit: {
            min: physicalVspMin,
            max: physicalVspMax,
          },
        },
      };

      onSubmit(submitObj);
      // close();
    } catch (e) {
      console.error(e);
    }
  }

  function addNewSchedule(e: any) {
    e.preventDefault();
    const tempDailyOperationSchedules: { hourFrom: number; hourTo: number; minVentLevel: number; maxVentLevel: number }[] = [...dailyOperationSchedules];
    tempDailyOperationSchedules.push({
      hourFrom: 0,
      hourTo: 0,
      minVentLevel: 0,
      maxVentLevel: 80,
    });
    setDailyOperationSchedules(tempDailyOperationSchedules);
  }

  function handleScheduleChange(idx: number, schedule: any) {
    const tempDailyOperationSchedules = [...dailyOperationSchedules];
    tempDailyOperationSchedules[idx] = schedule;
    setDailyOperationSchedules(tempDailyOperationSchedules);
  }
  function handleScheduleRemove(e: any, idx: number) {
    e.preventDefault();
    const tempDailyOperationSchedules = [...dailyOperationSchedules];
    tempDailyOperationSchedules.splice(idx, 1);
    setDailyOperationSchedules(tempDailyOperationSchedules);
  }

  return (
    <Modal.Content>
      <Form onSubmit={handleSubmit} noValidate>
        <Grid>
          <Grid.Column>
            <Form.Field>
              <label>Name</label>
              <Input type="name" placeholder="name" value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Field>

            <Form.Group widths="equal">
              <Form.Field>
                <label>Default ERV</label>
                <Checkbox checked={isDefaultErv} onChange={(e, { checked }) => setIsDefaultErv(checked)} />
                {`${isDefaultErv ? "Default" : "Custom"} `}
              </Form.Field>
              {!isDefaultErv && (
                <Form.Field>
                  <label>ERV Type</label>
                  <ErvTypeSelector ervTypeId={ervTypeId} setErvTypeId={setErvTypeId} />
                </Form.Field>
              )}
              <Form.Field>
                <label>Board</label>
                <Dropdown
                  value={board}
                  placeholder="Select Board Type"
                  selection
                  options={[
                    { key: 0, text: "default", value: "default" },
                    { key: 1, text: "custom (SLT)", value: "SLT" },
                    { key: 2, text: "custom (HNT)", value: "HNT" },
                  ]}
                  onChange={(e, data: any) => setBoard(data.value)}
                />
              </Form.Field>
              {board !== "default" && (
                <>
                  <Form.Field style={{ width: "100px" }}>
                    <label>VspMin</label>
                    <Input type="VspMin" placeholder="VspMin" value={physicalVspMin} onChange={(e) => setPhysicalVspMin(e.target.value)} />
                  </Form.Field>
                  <Form.Field style={{ width: "100px" }}>
                    <label>VspMax</label>
                    <Input type="VspMax" placeholder="VspMax" value={physicalVspMax} onChange={(e) => setPhysicalVspMax(e.target.value)} />
                  </Form.Field>
                </>
              )}
            </Form.Group>
            <Form.Field>
              <FilterManagement ervId={ervId} consumables={consumables} setConsumables={setConsumables} />
            </Form.Field>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "30px 0 10px 0",
                }}
              >
                <h4 style={{ margin: 0 }}>Daily Schedule</h4>
                <Button icon="plus" circular onClick={addNewSchedule} style={{ marginLeft: "20px" }} />
              </div>
              {dailyOperationSchedules.map((schedule: any, idx: number) => (
                <DailyOperationSchedule
                  {...schedule}
                  onChange={(schedule: any) => handleScheduleChange(idx, schedule)}
                  onRemove={(e: any) => handleScheduleRemove(e, idx)}
                  key={idx}
                  board={board}
                />
              ))}
            </div>
          </Grid.Column>
        </Grid>
        <Button type="submit" fluid>
          Submit
        </Button>
      </Form>
    </Modal.Content>

  );
}

const fanspeedOptions = [
  { key: 0, value: 0, text: "0단(VentLevel:0)" },
  { key: 1, value: 20, text: "1단(VentLevel:20)" },
  { key: 2, value: 50, text: "2단(VentLevel:50)" },
  { key: 3, value: 80, text: "3단(VentLevel:80)" },
];

const DailyOperationSchedule = ({ hourFrom, hourTo, minVentLevel, maxVentLevel, byUser, onChange, onRemove, board, disabled, ...props }: any) => {
  function handleChange(data: any) {
    onChange({
      hourFrom,
      hourTo,
      minVentLevel,
      maxVentLevel,
      ...data,
    });
  }

  return (
    <Form.Group {...props} style={{ marginBottom: 30 }}>
      <InputRangeWrap>
        <InputRange
          minValue={0}
          maxValue={24}
          value={{ min: hourFrom, max: hourTo }}
          onChange={(value: any) => handleChange({ hourFrom: value.min, hourTo: value.max })}
        />
      </InputRangeWrap>

      {board === "default" ? (
        <>
          <Form.Select
            label="min"
            options={fanspeedOptions}
            placeholder="min"
            value={minVentLevel}
            onChange={(e, { value }) => handleChange({ minVentLevel: value })}
          />
          <Form.Select
            label="max"
            options={fanspeedOptions}
            placeholder="max"
            value={maxVentLevel}
            onChange={(e, { value }) => handleChange({ maxVentLevel: value })}
          />
        </>
      ) : (
        <>
          <Form.Input
            label="min"
            type="number"
            min="0"
            max="100"
            transparent
            value={minVentLevel}
            disabled={disabled}
            onChange={(e, { value }: any) => {
              if (value < 0 || value > 100) return false;
              handleChange({ minVentLevel: value });
            }}
          />
          <Form.Input
            label="max"
            type="number"
            min="0"
            max="100"
            transparent
            value={maxVentLevel}
            disabled={disabled}
            onChange={(e, { value }: any) => {
              if (value < 0 || value > 100) return false;
              handleChange({ maxVentLevel: value });
            }}
          />
        </>
      )}

      <Button icon="minus" circular onClick={onRemove} style={{ marginLeft: "20px", height: "37px" }} />
      {byUser && <Label color="yellow">by user</Label>}
    </Form.Group>
  );
};

const InputRangeWrap = styled.div`
  width: 40%;
  padding-top: 12px;
  margin-left: 10px;
  margin-right: 20px;
  .input-range__track--active,
  .input-range__slider {
    background: #2185d0;
  }
  .input-range__slider {
    border: 1px solid #2185d0;
  }
`;
