import React from "react";
import { Link } from "react-router-dom";
import { Header, Segment, Table } from "semantic-ui-react";
import { EmptyMessage } from "../../styles/common";
import moment from "moment";

type TervCtrlEvent = {
  userId: string;
  userName: string;
  floorName: string;
  ervName: string;
  filterRemainPercent: string;
  filterRemainTimeInHours: number;
  lastFilterChangedAt: Date;
};
type TErvsFilterEventListProps = {
  ervFilterEvents: TervCtrlEvent[];
  orgId: string;
};
type TErvsFilterEventItemProps = {
  event: TervCtrlEvent;
  orgId: string;
};

function ErvsFilterEventList({ ervFilterEvents = [], orgId }: TErvsFilterEventListProps) {
  return (
    <Segment>
      <Header as="h3">Erv filter remains</Header>
      <Table compact="very" celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>user</Table.HeaderCell>
            <Table.HeaderCell>floor</Table.HeaderCell>
            <Table.HeaderCell>erv</Table.HeaderCell>
            <Table.HeaderCell>남은 필터 수명</Table.HeaderCell>
            <Table.HeaderCell>마지막 필터 교체일</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {ervFilterEvents.length > 0 ? (
            ervFilterEvents.map((event, index) => <ErvsFilterEventItem key={index} event={event} orgId={orgId} />)
          ) : (
            <Table.Row>
              <Table.Cell colSpan="5">
                <EmptyMessage>Empty</EmptyMessage>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Segment>
  );
}

export default ErvsFilterEventList;

const ErvsFilterEventItem = ({ event, orgId }: TErvsFilterEventItemProps) => (
  <Table.Row>
    <Table.Cell>
      <Link to={`/orgs/${orgId}/users/${event.userId}`}>{event.userName}</Link>
    </Table.Cell>
    <Table.Cell>{event.floorName}</Table.Cell>
    <Table.Cell>{event.ervName}</Table.Cell>
    <Table.Cell>
      {(event.filterRemainTimeInHours / 24).toFixed(2)} 일 ({parseInt(event.filterRemainPercent)} % 남음)
    </Table.Cell>
    <Table.Cell>
      {" "}
      {moment(event.lastFilterChangedAt).format("YYYY-MM-DD, a h:mm")} ({moment().diff(event.lastFilterChangedAt, "day")}일 경과){" "}
    </Table.Cell>
  </Table.Row>
);
