import React from "react";
import { Item, Segment, Icon, Header, Button, Card } from "semantic-ui-react";
import { toastSuccess, toastError } from "@util";
import { SectionCard } from "@Components/SectionCard";
import { INIT_USER_PAGE } from "@graphql/query";
import { CREATE_SECTION, REMOVE_SECTION, UPDATE_SECTION, ALLOCATE_AWAIR_IN_SECTION, UNALLOCATE_AWAIR_IN_SECTION } from "@graphql/mutation";
import { useMutation } from "@apollo/react-hooks";
import { IFloor, ISection, IUser } from "@interfaces";
import { useModal } from "@Hooks/useModal";
import { SectionForm } from "@Components/Modals";
import { EmptyMessage } from "@styles/common";

function SectionsSegment({ curFloor, user }: { curFloor: IFloor; user: IUser }) {
  const { ModalPortal: SectionModalPortal, openModal: openSectionModal, closeModal: closeSectionModal } = useModal();

  // section 추가
  const [createSection] = useMutation(CREATE_SECTION, {
    onCompleted(data) {
      toastSuccess("section 생성", `name : ${data.createSection.name}`);
    },
    onError(error) {
      console.error("error", error);
      toastError("section 생성 실패.", error.message);
    },
    refetchQueries: [{ query: INIT_USER_PAGE, variables: { userId: user._id } }],
  });

  // section 모달 open
  const onClickSectionModalCreate = () => {
    if (!curFloor || !curFloor.location) {
      toastError("Service Floor를 먼저 선택해주세요");
      return;
    }
    openSectionModal();
  };

  // section 생성 제출
  const sectionCreateSubmit = async (sectionData: any) => {
    await createSection({ variables: { floorId: curFloor._id, data: { name: sectionData.name, profile: sectionData.profile } } });
    closeSectionModal();
  };

  return (
    <>
      <Segment>
        <Header>
          Sections
          <Button size="mini" onClick={onClickSectionModalCreate} style={{ marginLeft: 10 }}>
            <Icon name="plus" />
            new
          </Button>
        </Header>
        {!curFloor?.sections?.length ? (
          <EmptyMessage>Empty</EmptyMessage>
        ) : (
          <Card.Group itemsPerRow={2}>
            {curFloor?.sections?.map((section: ISection) => (
              <SectionCard key={section._id} awairs={section.awairs} section={section} user={user} floor={curFloor}>
                <Card.Content>
                  <SectionCard.Label>{section.name}</SectionCard.Label>
                  <SectionCard.Awair />
                  <SectionCard.Description profile={section.profile} />
                  <SectionCard.Option />
                </Card.Content>
                <Card.Content extra>
                  <SectionCard.DetailBtns />
                  <SectionCard.Detail />
                </Card.Content>
              </SectionCard>
            ))}
          </Card.Group>
        )}
      </Segment>
      <SectionModalPortal title={`${user?.name} / ${curFloor?.location?.kaptInfo?.kaptName} / ${curFloor?.name}`}>
        <SectionForm onSubmit={sectionCreateSubmit} />
      </SectionModalPortal>
    </>
  );
}

export default SectionsSegment;
