import React, { useState, useCallback } from "react";
import { Button, Input, Modal, Form, TextArea } from "semantic-ui-react";
import { toastSuccess, toastError } from "@util";
import { SEND_NOTIFICATION_TO_USER, SEND_NOTIFICATION_TO_USERS_IN_ORGANIZATION } from "@graphql/mutation";
import { useMutation } from "@apollo/react-hooks";

type TOpenProps = {
  type: string;
  name: string;
  id: string;
  status?: string;
};

function ManualAppPushModal({ type, name, id, status }: TOpenProps) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const headerTitle = type === "user" ? `앱 푸시 (user: ${name})` : `앱 푸시 (org: ${name})`;

  const [sendNotificationToUser] = useMutation(SEND_NOTIFICATION_TO_USER, {
    onCompleted(data) {
      setTitle("");
      setBody("");
      setOpen(false);
      toastSuccess(`성공하였습니다`);
      setIsDisabled(false);
    },
    onError(error) {
      toastError(`실패했습니다`, error.message);
      setIsDisabled(false);
    },
  });

  const [sendNotificationToUsersInOrganization] = useMutation(SEND_NOTIFICATION_TO_USERS_IN_ORGANIZATION, {
    onCompleted(data) {
      setTitle("");
      setBody("");
      setOpen(false);
      toastSuccess(`성공하였습니다`);
      setIsDisabled(false);
    },
    onError(error) {
      toastError(`실패했습니다`, error.message);
      setIsDisabled(false);
    },
  });

  const onSubmit = useCallback(async () => {
    setIsDisabled(true);
    if (type === "user") {
      await sendNotificationToUser({ variables: { userId: id, title: title, body: body } });
    } else if (type === "org") {
      await sendNotificationToUsersInOrganization({ variables: { organizationId: id, title: title, body: body } });
    } else {
      return;
    }
  }, [type, id, title, body]);

  // const onSubmit = useCallback(async () => {
  //   setIsDisabled(true);
  //   const data = {
  //     title: title,
  //     body: body,
  //   };
  //   try {
  //     let result;
  //     let okNum = 0;
  //     let errorNum = 0;
  //     let totalNum = 0;
  //     if (type === "user") {
  //       result = await a pi.sendAppPushForUser(id, data);
  //     } else if (type === "org") {
  //       result = await a pi.sendAppPushForOrg(id, data);
  //     } else {
  //       return;
  //     }

  //     result.forEach((cur) => {
  //       cur.status === "ok" && okNum++;
  //       cur.status === "error" && errorNum++;
  //       totalNum++;
  //     });
  //     setTitle("");
  //     setBody("");
  //     setOpen(false);
  //     toastSuccess(`성공하였습니다`, `total:${totalNum}, ok:${okNum}, error:${errorNum}`);
  //   } catch (e) {
  //     toastError("실패하였습니다.");
  //   } finally {
  //     setIsDisabled(false);
  //   }
  // }, [type, name, id, title, body]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button size="mini" disabled={status === "filter" ? true : false}>
          앱 푸시 모달
        </Button>
      }
    >
      <Modal.Header>{headerTitle}</Modal.Header>
      <Modal.Content>
        <Form onSubmit={onSubmit}>
          <Form.Field required>
            <Input type="text" placeholder="제목" value={title} onChange={(e) => setTitle(e.target.value)} required={true} />
          </Form.Field>
          <Form.Field required>
            <TextArea style={{ height: 100 }} placeholder="본문" value={body} onChange={(e: any) => setBody(e.target.value)} required={true} />
          </Form.Field>
          <Button type="submit" fluid disabled={isDisabled}>
            Submit
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
}

export default ManualAppPushModal;
