import React, { useState, useRef, useEffect } from "react";
import type { IFloor } from "@interfaces";
import { toastError } from "@util";
import { useLazyQuery } from "@apollo/react-hooks";
import moment from "moment";
import { INIT_DASHBOARD_PAGE } from "@graphql/query";
import { lighten } from "polished";

type TProps = {
  userId?: string;
  floorId?: string;
  oneDaySample: number;
  setSelectedSectionIDs?: any;
  setSelectedErvIDs?: any;
};

function useDashboardChart({ userId, floorId, oneDaySample, setSelectedSectionIDs, setSelectedErvIDs }: TProps) {
  const [startDate, setStartDate] = useState(moment(Date.now()).startOf("day"));
  const [endDate, setEndDate] = useState(moment());
  const [startDateInput, setStartDateInput] = useState<any>(moment(Date.now()).startOf("day"));
  const [endDateInput, setEndDateInput] = useState<any>(moment());

  const [graphData, setGraphData] = useState(null);
  const [sectionIDs, setSectionIDs] = useState<any>([]);
  const [ervIDs, setErvIDs] = useState<any>([]);
  // const colors = ["#a6cee3", "#b2df8a", "#fb9a99", "#fdbf6f", "#cab2d6", "#ffff99", "#1f78b4", "#33a02c", "#000000"];
  // prettier-ignore
  const colors1 = ["#2abb9b", "#29f1c3", "#23cba7", "#7befb2", "#4daf7c", "#a2ded0", "#4ecdc4", "#91b496", "#049372", "#1e824c" ,"#00b16a", "#03a678", "#019875", "#26a65b", "#00e640", "#26c281", "#90c695", "#87d37c", "#3fc380", "#16a085", "#2ecc71", "#86e2d5", "#c8f7c5", "#36d7b7", "#66cc99" ];
  // prettier-ignore
  const colors2 = ["#fabe58","#f5ab35","#eb974e","#e87e04","#f89406","#f4b350","#f2784b","#eb9532","#ffcb05","#fcd670","#e67e22","#f39c12","#f9bf3b","#f27935","#f9690e","#d35400", "#e47833", "#f15a22", "#fcb941", "#f9b42d"]
  // const sectionIDsRef = useRef<any>([]);
  // const [selectedSectionID, setSelectedSectionID] = useState("");

  const yAxisIndexForHide = useRef(0);

  const [initDashboardPage, { data, loading }] = useLazyQuery(INIT_DASHBOARD_PAGE, {
    onCompleted(data: any) {
      console.log("data", data);
      let newGraphData: any = {
        temp: [],
        humid: [],
        CO2: [],
        VOC: [],
        PM25: [],
        awairScore: [],
        tempPrediction: [],
        humidPrediction: [],
        CO2Prediction: [],
        VOCPrediction: [],
        PM25Prediction: [],
        awairScorePrediction: [],
        fanspeed: [],
        vsp: [],
      };

      let fanspeedDatas = [];
      let vspDatas = [];
      let aqiDatas = {};

      const floor = data.user.floors.find((cur: IFloor) => cur._id === floorId);

      const sectionIDs = floor?.sections?.filter((cur: any) => cur?.indoorAtmLogsByDate?.measurements).map((cur: any) => cur._id);
      setSectionIDs(sectionIDs);
      const ervIDs = floor?.ervs?.filter((cur: any) => cur?.ervLogsByDate?.actions?.length).map((cur: any) => cur._id);
      setErvIDs(ervIDs);

      for (const erv of floor.ervs) {
        if (ervIDs.includes(erv._id)) {
          setSelectedErvIDs([erv._id]);
          break;
        }
      }

      for (const section of floor.sections) {
        if (sectionIDs.includes(section._id)) {
          setSelectedSectionIDs([section._id]);
          break;
        }
      }

      // const aaaa =[]
      // for (const erv of floor.ervs) {
      //   if(ervIDs.includes(erv._id)){
      //     aaaa.push(erv._id)
      //   }
      // }
      // setSelectedErvIDs(aaaa);

      // const bbbb = [];
      // for (const section of floor.sections) {
      //   if(sectionIDs.includes(section._id)){
      //     bbbb.push(section._id);
      //   }
      // }
      // setSelectedSectionIDs(bbbb);

      // for (const floor of data.user.floors) {
      // 팬스피드 데이터 가공
      for (let [index, erv] of floor.ervs.entries()) {
        if (!erv.ervLogsByDate || !erv.ervLogsByDate.actions.length) continue;

        const [basic, pro] = formatFanspeedDataForGraph(erv.ervLogsByDate.isVsp, erv.ervLogsByDate.actions);
        if (erv.ervLogsByDate.isVsp === true) {
          vspDatas.push({ name: `ventLevel[erv:${erv.name}]`, ervId: erv._id, data: pro.ventLevel, color: colors2[index], yAxis: 1 });
          // vspDatas.push({ name: `vspIn[erv:${erv.name}]`, data: pro.vspIn });
          // vspDatas.push({ name: `vspOut[erv:${erv.name}]`, data: pro.vspOut });
          yAxisIndexForHide.current = 0;
        } else {
          fanspeedDatas.push({ name: `fanspeed[erv:${erv.name}]`, ervId: erv._id, data: basic.fanspeed, color: colors2[index], yAxis: 1 });
          fanspeedDatas.push({ name: `ventLevel[erv:${erv.name}]`, ervId: erv._id, data: basic.ventLevel, color: colors2[index], yAxis: 2 });
          yAxisIndexForHide.current = 1;
        }

        // basic.length > 0 ? fanspeedDatas.push({ name: `fanspeed[ erv:${erv.name} / floor:${floor.name} ]`, data: basic }) : (yAxisIndexForHide.current = 0);
        // pro.length > 0
        //   ? fanspeedDatas.push({ name: `VSP[ erv:${erv.name} / floor:${floor.name} ]`, data: pro, yAxis: 1, color: "#4080B0" })
        //   : (yAxisIndexForHide.current = 1);
      }

      // innerAQI 데이터 가공
      for (let [index, section] of floor.sections.entries()) {
        if (!section.indoorAtmLogsByDate || !section.indoorAtmLogsByDate.measurements) continue;
        let i_temp = [];
        let i_humid = [];
        let co2 = [];
        // let co2_15 = [];
        // let co2_90 = [];
        let voc = [];
        // let voc_15 = [];
        // let voc_90 = [];
        let i_pm25 = [];
        let awairScores = [];
        let p_i_temp = [];
        let p_i_humid = [];
        let p_co2 = [];
        let p_voc = [];
        let p_i_pm25 = [];
        let p_awairScores = [];

        for (const item of section.indoorAtmLogsByDate.measurements) {
          const ts = moment(item.logtime).valueOf();
          item.temp && i_temp.push([ts, item.temp]);
          item.humid && i_humid.push([ts, item.humid]);
          item.co2 && co2.push([ts, item.co2]);
          // item.co2_15 && co2_15.push([ts, item.co2_15]);
          // item.co2_90 && co2_90.push([ts, item.co2_90]);
          item.voc && voc.push([ts, item.voc]);
          // item.voc_15 && voc_15.push([ts, item.voc_15]);
          // item.voc_90 && voc_90.push([ts, item.voc_90]);
          item.pm25 && i_pm25.push([ts, item.pm25]);
          item.awairScore && awairScores.push([ts, item.awairScore]);
        }

        for (const item of section.indoorAtmLogsByDate.predictions) {
          const ts = moment(item.logtime).valueOf();
          item.temp && p_i_temp.push([ts, item.temp]);
          item.humid && p_i_humid.push([ts, item.humid]);
          item.co2 && p_co2.push([ts, item.co2]);
          item.voc && p_voc.push([ts, item.voc]);
          item.pm25 && p_i_pm25.push([ts, item.pm25]);
          item.awairScore && p_awairScores.push([ts, item.awairScore]);
        }

        newGraphData.temp.push({ name: section.name, sectionId: section._id, data: i_temp, color: colors1[index] });
        newGraphData.humid.push({ name: section.name, sectionId: section._id, data: i_humid, color: colors1[index] });
        newGraphData.CO2.push({ type: "line", name: section.name, sectionId: section._id, data: co2, color: colors1[index] });
        // newGraphData.CO2.push({
        //   type: "line",
        //   name: `${section.name} (15min)`,
        //   data: co2_15,
        // });
        // newGraphData.CO2.push({
        //   type: "line",
        //   name: `${section.name} (90min)`,
        //   data: co2_90,
        // });
        newGraphData.VOC.push({ type: "line", sectionId: section._id, name: section.name, data: voc, color: colors1[index] });
        // newGraphData.VOC.push({
        //   type: "line",
        //   name: `${section.name} (15min)`,
        //   data: voc_15,
        // });
        // newGraphData.VOC.push({
        //   type: "line",
        //   name: `${section.name} (90min)`,
        //   data: voc_90,
        // });
        newGraphData.PM25.push({ name: section.name, sectionId: section._id, data: i_pm25, color: colors1[index] });
        newGraphData.awairScore.push({ name: section.name, sectionId: section._id, data: awairScores, color: colors1[index], yAxis: 0 });

        newGraphData.tempPrediction.push({ name: `${section.name}(예측)`, sectionId: section._id, data: p_i_temp, color: lighten(0.2, colors1[index]) });
        newGraphData.humidPrediction.push({ name: `${section.name}(예측)`, sectionId: section._id, data: p_i_humid, color: lighten(0.2, colors1[index]) });
        newGraphData.CO2Prediction.push({
          type: "line",
          name: `${section.name}(예측)`,
          sectionId: section._id,
          data: p_co2,
          color: lighten(0.2, colors1[index]),
        });
        newGraphData.VOCPrediction.push({
          type: "line",
          sectionId: section._id,
          name: `${section.name}(예측)`,
          data: p_voc,
          color: lighten(0.2, colors1[index]),
        });
        newGraphData.PM25Prediction.push({ name: `${section.name}(예측)`, sectionId: section._id, data: p_i_pm25, color: lighten(0.2, colors1[index]) });
        newGraphData.awairScorePrediction.push({
          name: `${section.name}(예측)`,
          sectionId: section._id,
          data: p_awairScores,
          color: lighten(0.2, colors1[index]),
          yAxis: 0,
        });
      }

      // outerAQI 데이터 가공
      if (floor.location && floor.location.outdoorAtmLogsByDate && floor.location.outdoorAtmLogsByDate.measurements) {
        let o_temp = [];
        let o_humid = [];
        let o_pm25 = [];

        for (const item of floor.location.outdoorAtmLogsByDate.measurements) {
          const ts = moment(item.logtime).valueOf();
          item.temp && o_temp.push([ts, item.temp]);
          item.humid && o_humid.push([ts, item.humid]);
          item.pm25 && o_pm25.push([ts, item.pm25]);
        }

        newGraphData.temp.push({ name: `Outside`, data: o_temp });
        newGraphData.humid.push({ name: `Outside`, data: o_humid });
        newGraphData.PM25.push({ name: `Outside`, data: o_pm25 });
      }

      newGraphData.fanspeed = fanspeedDatas;
      newGraphData.vsp = vspDatas;
      setGraphData(newGraphData);
    },
    onError(error) {
      console.error(error);
      toastError(`조회 실패.`, error.message);
    },
  });

  useEffect(() => {
    if (!startDate || !endDate) return;
    const sample = Math.min(Math.floor(oneDaySample * (moment(endDate).diff(moment(startDate), "minutes") / (24 * 60))), 4000); //최대 4000
    initDashboardPage({ variables: { userId: userId, from: startDate.valueOf(), to: endDate.valueOf(), sample: sample } });
  }, [startDate, endDate, oneDaySample]);

  const formatFanspeedDataForGraph = (isVsp: boolean, rawData: any[]): any => {
    const proFanspeedData: any = { ventLevel: [], vspIn: [], vspOut: [] };
    const basicFanspeedData: any = { fanspeed: [], ventLevel: [] };
    rawData.forEach((row: any) => {
      if (isVsp) {
        // proFanspeedData.vspIn.push([moment(row.logtime).valueOf(), row.vspIn]);
        // proFanspeedData.vspOut.push([moment(row.logtime).valueOf(), row.vspOut]);
        proFanspeedData.ventLevel.push([moment(row.logtime).valueOf(), row.ventLevel]);
      } else {
        basicFanspeedData.fanspeed.push([moment(row.logtime).valueOf(), row.fanspeed]);
        basicFanspeedData.ventLevel.push([moment(row.logtime).valueOf(), row.ventLevel]);
      }
    });
    return [basicFanspeedData, proFanspeedData];
  };

  const handleDatesChange = (dates: any) => {
    dates.startDate && setStartDateInput(moment(dates.startDate).startOf("day"));
    dates.endDate && setEndDateInput(moment(dates.endDate).endOf("day"));
  };

  const submitDates = () => {
    if (!startDateInput || !endDateInput) return;
    setStartDate(startDateInput.startOf("day"));
    setEndDate(endDateInput.endOf("day"));
    setGraphData(null);
  };

  const getCurFloor = (floors: any) => {
    if (!floors) return;
    return floors.find((cur: IFloor) => cur._id === floorId);
  };

  const checkIsActiveAwair = (sectionID: string) => {
    return sectionIDs.includes(sectionID);
  };

  const checkIsActiveErv = (ervId: string) => {
    return ervIDs.includes(ervId);
  };
  return {
    data,
    loading,
    startDate,
    endDate,
    startDateInput,
    endDateInput,
    graphData,
    getCurFloor,
    handleDatesChange,
    submitDates,
    checkIsActiveAwair,
    checkIsActiveErv,
  };
}

export { useDashboardChart };
