import React, { useState, useCallback, useMemo } from "react";
import DefaultLayout from "@Layouts/DefaultLayout";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";
import { Loader, Segment, Header, Form, Button, Divider, Grid, Radio, Icon, Checkbox, Modal, TextArea, Dropdown } from "semantic-ui-react";
import { toastSuccess, toastError } from "@util";
import { TierOptionSegment, ModalCloseButton } from "@styles/common";
import { policy1, policy2, policy3 } from "@Policy";
import { Helmet } from "react-helmet";
import { INIT_CONTRACT_REGISTRATION_PAGE } from "@graphql/query";
import { CREATE_CONTRACT, REMOVE_CUSTOMER_UID_IN_USER, ADD_CARD_INFO_IN_USER } from "@graphql/mutation";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Breadcrumbs from "@Components/Common/Breadcrumbs";
import AddCardInfoForm from "@Components/Common/Payment/AddCardInfoForm";
import CardInfoItem from "@Components/Common/Payment/CardInfoItem";
import { IFloor, IServiceTier } from "@interfaces";

function ContractReg() {
  const { userId, orgId }: { userId: string; orgId: string } = useParams();

  const [floorOptions, setFloorOptions] = useState([]);
  const [contractType, setContractType] = useState("membership"); // plan || membership

  const [selectedTierId, setSelectedTierId] = useState("");
  const [selectedFloorId, setSelectedFloorId] = useState(null);
  const [selectedCustomerUid, setSelectedCustomerUid] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const [isShowPolicy1, setIsShowPolicy1] = useState(false);
  const [isShowPolicy2, setIsShowPolicy2] = useState(false);
  const [isShowPolicy3, setIsShowPolicy3] = useState(false);

  const [isShowAddCardInfoForm, setIsShowAddCardInfoForm] = useState(false);

  const history = useHistory();

  const contractTypeOptions = useMemo(
    () => [
      { key: "membership", text: "membership", value: "membership" },
      { key: "plan", text: "plan", value: "plan" },
    ],
    []
  );

  const { loading, data } = useQuery(INIT_CONTRACT_REGISTRATION_PAGE, {
    variables: { userId: userId },
    onCompleted(data) {
      const opts = data.user.floors
        .filter((floor: IFloor) => floor) //null 값이 있는 경우 제외
        .map((floor: IFloor) => ({
          key: floor._id,
          text: `${floor?.location?.kaptInfo?.kaptAddr} ${floor.name}`,
          value: floor._id,
        }));
      setFloorOptions(opts);
      if (data.user.floors.length) setSelectedFloorId(data.user.floors[0]._id);

      const defaultServiceTier = data.serviceTiers.find((serviceTier: IServiceTier) => serviceTier.price === 33000);
      if (defaultServiceTier) setSelectedTierId(defaultServiceTier._id);
    },
  });

  // 유효성 체크
  const isValidation = useCallback(() => {
    if (!selectedCustomerUid) {
      toastError("입력 오류", "결제 카드를 선택해 주세요");
      return false;
    }
    if (!selectedFloorId) {
      toastError("입력 오류", "Floor를 선택해 주세요");
      return false;
    }
    if (!selectedTierId) {
      toastError("입력 오류", "Service Tier를 선택해 주세요");
      return false;
    }
    if (!isChecked) {
      toastError("입력 오류", "약관에 동의해 주세요");
      return false;
    }
    return true;
  }, [isChecked, selectedTierId, selectedFloorId, selectedCustomerUid]);

  // 전기결제 신청
  const [createContract] = useMutation(CREATE_CONTRACT, {
    onCompleted(data) {
      toastSuccess("정기결제신청이 완료되었습니다.");
      history.push(`/orgs/${orgId}/users/${userId}/contracts/${data.createContract._id}/billings`);
    },
    onError(error) {
      console.error("error", error);
      toastError("정기결제신청이 실패하였습니다.", error.message);
    },
  });

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (!isValidation()) return;
    setIsLoading(true);

    const submitData = {
      serviceTier: selectedTierId,
      type: contractType,
      floor: selectedFloorId,
    };

    createContract({ variables: { userId: userId, data: submitData, customerUid: selectedCustomerUid } });
  };

  const getMonthText = useCallback(() => {
    const currentTier = data.serviceTiers.find((tier: IServiceTier) => tier._id === selectedTierId);
    if (currentTier && currentTier.months) {
      return `정기결제(${currentTier.months}개월)`;
    } else {
      return "월 정기결제";
    }
  }, [data, selectedTierId]);

  const getMonthDuration = useCallback(() => {
    const currentTier = data.serviceTiers.find((tier: IServiceTier) => tier._id === selectedTierId);
    let months = 1;
    if (currentTier && currentTier.months) months = currentTier.months;

    return `${moment().format("YYYY-MM-DD")} ~ ${moment().add(months, "months").format("YYYY-MM-DD")}`;
  }, [data, selectedTierId]);

  // 결제 카드 추가
  const [addCardInfoInUser] = useMutation(ADD_CARD_INFO_IN_USER, {
    onCompleted(data) {
      toastSuccess("결제 카드 정보가 추가되었습니다");
      setIsShowAddCardInfoForm(false);
    },
    onError(error) {
      toastError("결제 카드 정보 추가를 실패하였습니다", error.message);
      console.error(error);
    },
    refetchQueries: [{ query: INIT_CONTRACT_REGISTRATION_PAGE, variables: { userId: userId } }],
  });

  // 결제 카드 삭제
  const [removeCustomerUidInUser] = useMutation(REMOVE_CUSTOMER_UID_IN_USER, {
    onCompleted(data) {
      setSelectedCustomerUid("");
      toastSuccess("결제 카드 정보가 삭제되었습니다");
    },
    onError(error) {
      toastError("결제 카드 정보 삭제가 실패하였습니다", error.message);
      console.error("error", error);
    },
    refetchQueries: [{ query: INIT_CONTRACT_REGISTRATION_PAGE, variables: { userId: userId } }],
  });

  if (loading)
    return (
      <DefaultLayout>
        <Loader active />
      </DefaultLayout>
    );

  return (
    <DefaultLayout>
      <Helmet>
        <title>{data.user.name}(서비스 등록)</title>
      </Helmet>

      <Segment>
        <Header as="h2">
          서비스 등록
          <span style={{ marginLeft: "2rem" }}>
            <Breadcrumbs />
          </span>
        </Header>
        <Divider />
        <Header as="h3">{data.user.name}</Header>
        <Icon name="mail" />
        {data.user.email}
        <br />
        <Icon name="phone" />
        {data.user.phone}
        <br />
        <Icon name="calendar" />
        {moment(data.user.createdAt).format("YYYY-MM-DD, h:mm a")}
      </Segment>

      <Segment>
        <Header as="h3">결제 카드 선택</Header>
        <div>
          {data.getCustomerUidsInUser.map((customerUid: string) => (
            <CardInfoItem
              key={customerUid}
              customerUid={customerUid}
              selectedCustomerUid={selectedCustomerUid}
              setSelectedCustomerUid={setSelectedCustomerUid}
              removeCustomerUidInUser={removeCustomerUidInUser}
              userId={userId}
            />
          ))}

          {selectedCustomerUid ? <p>선택된 카드번호의 뒷 네자리는 {selectedCustomerUid.split("_")[1]} 입니다.</p> : <p>선택된 결제 카드가 없습니다.</p>}
        </div>
        <AddCardCheckbox
          label="결제 카드 정보를 추가합니다."
          checked={isShowAddCardInfoForm}
          onClick={() => setIsShowAddCardInfoForm(!isShowAddCardInfoForm)}
        />
        {isShowAddCardInfoForm && <AddCardInfoForm userId={userId} addCardInfoInUser={addCardInfoInUser} />}
      </Segment>

      <Segment>
        <Header as="h3">Floor 선택</Header>
        <FloorDropdown selection options={floorOptions} value={selectedFloorId} onChange={(e: Event, data: any) => setSelectedFloorId(data.value)} />
      </Segment>

      <Segment>
        <Header as="h3" style={{ marginBottom: 40 }}>
          Service Tier 선택{" "}
        </Header>
        <ContractTypeDropdown selection options={contractTypeOptions} value={contractType} onChange={(e: Event, data: any) => setContractType(data.value)} />

        <Grid>
          {data.serviceTiers
            .filter((tier: IServiceTier) => tier.type === contractType && tier.status === "active")
            .sort((a: IServiceTier, b: IServiceTier) => (a.price ? a.price : 0) - (b.price ? b.price : 0))
            .map((tier: IServiceTier, idx: number) => (
              <Grid.Column key={tier._id} mobile={8} tablet={8} computer={4}>
                <TierOptionSegment onClick={() => setSelectedTierId(tier._id)}>
                  <Radio label={tier.name} name={tier.name} value={tier._id} checked={selectedTierId === tier._id} />
                  {contractType === "plan" && tier.price ? (
                    <div>
                      <p className="price">
                        {tier.price.toLocaleString()}원 ({tier.months}개월)
                      </p>
                      <p>무료 필터 배송: {tier.filterAmount} 회 </p>
                    </div>
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: tier?.desc || "" }}></div>
                  )}
                </TierOptionSegment>
              </Grid.Column>
            ))}
        </Grid>
        {selectedTierId ? (
          <p style={{ marginTop: 20 }}>
            {getMonthText()}
            <br />
            기간 : {getMonthDuration()}
          </p>
        ) : (
          <p style={{ marginTop: 20 }}>serviceTier가 선택되지 않았습니다.</p>
        )}
      </Segment>

      <Segment>
        {/* <Form onSubmit={onSubmit}> */}
        {/* <Form.Field> */}
        <CheckboxGroup>
          <Checkbox
            // label={`이용 약관 및 개인정보 처리방침에 동의합니다.`}
            onClick={(e, { checked }) => setIsChecked(!!checked)}
            checked={isChecked}
          />

          <span>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsShowPolicy1(true);
              }}
            >
              전자금융거래 기본약관
            </a>{" "}
            ,{" "}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsShowPolicy2(true);
              }}
            >
              개인정보 처리방침
            </a>{" "}
            및{" "}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsShowPolicy3(true);
              }}
            >
              자동 결제 약관
            </a>
            에 동의합니다.
          </span>
        </CheckboxGroup>
        {/* </Form.Field> */}

        {/* <Button type="submit" fluid disabled={isLoading ? true : false}>
          {" "}
          정기결제신청{" "}
        </Button> */}
        <Button onClick={onSubmit} fluid disabled={isLoading ? true : false} style={{ marginTop: 20 }}>
          정기결제신청
        </Button>
        {/* </Form> */}
      </Segment>

      <Modal open={isShowPolicy1} onClose={() => setIsShowPolicy1(false)}>
        <ModalCloseButton onClick={() => setIsShowPolicy1(false)}>
          <Icon name="close" size="large" />
        </ModalCloseButton>

        <Modal.Header>전자금융거래 기본약관</Modal.Header>
        <Modal.Content>
          <Form>
            <StyledTextArea readOnly defaultValue={policy1} />
          </Form>
        </Modal.Content>
      </Modal>

      <Modal open={isShowPolicy2} onClose={() => setIsShowPolicy2(false)}>
        <ModalCloseButton onClick={() => setIsShowPolicy2(false)}>
          <Icon name="close" size="large" />
        </ModalCloseButton>

        <Modal.Header>개인정보 처리방침</Modal.Header>
        <Modal.Content>
          <Form>
            <StyledTextArea readOnly defaultValue={policy2} />
          </Form>
        </Modal.Content>
      </Modal>

      <Modal open={isShowPolicy3} onClose={() => setIsShowPolicy3(false)}>
        <ModalCloseButton onClick={() => setIsShowPolicy3(false)}>
          <Icon name="close" size="large" />
        </ModalCloseButton>

        <Modal.Header>자동 결제 약관</Modal.Header>
        <Modal.Content>
          <Form>
            <StyledTextArea readOnly defaultValue={policy3} />
          </Form>
        </Modal.Content>
      </Modal>
    </DefaultLayout>
  );
}

export default ContractReg;

const FloorDropdown = styled(Dropdown)`
  /* margin-left: 1em; */
`;

const ContractTypeDropdown = styled(Dropdown)`
  position: absolute !important;
  top: 20px;
  right: 14px;
`;

const CheckboxGroup = styled.div`
  label:before {
    transform: scale(1.5);
  }

  span {
    margin-left: 1rem;
  }
`;

const StyledTextArea = styled(TextArea)`
  min-height: 300px;
`;

const AddCardCheckbox = styled(Checkbox)`
  margin: 30px 0;
`;
