import React from "react";
import type {ReactNode} from "react"; 
import { Card, Image } from "semantic-ui-react";
import {useSectionCardContext} from "../useSectionCardContext";
import type { IAwair } from "@interfaces";
import styled from "styled-components";

type TProps = {
  children: ReactNode
}

function Label({children}: TProps) {
  const  { awairs } : {awairs: IAwair[]} = useSectionCardContext();
  return <H2>
  {children || 'NO NAME'}
  {!!awairs.length && <StyledImage src="/awair_r2.png"  verticalAlign="middle" />}        
  </H2>
}
export { Label };

const H2 = styled.h2`
  font-size:14px;
  margin-bottom:4px;
  color: black;
`;

const StyledImage = styled(Image)`
  width: 30px;
  margin-left:3px;
`