import React from "react";
import styled from "styled-components";

interface Props {
  schedules: any;
  locationColors: any;
}
const ONE_HOUR_WIDTH = 20;
const START_HOUR = 9;
const END_HOUR = 22;

const dayOfTheWeekArray = [
  { key: 1, text: "월", value: 1 },
  { key: 2, text: "화", value: 2 },
  { key: 3, text: "수", value: 3 },
  { key: 4, text: "목", value: 4 },
  { key: 5, text: "금", value: 5 },
  { key: 6, text: "토", value: 6 },
  { key: 0, text: "일", value: 0 },
];

export default function SchedulePreview({ schedules, locationColors }: Props) {
  const getTimeBarWidth = (from: string, to: string) => {
    return (Number(to) - Number(from)) * ONE_HOUR_WIDTH;
  };

  const getTimeBarLeft = (from: string) => {
    return (Number(from) - START_HOUR) * ONE_HOUR_WIDTH;
  };

  const getTimeBarColor = (locationId: string) => {
    return locationColors && locationColors[locationId] ? locationColors[locationId] : "#444";
  };

  return (
    <PreviewWrap>
      <div className="guide">
        {[
          START_HOUR,
          START_HOUR + 1,
          START_HOUR + 2,
          START_HOUR + 3,
          START_HOUR + 4,
          START_HOUR + 5,
          START_HOUR + 6,
          START_HOUR + 7,
          START_HOUR + 8,
          START_HOUR + 9,
          START_HOUR + 10,
          START_HOUR + 11,
          START_HOUR + 12,
          START_HOUR + 13,
          START_HOUR + 14,
        ].map((cur, index) => (
          <span key={index} style={{ left: index * ONE_HOUR_WIDTH }}>
            <div className="label">{cur}</div>
          </span>
        ))}
      </div>
      {dayOfTheWeekArray.map((cur: any) => (
        <TimeBarWrap key={cur.key}>
          <p>{cur.text}</p>

          <TimeBar>
            {schedules[cur.value] &&
              schedules[cur.value].map((cur: any, index: number) => (
                <div
                  className="schedule"
                  key={index}
                  style={{
                    backgroundColor: getTimeBarColor(cur.location._id),
                    width: getTimeBarWidth(cur.startHour, cur.endHour),
                    left: getTimeBarLeft(cur.startHour),
                  }}
                ></div>
              ))}
          </TimeBar>
        </TimeBarWrap>
      ))}
    </PreviewWrap>
  );
}

const PreviewWrap = styled.div`
  margin-top: 10px;
  position: relative;
  .guide {
    position: absolute;
    z-index: 10;

    width: ${ONE_HOUR_WIDTH * (END_HOUR - START_HOUR + 1)}px; //  20 * 14
    height: 218px;
    left: 32px;
    /* background-color: red; */
    span {
      position: absolute;
      border-left: 1px dotted #ddd;
      width: 20px;
      height: 218px;

      .label {
        font-size: 10px;
        color: #ddd;
        margin-top: -17px;
        margin-left: -6px;
      }
    }
  }
`;

const TimeBarWrap = styled.div`
  clear: both;
  p {
    float: left;
    margin-bottom: 4;
  }
`;

const TimeBar = styled.div`
  left: 20px;
  overflow: hidden;
  position: relative;
  width: ${ONE_HOUR_WIDTH * (END_HOUR - START_HOUR + 1)}px; //  20 * 14
  height: 20px;
  background-color: #eee;
  border: 1px solid #ccc;

  .schedule {
    position: absolute;
    height: 18px;
  }
`;
