import React from "react";
import type { ReactNode } from "react";
import { Card, Image } from "semantic-ui-react";
import { useSectionCardContext } from "../useSectionCardContext";
import type { IAwair } from "@interfaces";
import { Button } from "semantic-ui-react";
import styled from "styled-components";

function DetailBtns() {
  const { detailType, setDetailType }: any = useSectionCardContext();

  return (
    <Container>
      <StyledButton onClick={() => setDetailType("CURRENT")} active={detailType === "CURRENT"}>
        현재 공기질
      </StyledButton>
      <StyledButton onClick={() => setDetailType("PREDICTION")} active={detailType === "PREDICTION"}>
        예측 공기질
      </StyledButton>
      <StyledButton onClick={() => setDetailType("AQI")} active={detailType === "AQI"}>
        AQI
      </StyledButton>
    </Container>
  );
}
export { DetailBtns };

const Container = styled.div`
  margin-top: -6px;
  margin-bottom: 10px;
  text-align: right;
`;

const StyledButton = styled(Button)`
  padding: 6px 10px !important;
  font-size: 6px !important;
`;

const H2 = styled.h2`
  font-size: 14px;
  margin-bottom: 4px;
  color: black;
`;

const StyledImage = styled(Image)`
  width: 30px;
  margin-left: 3px;
`;
