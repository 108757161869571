import React from "react";
import styled from "styled-components";
import {Rating} from "./Rating";

type TProps = {
  profile: any
}

function Description({profile}: TProps) {
  
  return <StyledDescription>
    <p>공간타입: {profile?.sectionType}</p>
    <p>ERV 영향력: <Rating status={profile?.ervVentInfluenceLevel} /> </p>
    <p> 소음 영향력: <Rating status={profile?.ervNoiseInfluenceLevel} /></p>
  </StyledDescription>
}
export { Description };

const StyledDescription = styled.div`
  margin-top: 10px;
  font-size: 12px;
  p {
    line-height: 0.8em;
    color: black;
  }
`;