import React from "react";
import { Segment } from "semantic-ui-react";
import styled from "styled-components";

type TProps = {
  children: any;
  label: String;
};

function Item({ children, label }: TProps) {
  const getLabelStr = (label: String) => {
    const labelStr = label.split("<br />").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
    return labelStr;
  };

  return (
    <StyledSegment>
      <p className="label">{getLabelStr(label)}</p>
      <p className="value">{children !== 0 && children !== isNaN ? Math.round(children) : "-"}</p>
    </StyledSegment>
  );
}
export { Item };

const StyledSegment = styled(Segment)`
  width: 20% !important;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  .value {
    font-size: 24px;
  }
`;
