import React, { useContext, useState } from "react";
import { Icon, Button, Segment, Header, Dropdown, Loader } from "semantic-ui-react";
import SelfContext from "@selfContext";
import { ToolBtns } from "@styles/common";
import DefaultLayout from "@Layouts/DefaultLayout";

import LocationItem from "@Components/Locations/LocationItem";
import ErvTypeItem from "@Components/Locations/ErvTypeItem";
import { toastSuccess, toastError } from "@util";

import { INIT_NESTS_PAGE } from "@graphql/query";
import { CREATE_LOCATION, UPDATE_LOCATION, CREATE_ERV_TYPE, UPDATE_ERV_TYPE, UPDATE_KAPTDATA_OF_APARTMENT } from "@graphql/mutation";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useModal } from "@Hooks/useModal";
import { ApartmentRegistrationForm } from "@Components/Modals";

function Locations() {
  const { self } = useContext(SelfContext);
  const [location, setLocation] = useState<any>();
  const [locationOptions, setLocationOptions] = useState<any>([]);

  const [ervType, setErvType] = useState<any>();
  const [ervTypeOptions, setErvTypeOptions] = useState<any>([]);
  const [ervTypeName, setErvTypeName] = useState<any>();
  const { ModalPortal : ApartmentRegModalPortal, openModal : openApartmentRegModal, closeModal : closeApartmentRegModal } =  useModal();

  // 초기화 (목록 조회)
  const { loading, data } = useQuery(INIT_NESTS_PAGE, {
    onCompleted(data) {
      setLocationOptions(data.locations.map((location: any) => new Object({ key: location._id, text: location.kaptInfo.kaptName, value: location })));
      setErvTypeOptions(data.ervTypes.map((ervType: any) => new Object({ key: ervType._id, text: ervType.name, value: ervType })));
    },
    onError(error) {
      console.error(error);
    },
  });

  const [createLocation] = useMutation(CREATE_LOCATION, {
    onCompleted(data) {
      toastSuccess("location이 생성되었습니다.");
    },
    onError(error) {
      console.error(error);
      toastError("location 생성 실패", error.message);
    },
    refetchQueries: [{ query: INIT_NESTS_PAGE }],
  });

  const [updateLocation] = useMutation(UPDATE_LOCATION, {
    onCompleted(data) {
      toastSuccess("location이 수정되었습니다.");
    },
    onError(error) {
      console.error(error);
      toastError("location 수정 실패", error.message);
    },
    refetchQueries: [{ query: INIT_NESTS_PAGE }],
  });

  const [updateKaptDataOfApartment] = useMutation(UPDATE_KAPTDATA_OF_APARTMENT, {
    onCompleted(data) {
      toastSuccess("kAptData Updated");
    },
    onError(error) {
      console.error(error);
      toastError("kAptData Updated 실패", error.message);
    },
    refetchQueries: [{ query: INIT_NESTS_PAGE }],
  });

  const [createErvType] = useMutation(CREATE_ERV_TYPE, {
    onCompleted(data) {
      toastSuccess("ERV Type이 생성되었습니다.");
    },
    onError(error) {
      console.error(error);
      toastError("ERV Type 생성 실패", error.message);
    },
    refetchQueries: [{ query: INIT_NESTS_PAGE }],
  });

  const [updateErvType] = useMutation(UPDATE_ERV_TYPE, {
    onCompleted(data) {
      toastSuccess("ERV Type이 수정되었습니다.");
    },
    onError(error) {
      console.error(error);
      toastError("ERV Type이 수정 실패", error.message);
    },
    refetchQueries: [{ query: INIT_NESTS_PAGE }],
  });

  const ApartmentRegSubmit = (data: any) => {
    createLocation({ variables: { data: { kaptCode: data.kaptCode, kaptInfo: { kaptName: data.name } } } });
    closeApartmentRegModal();
  }

  if (loading)
    return (
      <DefaultLayout>
        <Loader active />
      </DefaultLayout>
    );

  if ((self && self.role !== "superAdmin") || !self) return null;

  return (
    <>
      <DefaultLayout>
        <div>
          <Segment>
            <Header as="h3">
              Locations
              <ToolBtns>
                <Button size="mini" onClick={openApartmentRegModal}>
                  <Icon name="plus" />
                  register
                </Button>
              </ToolBtns>
              <br />
              <br />
            </Header>
            <div style={{ height: 35 }}>
              <Dropdown
                placeholder="Select Location"
                search
                fluid
                selection
                allowAdditions
                value={location}
                options={locationOptions}
                onChange={(e, data) => {
                  setLocation(data.value);
                }}
                onAddItem={(e, data) => {
                  setLocation({ kaptInfo: { kaptName: data.value } });
                  setLocationOptions([
                    ...locationOptions,
                    { key: { kaptInfo: { kaptName: data.value } }, text: data.value, value: { kaptInfo: { kaptName: data.value } } },
                  ]);
                }}
              />
            </div>
            {location && (
              <LocationItem key={location._id} ervTypes={data.ervTypes} location={location} createLocation={createLocation} updateLocation={updateLocation} />
            )}
          </Segment>

          <Segment>
            <Header as="h3">ERV Type</Header>
            <Dropdown
              placeholder="Select ErvType"
              search
              fluid
              selection
              allowAdditions
              options={ervTypeOptions}
              onChange={(e, data: any) => {
                setErvTypeName(data.value.name || data.text);
                setErvType(data.value);
              }}
              onAddItem={(e, data) => {
                setErvTypeName(data.value);
                setErvTypeOptions([...ervTypeOptions, { key: data.value, text: data.value, value: data.value }]);
              }}
            />
            {ervType && ervTypeName && (
              <ErvTypeItem key={ervType._id} ervTypeName={ervTypeName} ervType={ervType} createErvType={createErvType} updateErvType={updateErvType} />
            )}
          </Segment>
        </div>
      </DefaultLayout>
      <ApartmentRegModalPortal title="Location 추가">
        <ApartmentRegistrationForm onSubmit={ApartmentRegSubmit}/>
      </ApartmentRegModalPortal>      
    </>
  );
}

export default Locations;
