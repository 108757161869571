import { createGlobalStyle } from "styled-components";
import mediaQueries from "@styles/mediaQueries";

const GlobalStyle = createGlobalStyle`
  .ui.header{
    font-weight:400; 
  }

  ${mediaQueries("sm")`
    .ui.container {
      margin-left: 0em !important;
      margin-right: 0em !important;
    }
  
    .DateRangePicker {
      display: inline !important;
      .DateInput{
        width: 100px !important;
      }

      .DateInput_input{
        font-size: 1rem !important;
      }

      .DateRangePicker_picker{
        left:0px !important;
      }

      .DayPicker{
        width: 300px !important;
        overflow-x: scroll;
      }
    } 
  `}
    
  .image-marker__marker{
    z-index:10;  
   }

  .ui.loader:after {
    border-color: #ccc transparent transparent !important;
  }
  
`;

export default GlobalStyle;
