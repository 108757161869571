import React, { useState } from "react";
import styled from "styled-components";
import { Confirm, Icon } from "semantic-ui-react";

type TCardInfoItem = {
  customerUid: string;
  selectedCustomerUid: string;
  setSelectedCustomerUid: Function;
  removeCustomerUidInUser: Function;
  userId: string;
};

function CardInfoItem({ customerUid, selectedCustomerUid, setSelectedCustomerUid, removeCustomerUidInUser, userId }: TCardInfoItem) {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const getCardNum = () => {
    return `●●●●  ●●●●  ●●●●  ${customerUid.split("_")[1]}`;
  };

  return (
    <>
      <CardButton onClick={() => setSelectedCustomerUid(customerUid)} isChecked={customerUid === selectedCustomerUid}>
        <CardNum>{getCardNum()}</CardNum>
        <DeleteIcon name="close" size="large" onClick={() => setConfirmOpen(true)} />
      </CardButton>
      <Confirm
        open={confirmOpen}
        header={`결제 카드 삭제`}
        content={`정말로 삭제하시겠습니까?`}
        cancelButton="취소"
        confirmButton={{
          content: "삭제",
          negative: true,
        }}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => {
          removeCustomerUidInUser({ variables: { userId: userId, customerUid: customerUid } });
          setConfirmOpen(false);
        }}
      />
    </>
  );
}

export default CardInfoItem;

const CardButton = styled.div<{ isChecked: boolean }>`
  position: relative;
  display: inline-block;
  border: 1px solid #999;
  margin: 10px;
  width: 260px;
  height: 140px;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.isChecked ? `#666` : "none")};
`;

const CardNum = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: #999;
`;

const DeleteIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #999;
`;
