import React from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";

type TProps = {
  isActive: Boolean;
  itemNumber?: any;
  removeMarker: any;
};

function Marker({ isActive, itemNumber, removeMarker }: TProps) {
  if (itemNumber === null || isNaN(itemNumber)) return null;

  return (
    <Container>
      <div className={isActive ? "marker active" : "marker"}>{itemNumber}</div>
      <RemoveBtn>
        <StyledIcon onClick={removeMarker} name="minus" />
      </RemoveBtn>
    </Container>
  );
}
export { Marker };

const Container = styled.div`
  margin-top: 10px;

  .marker {
    background-color: #e87e04;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    color: white;
    display: inline-block;
  }

  .active {
    text-decoration: underline;
  }

  span {
    margin-left: 10px;
    font-size: 10px;
  }
`;

const RemoveBtn = styled.span`
  cursor: pointer;
`;

const StyledIcon = styled(Icon)`
  color: #e87e04;
`;
