import React from "react";
import { Link } from "react-router-dom";
import { Table } from "semantic-ui-react";
import CardItemActions from "@Components/Common/CardItemActions";
import mediaQueries from "@styles/mediaQueries";
import styled from "styled-components";
import moment from "moment";
import { IOrganization } from "@interfaces";
import { useModal } from "@Hooks/useModal";
import { OrgForm } from "@Components/Modals";

type OrgItemProps = {
  org: IOrganization;
  updateOrg: Function;
  handleRemove: (orgId: string) => void;
};

function OrgItem({ org, updateOrg, handleRemove }: OrgItemProps) {
  const { openModal, ModalPortal, closeModal } =  useModal();

  const onSubmit = (data: any) => {
    updateOrg({ variables: { organizationId: org._id, data: data } });
    closeModal();
  }

  return (
    <>
      <StyledTableRow>
        <Table.Cell>
          <Link to={`/orgs/${org._id}`}>{org.name}</Link>
        </Table.Cell>
        <Table.Cell>{org.admins && org.admins.length}</Table.Cell>
        <Table.Cell>{org.managers && org.managers.length}</Table.Cell>
        <Table.Cell>{org.users && org.users.length}</Table.Cell>
        <Table.Cell>{org.locations && org.locations.length}</Table.Cell>
        <Table.Cell>{moment(org.createdAt).format("YYYY-MM-DD")}</Table.Cell>
        <Table.Cell className="last-cell">
          <CardItemActions canModify={true} onModify={openModal} canRemove={true} onRemove={() => handleRemove(org._id)} />
        </Table.Cell>
      </StyledTableRow>
      <ModalPortal title="org">
        <OrgForm name={org.name} locations={org.locations} onSubmit={onSubmit} />
      </ModalPortal>
    </>
  );
}

export default OrgItem;

const StyledTableRow = styled(Table.Row)`
  position: relative;
  .last-cell {
    position: relative;
    ${mediaQueries("sm")`
      position:static;
    `}
  }
`;
