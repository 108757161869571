import React, { useState } from "react";
import { GET_REALTIME_SCORES } from "@graphql/query";
import { useQuery } from "@apollo/react-hooks";

type TProps = {
  floorId: string;
  sectionId?: string;
};

function useScores({ floorId, sectionId }: TProps) {
  const [temperature, setTemperature] = useState(0);
  const [humidity, setHumidity] = useState(0);
  const [co2, setCo2] = useState(0);
  const [voc, setVoc] = useState(0);
  const [pm25, setPm25] = useState(0);
  const [awairScore, setAwairScore] = useState(0);

  const [pmScore, setPmScore] = useState(0);
  const [aqiScore, setAqiScore] = useState(0);
  const [energyScore, setEnergyScore] = useState(0);

  const [aqiAccuracy, setAqiAccuracy] = useState(0);
  const [aqiPrediction, setAqiPrediction] = useState(0);

  const [temperaturePrediction, setTemperaturePrediction] = useState(0);
  const [humidityPrediction, setHumidityPrediction] = useState(0);
  const [co2Prediction, setCo2Prediction] = useState(0);
  const [vocPrediction, setVocPrediction] = useState(0);
  const [pm25Prediction, setPm25Prediction] = useState(0);

  const { data } = useQuery(GET_REALTIME_SCORES, {
    variables: { floorId: floorId },
    fetchPolicy: "no-cache",
    pollInterval: 6000,
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      // let count = 0;
      let logCount = 0;
      let aqiCount = 0;
      let predictionCount = 0;

      let temperatureSum = 0,
        humiditySum = 0,
        co2Sum = 0,
        vocSum = 0,
        pm25Sum = 0,
        awairScoreSum = 0,
        aqiAccuracySum = 0,
        aqiPredictionSum = 0,
        temperaturePredictionSum = 0,
        humidityPredictionSum = 0,
        co2PredictionSum = 0,
        vocPredictionSum = 0,
        pm25PredictionSum = 0;
      for (const section of data.floor.sections) {
        if (sectionId && sectionId !== section._id) continue;

        const log = section?.profile?.latestAtmLog;
        if (log?.awairScore) {
          temperatureSum += log.temp;
          humiditySum += log.humid;
          co2Sum += log.co2;
          vocSum += log.voc;
          pm25Sum += log.pm25;
          awairScoreSum += log.awairScore;
          logCount++;
        }

        const aiPerformance = section?.aiPerformance;
        if (aiPerformance?.aqiAccuracy && aiPerformance?.aqiPrediction) {
          aqiAccuracySum += section?.aiPerformance?.aqiAccuracy;
          aqiPredictionSum += section?.aiPerformance?.aqiPrediction;
          aqiCount++;
        }

        const prediction = section?.profile?.prediction;
        if (prediction) {
          temperaturePredictionSum += prediction.temp;
          humidityPredictionSum += prediction.humid;
          co2PredictionSum += prediction.co2;
          vocPredictionSum += prediction.voc;
          pm25PredictionSum += prediction.pm25;
          predictionCount++;
        }
      }

      temperatureSum && logCount && setTemperature(temperatureSum / logCount);
      humiditySum && logCount && setHumidity(humiditySum / logCount);
      co2Sum && logCount && setCo2(co2Sum / logCount);
      vocSum && logCount && setVoc(vocSum / logCount);
      pm25Sum && logCount && setPm25(pm25Sum / logCount);
      awairScoreSum && logCount && setAwairScore(awairScoreSum / logCount);

      aqiAccuracySum && aqiCount && setAqiAccuracy(Math.floor((aqiAccuracySum / aqiCount) * 100));
      aqiPredictionSum && aqiCount && setAqiPrediction(Math.floor((aqiPredictionSum / aqiCount) * 100));

      temperaturePredictionSum && predictionCount && setTemperaturePrediction(temperaturePredictionSum / predictionCount);
      humidityPredictionSum && predictionCount && setHumidityPrediction(humidityPredictionSum / predictionCount);
      co2PredictionSum && predictionCount && setCo2Prediction(co2PredictionSum / predictionCount);
      vocPredictionSum && predictionCount && setVocPrediction(vocPredictionSum / predictionCount);
      pm25PredictionSum && predictionCount && setPm25Prediction(pm25PredictionSum / predictionCount);

      const floorAiPerformance = data.floor.aiPerformance;
      floorAiPerformance && setPmScore(Math.floor(floorAiPerformance.pmScore * 100));
      floorAiPerformance && setAqiScore(Math.floor(floorAiPerformance.aqiScore * 100));
      floorAiPerformance && setEnergyScore(Math.floor(floorAiPerformance.energyScore * 100));
    },
  });

  return {
    temperature,
    humidity,
    co2,
    voc,
    pm25,
    awairScore,
    pmScore,
    aqiScore,
    energyScore,
    aqiAccuracy,
    aqiPrediction,
    temperaturePrediction,
    humidityPrediction,
    co2Prediction,
    vocPrediction,
    pm25Prediction,
  };
}

export { useScores };
