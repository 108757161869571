import React, { useState } from "react";
import { Button, Input, Modal, Form } from "semantic-ui-react";
import { toastSuccess, toastError } from "@util";
import { CHANGE_PASSWORD } from "@graphql/mutation";
import { useMutation } from "@apollo/react-hooks";

type TOpenProps = {
  accountId: string;
};

function ModifyPasswordModal({ accountId }: TOpenProps) {
  const [open, setOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    onCompleted(data) {
      setOldPassword("");
      setNewPassword("");
      setPasswordCheck("");
      setOpen(false);
      setIsDisabled(false);
      toastSuccess(`비밀번호가 수정되었습니다.`);
    },
    onError(error) {
      console.error(error);
      setIsDisabled(false);
      toastError(`비밀번호 수정이 실패하였습니다.`, error.message);
    },
  });

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (newPassword !== passwordCheck) {
      toastError(`비밀번호가 일치하지 않습니다.`);
      return;
    }
    setIsDisabled(true);
    changePassword({ variables: { accountId: accountId, oldPassword: oldPassword, newPassword: newPassword } });
  };

  return (
    <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open} trigger={<a href="#">비밀번호 변경</a>} style={{ maxWidth: 300 }}>
      <Modal.Header>비밀번호 변경</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field required>
            <Input type="password" placeholder="현재 비밀번호" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} required={true} />
          </Form.Field>
          <Form.Field required>
            <Input type="password" placeholder="새 비밀번호" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required={true} />
          </Form.Field>
          <Form.Field required>
            <Input type="password" placeholder="새 비밀번호 확인" value={passwordCheck} onChange={(e) => setPasswordCheck(e.target.value)} required={true} />
          </Form.Field>
          <Button type="submit" onClick={onSubmit} fluid disabled={isDisabled}>
            Submit
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
}

export default ModifyPasswordModal;
