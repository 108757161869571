import React, { useState } from "react";
import { Button, Form, Input, Modal, Dropdown } from "semantic-ui-react";
import { toastSuccess, toastError } from "@util";
import { INIT_EVENT_DASHBOARD_PAGE } from "@graphql/query";
import { USER_INQUIRY, CREATE_VISIT_REQUEST_FOR_FLOOR } from "@graphql/mutation";
import { useMutation } from "@apollo/react-hooks";

type TProps = {
  locationItems: any;
  orgId: String;
  closeModal: () => void;
};

export const OrderEventCreateForm = ({locationItems, orgId, closeModal }:TProps )  => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [dong, setDong] = useState("");
  const [ho, setHo] = useState("");
  const [kaptCode, setKaptCode] = useState("");

  const onChangePhone = (e: any) => {
    const strOriginal = e.target.value.replace(/[^0-9]/g, "");
    setPhone(strOriginal.replace(/(^01.{1})([0-9]+)([0-9]{4})/, "$1-$2-$3"));
  };

  //1) 유저생성
  const [userInquiry] = useMutation(USER_INQUIRY, {
    onCompleted(data) {
      if (!data.userInquiry.floors.length) {
        return;
      }
      createVisitRequestForFloor({
        variables: {
          floorId: data.userInquiry.floors[0]._id,
          data: {
            type: "filter",
          },
        },
      });
    },
    onError(error) {
      toastError(`user생성을 실패했습니다.`);
      console.error(error.message);
    },
  });

  // 2) 방문 : visitRequest 생성
  const [createVisitRequestForFloor] = useMutation(CREATE_VISIT_REQUEST_FOR_FLOOR, {
    onCompleted(data) {
      toastSuccess(`visitRequest가 추가되었습니다.`, "매니저와 일정을 지정해주세요.");
      closeModal();
    },
    onError(error) {
      toastError(`visitRequest가 추가를 실패했습니다.`);
      console.error(error.message);
    },
    refetchQueries: [{ query: INIT_EVENT_DASHBOARD_PAGE, variables: { organizationId: orgId } }],
  });

  // 3) 배송: delivery 생성

  return (
      <Modal.Content>
        <Form
          onSubmit={async () =>
            await userInquiry({
              variables: {
                data: {
                  name,
                  phone,
                  email,
                },
                kaptCode: kaptCode,
                floorName: `${dong}동 ${ho}호`,
              },
            })
          }
        >
          <Form.Group>분류 : 필터 주문</Form.Group>
          <Form.Group>
            <Form.Field required>
              <label>이름</label>
              <Input type="text" value={name} onChange={(e) => setName(e.target.value)} required={true} />
            </Form.Field>
            <Form.Field required>
              <label>휴대폰</label>
              <Input type="phone" value={phone} onChange={onChangePhone} maxLength={13} />
            </Form.Field>
            <Form.Field required>
              <label>이메일</label>
              <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required={true} />
            </Form.Field>
          </Form.Group>a
          <Form.Group>
            <Form.Field required>
              <label>단지 선택</label>
              <Dropdown
                fluid
                selection
                style={{ width: 300 }}
                placeholder="location 조회"
                options={locationItems}
                disabled={!locationItems.length}
                onChange={(e, data: any) => {
                  setKaptCode(data.value);
                }}
              />
            </Form.Field>

            <Form.Field required>
              <label>상세주소 - 동</label>
              <Input type="text" value={dong} onChange={(e) => setDong(e.target.value)} required={true} />
            </Form.Field>
            <Form.Field required>
              <label>상세주소 - 호</label>
              <Input type="text" value={ho} onChange={(e) => setHo(e.target.value)} required={true} />
            </Form.Field>
          </Form.Group>

          <Button type="submit" fluid>
            Submit
          </Button>
        </Form>
      </Modal.Content>
  );
}

