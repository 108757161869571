import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Header, Segment, Table } from "semantic-ui-react";
import { EmptyMessage } from "../../styles/common";

type TErvCtrlEvent = {
  userId: string;
  userName: string;
  floorName: string;
  ervName: string;
  ervCtrlName: string;
  type: string;
  startAt: Date;
};
type TErvCtrlEventListProps = {
  ervCtrlEvents: TErvCtrlEvent[];
  orgId: string;
};
type TErvCtrlEventItemProps = {
  ervCtrlEvent: TErvCtrlEvent;
  orgId: string;
};

function ErvCtrlEventList({ ervCtrlEvents, orgId }: TErvCtrlEventListProps) {
  return (
    <Segment>
      <Header as="h3">ERV Controller Event</Header>
      <Table compact="very" celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>type</Table.HeaderCell>
            <Table.HeaderCell>user</Table.HeaderCell>
            <Table.HeaderCell>floor</Table.HeaderCell>
            <Table.HeaderCell>ERV</Table.HeaderCell>
            <Table.HeaderCell>ERV Ctrl</Table.HeaderCell>
            <Table.HeaderCell>startAt</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {ervCtrlEvents && ervCtrlEvents.length > 0 ? (
            ervCtrlEvents.map((ervCtrlEvent, index) => <ErvCtrlEventItem key={index} ervCtrlEvent={ervCtrlEvent} orgId={orgId} />)
          ) : (
            <Table.Row>
              <Table.Cell colSpan="6">
                <EmptyMessage>Empty</EmptyMessage>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Segment>
  );
}

export default ErvCtrlEventList;

function ErvCtrlEventItem({ ervCtrlEvent, orgId }: TErvCtrlEventItemProps) {
  return (
    <Table.Row>
      <Table.Cell>{ervCtrlEvent.type}</Table.Cell>
      <Table.Cell>
        <Link to={`/orgs/${orgId}/users/${ervCtrlEvent.userId}`}>{ervCtrlEvent.userName}</Link>
      </Table.Cell>
      <Table.Cell>{ervCtrlEvent.floorName}</Table.Cell>
      <Table.Cell>{ervCtrlEvent.ervName}</Table.Cell>
      <Table.Cell>{ervCtrlEvent.ervCtrlName}</Table.Cell>
      <Table.Cell>{moment(ervCtrlEvent.startAt).format("YYYY-MM-DD, h:mm a")}</Table.Cell>
    </Table.Row>
  );
}
