import moment from "moment";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Button, Header, Icon, Segment, Table, Confirm, Menu, Input } from "semantic-ui-react";
import { EmptyMessage } from "@styles/common";
import { INIT_EVENT_DASHBOARD_PAGE } from "@graphql/query";
import { FINISH_VISIT_REQUEST, UPDATE_VISIT_REQUEST } from "@graphql/mutation";
import { useMutation } from "@apollo/react-hooks";
import { toastSuccess, toastError } from "@util";
import SelfContext from "@selfContext";
import styled from "styled-components";
import mediaQueries from "@styles/mediaQueries";
import { useModal } from "@Hooks/useModal";
import { OrderEventCreateForm } from "@Components/Modals";


type TVisitEventList = {
  visitEvents: any;
  orgId: String;
  managerOptions: any;
  locationItems: any;
  managerItems: any;
};

function VisitEventList({ visitEvents, orgId, managerOptions, locationItems, managerItems }: TVisitEventList) {
  const { self } = useContext(SelfContext);
  const [selectedEventsType, setSelectedEventsType] = useState("notFinished");
  const [finishVisitConfirm, setFinishVisitConfirm] = useState(false);
  const [finishFilterConfirm, setFinishFilterConfirm] = useState(false);
  const [finishVisitConfirmMsg, setFinishVisitConfirmMsg] = useState("");
  const [finishVisitId, setFinishVisitId] = useState("");
  const [currentLocationName, setCurrentLocationName] = useState("");
  const [currentManagerId, setCurrentManagerId] = useState("");
  const [searchText, setSearchText] = useState("");
  const { ModalPortal : OrderEventCreateModalPortal, openModal : openOrderEventCreateModal, closeModal : closeOrderEventCreateModal } =  useModal();

  // 주문 목록, 품질 관리 목록 :  방문 완료 처리
  const [finishVisitRequest] = useMutation(FINISH_VISIT_REQUEST, {
    onCompleted(data) {
      toastSuccess("방문 완료 처리되었습니다");
      setFinishVisitConfirm(false);
    },
    onError(error) {
      toastError(`방문 완료 처리 실패`, error.message);
      setFinishVisitConfirm(false);
    },
    refetchQueries: [{ query: INIT_EVENT_DASHBOARD_PAGE, variables: { organizationId: orgId } }],
  });

  // 매니저 배정
  const [updateVisitRequest] = useMutation(UPDATE_VISIT_REQUEST, {
    onCompleted(data) {
      toastSuccess("매니저가 배정되었습니다.");
    },
    onError(error) {
      toastError(`매니저 배정에 실패하였습니다.`, error.message);
    },
    refetchQueries: [{ query: INIT_EVENT_DASHBOARD_PAGE, variables: { organizationId: orgId } }],
  });

  const onClickFinishVisitBtn = (visitRequestId: string, label: string) => {
    setFinishVisitId(visitRequestId);
    setFinishVisitConfirmMsg(`방문 완료 상태로 변경합니다. (${label})`);
    setFinishVisitConfirm(true);
  };

  const onClickFinishFilterBtn = (visitRequestId: string, label: string) => {
    setFinishVisitId(visitRequestId);
    setFinishVisitConfirmMsg(`방문 완료 상태로 변경합니다. (${label})`);
    setFinishFilterConfirm(true);
  };

  // 주문 목록 조회 타입 (미완료, 완료)
  const filterByEventType = (visitStatus: any) => {
    if ((selectedEventsType === "notFinished" && visitStatus !== "finished") || (selectedEventsType === "finished" && visitStatus === "finished")) {
      return true;
    } else {
      return false;
    }
  };

  const filterByLocation = (locationName: any) => {
    if (!currentLocationName) return true;
    if (locationName.includes(currentLocationName)) {
      return true;
    } else {
      return false;
    }
  };

  const filterByManager = (managerId: any) => {
    if (!currentManagerId) return true;
    if (managerId === currentManagerId) {
      return true;
    } else {
      return false;
    }
  };

  const filterBySearch = (locationName: string, userName: string) => {
    if (!searchText) return true;
    if (locationName.includes(searchText) || userName.includes(searchText)) {
      return true;
    } else {
      return false;
    }
  };

  async function onConfirmFinishVisit(finishVisitId: string) {
    await finishVisitRequest({ variables: { visitRequestId: finishVisitId } });
  }

  // visitRequest 수정 : 필터 방문 처리
  const [updateVisitRequestForFilter] = useMutation(UPDATE_VISIT_REQUEST, {
    onCompleted(data) {
      toastSuccess("필터 방문 처리를 완료했습니다.");
      setFinishFilterConfirm(false);
    },
    onError(error) {
      toastError(`필터 방문 처리를 실패했습니다.`, error.message);
    },
    refetchQueries: [{ query: INIT_EVENT_DASHBOARD_PAGE, variables: { organizationId: orgId } }],
  });

  async function onConfirmFinishFilter(finishVisitId: string) {
    updateVisitRequestForFilter({ variables: { visitRequestId: finishVisitId, data: { status: "finished" } } });
  }

  // visitRequest 수정 : 필터 방문 처리
  const [updateVisitRequestForIsPaid] = useMutation(UPDATE_VISIT_REQUEST, {
    onCompleted(data) {
      toastSuccess("입금 상태 변경");
      setFinishFilterConfirm(false);
    },
    onError(error) {
      toastError(`입금 상태 변경에 실패했습니다`, error.message);
    },
    refetchQueries: [{ query: INIT_EVENT_DASHBOARD_PAGE, variables: { organizationId: orgId } }],
  });

  return (
    <>
      <Segment>
        <Header as="h3">
          방문 목록
          <StyledMenu text>
            <Button circular size="mini" icon="add" onClick={openOrderEventCreateModal} />
            <Menu.Item name="미완료" active={selectedEventsType === "notFinished"} onClick={() => setSelectedEventsType("notFinished")} />
            <Menu.Item name="완료" active={selectedEventsType === "finished"} onClick={() => setSelectedEventsType("finished")} />
            <Menu.Item>
              <Dropdown
                inline
                placeholder="location 조회"
                options={locationItems}
                disabled={!locationItems.length}
                onChange={(e, data: any) => {
                  const currentLocation = data.options.find((option: any) => option.value === data.value);
                  if (!currentLocation.text || currentLocation.text === "지정 안함") {
                    setCurrentLocationName("");
                    return;
                  }
                  setCurrentLocationName(currentLocation.text);
                }}
              />
            </Menu.Item>
            <Menu.Item>
              <Dropdown
                inline
                placeholder="manager 조회"
                options={managerItems}
                disabled={!managerItems.length}
                onChange={(e, data: any) => {
                  setCurrentManagerId(data.value);
                }}
              />
            </Menu.Item>
          </StyledMenu>
          <SearchInput value={searchText} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)} placeholder="신청자, 장소 검색" />
        </Header>
        <Table celled compact striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>분류</Table.HeaderCell>
              <Table.HeaderCell>신청자</Table.HeaderCell>
              <Table.HeaderCell>장소</Table.HeaderCell>
              <Table.HeaderCell>매니저</Table.HeaderCell>
              <Table.HeaderCell>입금확인</Table.HeaderCell>
              <Table.HeaderCell>방문상태</Table.HeaderCell>
              <Table.HeaderCell>신청일자</Table.HeaderCell>
              <Table.HeaderCell>방문일자</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {visitEvents
              .filter((cur: any) => filterByEventType(cur.visitStatus))
              .filter((cur: any) => filterByLocation(cur.locationsName))
              .filter((cur: any) => filterByManager(cur.managerId))
              .filter((cur: any) => filterBySearch(cur.locationsName, cur.userName)).length ? (
              visitEvents
                .filter((cur: any) => filterByEventType(cur.visitStatus))
                .filter((cur: any) => filterByLocation(cur.locationsName))
                .filter((cur: any) => filterByManager(cur.managerId))
                .filter((cur: any) => filterBySearch(cur.locationsName, cur.userName))
                .map((cur: any, index: any) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>{cur.itemNames}</Table.Cell>
                      <Table.Cell>
                        <Link to={`/orgs/${orgId}/users/${cur.userId}`}>{cur.userName}</Link>
                      </Table.Cell>
                      <Table.Cell>{cur.locationsName}</Table.Cell>
                      {/* {cur.itemNames !== "필터 주문" ? ( */}
                      <Table.Cell>
                        {self?.role === "superAdmin" || self?.role === "admin" ? (
                          <Dropdown
                            inline
                            options={managerOptions}
                            value={cur.managerId || "NONE"}
                            onChange={(e, onChangeData) => {
                              const managerId = onChangeData.value === "NONE" ? null : onChangeData.value;
                              updateVisitRequest({ variables: { visitRequestId: cur.visitRequestId, data: { manager: managerId } } });
                            }}
                          />
                        ) : (
                          <>{managerOptions.find((opt: any) => opt.value === cur.managerId)?.text || "미지정"}</>
                        )}
                      </Table.Cell>
                      {/* ) : (
                        <Table.Cell>{managerOptions.find((opt: any) => opt.value === cur.managerId)?.text || "미지정"}</Table.Cell>
                      )} */}
                      <Table.Cell>
                        <Dropdown
                          inline
                          options={[
                            { key: 0, value: false, text: "미확인" },
                            { key: 1, value: true, text: "확인" },
                          ]}
                          value={cur.isPaid}
                          onChange={(e, onChangeData) => {
                            updateVisitRequestForIsPaid({ variables: { visitRequestId: cur.visitRequestId, data: { isPaid: onChangeData.value } } });
                          }}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {cur.visitStatus}
                        {cur.visitStatus && (
                          <Button
                            style={{ marginLeft: 10 }}
                            size="mini"
                            compact
                            onClick={() =>
                              cur.itemNames !== "필터 주문"
                                ? onClickFinishVisitBtn(cur.visitRequestId, `${cur.userName}`)
                                : onClickFinishFilterBtn(cur.visitRequestId, `${cur.userName}`)
                            }
                            disabled={cur.visitStatus === "finished"}
                          >
                            확인
                          </Button>
                        )}
                      </Table.Cell>
                      <Table.Cell style={{ color: "#999" }}>{moment(cur.createdAt).locale("ko").format("MM-DD (dd) a hh:mm")}</Table.Cell>
                      <Table.Cell>
                        {cur.inviteeStartTime ? (
                          <>
                            <b>{moment(cur.inviteeStartTime).locale("ko").format("MM-DD (dd) a hh:mm")}</b>
                            <RescheduleBtn itemNames={cur.itemNames} visitRequestId={cur.visitRequestId} />
                          </>
                        ) : (
                          <span>
                            <a target="_blank" href={getCelendlyLink("air_consulting", cur.userName, cur.userEmail, cur.userPhone, cur.visitId)}>
                              미지정
                              <Icon name="share" />
                            </a>
                          </span>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })
            ) : (
              <Table.Row>
                <Table.Cell colSpan="8">
                  <EmptyMessage>Empty</EmptyMessage>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>

        <Confirm
          open={finishVisitConfirm}
          header={`방문완료 처리를 하시겠습니까? `}
          content={finishVisitConfirmMsg}
          cancelButton="취소"
          confirmButton="확인"
          onCancel={() => setFinishVisitConfirm(false)}
          onConfirm={() => onConfirmFinishVisit(finishVisitId)}
        />

        <Confirm
          open={finishFilterConfirm}
          header={`필터 방문완료 처리를 하시겠습니까?`}
          content={finishVisitConfirmMsg}
          cancelButton="취소"
          confirmButton="확인"
          onCancel={() => setFinishFilterConfirm(false)}
          onConfirm={() => onConfirmFinishFilter(finishVisitId)}
        />
      </Segment>
      <OrderEventCreateModalPortal title="방문 신청">
          <OrderEventCreateForm locationItems={locationItems} orgId={orgId} closeModal={closeOrderEventCreateModal} />
      </OrderEventCreateModalPortal>
    </>
  );
}

export default VisitEventList;

const StyledMenu = styled(Menu)`
  display: inline-flex !important;
  margin-left: 10px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  min-height: 0 !important;
`;

const getCelendlyLink = (slug: string, name: string, email: string, phone: string, visitId: string) => {
  const phoneStr = phone.replace("010", "8210");
  return `https://calendly.com/puffinplace/${slug}?full_name=${encodeURI(name)}&email=${email}&a1=${phoneStr}&a2=${visitId}`;
};

const RescheduleBtn = ({ itemNames, visitRequestId }: { itemNames: any; visitRequestId: any }) => {
  if (itemNames !== "필터 주문") return null;
  return (
    <a style={{ marginLeft: 10 }} target="_blank" href={`https://puffinplace.com/reschedule/request/${visitRequestId}`}>
      변경
    </a>
  );
};

const SearchInput = styled(Input)`
  position: absolute !important;
  top: 6px;
  right: 14px;
  width: 160px;
  input {
    padding: 0 0;
    font-size: 1rem;
  }
  ${mediaQueries("sm")`
     position: relative !important;
    margin: 4px 14px;
    width: 100%;
  `}
`;
