import React from "react";
import styled from "styled-components";
import { useSectionCardContext } from "../useSectionCardContext";
import { useScores } from "@Components/Scores";
const colorArr = ["#FEAC74", "#F6DB83", "#adadaa", "#88D3B4", "#7FC8F3"];

const criteriaGoodBad = {
  temp: {
    range: [-Infinity, 9, 11, 17, 18, 25, 26, 32, 34, Infinity],
    quality: [0, 1, 2, 3, 4, 3, 2, 1, 0],
  },
  humid: {
    range: [0, 15, 20, 35, 40, 50, 60, 65, 80, Infinity],
    quality: [0, 1, 2, 3, 4, 3, 2, 1, 0],
  },
  co2: {
    range: [0, 600, 1000, 1500, 2500, Infinity],
    quality: [4, 3, 2, 1, 0],
  },
  voc: {
    range: [0, 333, 1000, 3333, 8332, Infinity],
    quality: [4, 3, 2, 1, 0],
  },
  pm25: {
    range: [0, 15, 35, 55, 75, Infinity],
    quality: [4, 3, 2, 1, 0],
  },
  awairScore: {
    range: [0, 70, 80, 90, 95, Infinity],
    quality: [0, 1, 2, 3, 4],
  },
};

function Detail() {
  const { floor, section, detailType }: any = useSectionCardContext();
  const scores = useScores({ floorId: floor._id, sectionId: section._id });
  const getStatusIndex = (type: "temp" | "humid" | "co2" | "voc" | "pm25" | "awairScore", value: number) => {
    let i = 0;
    let elementValue;
    for (elementValue of criteriaGoodBad[type].range) {
      if (elementValue > value) {
        i--;
        break;
      }
      i++;
    }
    return criteriaGoodBad[type].quality[i];
  };

  const CurrentItems = () => {
    if (!scores.voc && !scores.co2 && !scores.pm25 && !scores.temperature && !scores.humidity) {
      return <Empty>미집계</Empty>;
    }
    return (
      <ItemGroup>
        <Item>
          <Label color={colorArr[getStatusIndex("co2", scores.co2)] || "#999"}>
            CO<sub>2</sub>
          </Label>
          <Score>{Math.round(scores.co2) || "-"}ppm</Score>
        </Item>
        <Item>
          <Label color={colorArr[getStatusIndex("voc", scores.voc)] || "#999"}>TVocs</Label>
          <Score>{Math.round(scores.voc) || "-"}ppb</Score>
        </Item>
        <Item>
          <Label color={colorArr[getStatusIndex("pm25", scores.pm25)] || "#999"}>PM2.5</Label>
          <Score>{Math.round(scores.pm25) || "-"}㎍/m³</Score>
        </Item>
        <Item>
          <Label color={colorArr[getStatusIndex("temp", scores.temperature)] || "#999"}>온도</Label>
          <Score>{Math.round(scores.temperature) || "-"}℃</Score>
        </Item>
        <Item>
          <Label color={colorArr[getStatusIndex("humid", scores.humidity)] || "#999"}>습도</Label>
          <Score>{Math.round(scores.humidity) || "-"}%</Score>
        </Item>
      </ItemGroup>
    );
  };

  const PredictionItems = () => {
    if (!scores.vocPrediction && !scores.co2Prediction && !scores.pm25Prediction && !scores.temperaturePrediction && !scores.humidityPrediction) {
      return <Empty>미집계</Empty>;
    }
    return (
      <ItemGroup>
        <Item>
          <Label color={colorArr[getStatusIndex("co2", scores.co2Prediction)] || "#999"}>
            CO<sub>2</sub>
          </Label>
          <Score>{Math.round(scores.co2Prediction) || "-"}ppm</Score>
        </Item>
        <Item>
          <Label color={colorArr[getStatusIndex("voc", scores.vocPrediction)] || "#999"}>TVocs</Label>
          <Score>{Math.round(scores.vocPrediction) || "-"}ppb</Score>
        </Item>
        <Item>
          <Label color={colorArr[getStatusIndex("pm25", scores.pm25Prediction)] || "#999"}>PM2.5</Label>
          <Score>{Math.round(scores.pm25Prediction) || "-"}㎍/m³</Score>
        </Item>
        <Item>
          <Label color={colorArr[getStatusIndex("temp", scores.temperaturePrediction)] || "#999"}>온도</Label>
          <Score>{Math.round(scores.temperaturePrediction) || "-"}℃</Score>
        </Item>
        <Item>
          <Label color={colorArr[getStatusIndex("humid", scores.humidityPrediction)] || "#999"}>습도</Label>
          <Score>{Math.round(scores.humidityPrediction) || "-"}%</Score>
        </Item>
      </ItemGroup>
    );
  };

  const AqiItems = () => {
    if (!scores.aqiAccuracy && !scores.aqiPrediction) {
      return <Empty>미집계</Empty>;
    }
    return (
      <ItemGroup>
        <Item>
          <Label color={"#999"}>AQI Accuracy</Label>
          <Score>{Math.round(scores.aqiAccuracy) || "-"}</Score>
        </Item>
        <Item>
          <Label color={"#999"}>AQI Prediction</Label>
          <Score>{Math.round(scores.aqiPrediction) || "-"}</Score>
        </Item>
      </ItemGroup>
    );
  };

  return (
    <>
      <Container>
        <SectionScores>
          {detailType === "CURRENT" && <CurrentItems />}
          {detailType === "PREDICTION" && <PredictionItems />}
          {detailType === "AQI" && <AqiItems />}
        </SectionScores>
      </Container>
    </>
  );
}
export { Detail };

const Container = styled.div`
  text-align: center;
`;

const SectionScores = styled.div`
  font-size: 12px;
  min-height: 42px;
`;

const Label = styled.div`
  color: ${(props) => props.color};
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 4px;
`;

const Score = styled.div`
  font-size: 10px;
`;

const Empty = styled.div`
  text-align: center;
  line-height: 3em;
`;

const ItemGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const Item = styled.div`
  flex: 1;
  img {
    width: 60%;
  }
`;

const Status = styled.div`
  color: ${(props) => props.color};
  font-size: 12px;
  font-weight: bold;
`;
