import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import DefaultLayout from "@Layouts/DefaultLayout";
import { Segment, Header, Loader } from "semantic-ui-react";
import { INIT_REPORT_PAGE } from "@graphql/query";
import { useQuery } from "@apollo/react-hooks";
import WeeklyReportArea from "@Components/Reports/WeeklyReportArea";
import { Button, Grid } from "semantic-ui-react";
import { IUser, IOrganization } from "@interfaces";
import styled from "styled-components";

function WeeklyReports() {
  const DEFAULT_ORG_ID = "5dd1fad2d6be7915873bb9cc";

  const { loading, data } = useQuery<{ organization: IOrganization }, { organizationId: string }>(INIT_REPORT_PAGE, {
    variables: {
      organizationId: DEFAULT_ORG_ID,
    },
  });

  const [userId, setUserId] = useState<string>();
  const [floorId, setFloorId] = useState<string>();

  const onClickItem = (userId: string, floorId: string) => {
    setUserId(userId);
    setFloorId(floorId);
  };

  const isActive = (curUserId: string, curFloorId: string) => {
    if (curUserId === userId && curFloorId === floorId) {
      return true;
    } else {
      return false;
    }
  };

  if (loading)
    return (
      <DefaultLayout>
        <Loader active />
      </DefaultLayout>
    );

  return (
    <DefaultLayout>
      <Helmet>
        <title>Reports</title>
      </Helmet>
      <Segment>
        <Grid columns="equal">
          <Grid.Column>
            <Header as="h3">주간 리포트 대상</Header>
            {data && data.organization.users ? (
              data.organization.users
                .filter((user: IUser) => {
                  return user.status === "paid" || user.status === "outlier" || user.status === "sponsor";
                })
                .map((user: IUser) => (
                  <div key={user._id} style={{ marginBottom: 10 }}>
                    {user.name} (status: {user.status})
                    {user.floors &&
                      user.floors.map(
                        (floor) =>
                          floor && (
                            <ButtonWrap key={floor._id}>
                              <Button size="mini" onClick={() => onClickItem(user._id, floor._id)} active={isActive(user._id, floor._id)}>
                                floor({floor.name})
                              </Button>
                              <Link to={`/reports/weekly/users/${user._id}/floors/${floor._id}`} target="_blank">
                                새창
                              </Link>
                            </ButtonWrap>
                          )
                      )}
                  </div>
                ))
            ) : (
              <p>주간 리포트 대상자가 없습니다.</p>
            )}
          </Grid.Column>
          <Grid.Column>{userId && floorId && <WeeklyReportArea userId={userId} floorId={floorId} />}</Grid.Column>
        </Grid>
      </Segment>
    </DefaultLayout>
  );
}

export default WeeklyReports;

const ButtonWrap = styled.div`
  margin-bottom: 4px;
  a {
    margin-left: 10px;
    font-size: 12px;
  }
`;
