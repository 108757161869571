import React, { useState } from "react";
import { Button, Icon, Popup } from "semantic-ui-react";
import moment from "moment";
import { CHANGE_FILTER_IN_ERV } from "@graphql/mutation";
import { useMutation } from "@apollo/react-hooks";

import { toastSuccess, toastError } from "@util";
import { IConsumables } from "@interfaces";

interface FilterManagement {
  ervId?: string;
  consumables: IConsumables;
  setConsumables: Function;
}

const FilterManagement = ({ ervId, consumables, setConsumables }: FilterManagement) => {
  const [filterChangeWarningOpen, setFilterChangeWarningOpen] = useState(false);

  // 필터 교체
  const [changeFilterInErv] = useMutation(CHANGE_FILTER_IN_ERV, {
    onCompleted(data) {
      setConsumables(data.changeFilterInErv.consumables);
      toastSuccess("교체 완료");
      setFilterChangeWarningOpen(false);
    },
    onError(error) {
      toastError("교체 실패", error.message);
      setFilterChangeWarningOpen(false);
      console.error(error);
    },
  });

  async function handleFilterChange(e: any) {
    e.preventDefault();
    changeFilterInErv({ variables: { ervId: ervId } });
  }

  if (!consumables) return null;

  return (
    <>
      <label>Filter Management</label>
      <Button
        animated
        onClick={(e) => {
          e.preventDefault();
          setFilterChangeWarningOpen(true);
        }}
      >
        <Button.Content visible>Filter Replace</Button.Content>
        <Button.Content hidden>
          <Icon name="exchange" />
        </Button.Content>
      </Button>
      {consumables.filterRemainPercent && <span>필터 수명 : {consumables.filterRemainPercent.toFixed(1)}%</span>}
      {consumables.lastFilterChangedAt ? (
        <span style={{ marginLeft: 10 }}>최근 교체일 : {moment(consumables.lastFilterChangedAt).format("YYYY-MM-DD, h:mm a")}</span>
      ) : (
        <span style={{ marginLeft: 10, color: "#CCC" }}>필터 교체 이력이 없습니다.</span>
      )}
      <Popup
        header="필터 교체 이력"
        content={
          consumables.filterChangeHistory.length
            ? consumables.filterChangeHistory.map((cur, index) => <p key={index}>{moment(cur.logtime).format("YYYY-MM-DD, h:mm a")}</p>)
            : "교체 이력 없음"
        }
        trigger={<Icon circular name="history" style={{ marginLeft: 10 }} />}
      />

      {filterChangeWarningOpen && (
        <>
          <label>필터교체합니까? (재고필터 차감)</label>
          <Button
            positive
            onClick={(e) => {
              handleFilterChange(e);
            }}
          >
            YES
          </Button>
          <Button
            negative
            onClick={(e) => {
              e.preventDefault();
              setFilterChangeWarningOpen(false);
            }}
          >
            NO
          </Button>
        </>
      )}
    </>
  );
};

export default FilterManagement;
