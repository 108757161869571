import React, {useEffect, useState, useRef} from "react";
import styled from "styled-components";
import {useDashboardChartContext} from "../useDashboardChartContext";
import Highcharts, { reduce } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {getChartOption, TKind} from "../DashboardChartOption";

type TProps = {
  kind: TKind 
}

function Chart({kind}: TProps) {
  const {graphData, selectedSectionIDs, selectedErvIDs, isShowErvLog} : any = useDashboardChartContext();
  const chartRef = useRef<any>();
  const containerRef = useRef<any>();
  const [chartOptions, setChartOptions] = useState();
  
  useEffect(()=>{
    if(!kind || !graphData || !selectedSectionIDs || !selectedErvIDs ) return;
    const chartOptions = getChartOption(kind, graphData, selectedSectionIDs, selectedErvIDs, isShowErvLog);
    setChartOptions(chartOptions);
  },[kind, graphData, selectedSectionIDs, selectedErvIDs, isShowErvLog])

  
  useEffect(()=>{
    if(!chartOptions) return;
    chartRef.current = Highcharts.chart(containerRef.current, chartOptions);
  },[chartOptions])


  if(!graphData) return null;
  
  return (
    <Container ref={containerRef}>
      <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOptions} updateArgs={[true]} oneToOne={true} />
    </Container>
  )
}
export { Chart };

const Container = styled.div`
  margin-top: 10px;
  color: #333;
  padding: 0;
  margin: 3% 0;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
`;