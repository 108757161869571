import React from "react";
import { BrowserRouter } from "react-router-dom";
import { SemanticToastContainer } from "react-semantic-toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import "semantic-ui-css/semantic.min.css";
import styled from "styled-components";
import GlobalStyle from "@styles/globalstyle";

import Routes from "@Routes";
import { Helmet } from "react-helmet";
import { ApolloProvider } from "@apollo/react-hooks";
import client from "@src/apollo";

const StyledSemanticToastContainer = styled(SemanticToastContainer)`
  .header {
    font-weight: 500 !important;
  }

  .close {
    /* 닫기 버튼 숨김 */
    display: none;
  }
`;

const App = () => (
  <ApolloProvider client={client}>
    <Helmet titleTemplate="%s - Puffin Planet" defaultTitle="Puffin Planet">
      <meta charSet="utf-8" />
      <meta name="description" content="Puffin Planet" />
    </Helmet>
    <GlobalStyle />
    <BrowserRouter>
      <Routes />
      <StyledSemanticToastContainer position="bottom-right" />
      <div id="root-modal" />
    </BrowserRouter>
  </ApolloProvider>
);

export default App;
