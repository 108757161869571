import React, { useContext } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Breadcrumb } from "semantic-ui-react";
import SelfContext from "@selfContext";

type TParams = {
  orgId: string;
  userId: string;
  locationId: string;
  floorId: string;
  managerId: string;
  adminId: string;
  awairId: string;
  contractId: string;
  ervId: string;
};

function Breadcrumbs() {
  const { orgId, userId, locationId, floorId, managerId, adminId, awairId, contractId, ervId }: TParams = useParams();
  const { self } = useContext(SelfContext);
  const location = useLocation();
  const lastPath = location.pathname.split("/").pop();

  return (
    <Breadcrumb>
      {self && self.role === "superAdmin" ? (
        <Breadcrumb.Section active={orgId ? false : true}>
          <Link to="/">Orgs</Link>
        </Breadcrumb.Section>
      ) : (
        <Breadcrumb.Section>Orgs</Breadcrumb.Section>
      )}

      {orgId && (
        <>
          <Breadcrumb.Divider />

          {self && self.role === "userAccount" ? (
            <Breadcrumb.Section>Org</Breadcrumb.Section>
          ) : (
            <Breadcrumb.Section active={userId || managerId || adminId || awairId ? false : true}>
              <Link to={`/orgs/${orgId}`}>Org</Link>
            </Breadcrumb.Section>
          )}
        </>
      )}

      {orgId && userId && (
        <>
          <Breadcrumb.Divider />
          <Breadcrumb.Section
            active={
              locationId ||
              awairId ||
              lastPath === "billings" ||
              lastPath === "contracts" ||
              lastPath === "contract-registration" ||
              lastPath === "pay-order" ||
              lastPath === "consulting" ||
              lastPath === "dashboard" ||
              lastPath === "position" ||
              ervId
                ? false
                : true
            }
          >
            <Link to={`/orgs/${orgId}/users/${userId}`}>User</Link>
          </Breadcrumb.Section>
        </>
      )}

      {orgId && userId && floorId && (lastPath === "dashboard") && (
        <>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active={true}>
            <Link to={`/orgs/${orgId}/users/${userId}/floors/${floorId}/dashboard`}>Dashboard</Link>
          </Breadcrumb.Section>
        </>
      )}

      {orgId && userId && floorId && (lastPath === "position") && (
        <>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active={true}>
            <Link to={`/orgs/${orgId}/users/${userId}/floors/${floorId}/position`}>Position</Link>
          </Breadcrumb.Section>
        </>
      )}

      {orgId && userId && locationId && floorId && (
        <>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active={lastPath === "contract-registration" || lastPath === "pay-order" ? false : true}>
            <Link to={`/orgs/${orgId}/users/${userId}/locations/${locationId}/floors/${floorId}`}>System Group</Link>
          </Breadcrumb.Section>
        </>
      )}

      {orgId && userId && ervId && (
        <>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active={true}>
            <Link to={`/orgs/${orgId}/users/${userId}/ervs/${ervId}`}>Erv</Link>
          </Breadcrumb.Section>
        </>
      )}

      {orgId && managerId && (
        <>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>
            <Link to={`/orgs/${orgId}/managers/${managerId}`}>Manager</Link>
          </Breadcrumb.Section>
        </>
      )}

      {orgId && adminId && (
        <>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>
            <Link to={`/orgs/${orgId}/admins/${adminId}`}>admin</Link>
          </Breadcrumb.Section>
        </>
      )}

      {orgId && awairId && (
        <>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>
            <Link to={`/orgs/${orgId}/awair/${awairId}`}>Awair</Link>
          </Breadcrumb.Section>
        </>
      )}

      {(lastPath === "billings" || lastPath === "contracts") && (
        <>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active={lastPath === "billings" ? false : true}>
            <Link to={`/orgs/${orgId}/users/${userId}/contracts`}>contracts</Link>
          </Breadcrumb.Section>
        </>
      )}

      {lastPath === "billings" && (
        <>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>
            <Link to={`/orgs/${orgId}/users/${userId}/contracts/${contractId}/billings`}>billings</Link>
          </Breadcrumb.Section>
        </>
      )}

      {lastPath === "contract-registration" && (
        <>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>
            <Link to={`/orgs/${orgId}/users/${userId}/contract-registration`}>contract-registration</Link>
          </Breadcrumb.Section>
        </>
      )}

      {lastPath === "pay-order" && (
        <>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>
            <Link to={`/orgs/${orgId}/users/${userId}/pay-order`}>pay-order</Link>
          </Breadcrumb.Section>
        </>
      )}

      {lastPath === "consulting" && (
        <>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>
            <Link to={`/orgs/${orgId}/users/${userId}/consulting`}>Consulting Report</Link>
          </Breadcrumb.Section>
        </>
      )}
    </Breadcrumb>
  );
}

export default Breadcrumbs;
