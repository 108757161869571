import React, { useState } from "react";
import { Segment, Header, Table, Divider, Icon, Button, Modal, Confirm, Loader } from "semantic-ui-react";
import DefaultLayout from "@Layouts/DefaultLayout";
import { useParams } from "react-router-dom";
import moment from "moment";
import ContractItem from "@Components/Common/ContractItem";
import BillingItem from "@Components/Billings/BillingItem";
import ChangeServiceTier from "@Components/Billings/ChangeServiceTier";
import { ManageBtns } from "@styles/common";
import styled from "styled-components";
import { toastSuccess, toastError } from "@util";
import Breadcrumbs from "@Components/Common/Breadcrumbs";
import { Helmet } from "react-helmet";
import { INIT_BILLINGS_PAGE } from "@graphql/query";
import { SET_NEXT_SCHEDULE_PAYMENT, CANCEL_CONTRACT, UNCANCEL_CONTRACT, CHANGE_SERVICETIER_OF_CONTRACT } from "@graphql/mutation";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { IBilling, IContract } from "@interfaces";

function Billings() {
  const { orgId, userId, contractId }: { orgId: string; userId: string; contractId: string } = useParams();

  // const [user, setUser] = useState([]);
  // const [contract, setContract] = useState(null);
  // const [billings, setBillings] = useState([]);
  // const [filterConfirm, setFilterConfirm] = useState(false);
  // const [filterCount, setFilterCount] = useState(null);

  // const [showAdditionalPayModal, setShowAdditionalPayModal] = useState(false);
  // const [showCreditCardModal, setShowCreditCardModal] = useState(false);
  const [showChangeTierModal, setShowChangeTierModal] = useState(false);
  const [openCancelConfirm, setOpenCancelConfirm] = useState(false);
  const [openUnCancelConfirm, setOpenUnCancelConfirm] = useState(false);

  const { loading, data } = useQuery<any, { userId: any; contractId: any }>(INIT_BILLINGS_PAGE, {
    variables: {
      userId: userId,
      contractId: contractId,
    },
  });

  // 스케쥴 추가
  const [setNextSchedulePayment] = useMutation<{ setNextScheduledPayment: IContract }, { contractId: string }>(SET_NEXT_SCHEDULE_PAYMENT, {
    onCompleted(data) {
      toastSuccess("다음 결제가 예약되었습니다.");
    },
    onError(error) {
      console.error("error", error);
      toastError("다음 결제 예약이 실패하였습니다.", error.message);
    },
    refetchQueries: [{ query: INIT_BILLINGS_PAGE, variables: { userId: userId, contractId: contractId } }],
  });

  const addSchedule = async () => {
    await setNextSchedulePayment({ variables: { contractId: contractId } });
  };

  // Contract 해제예약
  const [cancelContract] = useMutation<{ cancelContract: IContract }, { contractId: string }>(CANCEL_CONTRACT, {
    onCompleted(data) {
      toastSuccess("해당 Contract가 해제예약 되었습니다.", "예약된 결제건이 해제되었습니다.");
      setOpenCancelConfirm(false);
    },
    onError(error) {
      console.error("error", error);
      toastError("해당 Contract가 해제예약이 실패하였습니다.", error.message);
      setOpenCancelConfirm(false);
    },
    refetchQueries: [{ query: INIT_BILLINGS_PAGE, variables: { userId: userId, contractId: contractId } }],
  });

  const onClickCancelContract = async () => {
    await cancelContract({ variables: { contractId: contractId } });
  };

  // Contract 해제예약 취소
  const [unCancelContract] = useMutation<{ uncancelContract: IContract }, { contractId: string }>(UNCANCEL_CONTRACT, {
    onCompleted(data) {
      toastSuccess("Contract 해제예약이 취소 되었습니다.", "결제예약이 되었습니다.");
      setOpenUnCancelConfirm(false);
    },
    onError(error) {
      console.error("error", error);
      toastError("해당 Contract의 해제예약 취소가 실패하였습니다.", error.message);
      setOpenUnCancelConfirm(false);
    },
    refetchQueries: [{ query: INIT_BILLINGS_PAGE, variables: { userId: userId, contractId: contractId } }],
  });

  const onClickUnCancelContract = async () => {
    await unCancelContract({ variables: { contractId: contractId } });
  };

  // 요금제 변경
  const [changeServiceTierOfContract] = useMutation<{ changeServiceTierOfContract: IContract }, { contractId: string; serviceTierId: string }>(
    CHANGE_SERVICETIER_OF_CONTRACT,
    {
      onCompleted(data) {
        toastSuccess("Service Tier가 변경되었습니다.");
        setShowChangeTierModal(false);
      },
      onError(error) {
        toastError("Service Tier 변경이 실패하였습니다.", error.message);
        console.error("error", error);
        setShowChangeTierModal(false);
      },
      refetchQueries: [{ query: INIT_BILLINGS_PAGE, variables: { userId: userId, contractId: contractId } }],
    }
  );

  const onSubmitChangeTier = async (selectedTierId: string) => {
    if (!selectedTierId) return;
    await changeServiceTierOfContract({ variables: { contractId: contractId, serviceTierId: selectedTierId } });
  };

  //// 요금제 변경
  // const onSubmitChangeTier = async (selectedTierId, planOpt, setIsLoading) => {
  //   setIsLoading(true);
  //   try {
  //     if (!selectedTierId || !planOpt) return;

  //     const [newContract, cancelledBilling, nextBilling, paidBilling] = await a pi.changeServiceTiers({
  //       planOpt,
  //       selectedTierId,
  //       contractId,
  //     });

  //     const cancelledBillingIndex = billings.findIndex((v) => v._id === cancelledBilling._id);
  //     const newBillings = billings;
  //     newBillings[cancelledBillingIndex] = cancelledBilling;

  //     if (paidBilling) {
  //       setBillings([...newBillings, nextBilling, paidBilling]);
  //     } else {
  //       setBillings([...newBillings, nextBilling]);
  //     }

  //     setContract(newContract);
  //     setIsLoading(false);
  //     toastSuccess("Service Tier가 변경되었습니다.");
  //     setShowChangeTierModal(false);
  //   } catch (err) {
  //     console.error(err);
  //     setIsLoading(false);
  //     toastError("Service Tier 변경이 실패하였습니다.", err.response.data && err.response.data.message);
  //     setShowChangeTierModal(false);
  //   }
  // };

  //// 부가 서비스 결제
  // const onSubmitAddProduct = async (productId, qty) => {
  //   try {
  //     const billingData = await a pi.paymentProduct({
  //       contractId,
  //       productId,
  //       qty,
  //     });

  //     setBillings(billings.concat(billingData));
  //     toastSuccess("결제되었습니다.");
  //     setShowAdditionalPayModal(false);
  //   } catch (err) {
  //     console.error(err);
  //     toastError("결제에 실패하였습니다.", err.response.data && err.response.data.message);
  //     setShowAdditionalPayModal(false);
  //   }
  // };

  // 선택한 예약의 예약 취소 TODO: 구현 예정
  /*
  const onClickUnSchedule = async (merchantUid, setShowModal) => {
    try {
      const billing = await a pi.deletePaymentSchedule(merchantUid);
      const billingIndex = billings.findIndex((v) => v._id === billing._id);
      const newBillings = billings;
      newBillings[billingIndex] = billing;
      setBillings([...newBillings]);
      toastSuccess("예약된 결제건이 해제되었습니다.");
      setShowModal(false);
    } catch (err) {
      console.error(err);
      toastError("실패하였습니다.", err.response.data && err.response.data.message);
    }
  };
  */

  // 선택한 주문의 결제 취소 TODO: 구현 예정
  /*
  const onClickPaymentCancel = async (impUid, setShowModal) => {
    try {
      const billing = await a pi.paymentCancel({ impUid });
      const billingIndex = billings.findIndex((v) => v._id === billing._id);
      const newBillings = billings;
      newBillings[billingIndex] = billing;
      setBillings([...newBillings]);
      toastSuccess("해당 결제가 취소되었습니다.");
      setShowModal(false);
    } catch (err) {
      console.error(err);
      toastError("실패하였습니다.", err.response.data && err.response.data.message);
    }
  };
  */

  // const calFilterCount = (billingData) => {
  //   const filterCount = billingData.reduce((acc, cur) => {
  //     if (cur.type === "filter") {
  //       return acc + 1;
  //     } else {
  //       return acc;
  //     }
  //   }, 0);

  //   return filterCount;
  // };

  //필터 배송 처리
  /*
  const filterOnConfirm = async () => {
    try {
      const billings = await a pi.getBillingsByContractId(contractId);

      if (calFilterCount(billings) >= contract.serviceTier.filterAmount) {
        throw Error("할당된 필터 초과");
      }

      await a pi.createBilling({
        contract: contractId,
        type: "filter",
        payMethod: "other",
        serviceTier: contract.serviceTier,
        name: "무료필터",
        amount: 0,
        status: "paid",
        paidAt: moment().tz("Asia/Seoul"),
      });

      // initBillings();

      toastSuccess("필터 배송 처리되었습니다.");
    } catch (err) {
      console.error(err);
      toastError("필터 배송 처리에 실패하였습니다.");
    }
  };
  */

  if (loading)
    return (
      <DefaultLayout>
        <Loader active />
      </DefaultLayout>
    );

  return (
    <DefaultLayout>
      <Helmet>
        <title>{data.user.name}(billings)</title>
      </Helmet>

      <Segment>
        <Header as="h2">
          Contract
          <span style={{ marginLeft: "2rem" }}>
            <Breadcrumbs />
          </span>
        </Header>
        <Divider />
        <RelativeDiv>
          <Header as="h3">{data.user.name}</Header>
          <Icon name="mail" />
          {data.user.email}
          <br />
          <Icon name="phone" />
          {data.user.phone}
          <br />
          <Icon name="calendar" />
          {moment(data.user.createdAt).format("YYYY-MM-DD, h:mm a")}
        </RelativeDiv>

        {/* {data.contract && ( */}
        <>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>serviceTier</Table.HeaderCell>
                <Table.HeaderCell>type</Table.HeaderCell>
                <Table.HeaderCell>term</Table.HeaderCell>
                <Table.HeaderCell>status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <ContractItem key={data.contract._id} contract={data.contract} orgId={orgId} userId={userId} current="billings" />
            </Table.Body>
          </Table>
        </>

        <ManageBtns>
          {/* {data.contract.serviceTier.type === "plan" && (
            <>
              <Button size="mini" onClick={() => setFilterConfirm(true)} disabled={data.contract.status !== "active"}>
                <Icon name="plus" />
                필터 배송 처리({filterCount}/{data.contract.serviceTier.filterAmount})
              </Button>
              <Confirm
                open={filterConfirm}
                header={`필터 배송`}
                content={`필터 배송처리를 하겠습니까?`}
                cancelButton="취소"
                confirmButton={{
                  content: "확인",
                }}
                onCancel={() => setFilterConfirm(false)}
                onConfirm={() => {
                  filterOnConfirm();
                  setFilterConfirm(false);
                }}
              />
            </>
          )} */}

          {/* {data.contract.serviceTier.type === "membership" && (
            <Modal
              trigger={
                <Button size="mini" onClick={() => setShowChangeTierModal(true)} disabled={data.contract.status !== "active"}>
                  <Icon name="exchange" />
                  Service Tier 변경
                </Button>
              }
              open={showChangeTierModal}
            >
              <ChangeServiceTier setShowModal={setShowChangeTierModal} currentTierId={data.contract.serviceTier._id} onSubmit={onSubmitChangeTier} />
            </Modal>
          )} */}

          <Modal
            trigger={
              <Button size="mini" onClick={() => setShowChangeTierModal(true)} disabled={data.contract.status !== "active"}>
                <Icon name="exchange" />
                Service Tier 변경
              </Button>
            }
            open={showChangeTierModal}
          >
            <ChangeServiceTier
              setShowModal={setShowChangeTierModal}
              currentTierId={data.contract.serviceTier._id}
              onSubmit={onSubmitChangeTier}
              type={data.contract.serviceTier.type}
            />
          </Modal>

          <Button size="mini" onClick={addSchedule} disabled={data.contract.status !== "active"}>
            <Icon name="clock outline" />
            다음 결제 예약
          </Button>

          {data.contract.status === "scheduledInactive" ? (
            <Button size="mini" onClick={() => setOpenUnCancelConfirm(true)} floated="right">
              <Icon name="undo" />
              Contract 해제예약 취소
            </Button>
          ) : (
            <Button size="mini" onClick={() => setOpenCancelConfirm(true)} disabled={data.contract.status !== "active"} floated="right">
              <Icon name="cancel" />
              Contract 해제예약
            </Button>
          )}

          <Confirm
            open={openCancelConfirm}
            header={`해당 Contract를 해제 예약하시겠습니까? `}
            content={`Contract 해제일 이전에 예약 취소가 가능합니다.`}
            cancelButton="취소"
            confirmButton="Contract 해제"
            onCancel={() => setOpenCancelConfirm(false)}
            onConfirm={onClickCancelContract}
          />

          <Confirm
            open={openUnCancelConfirm}
            header={`해당 Contract를 해지예약을 취소하시겠습니까? `}
            content={`다음 결제예약이 진행됩니다.`}
            cancelButton="취소"
            confirmButton="Contract 해지예약 취소"
            onCancel={() => setOpenUnCancelConfirm(false)}
            onConfirm={onClickUnCancelContract}
          />
        </ManageBtns>
      </Segment>
      <Segment>
        <Header as="h2">Billings</Header>

        <Divider />

        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>거래명</Table.HeaderCell>
              <Table.HeaderCell>분류</Table.HeaderCell>
              <Table.HeaderCell>상태</Table.HeaderCell>
              <Table.HeaderCell>결제금액</Table.HeaderCell>
              <Table.HeaderCell>결제방법</Table.HeaderCell>
              <Table.HeaderCell>날짜</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.contract.billings
              .filter((billing: IBilling) => billing)
              .slice()
              .sort((a: IBilling, b: IBilling) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
              .map((billing: IBilling) => (
                // <BillingItem key={billing._id} billing={billing} onClickUnSchedule={onClickUnSchedule} onClickPaymentCancel={onClickPaymentCancel} />
                <BillingItem key={billing._id} billing={billing} />
              ))}
          </Table.Body>
        </Table>
      </Segment>
    </DefaultLayout>
  );
}

export default Billings;

const RelativeDiv = styled.div`
  position: relative;
`;
