import { useState, useCallback } from "react";
import { toast } from "react-semantic-toasts";

export const useInput: any = (initValue = null) => {
  const [value, setter] = useState(initValue);
  const handler = useCallback((e) => {
    setter(e.target.value);
  }, []);
  return [value, handler, setter];
};

export const toastSuccess = (title: string, description = "") => {
  toast({
    type: "success",
    title: title,
    description: description,
    animation: "fade right",
    time: 4000,
  });
};

export const toastError = (title: string, description = "") => {
  toast({
    type: "error",
    title: title,
    description: description,
    animation: "fade right",
    time: 4000,
  });
};

export const numberFormat = (inputNumber: number) => inputNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
export const millisToMin = (millis: number) => Math.floor(millis / 60000);

export const haveAuthOf = (self: any, role: any) => {
  if (self.role === "superAdmin" || (self.role === "admin" && (role === "manager" || role === "user")) || (self.role === "manager" && role === "user")) {
    return true;
  } else {
    return false;
  }
};

export function validateEmail(email: string) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function numFormat(variable: string | number) {
  variable = Number(variable).toString();
  if (Number(variable) < 10 && variable.length === 1) variable = "0" + variable;
  return variable;
}

export const checkIsAutoMode = (erv: any) => {
  if (erv.fwVersion === "v2Pro" && erv.manualControl && erv.manualControl.vsp !== null) {
    return false;
  } else if (erv.fwVersion === "v2" && erv.manualControl && erv.manualControl.fan !== null) {
    return false;
  } else {
    return true;
  }
};

// export const autoModeText = (erv: IErv) => {
//   if (erv.fwVersion === "v2Pro" && erv.manualControl && erv.manualControl.vsp !== null) {
//     const vspMsg = { 0: "정지", 40: "약", 60: "중", 80: "강" };
//     if (erv.manualControl.vsp === 0) {
//       return `OFF - ${vspMsg[erv.manualControl.vsp]}`;
//     }
//   } else if (erv.fwVersion === "v2" && erv.manualControl && erv.manualControl.fan !== null) {
//     const fanMsg = { 0: "정지", 1: "약", 2: "중", 3: "강" };
//     return `OFF - ${fanMsg[erv.manualControl.fan]}`;
//   } else {
//     return "ON";
//   }
// };

export const getUserStatusColor: any = (status: any, isHex = false) => {
  if (isHex) {
    if (status === "paid") return "#fbbd08";
    if (status === "consulting") return "#b5cc18";
    if (status === "new") return "#db2828";
    if (status === "filter") return "#e03997";
    if (status === "outlier") return "#2185d0";
    if (status === "sponsor") return "#767676";
    if (status === "active") return "#767676";
  }

  if (status === "paid") return "yellow";
  if (status === "consulting") return "olive";
  if (status === "new") return "red";
  if (status === "filter") return "pink";
  if (status === "outlier") return "blue";
  if (status === "sponsor") return "grey";
  if (status === "active") return "grey";

  return "#d4d4d5";
};
export const hexStringToBufferJson = (str: any) => {
  try {
    let packet = str.split(" ");
    packet = packet.map((val: any) => (val.length === 2 ? val : "0" + val));
    if (packet.find((data: any) => data.length > 2 || data.toLowerCase() !== (data[0] === "0" ? "0" : "") + parseInt(data, 16).toString(16))) return null;
    packet = Buffer.from(packet.map((data: any) => parseInt(data, 16))).toJSON();
    return packet;
  } catch (err) {
    return null;
  }
};
export const bufferJsonToHexString = (bufJson: any) => {
  if (!bufJson) return "";
  const buf: any = Buffer.from(bufJson);
  const arr = [...buf];
  const str = arr.map((data) => (data < 16 ? "0" : "") + data.toString(16).toUpperCase()).join(" ");
  return str;
};

export const omitDeepArrayWalk = (arr: any, key: any) => {
  return arr.map((val: any) => {
    if (Array.isArray(val)) return omitDeepArrayWalk(val, key);
    else if (typeof val === "object") return omitDeep(val, key);
    return val;
  });
};

export const omitDeep = (obj: any, key: any) => {
  const keys = Object.keys(obj);
  const newObj: any = {};
  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i];
      if (val instanceof Date) newObj[i] = val;
      else if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key);
      else if (typeof val === "object" && val !== null) newObj[i] = omitDeep(val, key);
      else newObj[i] = val;
    }
  });
  return newObj;
};

export function pickChangedItem(prev: any = {}, cur: any = {}) {
  const ret: any = {};
  for (const key in cur) {
    if (cur[key] !== prev[key]) {
      ret[key] = cur[key];
    }
  }
  return ret;
}

export function removeNullItem(cur: any = {}) {
  const nullValues = [undefined, null, ""];
  const ret: any = {};
  for (const key in cur) {
    if (nullValues.includes(cur[key])) continue;
    ret[key] = cur[key];
  }
  return ret;
}

