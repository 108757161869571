import React, { useState, useEffect } from "react";
import { toastSuccess, toastError } from "@util";
import { Icon, Checkbox, Form, Confirm, Button, Input, Dropdown, Rating, Radio } from "semantic-ui-react";
import { useInput, hexStringToBufferJson, bufferJsonToHexString, omitDeep } from "../../util";
import PinPointMap from "@Components/Common/PinPointMap";
import PacketForm from "@Components/Locations/PacketForm";
import { UPDATE_KAPTDATA_OF_APARTMENT } from "@graphql/mutation";
import { useMutation } from "@apollo/react-hooks";
import { useModal } from "@Hooks/useModal";
import { LocationForm } from "@Components/Modals";


type TLocationItem = {
  location: any;
  ervTypes: any;
  createLocation: any;
  updateLocation: any;
};

function LocationItem({ location, ervTypes, createLocation, updateLocation }: TLocationItem) {
  const [kaptInfo, setKaptInfo] = useState<any>({});
  const [coordinates, setCoordinates] = useState({});
  const [isOnRoadSide, setIsOnRoadSide] = useState<any>(false);
  const [isNewHouse, setIsNewHouse] = useState<any>(false);
  const [cleanliness, setCleanliness] = useState<any>("mid");
  const [ervNoiseLevel, setErvNoiseLevel] = useState<any>("mid");
  const [ervTypeId, setErvTypeId] = useState(location.ervType && location.ervType._id);
  const [visitDuration, setVisitDuration] = useState<any>();

  const [vspMode, setVspMode] = useState(false);
  const [vspModeType, setVspModeType] = useState();

  const [hackingType, setHackingType] = useState();
  const [locationDescription, onChangeLocationDescription, setLocationDescription] = useInput("");

  const [onPacketRequest, onChangeOnPacketRequest, setOnPacketRequest] = useInput("");
  const [offPacketRequest, onChangeOffPacketRequest, setOffPacketRequest] = useInput("");
  const [lowPacketRequest, onChangeLowPacketRequest, setLowPacketRequest] = useInput("");
  const [midPacketRequest, onChangeMidPacketRequest, setMidPacketRequest] = useInput("");
  const [highPacketRequest, onChangeHighPacketRequest, setHighPacketRequest] = useInput("");
  const [checkStatePacketRequest, onChangeCheckStatePacketRequest, setCheckStatePacketRequest] = useInput("");
  const [bypassOnPacketRequest, onChangeBypassOnPacketRequest, setBypassOnPacketRequest] = useInput("");
  const [bypassOffPacketRequest, onChangeBypassOffPacketRequest, setBypassOffPacketRequest] = useInput("");

  const [onPacketResponse, onChangeOnPacketResponse, setOnPacketResponse] = useInput("");
  const [offPacketResponse, onChangeOffPacketResponse, setOffPacketResponse] = useInput("");
  const [lowPacketResponse, onChangeLowPacketResponse, setLowPacketResponse] = useInput("");
  const [midPacketResponse, onChangeMidPacketResponse, setMidPacketResponse] = useInput("");
  const [highPacketResponse, onChangeHighPacketResponse, setHighPacketResponse] = useInput("");
  const [checkStatePacketResponse, onChangeCheckStatePacketResponse, setCheckStatePacketResponse] = useInput("");
  const [bypassOnPacketResponse, onChangeBypassOnPacketResponse, setBypassOnPacketResponse] = useInput("");
  const [bypassOffPacketResponse, onChangeBypassOffPacketResponse, setBypassOffPacketResponse] = useInput("");

  const [isOnPacket, setIsOnPacket] = useState(false);
  const [stopBit, setStopBit] = useState(1);
  const [isPeriodic, setIsPeriodic] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [notionLink, onChangeNotionLink, setNotionLink] = useInput("");
  const [storeUrl, onChangeStoreUrl, setStoreUrl] = useInput("");
  const [filterPrice, onChangeFilterPrice, setFilterPrice] = useInput();
  const [filterChangePrice, onChangeFilterChangePrice, setFilterChangePrice] = useInput();
  const { ModalPortal : LocationModalPortal, openModal : openLocationModal, closeModal : closeLocationModal } =  useModal();

  const ratings = ["very low", "low", "mid", "high", "very high"];

  const [updateKaptDataOfApartment] = useMutation(UPDATE_KAPTDATA_OF_APARTMENT, {
    onCompleted(data) {
      toastSuccess("업데이트 완료");
    },
    onError(error) {
      toastError("업데이트 실패", error.message);
    },
  });

  useEffect(() => {
    setKaptInfo(location.kaptInfo || { kaptName: location });
    if (location._id) {
      setCoordinates(location.coordinates);
      setErvTypeId(location.ervType?._id);
      setVisitDuration(location.visitDuration);
      setHackingType(location.hackingType);
      setLocationDescription(location.comment);
      setIsOnPacket(location.wallpad.controlType.isOnPacket);
      setFilterPrice(location.filterPrice);
      setFilterChangePrice(location.filterChangePrice);
      setStoreUrl(location.storeUrl);
      setNotionLink(location.notionLink);
      setIsOnRoadSide(location.profile.isOnRoadSide || false);
      setIsNewHouse(location.profile.isNewHouse || false);
      setCleanliness(location.profile.cleanliness || "mid");
      setErvNoiseLevel(location.profile.ervNoiseLevel || "mid");
      if (location.hackingType === "packet-snipping") {
        setIsOnPacket(location.wallpad.controlType.isOnPacket || false);
        setIsPeriodic(location.wallpad.controlType.isPeriodic || false);
        setStopBit(location.wallpad.controlType.stopBit || 1);
        setVspMode(location.wallpad.controlType.vspMode || false);
        setVspModeType(location.wallpad.controlType.vspModeType || undefined);
        setOnPacketRequest(bufferJsonToHexString(location.wallpad.controlPacket.onPacket.request));
        setOffPacketRequest(bufferJsonToHexString(location.wallpad.controlPacket.offPacket.request));
        setLowPacketRequest(bufferJsonToHexString(location.wallpad.controlPacket.lowPacket.request));
        setMidPacketRequest(bufferJsonToHexString(location.wallpad.controlPacket.midPacket.request));
        setHighPacketRequest(bufferJsonToHexString(location.wallpad.controlPacket.highPacket.request));
        setCheckStatePacketRequest(bufferJsonToHexString(location.wallpad.controlPacket.checkStatePacket.request));
        setBypassOnPacketRequest(bufferJsonToHexString(location.wallpad.controlPacket.bypassOnPacket.request));
        setBypassOffPacketRequest(bufferJsonToHexString(location.wallpad.controlPacket.bypassOffPacket.request));
        setOnPacketResponse(bufferJsonToHexString(location.wallpad.controlPacket.onPacket.response));
        setOffPacketResponse(bufferJsonToHexString(location.wallpad.controlPacket.offPacket.response));
        setLowPacketResponse(bufferJsonToHexString(location.wallpad.controlPacket.lowPacket.response));
        setMidPacketResponse(bufferJsonToHexString(location.wallpad.controlPacket.midPacket.response));
        setHighPacketResponse(bufferJsonToHexString(location.wallpad.controlPacket.highPacket.response));
        setCheckStatePacketResponse(bufferJsonToHexString(location.wallpad.controlPacket.checkStatePacket.response));
        setBypassOnPacketResponse(bufferJsonToHexString(location.wallpad.controlPacket.bypassOnPacket.response));
        setBypassOffPacketResponse(bufferJsonToHexString(location.wallpad.controlPacket.bypassOffPacket.response));
      }
    }
  }, []);
  function handleOnChangeOnPacket() {
    isOnPacket ? setIsOnPacket(false) : setIsOnPacket(true);
  }

  const handleSubmit = async () => {
    setConfirmOpen(false);
    let data: any = {
      kaptInfo,
      coordinates,
      ervType: ervTypeId,
      hackingType,
      comment: locationDescription,
      filterPrice: Number(filterPrice),
      filterChangePrice: Number(filterChangePrice),
      visitDuration,
      storeUrl,
      notionLink,
      profile: { isOnRoadSide, cleanliness, ervNoiseLevel, isNewHouse },
    };
    if (!ervTypeId && data.hackingType !== "impossible") {
      toastError("ERV를 먼저 등록해주세요");
      return;
    }
    if (data.hackingType === "packet-snipping") {
      data.wallpad = {
        controlType: {
          type: "rs485",
          isPeriodic,
          stopBit,
          isOnPacket,
          vspMode,
          vspModeType,
        },
        controlPacket: {
          onPacket: {
            request: hexStringToBufferJson(onPacketRequest),
            response: hexStringToBufferJson(onPacketResponse),
          },
          offPacket: {
            request: hexStringToBufferJson(offPacketRequest),
            response: hexStringToBufferJson(offPacketResponse),
          },
          lowPacket: {
            request: hexStringToBufferJson(lowPacketRequest),
            response: hexStringToBufferJson(lowPacketResponse),
          },
          midPacket: {
            request: hexStringToBufferJson(midPacketRequest),
            response: hexStringToBufferJson(midPacketResponse),
          },
          highPacket: {
            request: hexStringToBufferJson(highPacketRequest),
            response: hexStringToBufferJson(highPacketResponse),
          },
          checkStatePacket: {
            request: hexStringToBufferJson(checkStatePacketRequest),
            response: hexStringToBufferJson(checkStatePacketResponse),
          },
          bypassOnPacket: {
            request: hexStringToBufferJson(bypassOnPacketRequest),
            response: hexStringToBufferJson(bypassOnPacketResponse),
          },
          bypassOffPacket: {
            request: hexStringToBufferJson(bypassOffPacketRequest),
            response: hexStringToBufferJson(bypassOffPacketResponse),
          },
        },
      };
    }
    let submitLocation = { ...location, ...data };
    submitLocation = omitDeep(submitLocation, "__typename");

    if (location._id) {
      delete submitLocation._id;
      updateLocation({ variables: { locationId: location._id, data: submitLocation } });
    } else {
      await createLocation({ variables: { data: submitLocation } });
    }
  };

  // location modal 활성화
  const onClickLocationBtn = () => {
    if (kaptInfo.kaptCode && kaptInfo.kaptCode[0] !== "P") return;
    openLocationModal();
  }

  // location 수정 제출
  const locationSubmit = (data: any) => {
    setKaptInfo(Object.assign({...kaptInfo}, { kaptName: data.name || kaptInfo.kaptName, kaptAddr: data.address1 || kaptInfo.kaptAddr, zipCode: data.zipCode }));
    setCoordinates(data.coordinates || coordinates);
    closeLocationModal();
  }

  async function refreshKaptData() {
    await updateKaptDataOfApartment({ variables: { locationId: location._id } });
  }
  return (
    <Form>
      <br />
      <Form.Group>
        <Form.Field>
          <label>Location Information</label>
          <Button onClick={onClickLocationBtn}>{kaptInfo.kaptAddr || "주소 입력하기"}</Button>
        </Form.Field>

        <Form.Field>
          <label>Use Date</label>
          <Button>{(location.kaptInfo && location.kaptInfo.kaptUsedate) || "unknown"}</Button>
        </Form.Field>
        {location.type === "apartment" && (
          <Form.Field>
            <label>Update Info</label>
            <Button primary animated="fade" onClick={refreshKaptData}>
              <Button.Content hidden>
                <Icon name="refresh" />
                {location.kaptInfo.kaptCode}
              </Button.Content>
              <Button.Content visible>Update KAPT Info</Button.Content>
            </Button>
          </Form.Field>
        )}
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <label>ERV 소음 수준: {ervNoiseLevel}</label>
          <Rating
            icon="heart"
            rating={ratings.findIndex((val) => val === ervNoiseLevel) + 1}
            maxRating={5}
            onRate={(e, data: any) => {
              if (data.rating === 0) setErvNoiseLevel(null);
              setErvNoiseLevel(ratings[data.rating - 1]);
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>지역 공기 청정도: {cleanliness}</label>
          <Rating
            icon="heart"
            rating={ratings.findIndex((val) => val === cleanliness) + 1}
            maxRating={5}
            onRate={(e, data: any) => {
              if (data.rating === 0) setCleanliness(null);
              setCleanliness(ratings[data.rating - 1]);
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>대로변 여부</label>
          <Radio checked={isOnRoadSide} toggle onChange={(e, { checked }) => setIsOnRoadSide(checked)} />
        </Form.Field>

        <Form.Field>
          <label>새집 여부</label>
          <Radio checked={isNewHouse} toggle onChange={(e, { checked }) => setIsNewHouse(checked)} />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <label>필터 상점 URL</label>
          <Input placeholder="http://" value={storeUrl} onChange={onChangeStoreUrl} type="text" />
        </Form.Field>

        <Form.Field>
          <label>필터 주문 비용</label>
          <Input value={filterPrice} onChange={onChangeFilterPrice} type="number" />
        </Form.Field>

        <Form.Field>
          <label>필터 방문 교체 비용(필터 포함)</label>
          <Input value={filterChangePrice} onChange={onChangeFilterChangePrice} type="number" />
        </Form.Field>
        <Form.Field>
          <label>방문 소요 시간(min)</label>
          <Input
            style={{ width: 200 }}
            value={visitDuration}
            onChange={(e, data) => {
              setVisitDuration(Number(data.value));
            }}
            type="number"
          />
        </Form.Field>
      </Form.Group>

      {location.coordinates && <PinPointMap coordinates={location.coordinates} name={location.kaptInfo.kaptName} />}

      <h4 style={{ margin: "30px 0 6px" }}>ERV Information</h4>
      <Form.Group>
        <Form.Field>
          <label> ERV Type</label>
          <Dropdown
            style={{ width: 200 }}
            placeholder="ERV Name"
            fluid
            selection
            // text={selectedErvType.text}
            value={ervTypeId}
            search
            // options={ervTypes}
            options={ervTypes.map((ervType: any) => ({ key: ervType._id, text: ervType.name, value: ervType._id }))}
            onChange={(e, data) => {
              setErvTypeId(data.value);
            }}
          />
        </Form.Field>

        <Form.Field>
          <label>Hacking Type</label>
          <Dropdown
            style={{ width: 250 }}
            placeholder="Hacking Type"
            fluid
            selection
            value={hackingType}
            options={[
              { key: 1, text: "packet-snipping", value: "packet-snipping" },
              { key: 2, text: "board-replacement", value: "board-replacement" },
              { key: 3, text: "impossible", value: "impossible" },
            ]}
            onChange={(e, data: any) => setHackingType(data.value)}
          />
        </Form.Field>

        <Form.Field>
          <label>
            <a target="_blank" href={notionLink}>
              Link
            </a>
          </label>
          <Input placeholder="Notion Link" value={notionLink} onChange={onChangeNotionLink} type="text"></Input>
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <label>Descriptions</label>
        <Input style={{ width: 800 }} type="text" value={locationDescription} onChange={onChangeLocationDescription} placeholder="Any Descriptions" />
      </Form.Field>

      {hackingType === "packet-snipping" && (
        <>
          <h4 style={{ margin: "30px 0 6px" }}>Wall Pad Information</h4>
          <Form.Group>
            <Form.Field>
              <label>Stop Bit</label>
              <Dropdown
                placeholder="Stop Bit"
                fluid
                selection
                value={stopBit}
                options={[
                  { key: 1, text: 1, value: 1 },
                  { key: 2, text: 2, value: 2 },
                ]}
                onChange={(e, data: any) => setStopBit(data.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>Is Periodic</label>
              <Dropdown
                placeholder="Is Periodic"
                fluid
                selection
                value={isPeriodic}
                options={[
                  { key: 0, text: "No", value: false },
                  { key: 1, text: "Yes", value: true },
                ]}
                onChange={(e, data: any) => setIsPeriodic(data.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>VSP available</label>
              <Radio checked={vspMode} toggle onChange={(e, { checked }: any) => setVspMode(checked)} />
            </Form.Field>
            {vspMode && (
              <Form.Field>
                <label>VSP Mode Type</label>
                <Dropdown
                  placeholder="VSP Mode"
                  fluid
                  selection
                  value={vspModeType}
                  options={[
                    { key: 0, text: "SLT", value: "SLT" },
                    { key: 1, text: "HNT", value: "HNT" },
                  ]}
                  onChange={(e, data: any) => setVspModeType(data.value)}
                />
              </Form.Field>
            )}
          </Form.Group>

          <Checkbox onChange={handleOnChangeOnPacket} checked={isOnPacket} />
          <label>On Packet </label>
          {isOnPacket ? (
            <PacketForm
              header=""
              request={onPacketRequest}
              response={onPacketResponse}
              onChangeRequest={onChangeOnPacketRequest}
              onChangeResponse={onChangeOnPacketResponse}
              required={false}
            />
          ) : (
            <Form.Field />
          )}
          <PacketForm
            header="Off Packet"
            request={offPacketRequest}
            response={offPacketResponse}
            onChangeRequest={onChangeOffPacketRequest}
            onChangeResponse={onChangeOffPacketResponse}
            required={true}
          />
          <PacketForm
            header="Low Packet"
            request={lowPacketRequest}
            response={lowPacketResponse}
            onChangeRequest={onChangeLowPacketRequest}
            onChangeResponse={onChangeLowPacketResponse}
            required={true}
          />
          <PacketForm
            header="Mid Packet"
            request={midPacketRequest}
            response={midPacketResponse}
            onChangeRequest={onChangeMidPacketRequest}
            onChangeResponse={onChangeMidPacketResponse}
            required={true}
          />
          <PacketForm
            header="High Packet"
            request={highPacketRequest}
            response={highPacketResponse}
            onChangeRequest={onChangeHighPacketRequest}
            onChangeResponse={onChangeHighPacketResponse}
            required={true}
          />
          <PacketForm
            header="Check State Packet"
            request={checkStatePacketRequest}
            response={checkStatePacketResponse}
            onChangeRequest={onChangeCheckStatePacketRequest}
            onChangeResponse={onChangeCheckStatePacketResponse}
            required={false}
          />
          <PacketForm
            header="Bypass On Packet"
            request={bypassOnPacketRequest}
            response={bypassOnPacketResponse}
            onChangeRequest={onChangeBypassOnPacketRequest}
            onChangeResponse={onChangeBypassOnPacketResponse}
            required={false}
          />
          <PacketForm
            header="Bypass Off Packet"
            request={bypassOffPacketRequest}
            response={bypassOffPacketResponse}
            onChangeRequest={onChangeBypassOffPacketRequest}
            onChangeResponse={onChangeBypassOffPacketResponse}
            required={false}
          />
        </>
      )}
      <Button onClick={() => setConfirmOpen(true)}>Submit</Button>
      <Confirm open={confirmOpen} onCancel={() => setConfirmOpen(false)} onConfirm={handleSubmit} content="정말로 업데이트 하시겠습니까?" />
      <LocationModalPortal title="Location">
        <LocationForm 
          name={kaptInfo.kaptName}
          address1={kaptInfo.kaptAddr}
          coordinates={coordinates}
          // disableId={true}
          // disablePassword={true}
          disableDetailedAddress={true}
          onSubmit={locationSubmit}
        />
      </LocationModalPortal>      
    </Form>
  );
}
export default LocationItem;
