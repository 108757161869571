import React, { useState } from "react";
import { Input, Form, Button } from "semantic-ui-react";

function SignupForm({ onSubmit }: { onSubmit: any }) {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const strOriginal = e.target.value.replace(/[^0-9]/g, "");
    setPhone(strOriginal.replace(/(^01.{1})([0-9]+)([0-9]{4})/, "$1-$2-$3"));
  };

  return (
    <Form onSubmit={() => onSubmit({ id, password, name, phone, email })}>
      <Form.Field>
        <label>ID</label>
        <Input type="text" placeholder="ID" value={id} onChange={(e) => setId(e.target.value)} required />
      </Form.Field>
      <Form.Field>
        <label>Password</label>
        <Input type="password" placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
      </Form.Field>

      <Form.Field>
        <label>Password Check</label>
        <Form.Input
          error={password != passwordCheck ? "비밀번호가 일치하지 않습니다." : null}
          type="password"
          placeholder="passwordCheck"
          value={passwordCheck}
          onChange={(e) => setPasswordCheck(e.target.value)}
          required
        />
      </Form.Field>

      <Form.Field>
        <label>Name</label>
        <Input type="name" placeholder="name" value={name} onChange={(e) => setName(e.target.value)} required />
      </Form.Field>

      <Form.Field>
        <label>Phone</label>
        <Input type="phone" placeholder="phone" value={phone} onChange={onChangePhone} maxLength={13} required />
      </Form.Field>

      <Form.Field>
        <label>Email</label>
        <Input type="email" placeholder="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
      </Form.Field>
      <Button type="submit" fluid>
        Submit
      </Button>
    </Form>
  );
}

export default SignupForm;
