import React, { useState } from "react";
import { Button, Form, Input, Modal, Icon, Radio, Label, Checkbox, Rating } from "semantic-ui-react";
import { useInput } from "@util";
import LocationSelector from "@Components/User/LocationSelector";
import type { IFloor } from "@interfaces";

type TProps = {
  floor?:IFloor;
  onSubmit?: any;
};

export const FloorForm = ({onSubmit, floor }: TProps) => {
  const [name, setName] = useState(floor?.name || "");
  const [wifis, setWifis] = useState<any>(floor?.wifis || []);
  const [ssid, onChangeSsid, setSsid] = useInput("");
  const [password, onChangePassword, setPassword] = useInput("");
  const [location, setLocation] = useState<any>(floor?.location);
  const [floorLevel, onChangeFloorLevel] = useInput(floor?.profile?.floorLevel || 0);
  const [isNewHouse, setIsNewHouse] = useState( floor?.profile?.isNewHouse || false);
  const [ervNoiseLevel, setErvNoiseLevel] = useState<any>( floor?.profile?.ervNoiseLevel );
  const [showPassword, setShowPassword] = useState(false);
  const ratings = ["very low", "low", "mid", "high", "very high"];

  function addWifiList(e: any) {
    e.preventDefault();
    if (!ssid) return;
    if (wifis.find((wifi: any) => wifi.ssid === ssid)) return;
    setWifis([...wifis, { ssid: ssid, password: password }]);
    setSsid("");
    setPassword("");
  }
  function handleWifiListRemove(targetWifi: any) {
    const newWifis = wifis.filter((wifi: any) => !(wifi.ssid === targetWifi.ssid && wifi.password === targetWifi.password));
    setWifis(newWifis);
  }

  return (
    <Modal.Content>
      <Form onSubmit={() => onSubmit({ name, imageUrl : "", location: location._id, wifis, profile: { isNewHouse, floorLevel, ervNoiseLevel } })}>
        <Form.Group>
          <Form.Field>
            <label>Location</label>
            <LocationSelector location={location} setLocation={setLocation} allowAdditions={false} />
          </Form.Field>

          <Form.Field>
            <label>Name</label>
            <Input type="text" value={name} onChange={(e) => setName(e.target.value)} required={true} />
          </Form.Field>
        </Form.Group>

        <h4>Wi-Fi List</h4>
        <Form.Group>
          <Form.Field>
            <Input type="text" value={ssid} onChange={onChangeSsid} placeholer="SSID" />
          </Form.Field>
          <Form.Field>
            <Input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={onChangePassword}
              placeholer="Password"
              action={{ icon: "plus", onClick: (e: any) => addWifiList(e) }}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox checked={showPassword} onChange={(e, { checked }: any) => setShowPassword(checked)} />
            Show Password
          </Form.Field>
        </Form.Group>
        <div style={{ margin: "20px 0" }}>
          {wifis.map((wifi: any, idx: number) => (
            <Label key={idx} style={{ marginBottom: 4 }}>
              {`${wifi.ssid} / ${showPassword ? wifi.password : "••••••••"}`}
              <Icon name="delete" onClick={() => handleWifiListRemove(wifi)} />
            </Label>
          ))}
        </div>
        <Form.Group>
          <Form.Field>
            <label>층 수</label>
            <Input style={{ width: 80 }} focus type="number" value={floorLevel} onChange={onChangeFloorLevel} />
          </Form.Field>
          <Form.Field>
            <label>새집 여부</label>
            <Radio checked={isNewHouse} toggle onChange={(e, { checked }: any) => setIsNewHouse(checked)} />
          </Form.Field>
          <Form.Field>
            <label>ERV 소음 수준: {ervNoiseLevel}</label>
            <Rating
              icon="heart"
              rating={ratings.findIndex((val) => val === ervNoiseLevel) + 1}
              maxRating={5}
              clearable
              onRate={(e, data: any) => {
                if (data.rating === 0) setErvNoiseLevel(null);
                setErvNoiseLevel(ratings[data.rating - 1]);
              }}
            />
          </Form.Field>
        </Form.Group>

        <Button type="submit" fluid>
          Submit
        </Button>
      </Form>
    </Modal.Content>
  );
}
