import React, { useCallback, useRef, useContext, useState } from "react";
import moment from "moment";
import { Loader, Segment, Form, Input, Button, Header, Card, Menu, Icon, Label } from "semantic-ui-react";
import { toastSuccess, toastError } from "@util";
import SelfContext from "@selfContext";
import DefaultLayout from "@Layouts/DefaultLayout";
import { useInput } from "@util";
import { Helmet } from "react-helmet";
import { INIT_FARMS_PAGE } from "@graphql/query";
import { CREATE_ERV_CTRL, SYNC_ALL_AWAIRS } from "@graphql/mutation";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { IAwair, IErvCtrl } from "@interfaces";

function ErvCtrlReg() {
  const [newErvCtrlId, onChangeNewErvCtrlId, setNewErvCtrlId] = useInput("");
  const ervCtrlIdInput = useRef<Input>(null);
  const { self } = useContext(SelfContext);
  const [pageName, setPageName] = useState("ERV"); // ERV | AWAIR

  const { loading, data, refetch } = useQuery<{ awairs: IAwair[]; ervCtrls: IErvCtrl[] }, null>(INIT_FARMS_PAGE);

  // ervCtrl 생성
  const [createErvCtrl] = useMutation<{ createErvCtrl: IErvCtrl }, { data: { id: string } }>(CREATE_ERV_CTRL, {
    onCompleted(data) {
      setNewErvCtrlId("");
      toastSuccess("신규 ERV Controller가 등록되었습니다.", `ERV Controller ID : ${data.createErvCtrl.id}`);
    },
    onError(error) {
      toastError("신규 ERV Controller 등록에 실패하였습니다.", error.message);
      console.error(error);
    },
    refetchQueries: [{ query: INIT_FARMS_PAGE }],
  });

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      await refetch();
      if (data && data.ervCtrls.find((ervCtrl: IErvCtrl) => ervCtrl.id === newErvCtrlId)) {
        toastError("ERV Controller 등록 실패", "이미 등록되었습니다.");
        if (ervCtrlIdInput.current) ervCtrlIdInput.current.focus();
        return;
      }
      await createErvCtrl({ variables: { data: { id: newErvCtrlId } } });
    },
    [newErvCtrlId]
  );

  // awair sync
  const [syncAllAwairs] = useMutation<{ syncAllAwairs: IAwair[] }, null>(SYNC_ALL_AWAIRS, {
    onCompleted(_) {
      toastSuccess("awair sync 성공");
    },
    onError(error) {
      console.error(error);
      toastError("awair sync 실패", error.message);
    },
    refetchQueries: [{ query: INIT_FARMS_PAGE }],
  });

  const onClickAwairSync = async () => {
    await syncAllAwairs();
  };

  if (loading)
    return (
      <DefaultLayout>
        <Loader active />
      </DefaultLayout>
    );

  if (!data || !(self && self.role === "superAdmin")) return <DefaultLayout></DefaultLayout>;

  return (
    <DefaultLayout>
      <Helmet>
        <title>퍼핀 농장</title>
      </Helmet>
      <Segment>
        <Header as="h2">퍼핀 농장</Header>
        <Menu secondary>
          <Menu.Item
            name="퍼핀에그"
            active={pageName === "ERV"}
            onClick={() => {
              setPageName("ERV");
            }}
          />
          <Menu.Item
            name="어웨어"
            active={pageName === "AWAIR"}
            onClick={() => {
              setPageName("AWAIR");
            }}
          />
        </Menu>
      </Segment>
      {pageName === "ERV" ? (
        <>
          <Segment>
            <Form onSubmit={onSubmit}>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>ERV Controller ID</label>
                  <Input type="text" ref={ervCtrlIdInput} value={newErvCtrlId} onChange={onChangeNewErvCtrlId} required />
                </Form.Field>
              </Form.Group>

              <Button type="submit">Submit</Button>
            </Form>
          </Segment>

          <Segment>
            <Header as="h3">
              갈곳없는 퍼핀 에그
              <Label style={{ marginLeft: 10 }}>
                <Icon name="clone" />
                {data.ervCtrls.filter((ervCtrl: IErvCtrl) => !ervCtrl.erv).length}
              </Label>
            </Header>
            <Card.Group style={{ minHeight: 150 }}>
              {data.ervCtrls
                .filter((ervCtrl: IErvCtrl) => !ervCtrl.erv)
                .map((ervCtrl: IErvCtrl) => (
                  <Card key={ervCtrl._id}>
                    <Card.Content>
                      <Card.Header>{ervCtrl.name || "No Name"}</Card.Header>
                      <Card.Meta>ERV controller</Card.Meta>
                      <Card.Description>
                        <div>ID: {ervCtrl.id}</div>
                        <div>등록일 :{moment(ervCtrl.createdAt).format("YYYY-MM-DD, h:mm a")}</div>
                      </Card.Description>
                    </Card.Content>
                  </Card>
                ))}
            </Card.Group>
          </Segment>
        </>
      ) : (
        <></>
      )}
      {pageName === "AWAIR" ? (
        <>
          <Segment>
            <Button onClick={onClickAwairSync}>Awair Sync</Button>
          </Segment>

          <Segment>
            <Header as="h3">
              갈곳없는 어웨어
              <Label style={{ marginLeft: 10 }}>
                <Icon name="clone" />
                {data.awairs.filter((awair: IAwair) => !awair.section).length}
              </Label>
            </Header>
            <Card.Group style={{ minHeight: 150 }}>
              {data.awairs
                .filter((awair: IAwair) => !awair.section)
                .map((awair: IAwair) => (
                  <Card key={awair._id}>
                    <Card.Content>
                      <Card.Header>{awair.name}</Card.Header>
                      <Card.Meta>awair</Card.Meta>
                      <Card.Description>
                        UUID: {awair.uuid}
                        <br />
                        MAC Address: {awair.macAddr}
                        <br />
                        <Icon name="calendar" />
                        {moment(awair.createdAt).format("YYYY-MM-DD, h:mm a")}
                      </Card.Description>
                    </Card.Content>
                  </Card>
                ))}
            </Card.Group>
          </Segment>
        </>
      ) : (
        <></>
      )}
    </DefaultLayout>
  );
}

export default ErvCtrlReg;
