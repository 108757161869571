import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Header, Icon, Segment, Loader } from "semantic-ui-react";
import DefaultLayout from "@Layouts/DefaultLayout";
import ScheduleForm from "@Components/Managers/ScheduleForm";
import { INIT_MANAGERS_SCHEDULE_PAGE } from "@graphql/query";
import { UPDATE_ACCOUNT } from "@graphql/mutation";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { toastSuccess, toastError } from "@util";
import styled from "styled-components";

const colors = ["#19F5C0", "#2A7DF5", "#F525B3", "#F5A60C", "#1FF500", "#0747F5", "#EBCCF5", "#F52005"];

export default function ManagersSchedule() {
  const { orgId }: { orgId: string } = useParams();
  const [locationColors, setLocationColors] = useState();

  const { loading, data } = useQuery(INIT_MANAGERS_SCHEDULE_PAGE, {
    variables: { organizationId: orgId },
    // fetchPolicy: "network-only",
    onCompleted(data) {
      const colorObj: any = {};
      data.organization.locations.forEach((location: any, idx: number) => (colorObj[location._id] = colors[idx]));
      setLocationColors(colorObj);
    },
  });

  const [updateAccount] = useMutation(UPDATE_ACCOUNT, {
    onCompleted(data) {
      toastSuccess("일정 수정", `매니저 ID : ${data.updateAccount.id}`);
    },
    onError(error) {
      console.error(error);
      toastError("일정 수정 실패", error.message);
    },
    refetchQueries: [{ query: INIT_MANAGERS_SCHEDULE_PAGE, variables: { organizationId: orgId } }],
  });

  if (loading)
    return (
      <DefaultLayout>
        <Loader active />
      </DefaultLayout>
    );

  return (
    <DefaultLayout>
      <Segment>
        <Header as="h2">
          매니저 일정관리
          <Header.Subheader>orgName : {data.organization.name}</Header.Subheader>
        </Header>
      </Segment>
      {!!data.organization.managers.length && !!data.organization.locations ? (
        data.organization.managers.map((manager: any) => (
          <Segment key={manager._id}>
            <Header as="h3">
              <Icon name="user" style={{ fontSize: 20 }} /> {manager.name}
            </Header>
            <ScheduleForm manager={manager} updateAccount={updateAccount} locations={data.organization.locations} locationColors={locationColors} />
          </Segment>
        ))
      ) : (
        <P>manager와 location의 지정이 필요합니다. </P>
      )}
    </DefaultLayout>
  );
}

const P = styled.p`
  text-align: center;
  font-size: 18px;
  margin-top: 60px;
  color: #666;
`;
