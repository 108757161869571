import React, { useState } from "react";
import { Button, Form, Input, Modal, Icon, Dropdown } from "semantic-ui-react";
import styled from "styled-components";
import DaumPostcode from "react-daum-postcode";
import { ModalCloseButton } from "@styles/common";
import mediaQueries from "@styles/mediaQueries";
import Axios from "axios";
import { GET_APT_CODE } from "@graphql/query";
import { useLazyQuery } from "@apollo/react-hooks";

type TProps = {
  onSubmit: Function;
};

export const ApartmentRegistrationForm = ({onSubmit}: TProps) => {
  // const [isOpen, setIsOpen] = useState<boolean>(false);
  // const [onSubmit, setOnSubmit] = useState<any>(() => () => {});
  const [address, setAddress] = useState("");
  const [kaptCode, setKaptCode] = useState("");
  const [aptName, setAptName] = useState("");
  const [apts, setApts] = useState([]);
  const [isAddressShow, setIsAddressShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [getAptCode] = useLazyQuery(GET_APT_CODE, {
    onCompleted(data) {
      if (!data.getAptCode) {
        setErrorMsg("선택가능한 아파트가 없습니다.");
        throw Error("No Data");
      }
      let aptsData = [];
      Array.isArray(data.getAptCode) ? (aptsData = data.getAptCode) : aptsData.push(data.getAptCode); // 데이터가 하나의 경우 객체로 전달됨

      setApts(
        aptsData.map((cur: any) => ({
          key: cur.kaptCode,
          value: `${cur.kaptCode}__${cur.kaptName}`,
          text: cur.kaptName,
        }))
      );
    },
  });

  const selectHandler = (value: string) => {
    const aptData = value.split("__");
    setKaptCode(aptData[0]);
    setAptName(aptData[1]);
  };

  const handleAddress = async (data: any) => {
    setErrorMsg("");
    setApts([]);
    setAptName("");
    setKaptCode("");

    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    try {
      const kakaoResp = (
        await Axios.get(`https://dapi.kakao.com/v2/local/search/address.json?query=${fullAddress}`, {
          headers: {
            authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_KEY}`,
          },
        })
      ).data;

      setAddress(fullAddress);
      if (!(kakaoResp.documents && kakaoResp.documents[0])) return;

      const bjdCode = kakaoResp.documents[0].address.b_code;

      // let aptsData = [];
      await getAptCode({ variables: { bjdCode: bjdCode, limit: 100 } });
      // const aptResp = await a pi.getAptCode(bCodeData); ////////////////////

      // if (!aptResp) {
      //   setErrorMsg("선택가능한 아파트가 없습니다.");
      //   throw Error("No Data");
      // }
      // Array.isArray(aptResp) ? (aptsData = aptResp) : aptsData.push(aptResp); // 데이터가 하나의 경우 객체로 전달됨

      // setApts(
      //   aptsData.map((cur) => ({
      //     key: cur.kaptCode,
      //     value: `${cur.kaptCode}__${cur.kaptName}`,
      //     text: cur.kaptName,
      //   }))
      // );
    } catch (error) {
      console.error(error);
    } finally {
      setIsAddressShow(false);
    }
  };

  return (
    <>
      <Modal.Content>
        <Form onSubmit={() => onSubmit({ name: aptName, kaptCode: kaptCode })}>
          <Form.Field>
            <label>주소</label>
            <Input type="text" value={address} onFocus={() => setIsAddressShow(true)} required />
          </Form.Field>
          <Form.Field>
            <label>아파트 선택</label>
            <Dropdown
              selection
              search
              options={apts}
              disabled={!address}
              onChange={(e, data: any) => {
                selectHandler(data.value);
              }}
            />
          </Form.Field>
          <Form.Field>{errorMsg && <p style={{ color: "red" }}>{errorMsg}</p>}</Form.Field>

          <Button type="submit" fluid>
            Submit
          </Button>
        </Form>
      </Modal.Content>
     
      {isAddressShow && (
        <PostcodeWrap>
          <Postcode>
            <ModalCloseButton onClick={() => setIsAddressShow(false)}>
              <Icon name="close" size="large" />
            </ModalCloseButton>
            <DaumPostcode onComplete={handleAddress} />
          </Postcode>
        </PostcodeWrap>
      )}
    </>
  );
}


const PostcodeWrap = styled.div`
  width: 500px !important;
  ${mediaQueries("sm")`
    width:100% !important;                
  `}
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 2px 2px 4px grey;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 1001;
`;

const Postcode = styled.div`
  position: relative;
  padding-top: 30px;
`;
