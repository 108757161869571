import React, {useState} from "react";
import styled from "styled-components";
import { DateRangePicker } from "react-dates";
import { Button } from "semantic-ui-react";
import moment from "moment";

type TProps = {
  startDateInput: any,
  endDateInput: any,
  handleDatesChange: any,
  submitDates: any
}

function SelectDate({ startDateInput,endDateInput, handleDatesChange, submitDates}: TProps) {
  const [focusedInput, setFocusedInput] = useState(null);

  return (
    <Container>
      <DateRangePicker
        minimumNights={0}
        showDefaultInputIcon
        isOutsideRange={(date) => (date > moment().endOf("day") ? true : false)}
        startDateId="startDate"
        endDateId="endDate"
        displayFormat="YYYY-MM-DD"
        startDate={startDateInput}
        endDate={endDateInput}
        onDatesChange={(dates) => handleDatesChange(dates)}
        focusedInput={focusedInput}
        onFocusChange={(input: any) => {
          setFocusedInput(input);
        }}
      />
      <StyledButton onClick={submitDates}>확인</StyledButton>
    </Container>
  )
}
export { SelectDate };

const Container = styled.div`
  display:inline;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 10px;
  font-size: 12px;
  p {
    line-height: 0.8em;
  }
`;

const StyledButton = styled(Button)`
  height: 48px;
`