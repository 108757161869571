import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "@Pages/Dashboard";
import Dashboard2 from "@Pages/Dashboard2";
import EventDashBoard from "@Pages/EventDashBoard";
import Login from "@Pages/Login";
import Signup from "@Pages/Signup";
import Orgs from "@Pages/Orgs";
import Org from "@Pages/Org";
import ManagersSchedule from "@Pages/ManagersSchedule";
import User from "@Pages/User";
import Floor from "@Pages/Floor";
// import Awair from "@Pages/Awair";
import Farms from "@Pages/Farms";
import NotFound from "@Pages/404";
import PrivateRoute from "@PrivateRoute";
import SelfContext from "@selfContext";
import Billings from "@Pages/Billings";
import Contracts from "@Pages/Contracts";
import ContractReg from "@Pages/ContractReg";
import PayOrder from "@Pages/PayOrder";
// import Erv from "@Pages/Erv";
import Locations from "@Pages/Locations";
import Filters from "@Pages/Filters";
import ConsultingReport from "@Pages/ConsultingReport";
import WeeklyReports from "@Pages/WeeklyReports";
import WeeklyReport from "@Pages/WeeklyReport";
import DailyReports from "@Pages/DailyReports";
import DailyReport from "@Pages/DailyReport";
import Position from "@Pages/Position";

function Routes() {
  return (
    <Switch>
      <PrivateRoute exact path="/">
        <SelfContext.Consumer>
          {({ self }) => {
            if (self.role === `superAdmin`) return <Redirect to="/orgs" />;
            else if (self.role === `admin` || self.role === `manager`) return <Redirect to={`/orgs/${self.organization}`} />;
            else if (self.role === "userAccount") return <Redirect to={`/orgs/${self.organization}/users/${self.user._id}`} />;
          }}
        </SelfContext.Consumer>
      </PrivateRoute>
      <PrivateRoute exact path="/orgs">
        <SelfContext.Consumer>
          {({ self }) => {
            if (self.role === `superAdmin`) return <Orgs />;
            else if (self.role === `admin` || self.role === `manager`) return <Redirect to={`/orgs/${self.organization}`} />;
            else if (self.role === "userAccount") return <Redirect to={`/orgs/${self.organization}/users/${self.user._id}`} />;
          }}
        </SelfContext.Consumer>
      </PrivateRoute>

      {/* EventDashboard */}
      <PrivateRoute exact path="/orgs/:orgId">
        <SelfContext.Consumer>
          {({ self }) => {
            if (self.role === "userAccount") return <Redirect to={`/orgs/${self.organization}/users/${self.user._id}`} />;
            else return <EventDashBoard />;
          }}
        </SelfContext.Consumer>
      </PrivateRoute>

      <PrivateRoute exact path="/orgs/:orgId/managers/schedule" component={ManagersSchedule} />
      <PrivateRoute exact path="/orgs/:orgId/managers/:managerId" component={EventDashBoard} />

      {/* Setting */}
      {/* <PrivateRoute exact path="/orgs/:orgId/setting" component={Org} /> */}
      <PrivateRoute exact path="/orgs/:orgId/setting">
        <SelfContext.Consumer>
          {({ self }) => {
            if (self.role === "userAccount") return <Redirect to={`/orgs/${self.organization}/users/${self.user._id}`} />;
            else return <Org />;
          }}
        </SelfContext.Consumer>
      </PrivateRoute>
      <PrivateRoute exact path="/orgs/:orgId/users/:userId/locations/:locationId/floors/:floorId" component={Floor} />
      <PrivateRoute exact path="/orgs/:orgId/users/:userId" component={User} />

      <PrivateRoute path="/locations">
        <SelfContext.Consumer>
          {({ self }) => {
            if (self.role === `superAdmin`) return <Locations />;
            else if (self.role === `admin` || self.role === `manager`) return <Redirect to={`/orgs/${self.organization}`} />;
            else if (self.role === "userAccount") return <Redirect to={`/orgs/${self.organization}/users/${self.user._id}`} />;
          }}
        </SelfContext.Consumer>
      </PrivateRoute>

      <PrivateRoute path="/farms">
        <SelfContext.Consumer>
          {({ self }) => {
            if (self.role === `superAdmin`) return <Farms />;
            else if (self.role === `admin` || self.role === `manager`) return <Redirect to={`/orgs/${self.organization}`} />;
            else if (self.role === "userAccount") return <Redirect to={`/orgs/${self.organization}/users/${self.user._id}`} />;
          }}
        </SelfContext.Consumer>
      </PrivateRoute>

      <PrivateRoute path="/filters">
        <SelfContext.Consumer>
          {({ self }) => {
            if (self.role === `superAdmin`) return <Filters />;
            else if (self.role === `admin` || self.role === `manager`) return <Redirect to={`/orgs/${self.organization}`} />;
            else if (self.role === "userAccount") return <Redirect to={`/orgs/${self.organization}/users/${self.user._id}`} />;
          }}
        </SelfContext.Consumer>
      </PrivateRoute>

      {/* Report */}
      <PrivateRoute path="/reports/weekly/users/:userId/floors/:floorId" component={WeeklyReport} />
      <PrivateRoute path="/reports/weekly">
        <SelfContext.Consumer>
          {({ self }) => {
            if (self.role === `superAdmin`) return <WeeklyReports />;
            else if (self.role === `admin` || self.role === `manager`) return <Redirect to={`/orgs/${self.organization}`} />;
          }}
        </SelfContext.Consumer>
      </PrivateRoute>

      <PrivateRoute path="/reports/daily/users/:userId/floors/:floorId/status/:status/ddaynum/:dDayNum" component={DailyReport} />
      <PrivateRoute path="/reports/daily/users/:userId/floors/:floorId/status/:status" component={DailyReport} />
      <PrivateRoute path="/reports/daily">
        <SelfContext.Consumer>
          {({ self }) => {
            if (self.role === `superAdmin`) return <DailyReports />;
            else if (self.role === `admin` || self.role === `manager`) return <Redirect to={`/orgs/${self.organization}`} />;
            else if (self.role === "userAccount") return <Redirect to={`/orgs/${self.organization}/users/${self.user._id}`} />;
          }}
        </SelfContext.Consumer>
      </PrivateRoute>

      {/* Billing & Contract */}
      <PrivateRoute exact path="/orgs/:orgId/users/:userId/contracts/:contractId/billings" component={Billings} />
      <PrivateRoute exact path="/orgs/:orgId/users/:userId/contracts" component={Contracts} />
      <PrivateRoute exact path="/orgs/:orgId/users/:userId/contract-registration" component={ContractReg} />
      <PrivateRoute exact path="/orgs/:orgId/users/:userId/pay-order" component={PayOrder} />
      {/* <PrivateRoute exact path="/orgs/:orgId/users/:userId/locations/:locationId/floors/:floorId/contract-registration" component={ContractReg} /> */}

      {/* Consulting Report */}
      <PrivateRoute path="/orgs/:orgId/users/:userId/floors/:floorId/consulting" component={ConsultingReport} />

      {/* Dashboard */}
      <PrivateRoute path="/orgs/:orgId/users/:userId/floors/:floorId/dashboard" component={Dashboard} />
      <PrivateRoute path="/orgs/:orgId/users/:userId/floors/:floorId/dashboard2" component={Dashboard2} />
      
      {/* Position */}
      <PrivateRoute path="/orgs/:orgId/users/:userId/floors/:floorId/position" component={Position} />
      
      {/* <PrivateRoute path="/orgs/:orgId/awair/:awairId" component={Awair} /> */}
      {/* <PrivateRoute path="/orgs/:orgId/users/:userId/ervs/:ervId" component={Erv} /> */}
      <Route path="/login" component={Login} />
      <Route path="/signup" component={Signup} />

      <Route path="/error/404" component={NotFound} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}

export default Routes;
