import Highcharts from "highcharts";
// import { curryRight } from "lodash";
import moment from "moment";

const hourXaxis = [
  "00시",
  "01시",
  "02시",
  "03시",
  "04시",
  "05시",
  "06시",
  "07시",
  "08시",
  "09시",
  "10시",
  "11시",
  "12시",
  "13시",
  "14시",
  "15시",
  "16시",
  "17시",
  "18시",
  "19시",
  "20시",
  "21시",
  "22시",
  "23시",
];

export default {
  common: {
    time: {
      // timezone: 'Asia/Seoul',
      timezoneOffset: 9,
      useUTC: false,
    },
    xAxis: {
      type: "datetime",
      labels: {
        // formatter: function() {
        //   let moment = require('moment')
        //   return moment(this.value).format("HH:mm")
        // }
      },
    },
    chart: {
      type: "line",
      zoomType: "x",
    },

    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [1, new Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get("rgba")],
          ],
        },
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: "50",
          },
          chartOptions: {
            legend: {
              layout: "vertical",
              align: "center",
              verticalAlign: "top",
            },
          },
        },
      ],
    },
    legend: {
      layout: "horizontal",
      align: "left",
      verticalAlign: "top",
    },
  },
  temperature: {
    title: {
      style: {
        "font-weight": "bold",
        fontSize: "18px",
      },
      text: "Temperature",
    },
    yAxis: {
      title: {
        text: "Exchange rate",
      },
    },
  },
  humidity: {
    title: {
      text: "Humidity",
      style: {
        "font-weight": "bold",
        fontSize: "18px",
      },
    },
    yAxis: {
      title: {
        text: "Exchange rate",
      },
    },
  },
  CO2: {
    title: {
      text: "CO2",
      style: {
        "font-weight": "bold",
        fontSize: "18px",
      },
    },
    yAxis: {
      title: {
        text: "Exchange rate",
      },
      min: 200,
      startOnTick: false,
    },
  },
  VOC: {
    title: {
      text: "VOC",
      style: {
        "font-weight": "bold",
        fontSize: "18px",
      },
    },
    yAxis: {
      title: {
        text: "VOC",
      },
      min: 0,
      startOnTick: false,
    },
  },
  PM25: {
    title: {
      text: "PM25",
      style: {
        "font-weight": "bold",
        fontSize: "18px",
      },
    },
    yAxis: {
      title: {
        text: "Exchange rate",
      },
    },
  },
  vsp: {
    title: {
      text: "VSP",
      style: {
        "font-weight": "bold",
        fontSize: "18px",
      },
    },
    yAxis: [
      {
        title: {
          text: "VSP(0~99)",
        },
        labels: {
          style: {
            color: "#4080B0",
          },
        },
        opposite: true,
        gridLineColor: "transparent",
        min: 0,
        max: 100,
        startOnTick: false,
        endOnTick: false,
      },
    ],
  },
  fanSpeed: {
    title: {
      text: "Fanspeed",
      style: {
        "font-weight": "bold",
        fontSize: "18px",
      },
    },
    yAxis: [
      {
        title: {
          text: "speed(0~3)",
        },
        labels: {
          style: {
            color: "#7cb4ec",
          },
        },
        tickPositioner: function (min, max) {
          var step = 1,
            ret = [];
          for (var i = min; i <= max; i += step) {
            ret.push(i);
          }
          return ret;
        },
        gridLineColor: "transparent",
        min: 0,
        max: 3,
        startOnTick: false,
        endOnTick: false,
      },
      {
        title: {
          text: "ventLevel(0~100)",
        },
        labels: {
          style: {
            color: "#4080B0",
          },
        },
        opposite: true,
        gridLineColor: "transparent",
        min: 0,
        max: 100,
        startOnTick: false,
        endOnTick: false,
      },
    ],
  },
  awairScore: {
    title: {
      text: "AwairScore",
      style: {
        "font-weight": "bold",
        fontSize: "18px",
      },
    },
    yAxis: {
      title: {
        text: "score",
      },
    },
  },
  houryAwairScore: {
    chart: {
      //   type: "column",
      type: "spline",
    },
    xAxis: {
      type: "String",
      categories: hourXaxis,
    },
    title: {
      text: "어웨어 점수",
      style: {
        "font-weight": "bold",
        fontSize: "18px",
      },
    },
    yAxis: {
      title: {
        text: "score",
      },
    },
  },
  dailyAwairScore: {
    chart: {
      type: "spline",
      height: 240,
      events: {
        load: null,
      },
    },
    xAxis: {
      type: "String",
      categories: null,
      labels: {
        formatter: function () {
          return moment(this.value, "YYYY. MM. DD").format("MM/DD");
        },
      },
    },
    title: {
      text: "",
      style: {
        "font-weight": "bold",
        fontSize: "18px",
      },
    },
    yAxis: {
      title: {
        text: "score",
      },
    },
    tooltip: {
      enabled: false,
      crosshairs: true,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
        color: "#203864",
        lineWidth: 3,
        showInLegend: false,
      },
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
  },
  awairScorePieChart: {
    chart: {
      type: "pie",
      // height: 200,
      height: 220,
    },
    title: {
      text: "",
      // style: {
      //   "font-weight": "bold",
      //   fontSize: "18px",
      // },
    },
    colors: ["#9fcc62", "#f6c143", "#cb6f56", "#7f7f7f"],
    // legend: {
    //   align: "right",
    //   layout: "vertical",
    //   verticalAlign: "middle",
    //   padding: 0,
    // },
    legend: {
      itemStyle: {
        fontSize: "10px",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        size: "100%",
        dataLabels: {
          enabled: true,
          // distance: -30,
          distance: 10,
          color: "#000000",
          style: {
            fontSize: "13px",
          },
          formatter: function () {
            return this.percentage == 0 ? null : this.percentage.toFixed(1) + " %";
          },
        },
        showInLegend: true,
      },
    },
  },
  hourlyTempAndHumid: {
    chart: {
      //   type: "column",
      type: "spline",
    },
    xAxis: {
      type: "String",
      categories: hourXaxis,
    },
    title: {
      // text: "온습도",
      text: "",
      style: {
        "font-weight": "bold",
        fontSize: "18px",
      },
    },
    yAxis: [
      {
        title: {
          text: "Temperature",
        },
        labels: {
          format: "{value} °C",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
      },
      {
        title: {
          text: "Humidity",
        },

        labels: {
          format: "{value} %",
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    series: [
      {
        name: "Temperature",
        type: "spline",
        yAxis: 0,

        tooltip: {
          valueSuffix: " °C",
        },
      },
      {
        name: "Humidity",
        type: "spline",
        yAxis: 1,
        tooltip: {
          valueSuffix: " %",
        },
      },
    ],
  },
  hourlyCo2: {
    chart: {
      type: "spline",
      height: 180,
    },
    xAxis: {
      type: "String",
      categories: hourXaxis,
      plotBands: {
        color: "#fce7cf",
        from: 0,
        to: 0,
      },
    },
    title: {
      // text: "이산화탄소",
      text: "",
      style: {
        "font-weight": "bold",
        fontSize: "18px",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          return this.value + " ppm";
        },
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
        showInLegend: false,
      },
    },
  },
  hourlyVoc: {
    chart: {
      type: "spline",
      height: 180,
    },
    xAxis: {
      type: "String",
      categories: hourXaxis,
      plotBands: {
        color: "#fce7cf",
        from: 0,
        to: 0,
      },
    },
    title: {
      // text: "화학물질",
      text: "",
      style: {
        "font-weight": "bold",
        fontSize: "18px",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          return this.value + " ppb";
        },
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
        showInLegend: false,
      },
    },
  },
  hourlyPm25: {
    chart: {
      type: "spline",
      height: 180,
    },
    xAxis: {
      type: "String",
      categories: hourXaxis,
      plotBands: {
        color: "#fce7cf",
        from: 0,
        to: 0,
      },
    },
    title: {
      // text: "미세먼지",
      text: "",
      style: {
        "font-weight": "bold",
        fontSize: "18px",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          return this.value + " µg/m³";
        },
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
        showInLegend: false,
      },
    },
  },
};
