import React from "react";
import { Form, Input } from "semantic-ui-react";
import { hexStringToBufferJson } from "../../util";

type TPacketForm = {
  header: any;
  request: any;
  response: any;
  onChangeRequest: any;
  onChangeResponse: any;
  required: any;
};

function PacketForm({ header, request, response, onChangeRequest, onChangeResponse, required }: TPacketForm) {
  return (
    <>
      <label>{`${header} ${!hexStringToBufferJson(request) ? (request.length === 0 ? "" : "invalid") : request.split(" ").length} / ${
        !hexStringToBufferJson(response) ? (response.length === 0 ? "" : "invalid") : response.split(" ").length
      }`}</label>
      <Form.Group>
        <Form.Field>
          <Input
            style={{ width: 390 }}
            type="text"
            value={request}
            onChange={onChangeRequest}
            placeholder={`request${required ? " (required)" : ""}`}
            required={required ? true : false}
          />
        </Form.Field>
        <Form.Field>
          <Input style={{ width: 390 }} type="text" value={response} onChange={onChangeResponse} placeholder={`response`} />
        </Form.Field>
      </Form.Group>
    </>
  );
}

export default PacketForm;
