import React, { useState } from "react";
import { Button, Form, Input, Modal, Icon } from "semantic-ui-react";
import DaumPostcode from "react-daum-postcode";
import Axios from "axios";
import { KAKAO_KEY } from "@constants";
import styled from "styled-components";
import { ModalCloseButton } from "@styles/common";
import mediaQueries from "@styles/mediaQueries";

type TProps = {
  name: string;
  address1: string;
  address2?: string;
  zipCode?: string;
  coordinates: any;
  disableDetailedAddress: boolean;
  onSubmit: Function;
};

export const LocationForm = ({name: prevName, address1: prevAddress1, coordinates, disableDetailedAddress: prevDisableDetailedAddress, onSubmit}: TProps) => {
  const [name, setName] = useState(prevName || "");
  const [address1, setAddress1] = useState(prevAddress1);
  const [address2, setAddress2] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [latitude, setLatitude] = useState(coordinates && coordinates.latitude ? coordinates.latitude : "");
  const [longitude, setLongitude] = useState(coordinates && coordinates.longitude ? coordinates.longitude : "");
  const [isAddressShow, setIsAddressShow] = useState(false);
  const [disableDetailedAddress, setDisableDetailedAddress] = useState(prevDisableDetailedAddress || true);
  
  function handleAddress(data: any) {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    Axios.get(`https://dapi.kakao.com/v2/local/search/address.json?query=${fullAddress}`, {
      headers: {
        authorization: `KakaoAK ${KAKAO_KEY}`,
      },
    })
      .then((resp) => {
        setIsAddressShow(false);
        setAddress1(fullAddress);
        if (resp.data.documents && resp.data.documents[0]) {
          setLongitude(resp.data.documents[0].x);
          setLatitude(resp.data.documents[0].y);
          setZipCode(resp.data.documents[0].address.zip_code);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <>
        <Modal.Content>
          <Form
            onSubmit={() =>
              onSubmit({
                name,
                address1,
                address2,
                coordinates: { latitude, longitude },
                zipCode,
              })
            }
          >
            <Form.Field>
              <label>Name</label>
              <Input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
            </Form.Field>
            <Form.Field>
              <label>Address line 1</label>
              <Input type="text" value={address1} onChange={(e) => setAddress1(e.target.value)} onFocus={() => setIsAddressShow(true)} required />
            </Form.Field>
            {!disableDetailedAddress && (
              <Form.Field>
                <label>Address line 2</label>
                <Input type="text" value={address2} onChange={(e) => setAddress2(e.target.value)} />
              </Form.Field>
            )}
            <Form.Group widths="equal">
              <Form.Field>
                <label>Latitude</label>
                <Input type="text" value={latitude} onChange={(e) => setLatitude(e.target.value)} required />
              </Form.Field>
              <Form.Field>
                <label>Longitude</label>
                <Input type="text" value={longitude} onChange={(e) => setLongitude(e.target.value)} required />
              </Form.Field>
            </Form.Group>
            <Button type="submit" fluid>
              Submit
            </Button>
          </Form>
        </Modal.Content>
   
      {isAddressShow && (
        <PostcodeWrap>
          <Postcode>
            <ModalCloseButton onClick={() => setIsAddressShow(false)}>
              <Icon name="close" size="large" />
            </ModalCloseButton>
            <DaumPostcode onComplete={handleAddress} />
          </Postcode>
        </PostcodeWrap>
      )}
    </>
  );
}

const PostcodeWrap = styled.div`
  width: 500px !important;
  ${mediaQueries("sm")`
    width:100% !important;                
  `}
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 1001;
`;

const Postcode = styled.div`
  position: relative;
  padding-top: 30px;
`;
