import React, { useState, useEffect } from "react";
import { useInput } from "@util";
import { Form, Confirm, Button, Input, Dropdown } from "semantic-ui-react";
import FilterForm from "@Components/Locations/FilterForm";

type TErvTypeItem = {
  ervType: any;
  ervTypeName: any;
  createErvType: any;
  updateErvType: any;
};

function ErvTypeItem({ ervType, ervTypeName, createErvType, updateErvType }: TErvTypeItem) {
  const [name, setName] = useState("");
  const [type, setType] = useState();
  const [capacity, setCapacity] = useState();
  const [ervDescription, onChangeErvDescription, setErvDescription] = useInput("");
  //const [ervManufacturer, onChangeErvManufacturer] = useInput("");
  //const [boardManufacturer, onChangeBoardManufacturer] = useInput("");

  const [preFilterOAX, onChangePreFilterOAX, setPreFilterOAX] = useInput("");
  const [hepaFilterOAX, onChangeHepaFilterOAX, setHepaFilterOAX] = useInput("");
  const [preFilterRAX, onChangePreFilterRAX, setPreFilterRAX] = useInput("");
  const [carbonFilterSAX, onChangeCarbonFilterSAX, setCarbonFilterSAX] = useInput("");
  const [exchangeElementX, onChangeExchangeElementX, setExchangeElementX] = useInput("");

  const [preFilterOAY, onChangePreFilterOAY, setPreFilterOAY] = useInput("");
  const [hepaFilterOAY, onChangeHepaFilterOAY, setHepaFilterOAY] = useInput("");
  const [preFilterRAY, onChangePreFilterRAY, setPreFilterRAY] = useInput("");
  const [carbonFilterSAY, onChangeCarbonFilterSAY, setCarbonFilterSAY] = useInput("");
  const [exchangeElementY, onChangeExchangeElementY, setExchangeElementY] = useInput("");

  const [preFilterOAZ, onChangePreFilterOAZ, setPreFilterOAZ] = useInput("");
  const [hepaFilterOAZ, onChangeHepaFilterOAZ, setHepaFilterOAZ] = useInput("");
  const [preFilterRAZ, onChangePreFilterRAZ, setPreFilterRAZ] = useInput("");
  const [carbonFilterSAZ, onChangeCarbonFilterSAZ, setCarbonFilterSAZ] = useInput("");
  const [exchangeElementZ, onChangeExchangeElementZ, setExchangeElementZ] = useInput("");
  const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    setName(ervTypeName);
    const fetchErvInfo = (ervType: any) => {
      if (!ervType._id) return;
      setName(ervType.name);
      setCapacity(ervType.capacity);
      setType(ervType.type);
      setErvDescription(ervType.description);
      if (ervType.filters.preFilterOA) {
        setPreFilterOAX(ervType.filters.preFilterOA.length);
        setPreFilterOAY(ervType.filters.preFilterOA.width);
        setPreFilterOAZ(ervType.filters.preFilterOA.thickness);
      }
      if (ervType.filters.hepaFilterOA) {
        setHepaFilterOAX(ervType.filters.hepaFilterOA.length);
        setHepaFilterOAY(ervType.filters.hepaFilterOA.width);
        setHepaFilterOAZ(ervType.filters.hepaFilterOA.thickness);
      }
      if (ervType.filters.preFilterRA) {
        setPreFilterRAX(ervType.filters.preFilterRA.length);
        setPreFilterRAY(ervType.filters.preFilterRA.width);
        setPreFilterRAZ(ervType.filters.preFilterRA.thickness);
      }
      if (ervType.filters.carbonFilterSA) {
        setCarbonFilterSAX(ervType.filters.carbonFilterSA.length);
        setCarbonFilterSAY(ervType.filters.carbonFilterSA.width);
        setCarbonFilterSAZ(ervType.filters.carbonFilterSA.thickness);
      }
      if (ervType.filters.exchangeElement) {
        setExchangeElementX(ervType.filters.exchangeElement.length);
        setExchangeElementY(ervType.filters.exchangeElement.width);
        setExchangeElementZ(ervType.filters.exchangeElement.thickness);
      }
    };
    fetchErvInfo(ervType);
  }, []);

  const handleSubmit = async () => {
    setConfirmOpen(false);
    const preFilterOA = {
      medium: "non-woven-fabric",
      length: Number(preFilterOAX),
      width: Number(preFilterOAY),
      thickness: Number(preFilterOAZ),
    };
    const hepaFilterOA = {
      medium: "H13-hepa",
      length: Number(hepaFilterOAX),
      width: Number(hepaFilterOAY),
      thickness: Number(hepaFilterOAZ),
    };
    const preFilterRA = {
      medium: "non-woven-fabric",
      length: Number(preFilterRAX),
      width: Number(preFilterRAY),
      thickness: Number(preFilterRAZ),
    };
    const carbonFilterSA = {
      medium: "non-woven-carbon",
      length: Number(carbonFilterSAX),
      width: Number(carbonFilterSAY),
      thickness: Number(carbonFilterSAZ),
    };
    const exchangeElement = {
      medium: "heat-exchange-element",
      length: Number(exchangeElementX),
      width: Number(exchangeElementY),
      thickness: Number(exchangeElementZ),
    };
    // const submitErvType = new ErvType(
    //   Object.assign(ervType._id ? ervType : {}, {
    //     name: name,
    //     capacity: capacity,
    //     //manufacturer: ervManufacturer,
    //     description: ervDescription,
    //     type: type,
    //     filters: {
    //       preFilterOA: preFilterOA,
    //       hepaFilterOA: hepaFilterOA,
    //       preFilterRA: preFilterRA,
    //       carbonFilterSA: carbonFilterSA,
    //       exchangeElement: exchangeElement,
    //     },
    //     //board: boardManufacturer,
    //   })
    // );

    const submitErvType = {
      name: name,
      capacity: capacity,
      //manufacturer: ervManufacturer,
      description: ervDescription,
      type: type,
      filters: {
        preFilterOA: preFilterOA,
        hepaFilterOA: hepaFilterOA,
        preFilterRA: preFilterRA,
        carbonFilterSA: carbonFilterSA,
        exchangeElement: exchangeElement,
      },
      //board: boardManufacturer,
    };

    if (ervType._id) {
      // 수정
      await updateErvType({ variables: { ervTypeId: ervType._id, data: submitErvType } });
    } else {
      // 등록
      await createErvType({ variables: { data: submitErvType } });
    }

    // try {
    //   let res;
    //   if (submitErvType._id) {
    //     res = await submitErvType.modify(submitErvType);
    //   } else res = await a pi.createErvType(submitErvType);
    //   toastSuccess("업데이트 성공");
    //   fetchErvInfo(res);
    // } catch (err) {
    //   console.error(err);
    //   toastError("업데이트 실패");
    // } finally {
    // }
  };
  /*
  const handleSubmit = async () => {
    setConfirmOpen(false);
    const preFilterOA = new Filter({
      medium: "non-woven-fabric",
      length: Number(preFilterOAX),
      width: Number(preFilterOAY),
      thickness: Number(preFilterOAZ),
    });
    const hepaFilterOA = new Filter({
      medium: "H13-hepa",
      length: Number(hepaFilterOAX),
      width: Number(hepaFilterOAY),
      thickness: Number(hepaFilterOAZ),
    });
    const preFilterRA = new Filter({
      medium: "non-woven-fabric",
      length: Number(preFilterRAX),
      width: Number(preFilterRAY),
      thickness: Number(preFilterRAZ),
    });
    const carbonFilterSA = new Filter({
      medium: "non-woven-carbon",
      length: Number(carbonFilterSAX),
      width: Number(carbonFilterSAY),
      thickness: Number(carbonFilterSAZ),
    });
    const exchangeElement = new Filter({
      medium: "heat-exchange-element",
      length: Number(exchangeElementX),
      width: Number(exchangeElementY),
      thickness: Number(exchangeElementZ),
    });
    const submitErvType = new ErvType(
      Object.assign(ervType._id ? ervType : {}, {
        name: name,
        capacity: capacity,
        //manufacturer: ervManufacturer,
        description: ervDescription,
        type: type,
        filters: {
          preFilterOA: preFilterOA,
          hepaFilterOA: hepaFilterOA,
          preFilterRA: preFilterRA,
          carbonFilterSA: carbonFilterSA,
          exchangeElement: exchangeElement,
        },
        //board: boardManufacturer,
      })
    );
    try {
      let res;
      if (submitErvType._id) {
        res = await submitErvType.modify(submitErvType);
      } else res = await a pi.createErvType(submitErvType);
      toastSuccess("업데이트 성공");
      fetchErvInfo(res);
    } catch (err) {
      console.error(err);
      toastError("업데이트 실패");
    } finally {
    }
  };
  */
  return (
    <Form>
      <h4 style={{ margin: "30px 0 6px" }}>ERV Information</h4>
      <Form.Group>
        <Form.Field>
          <label>Capacity</label>
          <Dropdown
            style={{ width: 150 }}
            placeholder="cmH"
            fluid
            selection
            search
            allowAdditions
            value={capacity}
            options={[
              { key: 1, text: "150", value: 150 },
              { key: 2, text: "200", value: 200 },
              { key: 3, text: "250", value: 250 },
              { key: 4, text: "350", value: 350 },
              { key: 5, text: "500", value: 500 },
            ]}
            onChange={(e, data: any) => setCapacity(data.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Type</label>
          <Dropdown
            style={{ width: 150 }}
            placeholder=""
            fluid
            selection
            search
            value={type}
            options={[
              { key: 1, text: "일반", value: "일반" },
              { key: 2, text: "급기", value: "급기" },
            ]}
            onChange={(e, data: any) => setType(data.value)}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <label>Descriptions</label>
        <Input style={{ width: 800 }} type="text" value={ervDescription} onChange={onChangeErvDescription} placeholder="Any Descriptions" />
      </Form.Field>
      <FilterForm
        header="Pre Filter OA"
        filterX={preFilterOAX}
        filterY={preFilterOAY}
        filterZ={preFilterOAZ}
        onChangeFilterX={onChangePreFilterOAX}
        onChangeFilterY={onChangePreFilterOAY}
        onChangeFilterZ={onChangePreFilterOAZ}
        required={true}
      />
      <FilterForm
        header="Hepa Filter OA"
        filterX={hepaFilterOAX}
        filterY={hepaFilterOAY}
        filterZ={hepaFilterOAZ}
        onChangeFilterX={onChangeHepaFilterOAX}
        onChangeFilterY={onChangeHepaFilterOAY}
        onChangeFilterZ={onChangeHepaFilterOAZ}
        required={true}
      />
      <FilterForm
        header="Pre Filter RA"
        filterX={preFilterRAX}
        filterY={preFilterRAY}
        filterZ={preFilterRAZ}
        onChangeFilterX={onChangePreFilterRAX}
        onChangeFilterY={onChangePreFilterRAY}
        onChangeFilterZ={onChangePreFilterRAZ}
        required={type === "일반"}
      />
      <FilterForm
        header="Carbon Filter SA"
        filterX={carbonFilterSAX}
        filterY={carbonFilterSAY}
        filterZ={carbonFilterSAZ}
        onChangeFilterX={onChangeCarbonFilterSAX}
        onChangeFilterY={onChangeCarbonFilterSAY}
        onChangeFilterZ={onChangeCarbonFilterSAZ}
        required={false}
      />
      <FilterForm
        header="Heat Exchange Element"
        filterX={exchangeElementX}
        filterY={exchangeElementY}
        filterZ={exchangeElementZ}
        onChangeFilterX={onChangeExchangeElementX}
        onChangeFilterY={onChangeExchangeElementY}
        onChangeFilterZ={onChangeExchangeElementZ}
        required={false}
      />
      <Button onClick={() => setConfirmOpen(true)}>Submit</Button>
      <Confirm open={confirmOpen} onCancel={() => setConfirmOpen(false)} onConfirm={handleSubmit} content="정말로 업데이트 하시겠습니까?" />
    </Form>
  );
}
export default ErvTypeItem;
