import React from "react";
import DefaultLayout from "@Layouts/DefaultLayout";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Segment, Header, Table, Button, Divider, Icon, Loader } from "semantic-ui-react";
import ContractItem from "@Components/Common/ContractItem";
import Breadcrumbs from "@Components/Common/Breadcrumbs";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { EmptyMessage } from "@styles/common";
import { Helmet } from "react-helmet";
import { INIT_CONTRACTS_PAGE } from "@graphql/query";
import { CANCEL_ORDER } from "@graphql/mutation";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { IContract, IOrder } from "@interfaces";
import OrderItem from "@Components/Common/Payment/OrderItem";
import { toastSuccess, toastError } from "@util";

function Contracts() {
  const { userId, orgId }: { userId: string; orgId: string } = useParams();

  const { loading, data, error } = useQuery(INIT_CONTRACTS_PAGE, {
    variables: {
      userId: userId,
    },
  });

  // order 결제 취소
  const [cancelOrder] = useMutation(CANCEL_ORDER, {
    onCompleted(data) {
      toastSuccess("선택한 결제가 취소되었습니다.");
    },
    onError(error) {
      toastError(`결제 취소 실패`, error.message);
    },
    refetchQueries: [
      {
        query: INIT_CONTRACTS_PAGE,
        variables: {
          userId: userId,
        },
      },
    ],
  });

  if (error) return null;
  if (loading)
    return (
      <DefaultLayout>
        <Loader active />
      </DefaultLayout>
    );

  return (
    <DefaultLayout>
      <Helmet>
        <title>{data.user.name}(contracts)</title>
      </Helmet>
      <Segment>
        <Header as="h2">
          Contracts
          <span style={{ marginLeft: "2rem" }}>
            <Breadcrumbs />
          </span>
        </Header>

        <Divider />

        <>
          <Header as="h3">{data.user.name}</Header>
          <Icon name="mail" />
          {data.user.email}
          <br />
          <Icon name="phone" />
          {data.user.phone}
          <br />
          <Icon name="calendar" />
          {moment(data.user.createdAt).format("YYYY-MM-DD, h:mm a")}
        </>

        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>serviceTier</Table.HeaderCell>
              <Table.HeaderCell>type</Table.HeaderCell>
              <Table.HeaderCell>term</Table.HeaderCell>
              <Table.HeaderCell>status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.user.contracts.length > 0 ? (
              data.user.contracts
                .filter((contract: IContract) => contract)
                .map((contract: IContract) => <ContractItem key={contract._id} contract={contract} orgId={orgId} userId={userId} />)
            ) : (
              <Table.Row>
                <Table.Cell colSpan="5">
                  <EmptyMessage>Empty</EmptyMessage>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="5">
                <ContractRegLink to={`/orgs/${orgId}/users/${userId}/contract-registration`}>
                  <Button>Contract 추가</Button>
                </ContractRegLink>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>

      <Segment>
        <Header as="h2">Orders</Header>
        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>type</Table.HeaderCell>
              <Table.HeaderCell>금액</Table.HeaderCell>
              <Table.HeaderCell>status</Table.HeaderCell>
              <Table.HeaderCell>결제 일시</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.user.orders.length > 0 ? (
              data.user.orders
                .filter((order: IOrder) => order && order.status !== "ready")
                .map((order: IOrder) => (
                  <>
                    <OrderItem key={order._id} order={order} cancelOrder={cancelOrder} />
                  </>
                ))
            ) : (
              // data.user.orders.map((contract: IContract) => contract && <ContractItem key={contract._id} contract={contract} orgId={orgId} userId={userId} />)
              <Table.Row>
                <Table.Cell colSpan="5">
                  <EmptyMessage>Empty</EmptyMessage>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="5">
                <ContractRegLink to={`/orgs/${orgId}/users/${userId}/pay-order`}>
                  <Button>개별 결제</Button>
                </ContractRegLink>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>
    </DefaultLayout>
  );
}

export default Contracts;

const ContractRegLink = styled(Link)`
  float: right;
`;
