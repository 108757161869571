import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Grid } from "semantic-ui-react";
import LocationSelector from "@Components/User/LocationSelector";
import styled from "styled-components";
import { ILocation } from "@src/interfaces";

type TProps = {
  name?: string;
  locations?: ILocation[];
  onSubmit?: any;
};

export const OrgForm = ({ name: prevName, locations: prevLocations, onSubmit }: TProps) => {
  const [name, setName] = useState("");
  const [location, setLocation] = useState<any>();
  const [locations, setLocations] = useState<any>([]);

  useEffect(()=>{
    setName(prevName || "");
    prevLocations?.length && setLocations(prevLocations);
  },[]);


  const onClickAddLocation = (e: any) => {
    e.preventDefault();
    if (!location?._id) return;
    setLocations(Array.from(new Set([...locations, location])));
  };

  const onClickRemoveLocation = (e: any, locationId: string) => {
    e.preventDefault();
    setLocations(locations.filter((cur: ILocation) => cur._id !== locationId));
  };

  const onClickSubmit = () => {
    const submitData = { name, locations: locations.length ? locations.map((cur: ILocation) => cur._id) : [] };
    onSubmit(submitData);
  };
  return (
    <>
      <Modal.Content>
        <Form onSubmit={onClickSubmit}>
          <Form.Field>
            <label>Name</label>
            <Input type="name" placeholder="name" value={name} onChange={(e) => setName(e.target.value)} required={true} />
          </Form.Field>
          <Form.Field>
            <label>Location</label>
            <Grid>
              <Grid.Column width={8}>
                <LocationSelector location={location} setLocation={setLocation} allowAdditions={false} />
              </Grid.Column>
              <Grid.Column width={1}>
                <Button circular icon="plus" onClick={onClickAddLocation} />
              </Grid.Column>
            </Grid>
          </Form.Field>
          <Form.Field>
            {!!locations.length &&
              locations.map((cur: any) => (
                <LocationItem key={cur._id}>
                  <span>
                    {cur.kaptInfo.kaptName} ({cur.kaptInfo.kaptAddr}){" "}
                  </span>
                  <Button circular icon="minus" onClick={(e) => onClickRemoveLocation(e, cur._id)} />
                </LocationItem>
              ))}
          </Form.Field>

          <Button type="submit" fluid>
            Submit
          </Button>
        </Form>
      </Modal.Content>
    </>
  );
}

const LocationItem = styled.div`
  span {
    color: #888;
    padding-right: 10;
  }
  button {
    margin-left: 10;
  }
`;
