import React, { useContext } from "react";
import moment from "moment";
import { Dropdown, Segment, Icon, Header, Button } from "semantic-ui-react";
import { toastSuccess, toastError, omitDeep } from "@util";
import CardItemActions from "@Components/Common/CardItemActions";
import { INIT_USER_PAGE, INIT_FLOOR_PAGE } from "@graphql/query";
import { CREATE_FLOOR, REMOVE_FLOOR, UPDATE_FLOOR, SET_FLOOR_STATUS } from "@graphql/mutation";
import { useMutation } from "@apollo/react-hooks";
import { IFloor, IUser } from "@interfaces";
import SelfContext from "@selfContext";
import { useModal } from "@Hooks/useModal";
import { FloorForm } from "@Components/Modals";

function FloorSegment({ curFloor, curFloorId, setCurFloorId, user }: { curFloor: IFloor; curFloorId: string; setCurFloorId: Function; user: IUser }) {
  const { self } = useContext(SelfContext);
  const { ModalPortal: FloorCreateModalPortal, openModal: openFloorCreateModal, closeModal: closeFloorCreateModal } = useModal();
  const { ModalPortal: FloorModifyModalPortal, openModal: openFloorModifyModal, closeModal: closeFloorModifyModal } = useModal();

  // floor 선택
  const handleChangeFloor = (idx: any) => {
    if (user.floors && user.floors[idx]) setCurFloorId(user.floors[idx]._id);
  };

  // floor 추가
  const [createFloor] = useMutation(CREATE_FLOOR, {
    onCompleted(data) {
      toastSuccess("floor 생성", `id : ${data.createFloor.name}`);
    },
    onError(error) {
      console.error("error", error);
      toastError("floor 생성 실패.", error.message);
    },
    refetchQueries: [{ query: INIT_USER_PAGE, variables: { userId: user._id } }],
  });

  // floor 수정
  const [updateFloor] = useMutation(UPDATE_FLOOR, {
    onCompleted(data) {
      toastSuccess("floor 수정", `id : ${data.updateFloor.name}`);
    },
    onError(error) {
      console.error("error", error);
      toastError("floor 수정 실패.", error.message);
    },
    refetchQueries: [{ query: INIT_USER_PAGE, variables: { userId: user._id } }],
  });

  // floor 등록 제출
  const floorCreateSubmit = (data: any) => {
    createFloor({ variables: { userId: user._id, data: data } });
    closeFloorCreateModal();
  };

  // floor 수정 제출
  const floorModifySubmit = (data: any) => {
    updateFloor({ variables: { floorId: curFloorId, data: omitDeep(data, "__typename") } });
    closeFloorModifyModal();
  };

  // floor 삭제
  const [removeFloor] = useMutation(REMOVE_FLOOR, {
    onCompleted(data) {
      toastSuccess("floor 삭제", `id : ${data.removeFloor.name}`);
    },
    onError(error) {
      console.error("error", error);
      toastError("floor 삭제 실패.", error.message);
    },
    refetchQueries: [{ query: INIT_USER_PAGE, variables: { userId: user._id } }],
  });

  const handleRemoveFloor = async () => {
    await removeFloor({ variables: { userId: user._id, floorId: curFloorId } });
  };

  // floor의 state 변경
  const [setFloorStatus] = useMutation(SET_FLOOR_STATUS, {
    onCompleted(data) {
      toastSuccess("floor의 state 변경 성공");
    },
    onError(error) {
      console.error("error", error);
      toastError("floor의 state 변경 실패", error.message);
    },
    refetchQueries: [{ query: INIT_USER_PAGE, variables: { userId: user._id } }],
  });

  return (
    <>
      <Segment>
        <Header>
          Service Location and Floor
          {self?.role !== "userAccount" && (
            <Button size="mini" onClick={openFloorCreateModal} style={{ marginLeft: 10 }}>
              <Icon name="plus" />
              new
            </Button>
          )}
          <Header.Subheader>total {user.floors?.length}</Header.Subheader>
        </Header>
        {!!user.floors?.length && (
          <div style={{ position: "relative" }}>
            <Dropdown
              placeholder="Select Floor"
              search
              defaultValue={0}
              options={user.floors
                .filter((floor: IFloor) => !!floor)
                .map(
                  (floor: IFloor, idx: number) =>
                    new Object({ key: floor._id, text: `${floor.location && floor.location?.kaptInfo?.kaptName} ${floor.name}`, value: idx })
                )}
              onChange={(e, data) => handleChangeFloor(data.value)}
            />
            <CardItemActions canModify={true} canRemove={true} onModify={openFloorModifyModal} onRemove={handleRemoveFloor} />
            {curFloor && curFloor.location && (
              <>
                <br />
                <br />
                <Icon name="map marker alternate" />
                {`${curFloor?.location?.kaptInfo?.kaptAddr} ${curFloor.name}`}
                <br />
                <Icon name="time" />
                {`준공연월: ${curFloor.location?.kaptInfo?.kaptUsedate || "미입력"}`}
                <br />
                <Icon name="phone" />
                관리사무소: {curFloor.location?.kaptInfo?.kaptTel || "미입력"}
                <br />
                status :{" "}
                <Dropdown
                  inline
                  options={[
                    { key: "active", text: "active", value: "active" },
                    { key: "monitoring", text: "monitoring", value: "monitoring" },
                    { key: "inService", text: "inService", value: "inService" },
                    { key: "expired", text: "expired", value: "expired" },
                  ]}
                  value={curFloor.status}
                  onChange={(e, data) => {
                    setFloorStatus({ variables: { floorId: curFloorId, status: data.value } });
                  }}
                />{" "}
                {curFloor.statusUpdatedAt && <span>(변경일 {moment(curFloor.statusUpdatedAt).format("YYYY-MM-DD, h:mm a")}) </span>}
                <br />
              </>
            )}
          </div>
        )}
      </Segment>
      <FloorCreateModalPortal title={`${user.name}님의 Floor`}>
        <FloorForm onSubmit={floorCreateSubmit} />
      </FloorCreateModalPortal>
      <FloorModifyModalPortal title={`${user.name}님의 Floor`}>
        <FloorForm onSubmit={floorModifySubmit} floor={curFloor} />
      </FloorModifyModalPortal>
    </>
  );
}

export default FloorSegment;
