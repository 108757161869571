import React from "react";
import styled from "styled-components";
import {useSectionCardContext} from "../useSectionCardContext";
import { useScores } from "@Components/Scores";
import { Icon } from "semantic-ui-react";

const ratings = ["very low", "low", "mid", "high", "very high"];

type TProps = {
  status : "very low" | "low" | "mid" | "high" | "very high";
}

function Rating({status}:TProps) {

  const statusIndex = ratings.findIndex((val) => val === status) + 1;
        
  return (
    <span>
      {ratings.map((cur, index)=> {
        if(statusIndex > index){
          return <StyledIcon key={index} size="mini" name="circle" />
        }else{
          return <StyledIcon key={index} size="mini" name="circle outline" />
        }
      })}
    </span>
  )
}
export { Rating };

const StyledIcon = styled(Icon)`
  margin-right: 6px !important;
  color: #494846;
`;
