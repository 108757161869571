import React, { useState } from "react";
import type { IAwair, IFloor, ISection, IUser } from "@interfaces";
import { useMutation } from "@apollo/react-hooks";
import { toastSuccess, toastError } from "@util";
import { REMOVE_SECTION, UPDATE_SECTION, ALLOCATE_AWAIR_IN_SECTION, UNALLOCATE_AWAIR_IN_SECTION } from "@graphql/mutation";
import { INIT_USER_PAGE } from "@graphql/query";
import { useModal } from "@Hooks/useModal";

type TProps = {
  section?: ISection;
  user?: IUser;
  floor?: IFloor;
};

function useSectionCard({ section, user, floor }: TProps) {
  const { ModalPortal: SectionModalPortal, openModal: openSectionModal, closeModal: closeSectionModal } = useModal();
  const { ModalPortal: AwairSelectorModalPortal, openModal: openAwairSelectorModal, closeModal: closeAwairSelectorModal } = useModal();
  const [detailType, setDetailType] = useState<"CURRENT" | "PREDICTION" | "AQI">("CURRENT");

  // section 수정
  const [updateSection] = useMutation(UPDATE_SECTION, {
    onCompleted(data) {
      toastSuccess("section 수정", `name : ${data.updateSection.name}`);
    },
    onError(error) {
      console.error("error", error);
      toastError("section 수정 실패.", error.message);
    },
    refetchQueries: [{ query: INIT_USER_PAGE, variables: { userId: user?._id } }],
  });

  // section 삭제
  const [removeSection] = useMutation(REMOVE_SECTION, {
    onCompleted(data) {
      toastSuccess("section 삭제", `name : ${data.removeSection.name}`);
    },
    onError(error) {
      console.error("error", error);
      toastError("section 삭제 실패.", error.message);
    },
    refetchQueries: [{ query: INIT_USER_PAGE, variables: { userId: user?._id } }],
  });

  // section 수정 제출
  const sectionModifySubmit = async (data: any) => {
    await updateSection({ variables: { sectionId: section?._id, data: data } });
    closeSectionModal();
  };

  //section에 awair 할당
  const [allocateAwairInSection] = useMutation(ALLOCATE_AWAIR_IN_SECTION, {
    onCompleted(data) {
      toastSuccess("awair 할당", `name : ${data.allocateAwairInSection.name}`);
    },
    onError(error) {
      console.error("error", error);
      toastError("awair 할당 실패.", error.message);
    },
    refetchQueries: [{ query: INIT_USER_PAGE, variables: { userId: user?._id } }],
  });

  //section에 awair 할당 해제
  const [unallocateAwairInSection] = useMutation(UNALLOCATE_AWAIR_IN_SECTION, {
    onCompleted(data) {
      toastSuccess("awair 할당해제", `name : ${data.unallocateAwairInSection.name}`);
    },
    onError(error) {
      console.error("error", error);
      toastError("awair 할당해제 실패.", error.message);
    },
    refetchQueries: [{ query: INIT_USER_PAGE, variables: { userId: user?._id } }],
  });

  const handleRemove = async () => {
    if (!floor || !section) return;
    await removeSection({ variables: { floorId: floor._id, sectionId: section._id } });
  };

  const handleUnallocate = async (awairs: IAwair[]) => {
    if (!section || !awairs.length) return;
    for (const awair of awairs) {
      await unallocateAwairInSection({ variables: { sectionId: section._id, awairId: awair._id } });
    }
  };

  // awair selector 수정 제출
  const awairSelectorModifySubmit = async (data: any) => {
    await allocateAwairInSection({ variables: { sectionId: section?._id, awairId: data.awairId } });
    closeAwairSelectorModal();
  };

  return {
    sectionModifySubmit,
    SectionModalPortal,
    openSectionModal,
    handleRemove,
    handleUnallocate,
    AwairSelectorModalPortal,
    openAwairSelectorModal,
    awairSelectorModifySubmit,
    detailType,
    setDetailType,
  };
}

export { useSectionCard };
