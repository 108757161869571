import gql from "graphql-tag";

export const SIGNIN = gql`
  mutation signin($id: String!, $password: String!) {
    signin(id: $id, password: $password) {
      accessToken
    }
  }
`;

export const SIGNUP_SUPERADMIN = gql`
  mutation signupSuperAdmin($key: String!, $data: AccountInput!) {
    signupSuperAdmin(key: $key, data: $data) {
      _id
      id
      name
      status
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation createOrganization($data: OrganizatonInput!) {
    createOrganization(data: $data) {
      _id
      name
      status
    }
  }
`;

export const REMOVE_ORGANIZATION = gql`
  mutation removeOrganization($organizationId: ID!) {
    removeOrganization(organizationId: $organizationId) {
      _id
      name
      status
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($organizationId: ID!, $data: OrganizatonInput!) {
    updateOrganization(organizationId: $organizationId, data: $data) {
      _id
      name
      status
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation createAccount($organizationId: ID!, $role: String!, $data: AccountInput!) {
    createAccount(organizationId: $organizationId, role: $role, data: $data) {
      _id
      id
    }
  }
`;

export const REMOVE_ACCOUNT = gql`
  mutation removeAccount($organizationId: ID!, $accountId: ID!) {
    removeAccount(organizationId: $organizationId, accountId: $accountId) {
      _id
      id
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation updateAccount($accountId: ID!, $data: AccountInput!) {
    updateAccount(accountId: $accountId, data: $data) {
      _id
      id
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($organizationId: ID, $data: UserInput) {
    createUser(organizationId: $organizationId, data: $data) {
      _id
      name
    }
  }
`;

export const REMOVE_USER = gql`
  mutation removeUser($organizationId: ID, $userId: ID) {
    removeUser(organizationId: $organizationId, userId: $userId) {
      _id
      name
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($userId: ID, $data: UserInput) {
    updateUser(userId: $userId, data: $data) {
      _id
      name
    }
  }
`;

export const CREATE_FLOOR = gql`
  mutation createFloor($userId: ID, $data: FloorInput) {
    createFloor(userId: $userId, data: $data) {
      _id
      name
    }
  }
`;

export const REMOVE_FLOOR = gql`
  mutation removeFloor($userId: ID, $floorId: ID) {
    removeFloor(userId: $userId, floorId: $floorId) {
      _id
      name
    }
  }
`;

export const UPDATE_FLOOR = gql`
  mutation updateFloor($floorId: ID, $data: FloorInput) {
    updateFloor(floorId: $floorId, data: $data) {
      _id
      name
    }
  }
`;

export const CREATE_SECTION = gql`
  mutation createSection($floorId: ID, $data: SectionInput) {
    createSection(floorId: $floorId, data: $data) {
      _id
      name
    }
  }
`;

export const REMOVE_SECTION = gql`
  mutation removeSection($floorId: ID, $sectionId: ID) {
    removeSection(floorId: $floorId, sectionId: $sectionId) {
      _id
      name
    }
  }
`;

export const UPDATE_SECTION = gql`
  mutation updateSection($sectionId: ID, $data: SectionInput) {
    updateSection(sectionId: $sectionId, data: $data) {
      _id
      name
    }
  }
`;

export const CREATE_ERV = gql`
  mutation createErv($floorId: ID, $data: ErvInput) {
    createErv(floorId: $floorId, data: $data) {
      _id
      name
    }
  }
`;

export const REMOVE_ERV = gql`
  mutation removeErv($floorId: ID, $ervId: ID) {
    removeErv(floorId: $floorId, ervId: $ervId) {
      _id
      name
    }
  }
`;

export const UPDATE_ERV = gql`
  mutation updateErv($ervId: ID, $data: ErvInput) {
    updateErv(ervId: $ervId, data: $data) {
      _id
      name
    }
  }
`;

export const CREATE_ERV_CTRL = gql`
  mutation createErvCtrl($data: ErvCtrlInput) {
    createErvCtrl(data: $data) {
      _id
      id
    }
  }
`;

export const REMOVE_ERV_CTRL = gql`
  mutation removeErvCtrl($ervCtrlId: ID) {
    removeErvCtrl(ervCtrlId: $ervCtrlId) {
      _id
      id
    }
  }
`;

export const UPDATE_ERV_CTRL = gql`
  mutation updateErvCtrl($ervCtrlId: ID, $data: ErvCtrlInput) {
    updateErvCtrl(ervCtrlId: $ervCtrlId, data: $data) {
      _id
      id
    }
  }
`;

export const SYNC_ALL_AWAIRS = gql`
  mutation syncAllAwairs {
    syncAllAwairs {
      _id
    }
  }
`;

export const ALLOCATE_AWAIR_IN_SECTION = gql`
  mutation allocateAwairInSection($sectionId: ID, $awairId: ID) {
    allocateAwairInSection(sectionId: $sectionId, awairId: $awairId) {
      _id
      name
    }
  }
`;

export const UNALLOCATE_AWAIR_IN_SECTION = gql`
  mutation unallocateAwairInSection($sectionId: ID, $awairId: ID) {
    unallocateAwairInSection(sectionId: $sectionId, awairId: $awairId) {
      _id
      name
    }
  }
`;

export const ALLOCATE_ERVCTRL_TO_ERV = gql`
  mutation allocateErvCtrlToErv($ervId: ID, $ervCtrlId: ID) {
    allocateErvCtrlToErv(ervId: $ervId, ervCtrlId: $ervCtrlId) {
      _id
      name
    }
  }
`;

export const UNALLOCATED_ERVCTRL_TO_ERV = gql`
  mutation unallocateErvCtrlToErv($ervId: ID, $ervCtrlId: ID) {
    unallocateErvCtrlToErv(ervId: $ervId, ervCtrlId: $ervCtrlId) {
      _id
      name
    }
  }
`;

export const SET_ERVCONTROL_INFO = gql`
  mutation setErvControlInfo($ervCtrlId: ID) {
    setErvControlInfo(ervCtrlId: $ervCtrlId) {
      _id
      name
      id
    }
  }
`;

export const CREATE_ERV_TYPE = gql`
  mutation createErvType($data: ErvTypeInput) {
    createErvType(data: $data) {
      _id
    }
  }
`;

export const UPDATE_ERV_TYPE = gql`
  mutation updateErvType($ervTypeId: ID, $data: ErvTypeInput) {
    updateErvType(ervTypeId: $ervTypeId, data: $data) {
      _id
    }
  }
`;

export const CREATE_LOCATION = gql`
  mutation createLocation($data: LocationInput) {
    createLocation(data: $data) {
      _id
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation updateLocation($locationId: ID, $data: LocationInput) {
    updateLocation(locationId: $locationId, data: $data) {
      _id
    }
  }
`;

export const UPDATE_KAPTDATA_OF_APARTMENT = gql`
  mutation updateKaptDataOfApartment($locationId: ID) {
    updateKaptDataOfApartment(locationId: $locationId) {
      _id
    }
  }
`;

export const CREATE_CONTRACT = gql`
  mutation createContract($userId: ID!, $data: ContractInput!, $customerUid: String!) {
    createContract(userId: $userId, data: $data, customerUid: $customerUid) {
      _id
    }
  }
`;

export const SET_NEXT_SCHEDULE_PAYMENT = gql`
  mutation setNextScheduledPayment($contractId: ID!) {
    setNextScheduledPayment(contractId: $contractId) {
      _id
    }
  }
`;

export const CANCEL_CONTRACT = gql`
  mutation cancelContract($contractId: ID!) {
    cancelContract(contractId: $contractId) {
      _id
    }
  }
`;

export const UNCANCEL_CONTRACT = gql`
  mutation uncancelContract($contractId: ID!) {
    uncancelContract(contractId: $contractId) {
      _id
    }
  }
`;

export const ADD_CARD_INFO_IN_USER = gql`
  mutation addCardInfoInUser($userId: ID!, $cardInfo: CardInfoInput!) {
    addCardInfoInUser(userId: $userId, cardInfo: $cardInfo)
  }
`;

export const REMOVE_CUSTOMER_UID_IN_USER = gql`
  mutation removeCustomerUidInUser($userId: ID!, $customerUid: String!) {
    removeCustomerUidInUser(userId: $userId, customerUid: $customerUid) {
      _id
    }
  }
`;

export const SEND_NOTIFICATION_TO_USER = gql`
  mutation sendNotificationToUser($userId: ID!, $title: String!, $body: String!) {
    sendNotificationToUser(userId: $userId, title: $title, body: $body) {
      title
      logtime
    }
  }
`;

export const SEND_NOTIFICATION_TO_USERS_IN_ORGANIZATION = gql`
  mutation sendNotificationToUsersInOrganization($organizationId: ID!, $title: String!, $body: String!) {
    sendNotificationToUsersInOrganization(organizationId: $organizationId, title: $title, body: $body)
  }
`;

export const CHANGE_SERVICETIER_OF_CONTRACT = gql`
  mutation changeServiceTierOfContract($contractId: ID!, $serviceTierId: ID!) {
    changeServiceTierOfContract(contractId: $contractId, serviceTierId: $serviceTierId) {
      _id
    }
  }
`;

export const CHANGE_FILTER_IN_ERV = gql`
  mutation changeFilterInErv($ervId: ID!) {
    changeFilterInErv(ervId: $ervId) {
      _id
      consumables {
        lastFilterChangedAt
        filterRemainPercent
        filterChangeHistory {
          logtime
        }
      }
    }
  }
`;

export const SET_USER_STEP = gql`
  mutation setUserStep($userId: ID!, $status: String!) {
    setUserStep(userId: $userId, status: $status) {
      _id
      status
    }
  }
`;

export const FACTORY_RESET_ERV_CTRL = gql`
  mutation factoryResetErvCtrl($ervCtrlId: ID!, $factoryReset: Boolean!) {
    factoryResetErvCtrl(ervCtrlId: $ervCtrlId, factoryReset: $factoryReset) {
      _id
      action
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation createOrder($userId: ID!, $data: OrderInput!) {
    createOrder(userId: $userId, data: $data) {
      _id
      customerUid
      visitRequest {
        visitId
      }
    }
  }
`;

export const PAY_ORDER = gql`
  mutation payOrder($orderId: ID!, $customerUid: String!) {
    payOrder(orderId: $orderId, customerUid: $customerUid) {
      _id
      customerUid
      visitRequest {
        visitId
        inviteeId
      }
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation cancelOrder($orderId: ID!) {
    cancelOrder(orderId: $orderId) {
      _id
      customerUid
      visitRequest {
        visitId
        inviteeId
      }
    }
  }
`;

export const UPDATE_VISIT_REQUEST = gql`
  mutation updateVisitRequest($visitRequestId: ID, $data: VisitRequestInput) {
    updateVisitRequest(visitRequestId: $visitRequestId, data: $data) {
      _id
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($accountId: String!, $oldPassword: String!, $newPassword: String!) {
    changePassword(accountId: $accountId, oldPassword: $oldPassword, newPassword: $newPassword) {
      _id
      id
    }
  }
`;

/////// 아직 사용안함 ///////////////

export const REMOVE_CONTRACT = gql`
  mutation removeContract($userId: ID, $contractId: ID) {
    removeContract(userId: $userId, contractId: $contractId) {
      _id
    }
  }
`;

export const UPDATE_CONTRACT = gql`
  mutation updateContract($contractId: ID, $data: ContractInput) {
    updateContract(contractId: $contractId, data: $data) {
      _id
    }
  }
`;

export const RESET_AND_INVITE_USER_ACCOUNT_FOR_USER = gql`
  mutation resetAndInviteUserAccountForUser($userId: ID) {
    resetAndInviteUserAccountForUser(userId: $userId) {
      _id
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation createProduct($data: ProductInput) {
    createProduct(data: $data) {
      _id
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($productId: ID, $data: ProductInput) {
    updateProduct(productId: $productId, data: $data) {
      _id
    }
  }
`;

export const CREATE_SERVICE_TIER = gql`
  mutation createServiceTier($data: ServiceTierInput) {
    createServiceTier(data: $data) {
      _id
    }
  }
`;

export const UPDATE_SERVICE_TIER = gql`
  mutation updateServiceTier($serviceTierId: ID, $data: ServiceTierInput) {
    updateServiceTier(serviceTierId: $serviceTierId, data: $data) {
      _id
    }
  }
`;

export const SET_DEFAULT_SYSTEM_GROUP = gql`
  mutation setDefaultSystemGroup($floorId: ID) {
    setDefaultSystemGroup(floorId: $floorId) {
      _id
    }
  }
`;

export const UPDATE_SYSTEM_GROUP = gql`
  mutation updateSystemGroup($systemGroupId: ID, $data: SystemGroupInput) {
    updateSystemGroup(systemGroupId: $systemGroupId, data: $data) {
      _id
    }
  }
`;

export const REMOVE_VISIT_REQUEST = gql`
  mutation removeVisitRequest($userId: ID, $visitRequestId: ID) {
    removeVisitRequest(userId: $userId, visitRequestId: $visitRequestId) {
      _id
    }
  }
`;

export const SET_FLOOR_STATUS = gql`
  mutation setFloorStatus($floorId: ID, $status: String) {
    setFloorStatus(floorId: $floorId, status: $status) {
      _id
      status
    }
  }
`;

export const FINISH_VISIT_REQUEST = gql`
  mutation finishVisitRequest($visitRequestId: ID) {
    finishVisitRequest(visitRequestId: $visitRequestId) {
      _id
      status
    }
  }
`;

export const USER_INQUIRY = gql`
  mutation userInquiry($data: UserInput!, $kaptCode: String!, $floorName: String!) {
    userInquiry(data: $data, kaptCode: $kaptCode, floorName: $floorName) {
      _id
      floors {
        _id
        name
      }
    }
  }
`;

export const CREATE_VISIT_REQUEST_FOR_FLOOR = gql`
  mutation createVisitRequestForFloor($floorId: ID!, $data: VisitRequestInput!) {
    createVisitRequestForFloor(floorId: $floorId, data: $data) {
      _id
    }
  }
`;

export const ADD_IMAGE = gql`
  mutation addImage($adminId: ID!, $file: Upload!) {
    addImage(adminId: $adminId, file: $file) {
      _id
      fileName
      mimeType
      encoding
      url
      status
      createdAt
      updatedAt
    }
  }
`;
