import React from "react";
import type {ReactNode} from "react"; 
import { DashboardChartProvider } from "./useDashboardChartContext";
import { Chart,SelectChartBtns,SelectDate, SelectSample, CurrentDate } from "./layouts";

type TProps = {
  children: ReactNode,
  graphData?: any,
  selectedSectionIDs? : any[],
  selectedErvIDs? : any[],
  isShowErvLog? : boolean
}

  function DashboardChart({ children, graphData, selectedSectionIDs, selectedErvIDs,isShowErvLog }: TProps) {

  return (
    <DashboardChartProvider value={{graphData, selectedSectionIDs, selectedErvIDs, isShowErvLog}} >
     {children}
    </DashboardChartProvider>
  )
}

DashboardChart.Chart = Chart; 
DashboardChart.SelectChartBtns = SelectChartBtns; 
DashboardChart.SelectDate = SelectDate;
DashboardChart.SelectSample = SelectSample;
DashboardChart.CurrentDate = CurrentDate;

export { DashboardChart };