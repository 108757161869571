import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { Table, Input, Loader, Segment, Header, Confirm, Grid } from "semantic-ui-react";
import OrgItem from "@Components/Orgs/OrgItem";
import Breadcrumbs from "@Components/Common/Breadcrumbs";
import { toastSuccess, toastError } from "@util";
import DefaultLayout from "@Layouts/DefaultLayout";
import { Helmet } from "react-helmet";
import { INIT_ORGANIZATIONS_PAGE } from "@graphql/query";
import { CREATE_ORGANIZATION, UPDATE_ORGANIZATION, REMOVE_ORGANIZATION } from "@graphql/mutation";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { IOrganization } from "@interfaces";

function Orgs() {
  // orgs 조회
  const { loading, data, refetch } = useQuery<{ organizations: IOrganization[] }, null>(INIT_ORGANIZATIONS_PAGE);

  // org 생성
  const [orgName, setOrgName] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [createOrg] = useMutation<{ createOrganization: IOrganization }, { data: { name: string } }>(CREATE_ORGANIZATION, {
    onCompleted(data) {
      setOrgName("");
      toastSuccess("organization이 생성되었습니다.", `org name : ${data.createOrganization.name}`);
      setOpenConfirm(false);
    },
    onError(error) {
      toastError(`organization 생성 실패`, error.message);
    },
    refetchQueries: [{ query: INIT_ORGANIZATIONS_PAGE }],
  });

  async function validateInput() {
    if (orgName.trim() === "") {
      toastError("유효하지 않는 입력값입니다.", "공백문자");
      return false;
    }
    await refetch();
    if (data && data.organizations.find((cur: IOrganization) => cur.name === orgName.trim() && cur.status === "active")) {
      toastError("유효하지 않는 입력값입니다.", "이미 등록된 org 이름");
      return false;
    }
    return true;
  }

  async function handleSubmit() {
    if (!(await validateInput())) return;
    await createOrg({ variables: { data: { name: orgName } } });
  }

  // org 수정
  const [updateOrg] = useMutation(UPDATE_ORGANIZATION, {
    onCompleted(data) {
      toastSuccess("organization이 수정되었습니다.", `org name : ${data.updateOrganization.name}`);
    },
    onError(error) {
      toastError(`organization 수정 실패`, error.message);
    },
    refetchQueries: [{ query: INIT_ORGANIZATIONS_PAGE }],
  });

  // org 삭제
  const [removeOrg] = useMutation(REMOVE_ORGANIZATION, {
    onCompleted(data) {
      toastSuccess("organization이 삭제되었습니다.", `org name : ${data.removeOrganization.name}`);
    },
    onError(error) {
      toastError(`organization 삭제 실패`, error.message);
    },
    refetchQueries: [{ query: INIT_ORGANIZATIONS_PAGE }],
  });

  const handleRemove = useCallback(async (orgId) => {
    await removeOrg({ variables: { organizationId: orgId } });
  }, []);

  if (loading)
    return (
      <DefaultLayout>
        <Loader active />
      </DefaultLayout>
    );

  if (!data) return <DefaultLayout></DefaultLayout>;

  return (
    <DefaultLayout>
      <Helmet>
        <title>organizations</title>
      </Helmet>
      <Grid>
        <Grid.Column mobile={16} computer={16}>
          <Segment>
            <Header as="h2">
              organizations
              <Header.Subheader>
                <Breadcrumbs />
              </Header.Subheader>
            </Header>
            <Table celled padded>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>name</Table.HeaderCell>
                  <Table.HeaderCell>admins</Table.HeaderCell>
                  <Table.HeaderCell>managers</Table.HeaderCell>
                  <Table.HeaderCell>users</Table.HeaderCell>
                  <Table.HeaderCell>locations</Table.HeaderCell>
                  <Table.HeaderCell>createdAt</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.organizations
                  .filter((cur: IOrganization) => cur.status === "active")
                  .sort((a: IOrganization, b: IOrganization) => {
                    const bUserNum = b?.users?.length || 0;
                    const aUserNum = a?.users?.length || 0;
                    return bUserNum - aUserNum;
                  })
                  .map((orgData: IOrganization) => (
                    <OrgItem key={orgData._id} org={orgData} updateOrg={updateOrg} handleRemove={handleRemove} />
                  ))}
              </Table.Body>
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell colSpan="7">
                    <CreateOrg>
                      <Input
                        action={{
                          content: "등록",
                          onClick: () => orgName && setOpenConfirm(true),
                        }}
                        value={orgName}
                        onChange={(e) => setOrgName(e.target.value)}
                        required
                      />
                    </CreateOrg>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
            <Confirm
              open={openConfirm}
              header={`신규 Organization을 등록 하시겠습니까? `}
              content={`등록될 Organization name : ${orgName}`}
              cancelButton="취소"
              confirmButton="등록"
              onCancel={() => setOpenConfirm(false)}
              onConfirm={handleSubmit}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </DefaultLayout>
  );
}

export default Orgs;

const CreateOrg = styled.div`
  display: flex;
  justify-content: flex-end;
`;
