import React, { useState } from "react";
import moment from "moment";
import { Table, Button, Confirm } from "semantic-ui-react";
import styled from "styled-components";
import { IOrder, IItem } from "@interfaces";

function OrderItem({ order, cancelOrder }: { order: IOrder; cancelOrder: Function }) {
  const [confirmOpen, setConfirmOpen] = useState(false);

  return (
    <>
      <StyledTableRow status={order.status}>
        <Table.Cell>{getItemName(order.items)}</Table.Cell>
        <Table.Cell>{order.amount}</Table.Cell>
        <Table.Cell>{order.status}</Table.Cell>
        <Table.Cell>
          {moment(order.billings?.find((paidAt) => paidAt)?.paidAt).format("YYYY-MM-DD a h:mm")}
          {order.status === "cancelled" && (
            <SmallText> (결제 취소: {moment(order.billings?.find((paidAt) => paidAt)?.updatedAt).format("YYYY-MM-DD a h:mm")})</SmallText>
          )}
        </Table.Cell>
        <Table.Cell>
          {order.status === "paid" && (
            <Button size="mini" compact style={{ marginLeft: 10 }} onClick={() => setConfirmOpen(true)}>
              결제 취소
            </Button>
          )}
        </Table.Cell>
      </StyledTableRow>
      <Confirm
        open={confirmOpen}
        header={`결제 취소`}
        content={`해당 결제건을 취소하시겠습니까?`}
        cancelButton="닫기"
        confirmButton={{
          content: "결제 취소",
          negative: true,
        }}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => {
          cancelOrder({ variables: { orderId: order._id } });
          setConfirmOpen(false);
        }}
      />
    </>
  );
}

export default OrderItem;

const getItemName = (items: IItem[]) => {
  let itemNames = "";
  items.map((item) => {
    if (itemNames) itemNames += ", ";
    itemNames += item.product.name;
  });
  return itemNames;
};

const StyledTableRow = styled(Table.Row)`
  ${(props) => props.status === "cancelled" && "color: #999;"}
`;

const SmallText = styled.span`
  font-size: 10px;
`;
