import React from "react";
import type {ReactNode} from "react"; 

const DashboardChartContext = React.createContext(undefined);
type TProps = {
  children: ReactNode,
  value?: any
}

function DashboardChartProvider({ children, value }: TProps) {
  return (
    <DashboardChartContext.Provider value={value}>{children}</DashboardChartContext.Provider>
  );
}

function useDashboardChartContext() {
  const context = React.useContext(DashboardChartContext);
  if (context === undefined) {
    throw new Error("DashboardChartContext must be used within a DashboardChartProvider");
  }
  return context;
}

export { DashboardChartProvider, useDashboardChartContext };
