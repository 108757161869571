import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Button, Header, Icon, Segment, Grid, Loader } from "semantic-ui-react";
import Breadcrumbs from "@Components/Common/Breadcrumbs";
import VisitEventList from "@Components/EventDashBoard/VisitEventList";
import DeliveryEventList from "@Components/EventDashBoard/DeliveryEventList";
import QualityControlEventList from "@Components/EventDashBoard/QualityControlEventList";
import ErvCtrlEventList from "@Components/EventDashBoard/ErvCtrlEventList";
import AwairEventList from "@Components/EventDashBoard/AwairEventList";
import DefaultLayout from "@Layouts/DefaultLayout";
import { ToolBtns } from "@styles/common";
import ErvsFilterEventList from "@Components/EventDashBoard/ErvsFilterEventList";
import { Helmet } from "react-helmet";
import { INIT_EVENT_DASHBOARD_PAGE } from "@graphql/query";
import { useQuery } from "@apollo/react-hooks";

function EventDashBoard() {
  const { orgId } = useParams();

  const [awairEvents, setAwairEvents] = useState([]);
  const [ervCtrlEvents, setErvCtrlEvents] = useState([]);
  const [ervFilterEvents, setErvFilterEvents] = useState([]);

  const [visitEvents, setVisitEvents] = useState([]);
  const [qualityControlEvents, setQualityControlEvents] = useState([]);
  const [deliveryEvents, setDeliveryEvents] = useState([]);

  const [managerOptions, setManagerOptions] = useState([]);

  const [locationItems, setLocationItems] = useState([]);
  const [managerItems, setManagerItems] = useState([]);

  const history = useHistory();

  const { loading, data } = useQuery(INIT_EVENT_DASHBOARD_PAGE, {
    variables: { organizationId: orgId },
    onError(error) {
      console.error(error);
      history.push(`/error/404`);
    },
  });

  useEffect(() => {
    if (!data) return;
    try {
      // location option (value로 name을 지정)
      const locationOptions = data.organization.locations.map((cur) => ({
        key: cur.kaptInfo?.kaptCode,
        // value: cur.kaptInfo?.kaptName,
        // value: cur._id,
        value: cur.kaptInfo?.kaptCode,
        text: cur.kaptInfo?.kaptName,
      }));
      setLocationItems([{ key: "11111", value: "", text: "지정 안함" }, ...locationOptions]);

      // manager option
      const managerOptions = data.organization.managers.map((cur) => ({
        key: cur._id,
        value: cur._id,
        text: cur.name,
      }));
      setManagerItems([{ key: "11111", value: "", text: "지정 안함" }, ...managerOptions]);

      // 이벤트 목록 생성
      const awairEvents = [];
      const ervCtrlEvents = [];
      const ervFilterEvents = [];
      const visitEvents = [];
      const qualityControlEvents = [];
      const deliveryEvents = [];

      data.organization.users.map((user) => {
        // if (!user.floors) return;
        user.floors.map((floor) => {
          if (!floor) return;
          // if (!floor.sections) return;
          floor.sections.map((section) => {
            // if (!section.awairs) return;
            section.awairs.map((awair) => {
              // if (!awair.events) return;
              awair.events.map((event) => {
                if (event.isActive) {
                  awairEvents.push({
                    userId: user._id,
                    userName: user.name,
                    floorName: floor.name,
                    sectionName: section.name,
                    awairName: awair.name,
                    type: event.type,
                    startAt: event.startAt,
                  });
                }
              });
            });
          });
          floor.ervs.map((erv) => {
            erv.ervCtrl &&
              erv.ervCtrl.events.map((event) => {
                if (event.isActive) {
                  ervCtrlEvents.push({
                    userId: user._id,
                    userName: user.name,
                    floorName: floor.name,
                    ervName: erv.name,
                    ervCtrlName: erv.ervCtrl.name,
                    type: event.type,
                    startAt: event.startAt,
                  });
                }
              });

            if (erv.consumables && erv.consumables.filterRemainPercent && erv.consumables.filterRemainPercent < 50) {
              // if (erv.consumables && erv.consumables.filterRemainPercent) {
              ervFilterEvents.push({
                userId: user._id,
                userName: user.name,
                floorName: floor.name,
                ervName: erv.name,
                filterRemainPercent: erv.consumables.filterRemainPercent,
                filterRemainTimeInHours: erv.consumables.filterRemainTimeInHours,
                lastFilterChangedAt: erv.consumables.lastFilterChangedAt,
              });
            }
          });
          floor.visitRequests.map((visitRequest) => {
            if ((visitRequest.type !== "filter" && visitRequest.status === "ready") || visitRequest.status === "cancelled") return;

            // 품질관리 목록 추가
            if (
              visitRequest.type === "A/S" ||
              visitRequest.type === "sensorReplace" ||
              visitRequest.type === "ervCtrlReplace" ||
              visitRequest.type === "customerInterview"
            ) {
              qualityControlEvents.push({
                visitRequestId: visitRequest._id,
                type: visitRequest.type,
                userName: user.name,
                userId: user._id,
                name: visitRequest.name,
                floorName: floor.name,
                managerId: visitRequest.manager?._id,
                visitStatus: visitRequest.status,
                inviteeStartTime: visitRequest.inviteeStartTime,
                inviteeCanceledAt: visitRequest.inviteeCanceledAt,
                createdAt: visitRequest.createdAt,
              });
            }

            // 필터 주문 목록 추가
            if (visitRequest.type === "filter") {
              visitEvents.push({
                userId: user._id,
                userName: user.name,
                userEmail: user.email,
                userPhone: user.phone,
                itemNames: "필터 주문",
                createdAt: visitRequest.createdAt,
                managerId: visitRequest.manager?._id,
                locationsName: getLocationsStr(user.floors),
                visitRequestId: visitRequest._id,
                visitStatus: visitRequest.status,
                visitId: visitRequest.visitId,
                isPaid: visitRequest.isPaid,
                inviteeStartTime: visitRequest.inviteeStartTime,
              });
            }
          });
          floor.deliveries.map((delivery) => {
            const [dong, ho] = delivery.dongho.split("&");
            deliveryEvents.push({
              deliveryId: delivery._id,
              userName: user.name,
              userId: user._id,
              name: delivery.name,
              floorName: floor.name,
              address: delivery.address,
              type: delivery.type,
              status: delivery.status,
              kaptName: delivery.kaptName,
              phone: delivery.phone,
              dong: dong,
              ho: ho,
              managerId: delivery.manager?._id,
              createdAt: delivery.createdAt,
            });
          });
        });
        user.orders.map((order) => {
          if (!order) return;
          const paidBilling = order.billings.find((billing) => billing.status === "paid");
          if (!paidBilling) return; // 유효한 결제건이 있는 경우에만 진행
          // if (!order.visitRequest?.status || order.visitRequest?.status === "finished") return;
          if (!order.visitRequest?.status) return;

          let itemNames = "";
          order.items.map((item) => {
            if (itemNames) itemNames += ", ";
            itemNames += item.product.name;
          });

          // 주문목록
          visitEvents.push({
            userId: user._id,
            userName: user.name,
            userEmail: user.email,
            userPhone: user.phone,
            itemNames: itemNames,
            createdAt: order.createdAt,
            managerId: order.visitRequest?.manager?._id,
            locationsName: getLocationsStr(user.floors),
            visitRequestId: order.visitRequest?._id,
            visitStatus: order.visitRequest?.status,
            visitId: order.visitRequest?.visitId,
            isPaid: order.visitRequest?.isPaid,
            inviteeStartTime: order.visitRequest?.inviteeStartTime,
          });
        });
      });

      // 매니저 선택 옵션
      const tempManagersOpt = [{ key: 0, value: "NONE", text: "미지정" }];
      data.organization.managers
        .filter((manager) => manager)
        .map((manager) => {
          tempManagersOpt.push({
            key: manager._id,
            value: manager._id,
            text: manager.name,
          });
        });

      setManagerOptions(tempManagersOpt);

      setAwairEvents(awairEvents);
      setErvCtrlEvents(ervCtrlEvents);
      setErvFilterEvents(ervFilterEvents);
      setVisitEvents(visitEvents.sort((a, b) => new Date(a.inviteeStartTime).getTime() - new Date(b.inviteeStartTime).getTime()));
      setQualityControlEvents(qualityControlEvents);
      setDeliveryEvents(deliveryEvents);
    } catch (e) {
      console.error(e);
    }
  }, [data]);

  const getLocationsStr = (floors) => {
    const floorNamesArr = floors
      .filter((floor) => floor)
      .map((floor) => {
        return `${floor?.location?.kaptInfo?.kaptName} ${floor?.name}`;
      });
    if (!floorNamesArr.length) return "미지정";
    return floorNamesArr.join();
  };

  if (loading)
    return (
      <DefaultLayout>
        <Loader active />
      </DefaultLayout>
    );

  return (
    <DefaultLayout>
      <Helmet>
        <title>{data.organization.name}(Event DashBoard)</title>
      </Helmet>

      <Segment>
        <Header as="h2">
          {data.organization.name}
          <Header.Subheader>
            <Breadcrumbs />
          </Header.Subheader>
        </Header>
        <p>
          {" "}
          <Icon name="calendar" />
          {moment(data.organization.createdAt).format("YYYY-MM-DD, h:mm a")}{" "}
        </p>
        <ToolBtns>
          <Link to={`/orgs/${orgId}/setting`}>
            <Button size="mini">
              <Icon name="cog" />
              Setting
            </Button>
          </Link>
        </ToolBtns>
      </Segment>

      {/* <Grid> */}
      {/* <Grid.Column mobile={16} computer={8}> */}
      <VisitEventList visitEvents={visitEvents} orgId={orgId} managerOptions={managerOptions} locationItems={locationItems} managerItems={managerItems} />
      {/* </Grid.Column> */}
      {/* <Grid.Column mobile={16} computer={8}> */}
      <DeliveryEventList deliveryEvents={deliveryEvents} orgId={orgId} managerOptions={managerOptions} managerItems={managerItems} />

      <QualityControlEventList qualityControlEvents={qualityControlEvents} orgId={orgId} managerOptions={managerOptions} />
      {/* </Grid.Column> */}
      {/* </Grid> */}

      <AwairEventList awairEvents={awairEvents} orgId={orgId} />
      <ErvCtrlEventList ervCtrlEvents={ervCtrlEvents} orgId={orgId} />
      <ErvsFilterEventList ervFilterEvents={ervFilterEvents} orgId={orgId} />
    </DefaultLayout>
  );
}

export default EventDashBoard;
