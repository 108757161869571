import React from "react";
import { Checkbox } from "semantic-ui-react";
import styled from "styled-components";

type TProps = {
  checked: boolean,
  // isActiveAwair: boolean
}

function CheckBox({checked}: TProps) {
  return(
    <StyledCheckbox checked={checked} disabled={false}/>
  )
}
export { CheckBox };

const StyledCheckbox = styled(Checkbox)`
  position: absolute !important;
  top: 4px;
  right: 4px;
  color: rgba(0, 0, 0, 0.68);
`;
