import React from "react";
import { Item, Segment, Icon, Header, Button, Grid } from "semantic-ui-react";
import { toastSuccess, toastError } from "@util";
import { HvacItem } from "@Components/HvacItem";
import { INIT_USER_PAGE } from "@graphql/query";
import { CREATE_ERV } from "@graphql/mutation";
import { useMutation } from "@apollo/react-hooks";
import { IFloor, IErv, IUser } from "@interfaces";
import { useModal } from "@Hooks/useModal";
import { HvacForm } from "@Components/Modals";
import { EmptyMessage } from "@styles/common";

function HvacsSegment({ curFloor, user }: { curFloor: IFloor; user: IUser }) {
  const { ModalPortal: HvacModalPortal, openModal: openHvacModal, closeModal: closeHvacModal } = useModal();

  // erv 추가
  const [createErv] = useMutation(CREATE_ERV, {
    onCompleted(data) {
      toastSuccess("HVAC 생성", `name : ${data.createErv.name}`);
    },
    onError(error) {
      console.error("error", error);
      toastError("HVAC 생성 실패.", error.message);
    },
    refetchQueries: [{ query: INIT_USER_PAGE, variables: { userId: user._id } }],
  });

  // erv 모달 open
  const onClickErvCreate = () => {
    if (!curFloor || !curFloor.location) {
      toastError("Service Floor를 먼저 선택해주세요");
      return;
    }
    openHvacModal();
  };
  // erv 생성 제출
  const ervCreateSubmit = (data: any) => {
    createErv({ variables: { floorId: curFloor._id, data: data } });
    closeHvacModal();
  };

  return (
    <>
      <Segment>
        <Header>
          HVACs
          <Button size="mini" onClick={onClickErvCreate} style={{ marginLeft: 10 }}>
            <Icon name="plus" />
            new
          </Button>
        </Header>
        {!curFloor?.ervs?.length ? (
          <EmptyMessage>Empty</EmptyMessage>
        ) : (
          <Item.Group divided>
            {curFloor?.ervs?.map((hvac: IErv) => (
              <HvacItem key={hvac._id} ervCtrl={hvac.ervCtrl} hvac={hvac} user={user} floor={curFloor}>
                <Grid>
                  <Grid.Column mobile={16} computer={7}>
                    <HvacItem.Label>{hvac.name}</HvacItem.Label>
                    <HvacItem.ErvCtrl />
                  </Grid.Column>
                  <Grid.Column mobile={16} computer={9}>
                    <HvacItem.Description />
                    <HvacItem.Option />
                  </Grid.Column>
                </Grid>
                <HvacItem.Detail />
              </HvacItem>
            ))}
          </Item.Group>
        )}
      </Segment>
      <HvacModalPortal title="ERV">
        <HvacForm userName={user?.name} onSubmit={ervCreateSubmit} />
      </HvacModalPortal>
    </>
  );
}

export default HvacsSegment;
