import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const PinPointMap = ({ coordinates, name }: { coordinates: any; name: any }) => {
  const mapStyles = {
    height: "300px",
    width: "100%",
  };
  return (
    // <LoadScript google={window.google} googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}>
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}>
      <GoogleMap mapContainerStyle={mapStyles} zoom={15} center={{ lat: coordinates.latitude, lng: coordinates.longitude }}>
        <Marker key={name} position={{ lat: coordinates.latitude, lng: coordinates.longitude }} />
      </GoogleMap>
    </LoadScript>
  );
};
export default PinPointMap;
