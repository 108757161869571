// import ApolloClient from "apollo-client";
// import { InMemoryCache } from "apollo-cache-inmemory";
// import { createHttpLink } from "apollo-link-http";
// import { GRAPHQL_ENDPOINT } from "./constants";

// const client = new ApolloClient({
//   link: createHttpLink({ uri: GRAPHQL_ENDPOINT }),
//   cache: new InMemoryCache(),
// });

// export default client;

import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GRAPHQL_ENDPOINT } from "@constants";
import { createUploadLink } from "apollo-upload-client";

const uploadLink = createUploadLink({
  uri: GRAPHQL_ENDPOINT,
});

// const httpLink = createHttpLink({
//   uri: GRAPHQL_ENDPOINT,
// });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("currentUser");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache(),
});

export default client;
