import React from "react";
import { Form, Input } from "semantic-ui-react";

type TFilterForm = {
  header: any;
  filterX: any;
  filterY: any;
  filterZ: any;
  onChangeFilterX: any;
  onChangeFilterY: any;
  onChangeFilterZ: any;
  required: any;
};

function FilterForm({ header, filterX, filterY, filterZ, onChangeFilterX, onChangeFilterY, onChangeFilterZ, required }: TFilterForm) {
  return (
    <>
      <label>{header}</label>
      <Form.Group>
        <Form.Field>
          <Input
            style={{ width: 100 }}
            type="text"
            value={filterX}
            onChange={onChangeFilterX}
            placeholder={`length${required ? " (required)" : ""}`}
            required={!!required}
          />
        </Form.Field>
        <Form.Field>
          <Input
            style={{ width: 100 }}
            type="text"
            value={filterY}
            onChange={onChangeFilterY}
            placeholder={`width${required ? " (required)" : ""}`}
            required={!!required}
          />
        </Form.Field>
        <Form.Field>
          <Input
            style={{ width: 100 }}
            type="text"
            value={filterZ}
            onChange={onChangeFilterZ}
            placeholder={`thickness${required ? " (required)" : ""}`}
            required={!!required}
          />
        </Form.Field>
      </Form.Group>
    </>
  );
}

export default FilterForm;
