import React, { useState } from "react";
import { Button, Form, Modal, Dropdown } from "semantic-ui-react";
import { INIT_AWAIR_SELECTOR } from "@graphql/query";
import { useQuery } from "@apollo/react-hooks";

const stepOpt = [
  { key: "active", value: "active", text: "active" },
  { key: "new", value: "new", text: "new" },
  { key: "consulting", value: "consulting", text: "consulting" },
  { key: "filter", value: "filter", text: "filter" },
  { key: "paid", value: "paid", text: "paid" },
  { key: "outlier", value: "outlier", text: "outlier" },
  { key: "sponsor", value: "sponsor", text: "sponsor" },
];

type TProps = {
  userId: String;
  status: "active" | "new" | "consulting" | "filter" | "paid" | "outlier" | "sponsor";
  onSubmit: any;
};

export const UserStatusForm =({ onSubmit, userId, status : prevStatus}: TProps) => {
  const [status, setStatus] = useState(prevStatus);
  const { data } = useQuery(INIT_AWAIR_SELECTOR);

  return (
    <Modal.Content>
      {data && (
        <Form onSubmit={() => onSubmit({ userId, status })}>
          <Form.Field>
            <label>status 수동 지정</label>
            <Dropdown
              selection
              options={stepOpt}
              value={status}
              onChange={(e, data: any) => {
                setStatus(data.value);
              }}
            />
          </Form.Field>

          <Button type="submit" fluid>
            Submit
          </Button>
        </Form>
      )}
    </Modal.Content>
  );
}

