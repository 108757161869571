import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Header, Segment, Table } from "semantic-ui-react";
import { EmptyMessage } from "../../styles/common";

type TAwairEvent = {
  userId: string;
  userName: string;
  floorName: string;
  sectionName: string;
  awairName: string;
  type: string;
  startAt: Date;
};
type TAwairEventListProps = {
  awairEvents: TAwairEvent[];
  orgId: string;
};
type TAwairEventItemProps = {
  awairEvent: TAwairEvent;
  orgId: string;
};

function AwairEventList({ awairEvents, orgId }: TAwairEventListProps) {
  return (
    <Segment>
      <Header as="h3">Awair Event</Header>
      <Table compact="very" celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>type</Table.HeaderCell>
            <Table.HeaderCell>user</Table.HeaderCell>
            <Table.HeaderCell>floor</Table.HeaderCell>
            <Table.HeaderCell>section</Table.HeaderCell>
            <Table.HeaderCell>awair</Table.HeaderCell>
            <Table.HeaderCell>startAt</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {awairEvents && awairEvents.length > 0 ? (
            awairEvents.map((awairEvent, index) => <AwairEventItem key={index} awairEvent={awairEvent} orgId={orgId} />)
          ) : (
            <Table.Row>
              <Table.Cell colSpan="6">
                <EmptyMessage>Empty</EmptyMessage>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Segment>
  );
}

export default AwairEventList;

function AwairEventItem({ awairEvent, orgId }: TAwairEventItemProps) {
  return (
    <Table.Row>
      <Table.Cell>{awairEvent.type}</Table.Cell>
      <Table.Cell>
        <Link to={`/orgs/${orgId}/users/${awairEvent.userId}`}>{awairEvent.userName}</Link>
      </Table.Cell>
      <Table.Cell>{awairEvent.floorName}</Table.Cell>
      <Table.Cell>{awairEvent.sectionName}</Table.Cell>
      <Table.Cell>{awairEvent.awairName}</Table.Cell>
      <Table.Cell>{moment(awairEvent.startAt).format("YYYY-MM-DD, h:mm a")}</Table.Cell>
    </Table.Row>
  );
}
