import React, { useState, useCallback } from "react";
import DefaultLayout from "@Layouts/DefaultLayout";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";
import { Loader, Segment, Header, Form, Button, Divider, Grid, Radio, Icon, Checkbox, Modal, TextArea } from "semantic-ui-react";
import { toastSuccess, toastError } from "@util";
import { TierOptionSegment, ModalCloseButton } from "@styles/common";
import { policy1, policy2 } from "@Policy";
import { Helmet } from "react-helmet";
import { INIT_PAY_ORDER_PAGE } from "@graphql/query";
import { CREATE_ORDER, PAY_ORDER, REMOVE_CUSTOMER_UID_IN_USER, ADD_CARD_INFO_IN_USER } from "@graphql/mutation";

import { useQuery, useMutation } from "@apollo/react-hooks";
import Breadcrumbs from "@Components/Common/Breadcrumbs";
import AddCardInfoForm from "@Components/Common/Payment/AddCardInfoForm";
import CardInfoItem from "@Components/Common/Payment/CardInfoItem";
import { IProduct } from "@interfaces";

function PayOrder() {
  const { userId, orgId }: { userId: string; orgId: string } = useParams();
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedCustomerUid, setSelectedCustomerUid] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isShowPolicy1, setIsShowPolicy1] = useState(false);
  const [isShowPolicy2, setIsShowPolicy2] = useState(false);
  const [isShowAddCardInfoForm, setIsShowAddCardInfoForm] = useState(false);

  const history = useHistory();

  const { loading, data } = useQuery(INIT_PAY_ORDER_PAGE, {
    variables: { userId: userId },
    onError(error) {
      console.error(error);
    },
  });

  // 유효성 체크
  const isValidation = useCallback(() => {
    if (!selectedCustomerUid) {
      toastError("입력 오류", "결제 카드를 선택해 주세요");
      return false;
    }
    if (!selectedProductId) {
      toastError("입력 오류", "주문 상품을 선택해 주세요");
      return false;
    }
    if (!isChecked) {
      toastError("입력 오류", "약관에 동의해 주세요");
      return false;
    }
    return true;
  }, [isChecked, selectedProductId, selectedCustomerUid]);

  // 결제 수행
  const [payOrder] = useMutation(PAY_ORDER, {
    onCompleted(data) {
      toastSuccess("결제 되었습니다.");
      history.push(`/orgs/${orgId}/users/${userId}/contracts`);
    },
    onError(error) {
      console.error("error", error);
    },
  });

  // order 생성
  const [createOrder, { data: dataCreateOrder }] = useMutation(CREATE_ORDER, {
    onCompleted(data) {
      payOrder({ variables: { orderId: data.createOrder._id, customerUid: selectedCustomerUid } });
    },
    onError(error) {
      console.error("error", error);
    },
  });

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (!isValidation()) return;
    setIsLoading(true);

    const submitData = {
      items: [
        {
          product: selectedProductId,
          qty: 1,
        },
      ],
    };

    createOrder({ variables: { userId: userId, data: submitData } });
  };

  // 결제 카드 추가
  const [addCardInfoInUser] = useMutation(ADD_CARD_INFO_IN_USER, {
    onCompleted(data) {
      toastSuccess("결제 카드 정보가 추가되었습니다");
      setIsShowAddCardInfoForm(false);
    },
    onError(error) {
      toastError("결제 카드 정보 추가를 실패하였습니다", error.message);
      console.error(error);
    },
    refetchQueries: [{ query: INIT_PAY_ORDER_PAGE, variables: { userId: userId } }],
  });

  // 결제 카드 삭제
  const [removeCustomerUidInUser] = useMutation(REMOVE_CUSTOMER_UID_IN_USER, {
    onCompleted(data) {
      setSelectedCustomerUid("");
      toastSuccess("결제 카드 정보가 삭제되었습니다");
    },
    onError(error) {
      toastError("결제 카드 정보 삭제가 실패하였습니다", error.message);
      console.error("error", error);
    },
    refetchQueries: [{ query: INIT_PAY_ORDER_PAGE, variables: { userId: userId } }],
  });

  if (loading)
    return (
      <DefaultLayout>
        <Loader active />
      </DefaultLayout>
    );

  return (
    <DefaultLayout>
      <Helmet>
        <title>{data.user.name}(개별 결제)</title>
      </Helmet>

      <Segment>
        <Header as="h2">
          개별 결제
          <span style={{ marginLeft: "2rem" }}>
            <Breadcrumbs />
          </span>
        </Header>
        <Divider />
        <Header as="h3">{data.user.name}</Header>
        <Icon name="mail" />
        {data.user.email}
        <br />
        <Icon name="phone" />
        {data.user.phone}
        <br />
        <Icon name="calendar" />
        {moment(data.user.createdAt).format("YYYY-MM-DD, h:mm a")}
      </Segment>

      <Segment>
        <Header as="h3">결제 카드 선택</Header>
        <div>
          {data.getCustomerUidsInUser.map((customerUid: string) => (
            <CardInfoItem
              key={customerUid}
              customerUid={customerUid}
              selectedCustomerUid={selectedCustomerUid}
              setSelectedCustomerUid={setSelectedCustomerUid}
              removeCustomerUidInUser={removeCustomerUidInUser}
              userId={userId}
            />
          ))}

          {selectedCustomerUid ? <p>선택된 카드번호의 뒷 네자리는 {selectedCustomerUid.split("_")[1]} 입니다.</p> : <p>선택된 결제 카드가 없습니다.</p>}
        </div>
        <AddCardCheckbox
          label="결제 카드 정보를 추가합니다."
          checked={isShowAddCardInfoForm}
          onClick={() => setIsShowAddCardInfoForm(!isShowAddCardInfoForm)}
        />
        {isShowAddCardInfoForm && <AddCardInfoForm userId={userId} addCardInfoInUser={addCardInfoInUser} />}
      </Segment>

      <Segment>
        <Header as="h3" style={{ marginBottom: 40 }}>
          결제 선택{" "}
        </Header>
        <Grid>
          {data.products
            .filter((product: IProduct) => product && product.shop === "CLAIDOR" && product.status === "active")
            .map((product: IProduct, idx: number) => (
              <Grid.Column key={product._id} mobile={8} tablet={8} computer={4}>
                <TierOptionSegment onClick={() => setSelectedProductId(product._id)}>
                  <Radio label={product.name} name={product.name} value={product._id} checked={selectedProductId === product._id} />
                  <div>
                    <p className="price">{product.price.toLocaleString()}원</p>
                  </div>
                </TierOptionSegment>
              </Grid.Column>
            ))}
        </Grid>
      </Segment>

      <Segment>
        <CheckboxGroup>
          <Checkbox
            // label={`이용 약관 및 개인정보 처리방침에 동의합니다.`}
            onClick={(e, { checked }) => setIsChecked(!!checked)}
            checked={isChecked}
          />

          <span>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsShowPolicy1(true);
              }}
            >
              전자금융거래 기본약관
            </a>{" "}
            ,{" "}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsShowPolicy2(true);
              }}
            >
              개인정보 처리방침
            </a>
            에 동의합니다.
          </span>
        </CheckboxGroup>
        <Button onClick={onSubmit} fluid disabled={isLoading ? true : false} style={{ marginTop: 20 }}>
          결제하기
        </Button>
      </Segment>

      <Modal open={isShowPolicy1} onClose={() => setIsShowPolicy1(false)}>
        <ModalCloseButton onClick={() => setIsShowPolicy1(false)}>
          <Icon name="close" size="large" />
        </ModalCloseButton>

        <Modal.Header>전자금융거래 기본약관</Modal.Header>
        <Modal.Content>
          <Form>
            <StyledTextArea readOnly defaultValue={policy1} />
          </Form>
        </Modal.Content>
      </Modal>

      <Modal open={isShowPolicy2} onClose={() => setIsShowPolicy2(false)}>
        <ModalCloseButton onClick={() => setIsShowPolicy2(false)}>
          <Icon name="close" size="large" />
        </ModalCloseButton>

        <Modal.Header>개인정보 처리방침</Modal.Header>
        <Modal.Content>
          <Form>
            <StyledTextArea readOnly defaultValue={policy2} />
          </Form>
        </Modal.Content>
      </Modal>
    </DefaultLayout>
  );
}

export default PayOrder;

const CheckboxGroup = styled.div`
  label:before {
    transform: scale(1.5);
  }

  span {
    margin-left: 1rem;
  }
`;

const StyledTextArea = styled(TextArea)`
  min-height: 300px;
`;

const AddCardCheckbox = styled(Checkbox)`
  margin: 30px 0;
`;
