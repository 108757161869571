import React from "react";
import { Dropdown } from "semantic-ui-react";
import { INIT_LOCATION_SELECTOR } from "@graphql/query";
import { useQuery } from "@apollo/react-hooks";
import { ILocation } from "@interfaces";

interface LocationSelectorProps {
  location: ILocation;
  setLocation: Function;
  allowAdditions: any;
}

function LocationSelector({ location, setLocation, allowAdditions }: LocationSelectorProps) {
  const { loading, data } = useQuery(INIT_LOCATION_SELECTOR, {
    onError: (error) => {
      console.error(error);
    },
  });

  return (
    <Dropdown
      placeholder="Select Location"
      search
      selection
      style={{ minWidth: "100%" }}
      // allowAdditions={allowAdditions}
      // defaultValue={!loading && location && data.locations.find((loc: ILocation) => loc._id === location._id)._id}
      defaultValue={location && location._id}
      options={
        !loading ?
        data.locations.map((location: ILocation, idx: number) => new Object({ key: location._id, text: location.kaptInfo?.kaptName, value: location._id }))
        : []
      }
      onChange={(e, onChangeData: any) => {
        setLocation(data.locations.find((location: ILocation) => location._id === onChangeData.value));
      }}
      // onAddItem={(e, data) => {
      //   setLocation({ kaptInfo: { kaptName: data.value } });
      //   setLocations([...locations, { kaptInfo: { kaptName: data.value } }]);
      // }}
    />
  );
}
export default LocationSelector;
