import gql from "graphql-tag";

export const INIT_ORGANIZATIONS_PAGE = gql`
  query organizations {
    organizations {
      _id
      name
      status
      users {
        _id
        status
      }
      managers {
        _id
        status
      }
      admins {
        _id
        status
      }
      createdAt
      locations {
        _id
        kaptInfo
      }
    }
  }
`;

export const INIT_ORGANIZATION_PAGE = gql`
  query organization($organizationId: ID) {
    organization(organizationId: $organizationId) {
      _id
      name
      status
      admins {
        _id
        id
        name
        phone
        email
        status
        createdAt
      }
      managers {
        _id
        id
        name
        phone
        email
        status
        createdAt
        gCalendarId
      }
      users {
        _id
        name
        phone
        email
        profile
        status
        mailList
        createdAt
        floors {
          location {
            _id
            kaptInfo
          }
        }
        orders {
          _id
          visitRequest {
            type
            inviteeStartTime
          }
        }
      }
      locations {
        _id
        kaptInfo
      }
    }
  }
`;

export const INIT_USER_PAGE = gql`
  query initUserPage($userId: ID!) {
    user(userId: $userId) {
      _id
      name
      phone
      email
      profile
      status
      mailList
      createdAt
      statusUpdatedAt
      floors {
        _id
        createdAt
        updatedAt
        name
        status
        profile
        statusUpdatedAt
        aiPerformance {
          pmScore
          aqiScore
          energyScore
        }
        sections {
          _id
          name
          status
          profile
          requirement
          awairs {
            _id
            name
          }
        }
        # imageUrl
        location {
          _id
          # kaptCode
          kaptInfo
          available
          coordinates {
            latitude
            longitude
          }
          zipcode
          ervType {
            _id
            name
          }
        }
        wifis {
          ssid
          password
        }
        ervs {
          _id
          name
          status
          board
          limitation
          consumables {
            lastFilterChangedAt
            filterRemainPercent
            filterChangeHistory {
              logtime
            }
          }
          ervCtrl {
            _id
            id
            name
            status
            action
            autoControlOn
            controlSettings
          }
          ervType {
            _id
            name
          }
        }
      }
    }
  }
`;

export const INIT_LOCATION_SELECTOR = gql`
  query locations {
    locations {
      _id
      kaptCode
      kaptInfo
      available
      zipcode
      ceilingHeight
      comment
      board
      storeUrl
      filterPrice
      filterChangePrice
      notionLink
      profile
    }
  }
`;

export const INIT_ERV_TYPES_SELECTOR = gql`
  query ervTypes {
    ervTypes {
      _id
      name
    }
  }
`;

const FRAGMENT_FILTER = gql`
  {
    medium
    frame
    length
    width
    thickness
    stock
  }
`;

export const INIT_NESTS_PAGE = gql`
  query initNestsPage {
    locations {
      _id
      kaptInfo
      coordinates {
        latitude
        longitude
      }
      ervType {
        _id
        name
        capacity
        type
        description
      }
      hackingType
      # description
      comment
      wallpad
      storeUrl
      filterPrice 
      filterChangePrice
      visitDuration
      notionLink
      profile
    }
    ervTypes {
      _id
      name
      capacity
      type
      filters {
        preFilterOA {
          ...${FRAGMENT_FILTER}
        }
        hepaFilterOA {
          ...${FRAGMENT_FILTER}
        }
        preFilterRA {
          ...${FRAGMENT_FILTER}
        }
        carbonFilterSA {
          ...${FRAGMENT_FILTER}
        }
        exchangeElement {
          ...${FRAGMENT_FILTER}
        }
      }
      description
    }
  }
`;

export const INIT_CONTRACTS_PAGE = gql`
  query initContractsPage($userId: ID!) {
    user(userId: $userId) {
      _id
      name
      email
      phone
      createdAt
      contracts {
        _id
        startAt
        endAt
        reqCancelAt
        status
        serviceTier {
          _id
          name
          price
          type
          status
        }
      }
      orders {
        _id
        createdAt
        items {
          product {
            name
          }
          qty
        }
        amount
        billings {
          type
          name
          status
          paidAt
          updatedAt
        }
        status
      }
    }
  }
`;

export const INIT_BILLINGS_PAGE = gql`
  query initBillingsPage($userId: ID!, $contractId: ID!) {
    user(userId: $userId) {
      _id
      name
      email
      phone
      createdAt
    }
    contract(contractId: $contractId) {
      _id
      startAt
      endAt
      reqCancelAt
      status
      serviceTier {
        _id
        name
        price
        type
      }
      billings {
        _id
        type
        payMethod
        name
        amount
        status
        paidAt
        scheduleAt
        createdAt
        createdAt
      }
    }
  }
`;

export const INIT_DASHBOARD_PAGE = gql`
  query initDashboardPage($userId: ID!, $from: Date!, $to: Date!, $sample: Int) {
    user(userId: $userId) {
      _id
      name
      floors {
        _id
        name
        aiPerformance {
          pmScore
          aqiScore
          energyScore
        }
        location {
          outdoorAtmLogsByDate(from: $from, to: $to) {
            measurements {
              logtime
              temp
              humid
              pm25
            }
          }
        }
        sections {
          _id
          name
          profile
          awairs {
            _id
            name
          }
          indoorAtmLogsByDate(from: $from, to: $to, sample: $sample) {
            measurements {
              logtime
              temp
              humid
              co2
              co2_15
              co2_90
              voc
              voc_15
              voc_90
              pm25
              awairScore
            }
            predictions {
              logtime
              temp
              humid
              co2
              co2_15
              co2_90
              voc
              voc_15
              voc_90
              pm25
              awairScore
            }
          }
        }
        wifis {
          ssid
          password
        }
        ervs {
          _id
          name
          status
          board
          limitation
          consumables {
            lastFilterChangedAt
            filterRemainPercent
            filterChangeHistory {
              logtime
            }
          }
          ervCtrl {
            _id
            id
            name
            status
            action
            autoControlOn
            controlSettings
          }
          ervType {
            _id
            name
          }
          ervLogsByDate(from: $from, to: $to) {
            isVsp
            actions {
              fanspeed
              logtime
              ventLevel
              vspIn
              vspOut
            }
          }
        }
      }
    }
  }
`;

export const INIT_CONSULTING_REPORT_PAGE = gql`
  query initConsultingReportPage($userId: ID!, $from: Date!, $to: Date!) {
    user(userId: $userId) {
      _id
      name
      floors {
        _id
        name
        sections {
          _id
          name
          indoorAtmLogsByDate(from: $from, to: $to, sample: 4000) {
            # 2주일간 5분에 한번꼴로 집계
            measurements {
              logtime
              temp
              humid
              co2
              co2_15
              co2_90
              voc
              voc_15
              voc_90
              pm25
              awairScore
              puffinScore
            }
          }
        }
      }
    }
  }
`;

export const INIT_FARMS_PAGE = gql`
  {
    awairs {
      _id
      createdAt
      name
      type
      uuid
      isConnected
      macAddr
      status
      section {
        _id
      }
    }
    ervCtrls {
      _id
      createdAt
      uuid
      id
      name
      booked
      status
      info
      autoControlOn
      controlSettings
      erv {
        _id
      }
    }
  }
`;

export const INIT_AWAIR_SELECTOR = gql`
  query initAwairSelector {
    awairs {
      _id
      name
      macAddr
      section {
        _id
      }
    }
  }
`;

export const INIT_ERV_CTRL_SELECTOR = gql`
  query initErvCtrlSelector {
    ervCtrls {
      _id
      id
      name
      erv {
        _id
      }
    }
  }
`;

export const INIT_FEATHERS_PAGE = gql`
  query initFeathersPage {
    filters {
      _id
      createdAt
      updatedAt
      medium
      frame
      length
      width
      thickness
      stock
    }
  }
`;

export const GET_APT_CODE = gql`
  query getAptCode($bjdCode: String!, $limit: Int) {
    getAptCode(bjdCode: $bjdCode, limit: $limit) {
      kaptCode
      kaptName
    }
  }
`;

export const INIT_EVENT_DASHBOARD_PAGE = gql`
  query initEventDashbaordPage($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      _id
      name
      managers {
        _id
        name
      }
      locations {
        _id
        kaptInfo
      }
      createdAt
      users {
        _id
        name
        phone
        email
        floors {
          _id
          name
          location {
            _id
            kaptInfo
          }
          sections {
            _id
            name
            awairs {
              _id
              name
              events {
                type
                isActive
                startAt
                endAt
              }
            }
          }
          ervs {
            _id
            name
            consumables {
              filterRemainTimeInHours
              filterRemainPercent
              lastFilterChangedAt
              calFilterLifeAt
            }
            ervCtrl {
              _id
              name
              events {
                type
                isActive
                startAt
                endAt
              }
            }
          }
          visitRequests {
            _id
            createdAt
            type
            slug
            manager {
              _id
              id
              name
              email
            }
            status
            name
            email
            phone
            inviteeStartTime
            inviteeEndTime
            inviteeCanceledAt
            visitId
            isPaid
          }
          deliveries {
            _id
            address
            dongho
            type
            manager {
              _id
              id
              name
              email
            }
            status
            name
            email
            phone
            kaptName
            orderNo
            createdAt
          }
        }
        orders {
          _id
          createdAt
          items {
            product {
              name
              price
            }
            qty
            unitPrice
          }
          amount
          billings {
            _id
            type
            amount
            status
            paidAt
            lastSmsRequestInfo
            paymentInfo
          }
          visitRequest {
            _id
            createdAt
            type
            slug
            manager {
              _id
              id
              name
              email
            }
            status
            name
            email
            phone
            inviteeStartTime
            inviteeEndTime
            inviteeCanceledAt
            isPaid
            visitId
          }
        }
      }
      managers {
        _id
        id
        name
      }
    }
  }
`;

export const ME = gql`
  query me {
    me {
      _id
      createdAt
      updatedAt
      id
      name
      phone
      email
      status
      role
      user {
        _id
        name
      }
    }
  }
`;

export const INIT_CONTRACT_REGISTRATION_PAGE = gql`
  query initContractRegistrationPage($userId: ID!) {
    user(userId: $userId) {
      name
      email
      phone
      createdAt
      floors {
        _id
        name
        location {
          kaptInfo
        }
      }
    }
    serviceTiers {
      _id
      name
      price
      type
      filterAmount
      months
      status
      desc
    }
    getCustomerUidsInUser(userId: $userId)
  }
`;

export const INIT_PAY_ORDER_PAGE = gql`
  query initPayOrderPage($userId: ID!) {
    user(userId: $userId) {
      name
      email
      phone
      createdAt
      floors {
        _id
        name
        location {
          kaptInfo
        }
      }
    }
    products {
      _id
      name
      price
      desc
      isSupportQty
      shop
      status
    }
    getCustomerUidsInUser(userId: $userId)
  }
`;
export const INIT_CHANGE_SERVICE_TIER = gql`
  query initChangeServiceTier {
    serviceTiers {
      _id
      name
      price
      type
      filterAmount
      months
      status
      desc
    }
  }
`;

export const INIT_REPORT_PAGE = gql`
  query initReportPage($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      _id
      name
      status
      users {
        _id
        name
        phone
        email
        profile
        status
        mailList
        createdAt
        floors {
          _id
          name
        }
      }
    }
  }
`;

export const INIT_WEEKLY_REPORT_PAGE = gql`
  query initWeeklyReportPage($userId: ID!, $floorId: ID!) {
    user(userId: $userId) {
      name
      floors {
        _id
        location {
          kaptInfo
        }
      }
    }
    latestWeeklyFloorLog(floorId: $floorId) {
      _id
      floor {
        name
      }
      from
      to
      comment
      summary {
        co2Out
        vocOut
        filteredPm25
        filterRemainTimeInHours
        filterRemainPercent
        cadr
        avgTemp
        avgHumid
        avgCo2
        avgVoc
        avgPm25
        avgVentLevel
        prevScoreAvg
        currentScoreAvg
        scoreAvgDiff
        ventCalNum
        compToNorm
      }
      highlight {
        co2 {
          startAt
          endAt
          peakAt
          value
          maxVentLevel
          weight
        }
        voc {
          startAt
          endAt
          peakAt
          value
          maxVentLevel
          weight
        }
        dailyPm25 {
          fullDate
          shortDate
          avgIndoorValue
          avgOutdoorValue
        }
      }
      header {
        node
        nodeModel
        dataType
      }
      body
    }
  }
`;

export const INIT_DAILY_REPORT_PAGE = gql`
  query initDailyReportPage($userId: ID!, $floorId: ID!) {
    user(userId: $userId) {
      name
      floors {
        _id
        name
        location {
          kaptInfo
        }
      }
    }
    latestDailyFloorLog(floorId: $floorId) {
      _id
      from
      to
      comment
      forecast {
        pm25
      }
      summary {
        co2Out
        vocOut
        filteredPm25
        filterRemainTimeInHours
        filterRemainPercent
        cadr
        avgTemp
        avgHumid
        avgCo2
        avgVoc
        avgPm25
        avgVentLevel
        prevScoreAvg
        currentScoreAvg
        scoreAvgDiff
        ventCalNum
        compToNorm
        eqTreeNum
        eqPlantNum
        savedEnergyPrice
        savedHealthSpan
      }
      highlight {
        co2 {
          startAt
          endAt
          peakAt
          value
          maxVentLevel
          weight
        }
        co2Sleep {
          startAt
          endAt
          peakAt
          value
          maxVentLevel
          weight
        }
        voc {
          startAt
          endAt
          peakAt
          value
          maxVentLevel
          weight
        }
        dailyPm25 {
          fullDate
          shortDate
          avgIndoorValue
          avgOutdoorValue
        }
      }
    }
  }
`;

export const GET_MY_ORGANIZATION = gql`
  query myOrganization {
    myOrganization {
      _id
      name
      status
    }
  }
`;

const FRAGMENT_NODE = gql`
  {
    ... on Location {
      _id
      kaptInfo
    }
    ... on User {
      _id
      name
    }
    ... on Floor {
      _id
      name
    }
    ... on Section {
      _id
      name
    }
    ... on Erv {
      _id
      name
    }
    ... on ErvCtrl {
      _id
      name
    }
  }
`;

export const INIT_FLOOR_PAGE = gql`
  query initFloorPage($floorId: ID!, $userId: ID!) {
    floor(floorId: $floorId) {
      _id
      name
      location {
        _id
        kaptInfo
      }
      systemGroup {
        _id
        createdAt
        updatedAt
        nodes {
          profiles {
            node {
              ...${FRAGMENT_NODE}
            }
            nodeModel
          }
          requirements {
            node {
              ...${FRAGMENT_NODE}
            }
            nodeModel
          }
          limitations {
            node {
              ...${FRAGMENT_NODE}
            }
            nodeModel
          }

          actions {
            node {
              ...${FRAGMENT_NODE}
            }
            nodeModel
          }
        }
        relations {
          profilesToRequirements {
            links {
              from {
                node {
                  ...${FRAGMENT_NODE}
                }
                nodeModel
              }
              to {
                node {
                  ...${FRAGMENT_NODE}
                }
                nodeModel
              }
              weight
            }
            matrix
          }
          requirementsToLimitations {
            links {
              from {
                node {
                  ...${FRAGMENT_NODE}
                }
                nodeModel
              }
              to {
                node {
                  ...${FRAGMENT_NODE}
                }
                nodeModel
              }
              weight
            }
            matrix
          }
          limitationsToActions {
            links {
              from {
                node {
                  ...${FRAGMENT_NODE}
                }
                nodeModel
              }
              to {
                node {
                  ...${FRAGMENT_NODE}
                }
                nodeModel
              }
              weight
            }
            matrix
          }
          profilesToActions {
            links {
              from {
                node {
                  ...${FRAGMENT_NODE}
                }
                nodeModel
              }
              to {
                node {
                  ...${FRAGMENT_NODE}
                }
                nodeModel
              }
              weight
            }
            matrix
          }
        }
      }
      sections {
        _id
        name
        status
        profile
        requirement
        awairs {
          _id
          name
        }
      }
      ervs {
        _id
        name
        status
        board
        limitation
        consumables {
          lastFilterChangedAt
          filterRemainPercent
          filterChangeHistory {
            logtime
          }
        }
        ervCtrl {
          _id
          id
          name
          status
          action
          autoControlOn
          controlSettings
        }
        ervType {
          _id
          name
        }
      }
    }
    user(userId: $userId) {
      _id
      name
      floors {
        _id
        name
      }
    }
  }
`;

export const INIT_MANAGERS_SCHEDULE_PAGE = gql`
  query initManagersSchedulePage($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      _id
      name
      status
      managers {
        _id
        id
        name
        phone
        email
        status
        schedules {
          startHour
          endHour
          location {
            _id
          }
        }
        createdAt
      }
      locations {
        _id
        kaptInfo
      }
    }
  }
`;

export const GET_REALTIME_SCORES = gql`
  query getRealtimeScores($floorId: ID!) {
    floor(floorId: $floorId) {
      location {
        latestOutdoorAtmLog {
          latestAtmLog {
            temp
            humid
            pm25
          }
        }
      }
      ervs {
        _id
        consumables {
          filterRemainPercent
        }
        ervCtrl {
          status
        }
        latestErvLog {
          isVsp
          latestActionLog {
            fanspeed
            vspIn
            vspOut
          }
        }
      }
      sections {
        _id
        profile
        aiPerformance {
          aqiAccuracy
          aqiPrediction
        }
      }
      aiPerformance {
        pmScore
        aqiScore
        energyScore
      }
    }
  }
`;

export const INIT_POSITION_PAGE = gql`
  query initFloorPage($floorId: ID!, $userId: ID!) {
    floor(floorId: $floorId) {
      _id
      name
      location {
        _id
        # kaptCode
        kaptInfo
      }
      wifis {
        ssid
        password
      }
      aiPerformance {
        pmScore
        aqiScore
        energyScore
      }
      image {
        url
        status
      }
      positions {
        nodeModel
        node
        left
        top
      }
      sections {
        _id
        name
        status
        profile
        requirement
        awairs {
          _id
          name
        }
      }
      ervs {
        _id
        name
        status
        board
        limitation
        consumables {
          lastFilterChangedAt
          filterRemainPercent
          filterChangeHistory {
            logtime
          }
        }
        ervCtrl {
          _id
          id
          name
          status
          action
          autoControlOn
          controlSettings
        }
        ervType {
          _id
          name
        }
      }
    }
    user(userId: $userId) {
      _id
      name
      floors {
        _id
        name
      }
    }
  }
`;
