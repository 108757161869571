import moment from "moment";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Button, Header, Segment, Table, Confirm, Menu } from "semantic-ui-react";
import { EmptyMessage } from "@styles/common";
import { INIT_EVENT_DASHBOARD_PAGE } from "@graphql/query";
import { FINISH_VISIT_REQUEST, UPDATE_VISIT_REQUEST } from "@graphql/mutation";
import { useMutation } from "@apollo/react-hooks";
import { toastSuccess, toastError } from "@util";
import SelfContext from "@selfContext";
import styled from "styled-components";

type QualityControlEventList = {
  qualityControlEvents: any;
  orgId: string;
  managerOptions: any;
};

function QualityControlEventList({ qualityControlEvents, orgId, managerOptions }: QualityControlEventList) {
  const { self } = useContext(SelfContext);
  const [selectedEventsType, setSelectedEventsType] = useState("notFinished");
  const [finishVisitConfirm, setFinishVisitConfirm] = useState(false);
  const [finishVisitConfirmMsg, setFinishVisitConfirmMsg] = useState("");
  const [finishVisitId, setFinishVisitId] = useState("");

  // 주문 목록, 품질 관리 목록 :  방문 완료 처리
  const [finishVisitRequest] = useMutation(FINISH_VISIT_REQUEST, {
    onCompleted(data) {
      toastSuccess("방문 완료 처리되었습니다");
      setFinishVisitConfirm(false);
    },
    onError(error) {
      toastError(`방문 완료 처리 실패`, error.message);
      setFinishVisitConfirm(false);
    },
    refetchQueries: [{ query: INIT_EVENT_DASHBOARD_PAGE, variables: { organizationId: orgId } }],
  });

  // 매니저 배정
  const [updateVisitRequest] = useMutation(UPDATE_VISIT_REQUEST, {
    onCompleted(data) {
      toastSuccess("매니저가 배정되었습니다.");
    },
    onError(error) {
      toastError(`매니저 배정에 실패하였습니다.`, error.message);
    },
    refetchQueries: [{ query: INIT_EVENT_DASHBOARD_PAGE, variables: { organizationId: orgId } }],
  });

  const onClickFinishVisitBtn = (visitRequestId: string, label: string) => {
    setFinishVisitId(visitRequestId);
    setFinishVisitConfirmMsg(`방문 완료 상태로 변경합니다. (${label})`);
    setFinishVisitConfirm(true);
  };

  // 품질 관리 목록 조회 타입 (미완료, 완료)
  const checkEventType = (visitStatus: string) => {
    if ((selectedEventsType === "notFinished" && visitStatus !== "finished") || (selectedEventsType === "finished" && visitStatus === "finished")) {
      return true;
    } else {
      return false;
    }
  };

  async function onConfirmFinishVisit(finishVisitId: string) {
    await finishVisitRequest({ variables: { visitRequestId: finishVisitId } });
  }

  return (
    <Segment>
      <Header as="h3">
        품질 관리 목록
        <StyledMenu text>
          <Menu.Item name="미완료" active={selectedEventsType === "notFinished"} onClick={() => setSelectedEventsType("notFinished")} />
          <Menu.Item name="완료" active={selectedEventsType === "finished"} onClick={() => setSelectedEventsType("finished")} />
        </StyledMenu>
      </Header>
      <Table celled compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>type</Table.HeaderCell>
            <Table.HeaderCell>user / floor</Table.HeaderCell>
            <Table.HeaderCell>방문상태</Table.HeaderCell>
            <Table.HeaderCell>매니저</Table.HeaderCell>
            <Table.HeaderCell>일시</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {qualityControlEvents.filter((cur: any) => checkEventType(cur.visitStatus)).length ? (
            qualityControlEvents
              .filter((cur: any) => checkEventType(cur.visitStatus))
              .map((cur: any, index: any) => {
                return (
                  <Table.Row key={index} style={cur.visitStatus === "cancelled" ? { color: "#DDDDDD" } : {}}>
                    <Table.Cell>{getTypeLabel(cur.type)}</Table.Cell>
                    <Table.Cell>
                      <Link to={`/orgs/${orgId}/users/${cur.userId}`}>
                        {" "}
                        {cur.userName} /<br />
                        {cur.floorName}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      {cur.visitStatus}
                      {cur.visitStatus && (
                        <>
                          <br />
                          <Button
                            size="mini"
                            compact
                            onClick={() => onClickFinishVisitBtn(cur.visitRequestId, `${cur.userName} / ${cur.floorName}`)}
                            disabled={cur.visitStatus === "finished"}
                          >
                            확인
                          </Button>
                        </>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {self?.role === "superAdmin" || self?.role === "admin" ? (
                        <Dropdown
                          inline
                          options={managerOptions}
                          value={cur.managerId || "NONE"}
                          onChange={(e, onChangeData) => {
                            const managerId = onChangeData.value === "NONE" ? null : onChangeData.value;
                            updateVisitRequest({ variables: { visitRequestId: cur.visitRequestId, data: { manager: managerId } } });
                          }}
                        />
                      ) : (
                        <>{managerOptions.find((opt: any) => opt.value === cur.managerId)?.text || "미지정"}</>
                      )}
                    </Table.Cell>
                    <Table.Cell style={{ fontSize: 11, lineHeight: "1.2em" }}>
                      방문일시:
                      <br />
                      {cur.inviteeStartTime ? <b>{moment(cur.inviteeStartTime).format("YYYY-MM-DD HH:mm")}</b> : "미지정"}
                      <br />
                      신청일시:
                      <br />
                      {moment(cur.createdAt).format("YYYY-MM-DD HH:mm")}
                    </Table.Cell>
                  </Table.Row>
                );
              })
          ) : (
            <Table.Row>
              <Table.Cell colSpan="5">
                <EmptyMessage>Empty</EmptyMessage>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      <Confirm
        open={finishVisitConfirm}
        header={`방문완료처리를 하시겠습니까? `}
        content={finishVisitConfirmMsg}
        cancelButton="취소"
        confirmButton="확인"
        onCancel={() => setFinishVisitConfirm(false)}
        onConfirm={() => onConfirmFinishVisit(finishVisitId)}
      />
    </Segment>
  );
}

export default QualityControlEventList;

const StyledMenu = styled(Menu)`
  display: inline-flex !important;
  margin-left: 20px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  min-height: 0 !important;
`;

const getTypeLabel = (type: string) => {
  if (type === "filterReplace") return "필터";
  if (type === "puffinPlaceInstall") return "공기질 컨설팅";
  if (type === "sensorReplace") return "공기질 센서 교체";
  if (type === "ervCtrlReplace") return "ERV 컨트롤러 교체";
  if (type === "customerInterview") return "기타";
  return "미분류";
};
