import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Modal } from "semantic-ui-react";



export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  
  type TProps = {
    children: React.ReactNode;
    title?: String;
  }

  const ModalPortal: React.FC<TProps> = ({ children, title }) => {
    const ref = useRef<Element | null>();
    const [mounted, setMounted] = useState(false);
  
    useEffect(()=>{
      setMounted(true);
      if(document) {
        const dom = document.querySelector("#root-modal");
        ref.current = dom;
      }
    },[]);
  
    if( ref.current && mounted && isOpen) {
      return createPortal(
        <Modal open={mounted} onClose={closeModal} closeOnEscape={true} closeOnDimmerClick={true}>
          {title && <Modal.Header>{title}</Modal.Header>}
          {children}
        </Modal>,
        ref.current
      )
    }
    return null;
  }

  return {
    openModal,
    closeModal,
    ModalPortal
  }
}
