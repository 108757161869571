import React from "react";
import moment from "moment";
import { Table } from "semantic-ui-react";
import StatusLabel from "@Components/Common/StatusLabel";
import { IBilling } from "@interfaces";

function BillingItem({ billing }: { billing: IBilling }) {
  return (
    <Table.Row>
      <Table.Cell>
        {billing.name}
        {billing.type === "filter" && <StatusLabel bool={billing.isChecked ? billing.isChecked : false} text={Boolean(billing.isChecked).toString()} />}
      </Table.Cell>
      <Table.Cell>{billing.type}</Table.Cell>
      <Table.Cell>{billing.status}</Table.Cell>
      <Table.Cell>{billing.amount}원</Table.Cell>
      <Table.Cell>{billing.payMethod}</Table.Cell>
      <Table.Cell>
        {billing.paidAt && (
          <>
            <div>결제일 : {moment(billing.paidAt).format("YYYY-MM-DD, h:mm a")}</div>
            {billing.status === "cancelled" && <div>취소일 : {moment(billing.updatedAt).format("YYYY-MM-DD, h:mm a")}</div>}
          </>
        )}
        {billing.scheduleAt && (
          <>
            <div>예약 신청일 : {moment(billing.createdAt).format("YYYY-MM-DD, h:mm a")}</div>
            <div>결제 예정일 : {moment(billing.scheduleAt).format("YYYY-MM-DD, h:mm a")}</div>
            {billing.status === "deletedSchedule" && <div>취소일 : {moment(billing.updatedAt).format("YYYY-MM-DD, h:mm a")}</div>}
          </>
        )}
      </Table.Cell>
    </Table.Row>
  );
}

export default BillingItem;
