import React, { useState } from "react";
import { Icon, Button, Form, Modal, Radio, List } from "semantic-ui-react";
import InputRange from "react-input-range";
import { bufferJsonToHexString } from "@util";
import styled from "styled-components";

type TProps = {
  ervCtrl: any;
  onSubmit: any;
  onControlInfoUpdate: any;
};

export const ErvCtrlForm = ({ervCtrl, onSubmit, onControlInfoUpdate,}: TProps) => {
  const [controlSettings, setControlSettings] = useState<any>(ervCtrl.controlSettings);
  const [manualControl, setManualControl] = useState<any>(
    ervCtrl?.action?.manualControl ? 
    { controlOn: false, vspIn: 0, vspOut: 0, ventLevel: 0, ...ervCtrl.action.manualControl }
    :
    { controlOn: false, vspIn: 0, vspOut: 0, ventLevel: 0 }
  );
  const [autoControlOn, setAutoControlOn] = useState(ervCtrl.autoControlOn || false);
  const [reversed, setReversed] = useState<boolean>(ervCtrl.controlSettings?.controlType?.inOutReversed);

  // const { loading, data } = useQuery(INIT_AWAIR_SELECTOR);

  const handleAutoControlOn = (checked : boolean) => {
    if (checked) {
      setManualControl(null);
    } else {
      setManualControl({ controlOn: false, vspIn: 0, vspOut: 0, ventLevel: 0 });
    }
    setAutoControlOn(checked);
  }

  async function handleSubmit() {
    const newControSettings = { ...controlSettings, controlType: { ...controlSettings.controlType, inOutReversed: reversed } };
    onSubmit({
      ervCtrlId: ervCtrl._id,
      data: {
        autoControlOn: !!autoControlOn,
        action: {
          ...ervCtrl.action,
          manualControl: { ...ervCtrl.action.manualControl , ...manualControl },
        },
        controlSettings: newControSettings,
      },
    });
  }

  async function handleSetControlInfo(e: any) {
    e.preventDefault();
    onControlInfoUpdate(ervCtrl._id);
  }

  if (!ervCtrl) return null;

  return (
    <Modal.Content>
      <Form onSubmit={handleSubmit} noValidate>
        <Form.Group>
          <Form.Field>
            <label>Auto Mode</label>
            <Radio
              checked={autoControlOn}
              toggle
              onChange={(e, { checked }: any) => {
                handleAutoControlOn(checked);
              }}
            />
          </Form.Field>
          {manualControl && !autoControlOn && (
            <>
              <Form.Field>
                <label>Control ON</label>
                <Radio
                  checked={manualControl.controlOn}
                  toggle
                  onChange={(e, { checked }) => setManualControl({ ...Object.assign({}, manualControl, { controlOn: checked }) })}
                />
              </Form.Field>
            </>
          )}
        </Form.Group>
        {manualControl && !autoControlOn && manualControl.controlOn && controlSettings?.controlType.vspMode && (
          <>
            <Form.Group style={{ marginBottom: 30 }}>
              <Form.Field style={{ width: "300px" }}>
                <label>vspIn</label>
                <InputRange
                  minValue={0}
                  maxValue={100}
                  step={1}
                  value={manualControl.vspIn || 0}
                  onChange={(value) => setManualControl({ ...Object.assign({}, manualControl, { vspIn: value }) })}
                />
              </Form.Field>
              <Form.Field style={{ width: "300px" }}>
                <label>vspOut</label>
                <InputRange
                  minValue={0}
                  maxValue={100}
                  step={1}
                  value={manualControl.vspOut || 0}
                  onChange={(value) => setManualControl({ ...Object.assign({}, manualControl, { vspOut: value }) })}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Reversed</label>
                <Radio checked={reversed} toggle onChange={(e, { checked }: any) => setReversed(checked)} />
              </Form.Field>
            </Form.Group>
          </>
        )}
        {manualControl && !autoControlOn && manualControl.controlOn && !controlSettings?.controlType.vspMode && (
          <Form.Group style={{ marginBottom: 30 }}>
            <Form.Field style={{ width: "300px" }}>
              <label>ventLevel</label>
              <InputRange
                minValue={0}
                maxValue={100}
                step={33.33}
                value={manualControl.ventLevel || 0}
                onChange={(value) => setManualControl({ ...Object.assign({}, manualControl, { ventLevel: value }) })}
              />
            </Form.Field>
          </Form.Group>
        )}
        <ControlInfoBtnWrap>
          <label>Control Info</label>
          <Button animated="vertical" onClick={handleSetControlInfo}>
            <Button.Content visible>Update</Button.Content>
            <Button.Content hidden>
              <Icon name="arrow circle down" />
            </Button.Content>
          </Button>
        </ControlInfoBtnWrap>
        {controlSettings && (
          <List>
            <List.Item>
              <List.Icon name="connectdevelop" />
              <List.Content>
                <List.Header>Network</List.Header>
                <List.List>
                  <List.Item>
                    <List.Icon name="wifi" />
                    <List.Content>
                      <List.Header>Wi-Fi</List.Header>
                      <List.Description>{`${controlSettings.network.wifis[0] && controlSettings.network.wifis[0].ssid}/••••••••`}</List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="server" />
                    <List.Content>
                      <List.Header>Host</List.Header>
                      <List.Description>{`${controlSettings.network.hosts[0] && controlSettings.network.hosts[0]}`}</List.Description>
                    </List.Content>
                  </List.Item>
                </List.List>
              </List.Content>
            </List.Item>

            <List.Item>
              <List.Icon name="configure" />
              <List.Content>
                <List.Header>Control Type</List.Header>
                <List.Description>{`mode: ${controlSettings.controlType.vspMode ? "pro" : "nest"}, signal: ${
                  controlSettings.controlType.isPeriodic ? "periodic" : "non-periodic"
                }, Stop Bit: ${controlSettings.controlType.stopBit}`}</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="random" />
              <List.Content>
                <List.Header>Control Packets</List.Header>
                {controlSettings.controlPacket && (
                  <List.List>
                    {controlSettings.controlType.isOnPacket && (
                      <List.Item>
                        <List.Icon name="bolt" />
                        <List.Content>
                          <List.Header>Signal - On</List.Header>
                          <List.Description>{`${bufferJsonToHexString(controlSettings.controlPacket.onPacket.request)}`}</List.Description>
                        </List.Content>
                      </List.Item>
                    )}
                    <List.Item>
                      <List.Icon name="bolt" />
                      <List.Content>
                        <List.Header>Signal - Off</List.Header>
                        <List.Description>{`${bufferJsonToHexString(controlSettings.controlPacket.offPacket.request)}`}</List.Description>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="bolt" />
                      <List.Content>
                        <List.Header>Signal - Low</List.Header>
                        <List.Description>{`${bufferJsonToHexString(controlSettings.controlPacket.lowPacket.request)}`}</List.Description>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="bolt" />
                      <List.Content>
                        <List.Header>Signal - Mid</List.Header>
                        <List.Description>{`${bufferJsonToHexString(controlSettings.controlPacket.midPacket.request)}`}</List.Description>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="bolt" />
                      <List.Content>
                        <List.Header>Signal - High</List.Header>
                        <List.Description>{`${bufferJsonToHexString(controlSettings.controlPacket.highPacket.request)}`}</List.Description>
                      </List.Content>
                    </List.Item>
                  </List.List>
                )}
              </List.Content>
            </List.Item>
          </List>
        )}

        <Button type="submit" fluid>
          Submit
        </Button>
      </Form>
    </Modal.Content>
  );
}

const ControlInfoBtnWrap = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  label {
    margin-right: 10px;
  }
`;
