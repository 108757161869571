import React, { useState, useEffect } from "react";
import Highcharts, { reduce } from "highcharts";
import HighchartsReact from "highcharts-react-official";

function WeeklyReportCharts({ fanspeedData, highlightType, vocOrCo2Data = [] }: { fanspeedData: any; highlightType: any; vocOrCo2Data: any }) {
  const [optionData, setOptionData] = useState<any>();

  useEffect(() => {
    const maxFanVal = fanspeedData.reduce((acc: any, cur: any) => Math.max(acc, cur[1]), 0);
    let highlightData;
    let maxVal;

    highlightData = vocOrCo2Data;
    maxVal = vocOrCo2Data.reduce((acc: any, cur: any) => Math.max(acc, cur[1]), 0);
    const numDigit = maxVal.toString().length;
    const highlightDeco: any = document.querySelector(".highlight__deco");
    if (numDigit == 4) highlightDeco.style.left = "56px";
    if (numDigit == 5) highlightDeco.style.left = "63px";

    let yAxisOpt1 = {};
    let yAxisOpt2 = {};

    const maxVspVal = fanspeedData.reduce((acc: any, cur: any) => Math.max(acc, cur[1]), 0);

    yAxisOpt1 = {
      labels: {
        format: "{value}",
        style: {
          color: "#0CDDC0",
        },
      },
      title: false,
      max: maxVal,
      min: 0,
    };

    yAxisOpt2 = {
      title: false,
      labels: {
        style: {
          color: "#ffd215",
        },
        format: "{value}%",
      },
      max: Math.min(maxVspVal + 10, 100),
      min: 0,
      tickPositioner: function (min: number, max: number) {
        const ret = [];
        for (var i = min; i <= max; i += 10) {
          ret.push(i);
        }
        return ret;
      },
      opposite: true,
      gridLineColor: "transparent",
    };

    const optionObj = {
      options: {
        title: {
          text: null,
        },
        chart: {
          spacingTop: 20,
        },
        time: {
          timezoneOffset: 9,
          useUTC: false,
        },
        xAxis: {
          type: "datetime",
          pointIntervalUnit: "hour",
          tickInterval: 4 * 60 * 60 * 1000,
          visible: false, //
        },
        yAxis: [yAxisOpt1, yAxisOpt2],
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            animation: false,
          },
        },
        series: [
          {
            type: "area",
            name: "#{highlightType}",
            data: highlightData,
            lineWidth: 3,
            zIndex: 2,
            color: "#00C3C5",
            fillColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, "rgba(12,221,192, 0.8)"],
                [1, "rgba(12,221,192, 0)"],
              ],
            },
          },
          {
            yAxis: 1,
            type: "area",
            name: "fanspeed",
            data: fanspeedData,
            lineWidth: 3,
            zIndex: 1,
            color: "#FFD215",
            fillColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, "rgba(255, 210, 21, 0.8)"],
                [1, "rgba(255, 210, 21, 0)"],
              ],
            },
          },
        ],
      },
      longListCondition: null,
    };

    setOptionData(optionObj);
  }, [fanspeedData, highlightType, vocOrCo2Data]);

  return (
    <div>
      {optionData && optionData.options.series !== undefined && <HighchartsReact highcharts={Highcharts} options={optionData.options} updateArgs={[true]} />}
    </div>
  );
}

export default WeeklyReportCharts;
