import React, { useState } from "react";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { IAccount } from "@interfaces";

type TProps = {
  member?: IAccount;
  onSubmit: Function;
};

export const ManagerForm = ({ onSubmit, member }: TProps) => {

  const [id, setId] = useState(member?.id);
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [name, setName] = useState(member?.name);
  const [phone, setPhone] = useState(member?.phone);
  const [email, setEmail] = useState(member?.email);
  const [gCalendarId, setGCalendarId] = useState(member?.gCalendarId);

  const onChangePhone = (e: any) => {
    const strOriginal = e.target.value.replace(/[^0-9]/g, "");
    setPhone(strOriginal.replace(/(^01.{1})([0-9]+)([0-9]{4})/, "$1-$2-$3"));
  };

  const handleOnSubmit = () => {
    if(!member && password !== passwordCheck) return;
    onSubmit(member ? { name, phone, email, gCalendarId } : { id, password, name, phone, email, gCalendarId })
  }

  return (
      <Modal.Content>
        <Form onSubmit={handleOnSubmit}>
          <Form.Field disabled={!!member}>
            <label>ID</label>
            <Input type="text" placeholder="ID" value={id} onChange={(e) => setId(e.target.value)} required={true} />
          </Form.Field>
          <Form.Field disabled={!!member}>
            <label>Password</label>
            <Input type="password" placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)} 
              required={!member}
            />
          </Form.Field>
          <Form.Field disabled={!!member}>
            <label>Password Check</label>
            <Form.Input
              error={password !== passwordCheck ? "비밀번호가 일치하지 않습니다." : null}
              type="password"
              placeholder="passwordCheck"
              value={passwordCheck}
              onChange={(e) => setPasswordCheck(e.target.value)}
              required={!member}
            />
          </Form.Field>

          <Form.Field>
            <label>Name</label>
            <Input type="name" placeholder="name" value={name} onChange={(e) => setName(e.target.value)} required={true} />
          </Form.Field>

          <Form.Field>
            <label>Phone</label>
            <Input type="phone" placeholder="phone" value={phone} onChange={onChangePhone} maxLength={13} required={true} />
          </Form.Field>

          <Form.Field>
            <label>Email</label>
            <Input type="email" placeholder="email" value={email} onChange={(e) => setEmail(e.target.value)} required={true} />
          </Form.Field>

          <Form.Field>
            <label>구글 캘린더 ID</label>
            <Input placeholder="gCalendarId" value={gCalendarId} onChange={(e) => setGCalendarId(e.target.value)} required={false} />
          </Form.Field>

          <Button type="submit" fluid>
            Submit
          </Button>
        </Form>
      </Modal.Content>
 
  );
}