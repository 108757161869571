import React from "react";
import { Link } from "react-router-dom";
import { Card, Header, Icon, Label, Button } from "semantic-ui-react";
import { CardButton } from "@styles/common";

type TMemberList = {
  role: any;
  onCreate: Function;
  canCreate: boolean;
  memberCnt: Number;
  orgId: String;
  children: JSX.Element[] | JSX.Element;
};

function MemberList({ role, onCreate, canCreate, memberCnt, orgId, children }: TMemberList) {
  return (
    <>
      <Header as="h3">
        {role.charAt(0).toUpperCase() + role.slice(1) + "s"}
        <Label style={{ marginLeft: 10 }}>
          <Icon name="user" />
          {memberCnt}
        </Label>
        {role === "manager" && (
          <Button as={Link} to={`/orgs/${orgId}/managers/schedule`} size="mini" style={{ float: "right" }}>
            <Icon name="calendar alternate outline" />
            일정 관리
          </Button>
        )}
      </Header>

      <Card.Group style={{ minHeight: 150 }}>
        {children}
        {canCreate && (
          <CardButton onClick={onCreate}>
            <p>{role.charAt(0).toUpperCase() + role.slice(1)} 등록</p>
          </CardButton>
        )}
      </Card.Group>
    </>
  );
}

export default MemberList;
