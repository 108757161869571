import React from "react";
import styled from "styled-components";
import { Checkbox, Button } from "semantic-ui-react";

type TProps = {
  selectedGraph: "temp" | "humid" | "VOC" | "CO2" | "PM25" | "awairScore"  ,
  setSelectedGraph: any,
  isShowErvLog: boolean,
  setIsShowErvLog: any
}

function SelectChartBtns({selectedGraph, setSelectedGraph, isShowErvLog, setIsShowErvLog} : TProps) {
  return (
    <Container>
        <Button.Group>
          <Button active={selectedGraph === "awairScore"} onClick={()=>setSelectedGraph("awairScore")}>AwairScore</Button>
          <Button active={selectedGraph === "CO2"} onClick={()=>setSelectedGraph("CO2")}>CO2</Button>
          <Button active={selectedGraph === "VOC"} onClick={()=>setSelectedGraph("VOC")}>TVocs</Button>
          <Button active={selectedGraph === "PM25"} onClick={()=>setSelectedGraph("PM25")}>PM2.5</Button>
          <Button active={selectedGraph === "temp"} onClick={()=>setSelectedGraph("temp")}>Temperature</Button>
          <Button active={selectedGraph === "humid"} onClick={()=>setSelectedGraph("humid")}>Humidity</Button>
        </Button.Group>

        <Checkbox style={{marginLeft:"10px"}} toggle label="ERV Logs" checked={isShowErvLog} onClick={()=>setIsShowErvLog(!isShowErvLog)} /> 
    </Container>
  )
}
export { SelectChartBtns };

const Container = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;