import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Grid, Loader } from "semantic-ui-react";
import DefaultLayout from "@Layouts/DefaultLayout";
import { Helmet } from "react-helmet";
import UserSegment from "@Components/User/UserSegment";
import FloorSegment from "@Components/User/FloorSegment";
import MapSegment from "@Components/User/MapSegment";
import SectionSegment from "@Components/User/SectionsSegment";
import HvacsSegment from "@Components/User/HvacsSegment";
import { INIT_USER_PAGE } from "@graphql/query";
import { useQuery } from "@apollo/react-hooks";

function User() {
  const { userId, orgId }: { userId: string; orgId: string } = useParams();
  const [curFloorId, setCurFloorId] = useState("");
  const [confrimContent, setConfirmContent] = useState("");

  // user 조회
  const { loading, data, error } = useQuery(INIT_USER_PAGE, {
    variables: { userId: userId },
    onCompleted: (data) => {
      if (data.user.floors[0]) {
        setCurFloorId(data.user.floors[0]._id);
      }
      let content = `${data.user.email}로 로그인 정보 메일을 전송하겠습니까?`;
      if (data.user.lastInvitedTime) content += ` (마지막 전송 날짜 : ${moment(data.user.lastInvitedTime).format("YYYY-MM-DD, h:mm a")})`;
      setConfirmContent(content);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  // 현재 floor 조회
  const getCurFloor = (curFloorId: any, data: any) => {
    if (!data || !curFloorId) return;
    return data.user.floors.find((cur: any) => cur && cur._id === curFloorId);
  };
  const curFloor = useMemo(() => getCurFloor(curFloorId, data), [curFloorId, data]);

  if (loading)
    return (
      <DefaultLayout>
        <Loader active />
      </DefaultLayout>
    );

  if (error) return <DefaultLayout></DefaultLayout>;

  return (
    <DefaultLayout>
      <>
        <Helmet>
          <title>{data.user.name}(user)</title>
        </Helmet>
        <Grid>
          <Grid.Column mobile={16} computer={9}>
            <UserSegment user={data.user} orgId={orgId} confrimContent={confrimContent} />
            <FloorSegment curFloor={curFloor} curFloorId={curFloorId} setCurFloorId={setCurFloorId} user={data.user} />
          </Grid.Column>
          <Grid.Column mobile={16} computer={7}>
            <MapSegment curFloorId={curFloorId} orgId={orgId} userId={userId} curFloor={curFloor} userStatus={data.user.status} />
          </Grid.Column>
        </Grid>

        <Grid>
          <Grid.Column mobile={16} computer={9}>
            <SectionSegment curFloor={curFloor} user={data.user} />
          </Grid.Column>

          <Grid.Column mobile={16} computer={7}>
            <HvacsSegment curFloor={curFloor} user={data.user} />
          </Grid.Column>
        </Grid>
      </>
    </DefaultLayout>
  );
}
export default User;
