import React from "react";
import styled from "styled-components";
import { useHvacItemContext } from "../useHvacItemContext";
import moment from "moment";
import { Label } from "semantic-ui-react";

function Description() {
  const { hvac, aiPerformance }: any = useHvacItemContext();
  return (
    <StyledDescription>
      <p>ERV Type: {hvac.ervType?.name}</p>
      <p>Board: {hvac.board}</p>
      {hvac.board !== "default" && (
        <>
          <p>
            vspMin :{hvac.limitation?.physicalVspLimit?.min || "-"}, vspMax: {hvac.limitation?.physicalVspLimit?.max || "-"}
          </p>
          <p>
            필터 수명:{hvac.consumables?.filterRemainPercent.toFixed(1) || "-"}% ({moment(hvac.consumables?.lastFilterChangedAt).format("YYYY-MM-DD")})
          </p>
        </>
      )}
      <p>Daily Schedule 지정횟수: {hvac.limitation?.dailyOperationSchedules?.length}</p>
      {!!aiPerformance?.pmScore && (
        <Label size="tiny" style={{ marginLeft: 4, marginBottom: 2, padding: 4 }}>
          PM Score: {Math.floor(aiPerformance?.pmScore * 100)}
        </Label>
      )}
      {!!aiPerformance?.aqiScore && (
        <Label size="tiny" style={{ marginLeft: 4, marginBottom: 2, padding: 4 }}>
          Aqi Score: {Math.floor(aiPerformance?.aqiScore * 100)}
        </Label>
      )}
      {!!aiPerformance?.energyScore && (
        <Label size="tiny" style={{ marginLeft: 4, marginBottom: 2, padding: 4 }}>
          Energy Score: {Math.floor(aiPerformance?.energyScore * 100)}
        </Label>
      )}
    </StyledDescription>
  );
}
export { Description };

const StyledDescription = styled.div`
  margin-top: 10px;
  font-size: 12px;
  p {
    line-height: 0.8em;
    color: black;
  }
`;
