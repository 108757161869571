import React from "react";
import { Table } from "semantic-ui-react";
import { IFilter } from "@interfaces";

type TFilterItem = {
  index: number;
  filter: IFilter;
  // handleSubmit?: any;
  // handleRemove?: any;
};

// function FilterItem({ index, filter, handleSubmit, handleRemove }: TFilterItem) {
function FilterItem({ index, filter }: TFilterItem) {
  /*
  const [stock, onChangeStock, setStock] = useInput(0);
  const handleOnClick = () => {
    if (Number(stock) <= 0) return;
    const cart = Object.assign({}, filter);
    cart.stock = Number(stock);
    handleSubmit(cart);
    setStock(0);
  };
  const handleOnClickRemove = () => {
    const cart = Object.assign({}, filter);
    handleRemove(cart);
  };
  */
  return (
    <Table.Row>
      <Table.Cell>{index + 1}</Table.Cell>
      <Table.Cell>{`${filter.length} x ${filter.width} x ${filter.thickness}`}</Table.Cell>
      <Table.Cell>{filter.medium}</Table.Cell>
      <Table.Cell>{filter.stock}</Table.Cell>
    </Table.Row>
  );
}
export default FilterItem;

/* 
{
  <Table.Row>
{handleSubmit ? (
  <>
    <Table.Cell>{index + 1}</Table.Cell>
    <Table.Cell>{`${filter.length} x ${filter.width} x ${filter.thickness}`}</Table.Cell>
    <Table.Cell>
      {filter.locations &&
        filter.locations.reduce((acc: any, cur: any, idx: any) => {
          return acc + cur.kaptInfo.kaptName + (idx === filter.locations.length - 1 ? "" : " / ");
        }, "")}
    </Table.Cell>
    <Table.Cell>{filter.stock}</Table.Cell>
    <Table.Cell>
      <Input style={{ width: 80 }} focus type="number" value={stock} onChange={onChangeStock} />
    </Table.Cell>
    <Table.Cell>
      <Button animated="vertical" onClick={handleOnClick}>
        <Button.Content hidden>Cart</Button.Content>
        <Button.Content visible>
          <Icon name="shop" />
        </Button.Content>
      </Button>
    </Table.Cell>
  </>
) : (
  <>
    <Table.Cell>{index + 1}</Table.Cell>
    <Table.Cell>{`${filter.length} x ${filter.width} x ${filter.thickness}`}</Table.Cell>
    <Table.Cell>{filter.medium}</Table.Cell>
    <Table.Cell>{filter.stock}</Table.Cell>

    {handleRemove && (
      <Table.Cell>
        <Button animated="vertical" onClick={handleOnClickRemove}>
          <Button.Content hidden>Remove</Button.Content>
          <Button.Content visible>
            <Icon name="cancel" />
          </Button.Content>
        </Button>
      </Table.Cell>
    )}
  </>
)}
</Table.Row> 
}
*/
