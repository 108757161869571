import moment from "moment";
import React, { useContext, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Button, Header, Icon, Segment, Confirm, Input, Menu, Loader, Dropdown } from "semantic-ui-react";
import Breadcrumbs from "@Components/Common/Breadcrumbs";
import MemberList from "@Components/Org/MemberList";
import MemberItem from "@Components/Org/MemberItem";
import UserItem from "@Components/Org/UserItem";
import ManualAppPushModal from "@Components/Modals/ManualAppPushModal";
import DefaultLayout from "@Layouts/DefaultLayout";
import SelfContext from "@selfContext";
import { ManageBtns, ToolBtns } from "@styles/common";
import { toastSuccess, toastError } from "@util";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { INIT_ORGANIZATION_PAGE } from "@graphql/query";
import {
  UPDATE_ORGANIZATION,
  REMOVE_ORGANIZATION,
  CREATE_ACCOUNT,
  UPDATE_ACCOUNT,
  REMOVE_ACCOUNT,
  CREATE_USER,
  UPDATE_USER,
  REMOVE_USER,
  SET_USER_STEP,
} from "@graphql/mutation";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { IUser, IAccount, ILocation } from "@interfaces";
import { useModal } from "@Hooks/useModal";
import { OrgForm, AdminForm, ManagerForm, UserForm } from "@Components/Modals";

function Org() {
  const { orgId }: { orgId: string } = useParams();
  const { self } = useContext(SelfContext);
  const [userSearchText, setUserSearchText] = useState("");
  const [removeConfirmOpen, setRemoveConfirmOpen] = useState(false);
  const [currentUserStatus, setCurrentUserStatus] = useState("");
  const [currentLocationId, setCurrentLocationId] = useState();
  const [locationItems, setLocationItems] = useState<any>([]);
  const history = useHistory();
  const { ModalPortal : OrgModalPortal, openModal : openOrgModal, closeModal : closeOrgModal } =  useModal();
  const { ModalPortal : AdminModalPortal, openModal : openAdminModal, closeModal : closeAdminModal } =  useModal();
  const { ModalPortal : ManagerModalPortal, openModal : openManagerModal, closeModal : closeManagerModal } =  useModal();
  const { ModalPortal : UserModalPortal, openModal : openUserModal, closeModal : closeUserModal } =  useModal();

  // org 조회
  const { loading, data } = useQuery(INIT_ORGANIZATION_PAGE, {
    variables: { organizationId: orgId },
    onCompleted(data) {
      const options = data.organization.locations.map((cur: ILocation) => ({
        key: cur._id,
        value: cur._id,
        text: cur.kaptInfo?.kaptName,
      }));
      setLocationItems([{ key: "11111", value: "", text: "지정 안함" }, ...options]);
    },
    onError(error) {
      console.error(error);
    },
    // fetchPolicy: "network-only",
  });

  // org 수정
  const [updateOrg] = useMutation(UPDATE_ORGANIZATION, {
    onCompleted(data) {
      toastSuccess("organization이 수정되었습니다.", `org name : ${data.updateOrganization.name}`);
    },
    onError(error) {
      console.error(error);
      toastError("organization 수정을 실패하였습니다.", error.message);
    },
    refetchQueries: [{ query: INIT_ORGANIZATION_PAGE, variables: { organizationId: orgId } }],
  });

  // org 수정 제출
  const orgModifySubmit = (data: any) => {
    updateOrg({ variables: { organizationId: orgId, data: data } });
    closeOrgModal();
  }

  // org 삭제
  const [removeOrg] = useMutation(REMOVE_ORGANIZATION, {
    onCompleted(data) {
      toastSuccess("organization이 삭제되었습니다.", `org name : ${data.removeOrganization.name}`);
      history.push("/orgs");
      setRemoveConfirmOpen(false);
    },
    onError(error) {
      console.error(error);
      toastError("organization 삭제를 실패하였습니다.", error.message);
      setRemoveConfirmOpen(false);
    },
    refetchQueries: [{ query: INIT_ORGANIZATION_PAGE, variables: { organizationId: orgId } }],
  });

  const handleOrgRemove = async () => {
    await removeOrg({ variables: { organizationId: orgId } });
  };

  // admin, manager 생성
  const [createAccount] = useMutation(CREATE_ACCOUNT, {
    onCompleted(data) {
      toastSuccess("account 생성", `id : ${data.createAccount.id}`);
    },
    onError(error) {
      console.error(error);
      toastError("account 생성 실패", error.message);
    },
    refetchQueries: [{ query: INIT_ORGANIZATION_PAGE, variables: { organizationId: orgId } }],
  });

  // admin 등록 제출
  const adminSubmit = (data: any) => {
    createAccount({ variables: { organizationId: orgId, role: "admin", data: data } });
    closeAdminModal();
  }

  // manager 등록 제출
  const managerSubmit = (data: any) => {
    createAccount({ variables: { organizationId: orgId, role: "manager", data: data } });
    closeManagerModal();
  }

  // admin, manager 수정
  const [updateAccount] = useMutation(UPDATE_ACCOUNT, {
    onCompleted(data) {
      toastSuccess("account 수정", `id : ${data.updateAccount.id}`);
    },
    onError(error) {
      console.error(error);
      toastError("account 수정 실패", error.message);
    },
    refetchQueries: [{ query: INIT_ORGANIZATION_PAGE, variables: { organizationId: orgId } }],
    awaitRefetchQueries: true,
  });

  // admin, manager 삭제
  const [removeAccount] = useMutation(REMOVE_ACCOUNT, {
    onCompleted(data) {
      toastSuccess("account 삭제", `id : ${data.removeAccount.id}`);
    },
    onError(error) {
      console.error(error);
      toastError("account 삭제 실패", error.message);
    },
    refetchQueries: [{ query: INIT_ORGANIZATION_PAGE, variables: { organizationId: orgId } }],
  });

  // user 생성
  const [createUser] = useMutation(CREATE_USER, {
    onCompleted(data) {
      toastSuccess("user 생성", `name : ${data.createUser.name}`);
    },
    onError(error) {
      console.error(error);
      toastError("user 생성 실패", error.message);
    },
    refetchQueries: [{ query: INIT_ORGANIZATION_PAGE, variables: { organizationId: orgId } }],
  });

  // user 등록 제출
  const userSubmit = (data: any) => {
    createUser({ variables: { organizationId: orgId, data: data } });
    closeUserModal();
  }

  // user 수정
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted(data) {
      toastSuccess("user 수정", `name : ${data.updateUser.name}`);
    },
    onError(error) {
      console.error(error);
      toastError("user 수정 실패", error.message);
    },
    refetchQueries: [{ query: INIT_ORGANIZATION_PAGE, variables: { organizationId: orgId } }],
  });

  // user 삭제
  const [removeUser] = useMutation(REMOVE_USER, {
    onCompleted(data) {
      toastSuccess("user 삭제", `name : ${data.removeUser.name}`);
    },
    onError(error) {
      console.error(error);
      toastError("user 삭제 실패", error.message);
    },
    refetchQueries: [{ query: INIT_ORGANIZATION_PAGE, variables: { organizationId: orgId } }],
  });

  // user status 수정
  const [setUserStep] = useMutation(SET_USER_STEP, {
    onCompleted(data) {
      toastSuccess("status 지정", `${data.setUserStep.status}`);
    },
    onError(error) {
      console.error("error", error);
      toastError("status 지정 실패.", error.message);
    },
    refetchQueries: [{ query: INIT_ORGANIZATION_PAGE, variables: { organizationId: orgId } }],
  });

  const searchUser = (userName: string) => {
    if (userSearchText.trim() === "") {
      return true;
    } else if (userName.indexOf(userSearchText) !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const sortByUserStatus = (users: IUser[]) => {
    let newUsers: IUser[] = [];
    let consultingUsers: IUser[] = [];
    let paidUsers: IUser[] = [];
    let outlierUsers: IUser[] = [];
    let sponsorUsers: IUser[] = [];
    let activeUsers: IUser[] = [];
    let filterUsers: IUser[] = [];

    users.forEach((user) => {
      user.status === "new" && newUsers.push(user);
      user.status === "consulting" && consultingUsers.push(user);
      user.status === "paid" && paidUsers.push(user);
      user.status === "outlier" && outlierUsers.push(user);
      user.status === "sponsor" && sponsorUsers.push(user);
      user.status === "filter" && filterUsers.push(user);
      user.status === "active" && activeUsers.push(user);
    });
    return [...newUsers, ...consultingUsers, ...outlierUsers, ...paidUsers, ...sponsorUsers, ...filterUsers, ...activeUsers];
  };

  const filterByStatus = (userStatus: "new" | "consulting" | "paid" | "outlier" | "sponsor" | "active" | "filter") => {
    if (!currentUserStatus) {
      return true;
    } else if (userStatus === currentUserStatus) {
      return true;
    } else {
      return false;
    }
  };

  const filterByLocation = (floors: any) => {
    if (!currentLocationId) return true;
    return floors.some((floor: any) => floor?.location?._id === currentLocationId);
  };

  const haveAuthOf = (role: "superAdmin" | "admin" | "manager" | "user") => {
    if (self.role === "superAdmin" || (self.role === "admin" && (role === "manager" || role === "user")) || (self.role === "manager" && role === "user")) {
      return true;
    } else {
      return false;
    }
  };

  if (loading)
    return (
      <DefaultLayout>
        <Loader active />
      </DefaultLayout>
    );

  return (
    <DefaultLayout>
      {data.organization.name && (
        <Helmet>
          <title>{data.organization.name}(organization)</title>
        </Helmet>
      )}
      <Segment>
        <Header as="h2">
          {data.organization.name}
          <Header.Subheader>
            <Breadcrumbs />
          </Header.Subheader>
        </Header>

        <p>
          {!!data.organization.locations.length && (
            <>
              <Icon name="map" />
              {data.organization.locations.map((cur: ILocation, index: number) => (
                <span key={index}>
                  <a target="_blank" href={`https://puffinplace.com/filter/org/${orgId}/location/${cur._id}`}>
                    {cur.kaptInfo?.kaptName}
                  </a>{" "}
                  ({cur.kaptInfo?.kaptAddr}){index + 1 < data.organization.locations.length && <span>, </span>}{" "}
                </span>
              ))}
              <br />
            </>
          )}
          <Icon name="calendar" />
          {moment(data.organization.createdAt).format("YYYY-MM-DD, h:mm a")}
        </p>

        {self.role === "superAdmin" && (
          <ManageBtns>
            <Button size="mini" onClick={openOrgModal}>
              <Icon name="edit" />
              Modify
            </Button>
            <Button size="mini" onClick={() => setRemoveConfirmOpen(true)}>
              <Icon name="trash" />
              Delete
            </Button>
            <Confirm
              open={removeConfirmOpen}
              header={`Organization(${data.organization.name}) 삭제`}
              content={`정말로 삭제하시겠습니까? Organization 내의 모든 유저 삭제 후, 삭제가 가능합니다.`}
              cancelButton="취소"
              confirmButton="삭제"
              onCancel={() => setRemoveConfirmOpen(false)}
              onConfirm={handleOrgRemove}
            />
            <ManualAppPushModal type="org" name={data.organization.name} id={data.organization._id} />
          </ManageBtns>
        )}

        <ToolBtns>
          <Link to={`/orgs/${orgId}`}>
            <Button size="mini">
              <Icon name="bullhorn" />
              Event
            </Button>
          </Link>
        </ToolBtns>
      </Segment>

      {haveAuthOf("admin") ? (
        <Segment>
          <MemberList role="admin" memberCnt={data.organization.admins.length} canCreate={haveAuthOf("admin")} onCreate={openAdminModal} orgId={orgId}>
            {data.organization.admins.map((admin: IAccount) => (
              <MemberItem
                key={admin._id}
                member={admin}
                role="admin"
                orgId={orgId}
                // to={`/orgs/${orgId}/admins/${admin._id}`}
                updateAccount={updateAccount}
                removeAccount={removeAccount}
                canModify={haveAuthOf("admin")}
                canRemove={haveAuthOf("admin")}
                isMe={false} // TODO
              ></MemberItem>
            ))}
          </MemberList>
        </Segment>
      ) : (
        <></>
      )}

      {haveAuthOf("manager") ? (
        <Segment>
          <MemberList
            role="manager"
            memberCnt={data.organization.managers.length}
            canCreate={haveAuthOf("manager")}
            onCreate={openManagerModal}
            orgId={orgId}
          >
            {data.organization.managers.map((manager: IAccount) => (
              <MemberItem
                key={manager._id}
                member={manager}
                role="manager"
                orgId={orgId}
                // to={`/orgs/${orgId}/managers/${manager._id}`}
                updateAccount={updateAccount}
                removeAccount={removeAccount}
                canModify={haveAuthOf("manager")}
                canRemove={haveAuthOf("manager")}
                isMe={false} // TODO
              ></MemberItem>
            ))}
          </MemberList>
        </Segment>
      ) : (
        <></>
      )}

      <Segment style={{ position: "relative" }}>
        <Menu text>
          <Menu.Item name="all" active={currentUserStatus === ""} onClick={() => setCurrentUserStatus("")} />
          <Menu.Item name="new" active={currentUserStatus === "new"} onClick={() => setCurrentUserStatus("new")} />
          <Menu.Item name="consulting" active={currentUserStatus === "consulting"} onClick={() => setCurrentUserStatus("consulting")} />
          <Menu.Item name="paid" active={currentUserStatus === "paid"} onClick={() => setCurrentUserStatus("paid")} />
          <Menu.Item name="outlier" active={currentUserStatus === "outlier"} onClick={() => setCurrentUserStatus("outlier")} />
          <Menu.Item name="sponsor" active={currentUserStatus === "sponsor"} onClick={() => setCurrentUserStatus("sponsor")} />
          <Menu.Item name="filter" active={currentUserStatus === "filter"} onClick={() => setCurrentUserStatus("filter")} />
          <Menu.Item>
            <Dropdown
              inline
              placeholder="location 조회"
              options={locationItems}
              disabled={!data.organization.locations.length}
              onChange={(e, data: any) => {
                setCurrentLocationId(data.value);
              }}
            />
          </Menu.Item>
        </Menu>
        <UserSearchInput
          value={userSearchText}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserSearchText(e.target.value)}
          placeholder="User 검색"
        />
        <MemberList
          role="user"
          // memberCnt={orgData.users.filter((user) => filterByStatus(user.step)).filter((user) => searchUser(user.name)).length}
          memberCnt={
            sortByUserStatus(data.organization.users)
              .filter((user) => filterByStatus(user.status))
              .filter((user) => filterByLocation(user.floors))
              .filter((user) => searchUser(user.name)).length
          }
          canCreate={haveAuthOf("user")}
          onCreate={openUserModal}
          orgId={orgId}
        >
          {sortByUserStatus(data.organization.users)
            .filter((user) => filterByStatus(user.status))
            .filter((user) => filterByLocation(user.floors))
            .filter((user) => searchUser(user.name))
            .map((user) => (
              <UserItem
                key={user._id}
                user={user}
                orgId={orgId}
                to={`/orgs/${orgId}/users/${user._id}`}
                // onUpdate={fetchUsers}
                updateUser={updateUser}
                removeUser={removeUser}
                setUserStep={setUserStep}
                // onUpdate={null}
                canModify={haveAuthOf("user")}
                canRemove={haveAuthOf("user")}
              ></UserItem>
            ))}
        </MemberList>
      </Segment>
      <OrgModalPortal title="Org">
        <OrgForm name={data.organization.name} locations={data.organization.locations} onSubmit={orgModifySubmit} />
      </OrgModalPortal>
      <AdminModalPortal title="Admin">
        <AdminForm onSubmit={adminSubmit}/>
      </AdminModalPortal>
      <ManagerModalPortal title="Manager">
        <ManagerForm onSubmit={managerSubmit}/>
      </ManagerModalPortal>
      <UserModalPortal title="User">
        <UserForm onSubmit={userSubmit}/>
      </UserModalPortal>
    </DefaultLayout>
  );
}

export default Org;

const UserSearchInput = styled(Input)`
  position: absolute !important;
  top: 30px;
  right: 14px;
  width: 160px;
`;
