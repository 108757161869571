import React from "react";
import Chart from "react-google-charts";

const colors = ["#a6cee3", "#b2df8a", "#fb9a99", "#fdbf6f", "#cab2d6", "#ffff99", "#1f78b4", "#33a02c", "#000000"];

function SankeyDiagram({ links, onLinksSelected }: any) {

  const options = {
    width: 800,
    height: Math.max(800, links.length * 20 ),
    // height: 800,
    sankey: {
      node: {
        width: 10,
        nodePadding: 100,
        interactivity: true,
        colors: colors,
      },
      link: {
        interactivity: true,
        /*      colorMode: "gradient",
        colors: colors,*/
      },
    },
  };

  return (
    <Chart
      chartType="Sankey"
      data={links}
      options={options}
      chartEvents={[
        {
          eventName: "select",
          callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            chartWrapper.setRefreshInterval(1000);
            const selections = chart.getSelection();
            const dataTable = chartWrapper.getDataTable();
            const rowList: any = [];
            dataTable &&
              selections.forEach((selection: any) => {
                if (!selection.name) rowList.push({ row: selection.row + 1, value: dataTable.getValue(selection.row, 2) });
                else if (selection.name) {
                  const rows = dataTable.getNumberOfRows();
                  for (let row = 0; row < rows; row++) {
                    if (dataTable.getValue(row, 0) === selection.name || dataTable.getValue(row, 1) === selection.name)
                      rowList.push({ row: row + 1, value: dataTable.getValue(row, 2) });
                  }
                }
              });
            onLinksSelected(rowList, chartWrapper);
          },
        },
      ]}
    />
  );
}
export default SankeyDiagram;
