import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet";

function NotFound() {
  return (
    <Container>
      <Helmet>
        <title>404 Error</title>
      </Helmet>
      <div>
        <h1>Page Not Found!</h1>
        <Link to="/">Return to Home Page</Link>
      </div>
    </Container>
  );
}

export default NotFound;

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
