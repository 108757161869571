import React from "react";
import { Card, Image } from "semantic-ui-react";
import {useSectionCardContext} from "../useSectionCardContext";
import type { IAwair } from "@interfaces";
import styled from "styled-components";

type TProps = {
  noEdit?: boolean
}

function Awair({noEdit}:TProps) {
  const  { awairs, openAwairSelectorModal } : {awairs: IAwair[], openAwairSelectorModal: ()=>{}} = useSectionCardContext();


  if(awairs.length === 0 ) {
    if(noEdit) return <P>awair: 미할당</P>
    return <NoAwairMsg onClick={openAwairSelectorModal}>어웨어 할당하기</NoAwairMsg>
  }
  return (
    <P>awair: {awairs[0].name || "NO NAME"}
      {awairs.length > 1 && `외 ${awairs.length-1}개`}</P>
  )
}

const NoAwairMsg = styled.p`
  color: #4183c4;
  cursor: pointer;
`;

const P = styled.p`
  color: rgba(0,0,0,0.6);
  font-size: 14px;
`

export { Awair };
