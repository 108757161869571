import React, { useState } from "react";
import { Confirm, Dropdown, Icon } from "semantic-ui-react";
import styled from "styled-components";
import {useSectionCardContext} from "../useSectionCardContext";

function Option() {
  const  { awairs, handleRemove, handleUnallocate, openSectionModal } : any = useSectionCardContext();
  const [confirmOpenRemove, setConfirmOpenRemove] = useState(false);
  const [confirmOpenUnallocate, setConfirmOpenUnallocate] = useState(false);
  return(
    <>
      <Container>
        <Dropdown icon="setting" direction="left">
          <Dropdown.Menu>
            <Dropdown.Item text="section 수정" icon="edit" onClick={openSectionModal} />
            <Dropdown.Item text="awair 해제" icon="trash" disabled={!awairs.length} onClick={() => setConfirmOpenUnallocate(true)} />
            <Dropdown.Item text="section 삭제" icon="trash" onClick={() => setConfirmOpenRemove(true)} />
          </Dropdown.Menu>
        </Dropdown>
        <Confirm
          open={confirmOpenRemove}
          header={`삭제`}
          content={`정말로 삭제하시겠습니까?`}
          cancelButton="취소"
          confirmButton={{
            content: "삭제",
            negative: true,
          }}
          onCancel={() => setConfirmOpenRemove(false)}
          onConfirm={() => {
            handleRemove();
            setConfirmOpenRemove(false);
          }}
        />
      </Container>
      <Confirm
        open={confirmOpenUnallocate}
        header={`할당해제`}
        content={`선택된 awair를 해제하시겠습니까?`}
        cancelButton="취소"
        confirmButton={{
          content: "해제",
          negative: true,
        }}
        onCancel={() => setConfirmOpenUnallocate(false)}
        onConfirm={() => {
          handleUnallocate(awairs);
          setConfirmOpenUnallocate(false);
        }}
      />
    </>
  )
}
export { Option };

const Container = styled.div`
position: absolute !important;
top: 4px;
right: 4px;
color: rgba(0, 0, 0, 0.68);
`