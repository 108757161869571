import moment from "moment";
import React from "react";
import { Card, Icon } from "semantic-ui-react";
import { NoEffectIcon } from "@styles/common";
import CardItemActions from "@Components/Common/CardItemActions";
import { IAccount } from "@interfaces";
import { useModal } from "@Hooks/useModal";
import { AdminForm, ManagerForm } from "@Components/Modals";

type TMemberItem = {
  member: IAccount;
  role: string;
  orgId: string;
  updateAccount: Function;
  removeAccount: Function;
  canModify: boolean;
  canRemove: boolean;
  isMe: boolean;
};

function MemberItem({ member, role, orgId, updateAccount, removeAccount, canModify, canRemove, isMe }: TMemberItem) {
  const { ModalPortal : AdminModalPortal, openModal : openAdminModal, closeModal : closeAdminModal } =  useModal();
  const { ModalPortal : ManagerModalPortal, openModal : openManagerModal, closeModal : closeManagerModal } =  useModal();

  // admin 등록 제출
  const adminSubmit = (data: any) => {
    updateAccount({ variables: { accountId: member._id, data: data } });  
    closeAdminModal();
  }

  // manager 등록 제출
  const managerSubmit = (data: any) => {
    updateAccount({ variables: { accountId: member._id, data: data } });
    closeManagerModal();
  }

  async function handleModify() {
    if (role === "admin") { openAdminModal();}
    else { openManagerModal(); }
  }

  async function handleRemove() {
    await removeAccount({ variables: { accountId: member._id, organizationId: orgId } });
    // try {
    //   await member.remove();
    //   toastSuccess("삭제 성공");
    // } catch (err) {
    //   console.error(err);
    //   toastError("삭제 실패", err.response.data && err.response.data.message);
    // } finally {
    //   onUpdate();
    // }
  }

  return (
    <>
      <Card>
        <Card.Content>
          <Card.Header>{member.name}</Card.Header>
          <Card.Meta>
            {role}
            {isMe && <NoEffectIcon name="star" />}
          </Card.Meta>
          <Card.Description>
            <div>
              <Icon name="user" />
              {member.id}
            </div>
            <div>
              <Icon name="phone" />
              {member.phone}
            </div>
            <div>
              <Icon name="calendar" />
              {moment(member.createdAt).format("YYYY-MM-DD, h:mm a")}
            </div>
          </Card.Description>
          <CardItemActions canModify={canModify} onModify={handleModify} canRemove={canRemove} onRemove={handleRemove} />
        </Card.Content>
      </Card>
      <AdminModalPortal title="Admin">
        <AdminForm onSubmit={adminSubmit} member={member} />
      </AdminModalPortal>
      <ManagerModalPortal title="Manager">
        <ManagerForm onSubmit={managerSubmit} member={member} />
      </ManagerModalPortal>
    </>
  );
}
export default MemberItem;
