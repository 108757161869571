import React from "react";
import { useParams } from "react-router-dom";
import DailyReportArea, { TDailyReportAreaProps } from "@Components/Reports/DailyReportArea";

function DailyReport() {
  const { userId, floorId, status, dDayNum }: TDailyReportAreaProps = useParams();
  return <DailyReportArea userId={userId} floorId={floorId} status={status} dDayNum={dDayNum} />;
}

export default DailyReport;
