import styled from "styled-components";
import { Container, Grid, Card, Icon, Button, Segment } from "semantic-ui-react";
import mediaQueries from "@styles/mediaQueries";

export const WideContainer = styled(Container)`
  @media only screen and (min-width: 1232px) {
    width: 1232px !important;
  }
`;

export const ItemNumGrid = styled(Grid)`
  margin-top: 10px !important;
  margin-bottom: -1.1rem !important;
  background-color: #eee;
`;

export const ItemNumGridCol = styled(Grid.Column)`
  font-size: 10px;
  text-align: center;
  color: black;
`;

export const ItemNum = styled.div`
  line-height: 1.5em;
  font-size: 20px;
`;

export const CardButton = styled(Card)`
  min-height: 135.15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mediaQueries("sm")`
     width: 100%;
  `}
`;

export const NoEffectIcon = styled(Icon)`
  cursor: default !important;
  &:hover {
    color: inherit !important;
  }
`;

export const ModalCloseButton = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const TabButtonGroup = styled(Button.Group)`
  position: absolute;
  top: 10px;
  right: 12px;
`;

export const ManageBtns = styled.div`
  margin-top: 1rem;
  button {
    margin-bottom: 0.2rem !important;
  }
`;

export const ToolBtns = styled.div`
  position: absolute;
  top: 1em;
  right: 1em;
  & > * {
    display: block;
    margin-bottom: 4px;
  }
  text-align: right;
`;

export const OneLine = styled.span`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
`;

export const SingleDatePickerWarp = styled.div`
  margin: 1rem 0;
  .SingleDatePicker {
    margin-left: 1rem;
  }
`;

export const EmptyMessage = styled.div`
  margin: 1rem auto;
  text-align: center;
  color: #ccc;
`;

export const TierOptionSegment = styled(Segment)`
  cursor: pointer;
  label {
    font-size: 0.8rem !important;
    margin-bottom: 1rem;
    padding-left: 1.2rem !important;
  }
  div {
    margin-bottom: 1rem;
  }
  ul {
    padding-left: 2rem;
  }
  .price {
    font-size: 1.2rem;
    font-weight: 400;

    span {
      font-size: 1rem;
    }
  }
`;
