import React, { useState, useCallback, useRef } from "react";
import styled from "styled-components";
import { Form, Button, Input } from "semantic-ui-react";
import { useInput, numFormat } from "@util";
import mediaQueries from "@styles/mediaQueries";

function AddCardInfoForm({ userId, addCardInfoInUser }: { userId: string; addCardInfoInUser: Function }) {
  const [cardNum1, setCardNum1] = useState("");
  const [cardNum2, setCardNum2] = useState("");
  const [cardNum3, setCardNum3] = useState("");
  const [cardNum4, setCardNum4] = useState("");

  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");

  const [birth, onChangeBirth] = useInput("");
  const [pwd2Digit, onChangePwd2Digit] = useInput("");

  const [isLoading, setIsLoading] = useState(false);

  const cardNum1Ref = useRef<HTMLInputElement>(null);
  const cardNum2Ref = useRef<HTMLInputElement>(null);
  const cardNum3Ref = useRef<HTMLInputElement>(null);
  const cardNum4Ref = useRef<HTMLInputElement>(null);

  const ExpiryMonthRef = useRef<HTMLInputElement>(null);
  const ExpiryYearRef = useRef<HTMLInputElement>(null);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setIsLoading(true);

      const cardInfo = {
        card_number: `${cardNum1}-${cardNum2}-${cardNum3}-${cardNum4}`,
        expiry: `20${expiryYear}-${numFormat(expiryMonth)}`, //YYYY-MM 꼴로 변형
        birth: birth,
        pwd_2digit: pwd2Digit,
      };

      addCardInfoInUser({ variables: { userId: userId, cardInfo: cardInfo } });
    },
    [cardNum1, cardNum2, cardNum3, cardNum4, expiryYear, expiryMonth, birth, pwd2Digit, userId]
  );

  const onChangeCardNumInput = useCallback((e, data) => {
    const focusedInput = +data.name.split("-")[1];
    if (e.target.value.length > 4 || isNaN(e.target.value)) return;

    switch (focusedInput) {
      case 1:
        setCardNum1(e.target.value);
        if (e.target.value.length === 4 && focusedInput < 4 && cardNum2Ref.current) {
          cardNum2Ref.current.focus();
        }
        break;
      case 2:
        setCardNum2(e.target.value);
        if (e.target.value.length === 4 && focusedInput < 4 && cardNum3Ref.current) {
          cardNum3Ref.current.focus();
        }
        break;
      case 3:
        setCardNum3(e.target.value);
        if (e.target.value.length === 4 && focusedInput < 4 && cardNum4Ref.current) {
          cardNum4Ref.current.focus();
        }
        break;
      case 4:
        setCardNum4(e.target.value);
        break;
      default:
        break;
    }
  }, []);

  const onChangeExpiryInput = useCallback((e, data) => {
    const focusedInput = +data.name.split("-")[1];
    if (e.target.value.length > 2 || isNaN(e.target.value)) return;

    switch (focusedInput) {
      case 1:
        if (e.target.value > 12) return;
        setExpiryMonth(e.target.value);
        if (e.target.value.length === 2 && focusedInput < 2 && ExpiryYearRef.current) {
          ExpiryYearRef.current.focus();
        }
        break;
      case 2:
        setExpiryYear(e.target.value);
        break;
      default:
        break;
    }
  }, []);

  return (
    <Form onSubmit={onSubmit} autoComplete="off">
      {/*  자동완성 제거 */}
      <input style={{ display: "none" }} aria-hidden="true" />
      <input type="password" style={{ display: "none" }} aria-hidden="true" />
      <Form.Field>
        <label>카드번호</label>
        <CardNumInput
          type="text"
          name="num-1"
          value={cardNum1}
          onChange={onChangeCardNumInput}
          ref={cardNum1Ref}
          placeholder="&#9679;&#9679;&#9679;&#9679;"
          maxLength={4}
          required
        />
        <CardNumInput
          type="text"
          name="num-2"
          value={cardNum2}
          onChange={onChangeCardNumInput}
          ref={cardNum2Ref}
          placeholder="&#9679;&#9679;&#9679;&#9679;"
          maxLength={4}
          required
        />
        <CardNumInput
          type="text"
          name="num-3"
          value={cardNum3}
          onChange={onChangeCardNumInput}
          ref={cardNum3Ref}
          placeholder="&#9679;&#9679;&#9679;&#9679;"
          maxLength={4}
          required
        />
        <CardNumInput
          type="text"
          name="num-4"
          value={cardNum4}
          onChange={onChangeCardNumInput}
          ref={cardNum4Ref}
          placeholder="&#9679;&#9679;&#9679;&#9679;"
          maxLength={4}
          required
        />
      </Form.Field>
      <Form.Group widths="equal">
        <Form.Field>
          <label>카드 유효기간(월/연도)</label>
          <ExpiryInput
            type="text"
            name="expiry-1"
            value={expiryMonth}
            onChange={onChangeExpiryInput}
            ref={ExpiryMonthRef}
            placeholder="MM"
            maxLength={2}
            required
          />{" "}
          /{" "}
          <ExpiryInput
            type="text"
            name="expiry-2"
            value={expiryYear}
            onChange={onChangeExpiryInput}
            ref={ExpiryYearRef}
            placeholder="YY"
            maxLength={2}
            required
          />
        </Form.Field>
        <Form.Field>
          <label>생년월일</label>
          <ShortInput type="text" name="birth" value={birth} onChange={onChangeBirth} required maxLength={6} autoComplete="none" placeholder="YYMMDD" />
        </Form.Field>
        <Form.Field>
          <label>카드 비밀번호 앞 두자리</label>
          <ShortInput type="password" name="digit" value={pwd2Digit} maxLength={2} onChange={onChangePwd2Digit} required autoComplete="new-password" />
        </Form.Field>
      </Form.Group>
      <Button type="submit" fluid disabled={isLoading ? true : false}>
        결제 정보 등록
      </Button>
    </Form>
  );
}

export default AddCardInfoForm;

const CardNumInput = styled(Input)`
  width: 90px !important;
  margin-right: 4px;

  ${mediaQueries("sm")`
    width: 24% !important;
    margin-right: 1%;
    
    &:last-child{
      margin-right: 0px;
    }
    input::placeholder{
      font-size:10px !important;
    }
  `}
`;

const ExpiryInput = styled(Input)`
  width: 60px !important;
`;

const ShortInput = styled(Input)`
  max-width: 150px !important;
`;
