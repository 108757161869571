import React, { useState } from "react";
import { Button, Form, Loader, Modal, Dropdown } from "semantic-ui-react";
import { INIT_ERV_CTRL_SELECTOR } from "@graphql/query";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";

type TProps = {
  onSubmit: Function;
};

export const ErvCtrlSelectorForm = ({onSubmit}: TProps) => {
  const [ervCtrlId, setErvCtrlId] = useState("");
  const { loading, data } = useQuery(INIT_ERV_CTRL_SELECTOR);

  return (
    <Modal.Content>
      {loading &&
        <LoaderArea>
          <Loader active inverted inline='centered' />
        </LoaderArea>}
      {data && (
        <Form onSubmit={() => onSubmit({ ervCtrlId })}>
          <Form.Field>
            <label>Select ErvCtrl</label>
            <Dropdown
              placeholder="Select ERV Controller"
              search
              selection
              clearable
              // value={ervCtrl && ervCtrls.findIndex((Ctrl) => ervCtrl._id === Ctrl._id)}
              value={ervCtrlId}
              options={data.ervCtrls.filter((cur: any) => !cur.erv).map((cur: any, idx: number) => ({ key: cur._id, text: cur.id, value: cur._id }))}
              onChange={(e, data: any) => setErvCtrlId(data.value)}
            />
            {/* <Dropdown
              placeholder="Select ErvCtrl"
              search
              selection
              fluid
              clearable
              // defaultValue={awairId}
              // value={awair && data.awairs.findIndex((cur) => cur._id === awair._id)}
              value={awairId}
              options={data.awairs
                .filter((cur) => !cur.section)
                .map((cur, idx) => ({ key: cur._id, text: `${cur.name}  / macAddr: ${cur.macAddr} / ${cur._id}`, value: cur._id }))}
              onChange={(e, data) => {
                setAwairId(data.value);
                // setAwair(data.awairs[data.value]);
              }}
            /> */}
          </Form.Field>

          <Button type="submit" fluid>
            Submit
          </Button>
        </Form>
      )}
    </Modal.Content>
  );
}

const LoaderArea = styled.div`
  width: 100%;
  height: 50px;
  position: "relative";
`

