import React from "react";
import { Dropdown } from "semantic-ui-react";
import { INIT_ERV_TYPES_SELECTOR } from "../../graphql/query";
import { useQuery } from "@apollo/react-hooks";
import { IErvType } from "@interfaces";

function ErvTypeSelector({ ervTypeId, setErvTypeId }: { ervTypeId: string; setErvTypeId: Function }) {
  const { loading, data } = useQuery(INIT_ERV_TYPES_SELECTOR, {
    onError: (error) => {
      console.error(error);
    },
  });

  return (
    <Dropdown
      placeholder="Select ERV Type"
      search
      selection
      fluid
      value={ervTypeId}
      options={!loading ? data.ervTypes.map((ervType: IErvType) => ({ key: ervType._id, text: ervType.name, value: ervType._id })) : []}
      onChange={(e, onChangeData) => {
        setErvTypeId(onChangeData.value);
      }}
    />
  );
}
export default ErvTypeSelector;
