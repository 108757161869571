import React from "react";
import styled from "styled-components";
import Header from "@Components/Layout/Header";
import Footer from "@Components/Layout/Footer";
import { WideContainer } from "@styles/common";

interface Props {
  children?: JSX.Element[] | JSX.Element;
  isFixedWidth?: boolean;
}

function DefaultLayout({ children, isFixedWidth = false }: Props) {
  return (
    <AppContainer>
      <Header />
      <WideContainer>
        <MainContainer isFixedWidth={isFixedWidth}>
          <Main>{children}</Main>
        </MainContainer>
      </WideContainer>
      <Footer />
    </AppContainer>
  );
}
export default DefaultLayout;

const AppContainer = styled.div`
  background: #f2f2f2;
  min-height: 100vh; /* will cover the 100% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 200px; /* height of your footer */
  margin: 0 auto;
`;

const MainContainer = styled.div<{ isFixedWidth: boolean }>`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 1rem 0;
  ${(props) => props.isFixedWidth && "width: 796px !important;"}
  ${(props) => props.isFixedWidth && "margin: 0 auto;"}
`;

const Main = styled.main`
  flex-grow: 1;
`;
