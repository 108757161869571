import React, { useState, useEffect } from "react";
import { Form, Input, Grid, Select, Button, Icon, Table } from "semantic-ui-react";
import SchedulePreview from "@Components/Managers/SchedulePreview";
import { toastError } from "@util";
import styled from "styled-components";
import { EmptyMessage } from "@styles/common";

interface Props {
  manager: any;
  updateAccount: Function;
  locations: any;
  locationColors: any;
}

export default function ScheduleForm({ manager, updateAccount, locations, locationColors }: Props) {
  const [dayOfTheWeek, setDayOfTheWeek] = useState<any>(1);
  const [startHour, setStartHour] = useState<any>("");
  const [endHour, setTo] = useState<any>("");
  const [locationId, setLocationId] = useState(locations.length ? locations[0]._id : "");
  const [scheduleList, setScheduleList] = useState<any[]>([]);

  useEffect(() => {
    if (!manager.schedules) return;

    const shedules: any = [];
    manager.schedules.forEach((outer: any, curDayOfTheWeek: number) => {
      outer.forEach((inner: any, index: number) => {
        const sheduleItem = {
          dayOfTheWeek: curDayOfTheWeek,
          startHour: inner.startHour,
          endHour: inner.endHour,
          locationId: inner.location._id,
        };
        shedules.push(sheduleItem);
      });
      setScheduleList(shedules);
    });
  }, [manager]);

  const dayOfTheWeekSelectItem = [
    { key: 1, text: "월", value: 1 },
    { key: 2, text: "화", value: 2 },
    { key: 3, text: "수", value: 3 },
    { key: 4, text: "목", value: 4 },
    { key: 5, text: "금", value: 5 },
    { key: 6, text: "토", value: 6 },
    { key: 0, text: "일", value: 7 },
  ];

  const onChangeFrom = (e: any) => {
    const value = Number(e.target.value);
    if (isNaN(value) || value < 0 || value >= 24) return;
    return setStartHour(value);
  };

  const onChangeTo = (e: any) => {
    const value = Number(e.target.value);
    if (isNaN(value) || value < 0 || value > 24) return;
    return setTo(value);
  };

  const onSubmit = async () => {
    if (!(Number(startHour) >= 0 && Number(endHour) <= 24) || Number(startHour) >= Number(endHour)) {
      return toastError("입력된 시간이 유효하지 않습니다");
    }

    let isError = false;
    manager.schedules.forEach((outer: any, curDayOfTheWeek: number) => {
      outer.forEach((inner: any, index: number) => {
        const curStartHour = Number(inner.startHour);
        const curEndHour = Number(inner.endHour);
        if (
          curDayOfTheWeek === dayOfTheWeek &&
          ((endHour > curStartHour && endHour < curEndHour) ||
            (startHour > curStartHour && startHour < curEndHour) ||
            (startHour <= curStartHour && endHour >= curEndHour))
        ) {
          isError = true;
        }
      });
    });
    if (isError) return toastError("입력된 시간이 이미 지정되었습니다");

    const newSchedules: any = [[], [], [], [], [], [], []];

    scheduleList.forEach((cur: any) => {
      newSchedules[cur.dayOfTheWeek].push({
        startHour: cur.startHour,
        endHour: cur.endHour,
        location: cur.locationId,
      });
    });

    const newSchedule = {
      startHour: startHour,
      endHour: endHour,
      location: locationId,
    };

    newSchedules[dayOfTheWeek] = [...newSchedules[dayOfTheWeek], newSchedule];
    await updateAccount({ variables: { accountId: manager._id, data: { schedules: newSchedules } } });
  };

  const removeSchedule = async (schedule: any) => {
    const newSchedules: any = [[], [], [], [], [], [], []];
    scheduleList.forEach((cur: any) => {
      if (
        !(
          cur.dayOfTheWeek === schedule.dayOfTheWeek &&
          cur.startHour === schedule.startHour &&
          cur.endHour === schedule.endHour &&
          cur.locationId === schedule.locationId
        )
      ) {
        newSchedules[cur.dayOfTheWeek].push({
          startHour: cur.startHour,
          endHour: cur.endHour,
          location: cur.locationId,
        });
      }
    });
    await updateAccount({ variables: { accountId: manager._id, data: { schedules: newSchedules } } });
  };

  const sortList = (a: any, b: any) => {
    const aKey = dayOfTheWeekSelectItem.find((cur) => cur.value === a.dayOfTheWeek)?.key || -1;
    const bKey = dayOfTheWeekSelectItem.find((cur) => cur.value === b.dayOfTheWeek)?.key || -1;
    if (aKey > bKey) {
      return 1;
    } else if (aKey < bKey) {
      return -1;
    }
    if (a.startHour > b.startHour) {
      return 1;
    } else {
      return -1;
    }
  };

  return (
    <Grid>
      <Grid.Column mobile={16} computer={5}>
        <Form onSubmit={onSubmit}>
          <InputWrap>
            <label>장소</label>
            <Select
              value={locationId}
              options={locations.map((location: any) => {
                return { key: location._id, text: location.kaptInfo.kaptName, value: location._id };
              })}
              onChange={(e, data: any) => setLocationId(data.value)}
            />
          </InputWrap>
          <Grid columns="equal">
            <Grid.Column>
              <InputWrap>
                <label>요일</label>
                <Select value={dayOfTheWeek} options={dayOfTheWeekSelectItem} onChange={(e, data: any) => setDayOfTheWeek(data.value)} />
              </InputWrap>
            </Grid.Column>
            <Grid.Column>
              <InputWrap>
                <label>시작시간</label>
                <Input type="hour" placeholder="0~24" value={startHour} onChange={(e) => onChangeFrom(e)} required={true} />
              </InputWrap>
            </Grid.Column>
            <Grid.Column>
              <InputWrap>
                <label>종료시간</label>
                <Input type="hour" placeholder="0~24" value={endHour} onChange={(e) => onChangeTo(e)} required={true} />
              </InputWrap>
            </Grid.Column>
          </Grid>
          <Button type="submit" icon="plus" fluid style={{ marginTop: 10 }} />
        </Form>
      </Grid.Column>
      <Grid.Column mobile={16} computer={5}>
        <Table unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>장소</Table.HeaderCell>
              <Table.HeaderCell>요일</Table.HeaderCell>
              <Table.HeaderCell>시간</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {scheduleList
              .sort((a: any, b: any) => sortList(a, b))
              .map((schedule: any, idx: number) => (
                <Table.Row key={idx}>
                  <Table.Cell>
                    <span style={{ color: locationColors ? locationColors[schedule.locationId] : "#444" }}>
                      {locations.find((location: any) => location._id === schedule.locationId).kaptInfo.kaptName}
                    </span>
                  </Table.Cell>
                  <Table.Cell>{dayOfTheWeekSelectItem.find((cur) => cur.value === schedule.dayOfTheWeek)?.text}</Table.Cell>
                  <Table.Cell>
                    {schedule.startHour}시~{schedule.endHour}시
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name="delete" onClick={() => removeSchedule(schedule)} />
                  </Table.Cell>
                </Table.Row>
              ))}

            {!scheduleList.length && (
              <Table.Cell colSpan="4">
                <EmptyMessage>Empty</EmptyMessage>
              </Table.Cell>
            )}
          </Table.Body>
        </Table>
      </Grid.Column>
      <Grid.Column mobile={16} computer={6}>
        <SchedulePreview schedules={manager.schedules} locationColors={locationColors} />
      </Grid.Column>
    </Grid>
  );
}

const InputWrap = styled.div`
  margin-bottom: 10px;
  label {
    margin-right: 10px;
    display: block;
  }
  .ui.selection.dropdown {
    min-width: 100%;
  }
  input {
    width: 100%;
  }
`;
