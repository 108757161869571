import React from "react";
import { Item, Icon } from "semantic-ui-react";
import {useHvacItemContext} from "../useHvacItemContext";
import styled from "styled-components";

type TProps = {
  noEdit?: boolean
}

function ErvCtrl({noEdit}:TProps) {
  const  { hvac, ervCtrl, openErvCtrl, openErvCtrlSelector, setIsOpenDetail, isOpenDetail } : any = useHvacItemContext();
  
  if(noEdit){
    return (
      <Item.Meta>
       <div>{`${hvac.ervType && hvac.ervType.name} - ${hvac.board}`}</div>
        {ervCtrl ? (
          <>
            <p>ervCtrl: {ervCtrl.id}</p>
            <DetailBtn >
              <Icon size="large" name={isOpenDetail ? "caret up" : "caret down"} onClick={()=>setIsOpenDetail( (isOpen : boolean) => !isOpen)} />    
            </DetailBtn>
          </>
        ) : (
          <p>ervCtrl: 미할당</p>
        )}
      </Item.Meta>
    )
  }

  return (
    <Item.Meta>
     <div>{`${hvac.ervType && hvac.ervType.name} - ${hvac.board}`}</div>
      {ervCtrl ? (
        <>
          <ClickableMsg onClick={openErvCtrl}>ervCtrl: {ervCtrl.id}</ClickableMsg>
          <DetailBtn >
            <Icon size="large" name={isOpenDetail ? "caret up" : "caret down"} onClick={()=>setIsOpenDetail( (isOpen : boolean) => !isOpen)} />    
          </DetailBtn>
        </>  
      ) : (
        <ClickableMsg onClick={openErvCtrlSelector}>ERV Ctrl 할당하기</ClickableMsg>
      )}
  </Item.Meta>
  )
}

const ClickableMsg = styled.div`
  display: inline-block;
  margin-top: 8px !important;
  color: #4183c4;
  cursor: pointer;
`;

const DetailBtn = styled.div`
  margin-top: 10px;
`;

export { ErvCtrl };
