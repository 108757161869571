import React, { useCallback, useRef } from "react";
import { useInput } from "@util";
import { useHistory, useLocation } from "react-router-dom";
import { Input, Form, Button } from "semantic-ui-react";
import { toastError } from "@util";
import AuthLayout from "@Layouts/AuthLayout";
import { Helmet } from "react-helmet";
import { SIGNIN } from "@graphql/mutation";
import { useMutation } from "@apollo/react-hooks";

function Login() {
  const [id, onChangeId, setId] = useInput("");
  const [password, onChangePassword, setPassword] = useInput("");
  const idInput = useRef<Input>(null);
  const history = useHistory();
  const location = useLocation();
  const { from }: any = location.state || { from: { pathname: "/" } };

  const [signin] = useMutation(SIGNIN, {
    onCompleted(data) {
      localStorage.setItem("currentUser", data.signin.accessToken);
      history.replace(from);
    },
    onError(error) {
      initInput();
      toastError(`로그인을 실패하였습니다`, error.message);
    },
  });

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (localStorage.getItem("currentUser")) localStorage.removeItem("currentUser");
      signin({ variables: { id, password } });
    },
    [id, password]
  );

  const initInput = () => {
    setId("");
    setPassword("");
    if (idInput.current) idInput.current.focus();
  };

  return (
    <AuthLayout>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <h2>Login</h2>

      <Form onSubmit={onSubmit}>
        <Form.Field>
          <label>ID</label>
          <Input type="text" placeholder="ID" value={id} onChange={onChangeId} ref={idInput} required />
        </Form.Field>

        <Form.Field>
          <label>Password</label>
          <Input type="password" placeholder="password" value={password} onChange={onChangePassword} required />
        </Form.Field>

        <Button type="submit">Login</Button>

        <a href="/signup">Register Now</a>
      </Form>
    </AuthLayout>
  );
}

export default Login;
