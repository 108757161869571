import React, { useState } from "react";
import { ModalCloseButton, TierOptionSegment } from "@styles/common";
import { Modal, Icon, Radio, Grid, Form, Button } from "semantic-ui-react";
import { INIT_CHANGE_SERVICE_TIER } from "@graphql/query";
import { useQuery } from "@apollo/react-hooks";
import { IServiceTier } from "@interfaces";

type TChangeServiceTier = {
  setShowModal: Function;
  currentTierId: string;
  type: string;
  onSubmit: Function;
};

function ChangeServiceTier({ setShowModal, currentTierId, type, onSubmit }: TChangeServiceTier) {
  const [tiers, setTiers] = useState<IServiceTier[]>([]);
  const [selectedTierId, setSelectedTierId] = useState<string>();
  // const [submitLoading, setSubmitLoading] = useState(false);
  // const [showOptNow, setShowOptNow] = useState(false);

  // serviceTier 조회
  const { loading, data, error } = useQuery<{ serviceTiers: IServiceTier[] }, null>(INIT_CHANGE_SERVICE_TIER, {
    onCompleted(data) {
      setTiers(data.serviceTiers.filter((tier: IServiceTier) => tier.type === type));
    },
  });

  // const onClickTier = (tierId) => {
  //   setSelectedTierId(tierId);
  //   const currentPrice = tiers.find((tier) => tier._id === currentTierId).price;
  //   const selectedPrice = tiers.find((tier) => tier._id === tierId).price;
  //   if (selectedPrice > currentPrice) {
  //     setShowOptNow(true);
  //   } else {
  //     setShowOptNow(false);
  //   }
  // };

  if (loading || error) return <></>;

  return (
    <>
      <ModalCloseButton onClick={() => setShowModal(false)}>
        <Icon name="close" size="large" />
      </ModalCloseButton>
      <Modal.Header>Service Tier 변경</Modal.Header>
      <Modal.Content>
        <Form onSubmit={() => onSubmit(selectedTierId)}>
          <Form.Field>
            <Grid columns="equal">
              {tiers &&
                tiers.map((tier: IServiceTier) => (
                  <Grid.Column key={tier._id}>
                    {currentTierId !== tier._id ? (
                      <TierOptionSegment onClick={() => setSelectedTierId(tier._id)} style={{ minHeight: 200 }}>
                        <Radio label={tier.name} name={tier.name} value={tier._id} checked={selectedTierId === tier._id} />
                        <div dangerouslySetInnerHTML={{ __html: tier.desc ? tier.desc : "" }}></div>
                      </TierOptionSegment>
                    ) : (
                      <TierOptionSegment style={{ minHeight: 200, color: "#c1c1c1", cursor: "default" }}>
                        <Radio label={tier.name} name={tier.name} disabled />
                        <div dangerouslySetInnerHTML={{ __html: tier.desc ? tier.desc : "" }}></div>
                      </TierOptionSegment>
                    )}
                  </Grid.Column>
                ))}
            </Grid>
          </Form.Field>

          <Button type="submit" fluid>
            {" "}
            Service Tier 변경{" "}
          </Button>
        </Form>
      </Modal.Content>
    </>
  );
}
export default ChangeServiceTier;
