import React from "react";
import moment from "moment";
import styled from "styled-components";

type TProps = {
  startDate: any,
  endDate: any,
}

function CurrentDate({startDate, endDate}: TProps) {
  return (
    <Container>
    선택구간 : {moment(startDate).format("YYYY-MM-DD")} ~ {moment(endDate).format("YYYY-MM-DD")}
    </Container>
  )
}
export { CurrentDate };

const Container = styled.div`
  color: #666666;
`
