import React, { useState } from "react";
import { Confirm, Dropdown } from "semantic-ui-react";
import styled from "styled-components";

interface CardItemActionsProps {
  canModify: boolean;
  canRemove: boolean;
  onModify: (event: React.MouseEvent) => void;
  onRemove: Function;
  onModifyState?: (event: React.MouseEvent) => void;
}

function CardItemActions({ canModify, canRemove, onModify, onRemove, onModifyState }: CardItemActionsProps) {
  const [confirmOpen, setConfirmOpen] = useState(false);

  return (
    <>
      <StyledDropdown icon="setting" direction="left">
        <Dropdown.Menu>
          <Dropdown.Item text="Modify" icon="edit" disabled={!canModify} onClick={onModify} />
          {onModifyState && <Dropdown.Item text="state변경" icon="edit" onClick={onModifyState} />}
          <Dropdown.Item text="Delete" icon="trash" disabled={!canRemove} onClick={() => setConfirmOpen(true)} />
        </Dropdown.Menu>
      </StyledDropdown>
      <Confirm
        open={confirmOpen}
        header={`삭제`}
        content={`정말로 삭제하시겠습니까?`}
        cancelButton="취소"
        confirmButton={{
          content: "삭제",
          negative: true,
        }}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => {
          onRemove();
          setConfirmOpen(false);
        }}
      />
    </>
  );
}

export default CardItemActions;

const StyledDropdown = styled(Dropdown)`
  position: absolute !important;
  top: 10px;
  right: 10px;
  color: rgba(0, 0, 0, 0.68);
`;
