import React from "react";
import {useHvacItemContext} from "../useHvacItemContext";
import { Icon } from "semantic-ui-react";
import type { IErvCtrl } from "@interfaces";
import styled from "styled-components";
import { bufferJsonToHexString } from "@util";
import StatusLabel from "@Components/Common/StatusLabel";

function Detail() {
  const  { ervCtrl, isOpenDetail } : {ervCtrl: IErvCtrl, isOpenDetail: boolean, setIsOpenDetail: any} = useHvacItemContext();

  if(!isOpenDetail) return null;
  
  return (
  <Container>
      <div style={{marginBottom:20}}>
        <Label><Icon name="toggle on" />Setting</Label>
          <StatusLabel bool={ervCtrl?.autoControlOn || false} text="Auto Mode" />
          {!ervCtrl?.autoControlOn && ervCtrl?.action?.manualControl && (
            <StatusLabel bool={ervCtrl?.action?.manualControl.controlOn} text="Control ON" />
          )}
          {!ervCtrl?.autoControlOn && ervCtrl?.action?.manualControl?.controlOn && ervCtrl?.controlSettings?.controlType?.inOutReversed && (
            <StatusLabel bool={true} text="Reversed" />
          )}
        <div style={{marginLeft:20}}>
          {!ervCtrl?.autoControlOn && ervCtrl?.action?.manualControl && ervCtrl?.action?.manualControl.controlOn && ervCtrl?.controlSettings?.controlType?.vspMode && (
            <div>
              <Label>vspIn : </Label>{ervCtrl?.action?.manualControl?.vspIn || 0}<br />
              <Label>vspOut : </Label>{ervCtrl?.action?.manualControl?.vspOut || 0}<br />
            </div>
          )}
          {!ervCtrl?.autoControlOn && ervCtrl?.action?.manualControl && ervCtrl?.action?.manualControl.controlOn && !ervCtrl?.controlSettings?.controlType?.vspMode && (
            <div>
              <Label>ventLevel : </Label>{ervCtrl?.action?.manualControl?.ventLevel || 0}<br />
            </div>
          )}
        </div>
      </div>
      <div style={{marginBottom:10}}>
      <Icon name="connectdevelop" />Network
        <div style={{marginLeft:20}}>
          <Label><Icon name="wifi" />Wi-Fi :  </Label>
          {`${ervCtrl?.controlSettings?.network?.wifis[0] && ervCtrl.controlSettings.network.wifis[0].ssid}/••••••••`}
          <br />
          <Label><Icon name="server" />Host : </Label>
          {ervCtrl?.controlSettings?.network?.hosts[0] && ervCtrl.controlSettings.network.hosts[0]}
        </div>
      </div>
      <div style={{marginBottom:10}}>
      <Label><Icon name="configure" />Control Type : </Label>
        {`mode: ${ervCtrl?.controlSettings?.controlType?.vspMode ? "pro" : "nest"}, signal: ${
          ervCtrl?.controlSettings?.controlType?.isPeriodic ? "periodic" : "non-periodic"
        }, Stop Bit: ${ervCtrl?.controlSettings?.controlType?.stopBit}`}
      </div>
      <div style={{marginBottom:10}}>
        <Icon name="random" />Control Packets
        <div style={{marginLeft:20}}>
          {ervCtrl?.controlSettings?.controlType?.isOnPacket &&
            <>
              <Label><Icon name="configure" />Signal - on : </Label>
              {bufferJsonToHexString(ervCtrl?.controlSettings?.controlPacket?.onPacket.request)}
              <br />
            </>
          }
          <Label><Icon name="bolt" />Signal - off  : </Label>
          {bufferJsonToHexString(ervCtrl?.controlSettings?.controlPacket?.offPacket.request)}
          <br />
          <Label><Icon name="bolt" />Signal - Low  : </Label>
          {bufferJsonToHexString(ervCtrl?.controlSettings?.controlPacket?.lowPacket.request)}
          <br />
          <Label><Icon name="bolt" />Signal - Mid  : </Label>
          {bufferJsonToHexString(ervCtrl?.controlSettings?.controlPacket?.midPacket.request)}
          <br />
          <Label><Icon name="bolt" />Signal - High  : </Label>
          {bufferJsonToHexString(ervCtrl?.controlSettings?.controlPacket?.highPacket.request)}
        </div>  
      </div>
      
  </Container>
  )
}
export { Detail };

const Container = styled.div`
  margin-top: 10px;
  background-color: #858377 ; 
  border-radius: 10px;
  color:#E8E8DB; 
  padding: 10px;
`;

const Label = styled.span`
  margin-right: 10px;
`;
