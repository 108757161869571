import React, { useContext } from "react";
import styled from "styled-components";
import { Link, NavLink, useHistory } from "react-router-dom";
import { WideContainer } from "@styles/common";
import { Dropdown } from "semantic-ui-react";
import SelfContext from "@selfContext";
import {} from "@src/util";

function Header() {
  const { self } = useContext(SelfContext);
  const history = useHistory();

  const logout = () => {
    localStorage.removeItem("currentUser");
    history.push("/login");
  };

  return (
    <StyledHeader>
      <WideContainer>
        <Title>
          <Link to="/">
            <h1>Puffin Planet</h1>
          </Link>
        </Title>
        <Nav>
          <NavItem exact to="/" isActive={checkRootPath}>
            Home
          </NavItem>
          {self && self.role === "superAdmin" && <NavItem to="/locations">Nests</NavItem>}
          {self && self.role === "superAdmin" && <NavItem to="/farms">Farms</NavItem>}
          {self && self.role === "superAdmin" && <NavItem to="/filters">Feathers</NavItem>}
          {self && self.role === "superAdmin" && (
            <Dropdown text={"Reports"} style={{ marginRight: 20 }}>
              <Dropdown.Menu style={{ right: 0, left: "auto" }}>
                <Dropdown.Item text="Weekly Reports" as={Link} to="/reports/weekly" />
                <Dropdown.Item text="Daily Reports" as={Link} to="/reports/daily" />
              </Dropdown.Menu>
            </Dropdown>
          )}

          {self && (
            <Dropdown text={self.role === "userAccount" ? self.user.name : self.name} icon="user" labeled button className="icon">
              <Dropdown.Menu>
                <Dropdown.Header content={self.role} />
                <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Nav>
      </WideContainer>
    </StyledHeader>
  );
}

export default Header;

const checkRootPath = (match: any, location: any) => {
  if (location.pathname === "/" || location.pathname.indexOf("/org") != -1) {
    return true;
  } else {
    return false;
  }
};

const StyledHeader = styled.header`
  background: white;
  margin-bottom: 1rem;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  min-height: 96px;
  .container {
    position: relative;
    padding: 10px;
  }
`;

const Title = styled.div`
  font-weight: 400;
  padding-top: 20px;
  a {
    display: inline-block;
  }
`;

const Nav = styled.nav`
  position: absolute;
  top: 30px;
  right: 10px;
  @media only screen and (max-width: 720px) {
    position: relative;
    top: 0;
    right: 0;
  }
`;

const NavItem = styled(NavLink)`
  font-size: 14px;
  color: #222;
  margin-right: 25px;
  font-weight: 400;

  &.active {
    font-weight: bold;
  }
`;
