import React, { useState } from "react";
import moment from "moment";
import "moment/locale/ko";
// import "@styles/weekltReportStyle.css";
import { INIT_DAILY_REPORT_PAGE } from "@graphql/query";
import { useQuery } from "@apollo/react-hooks";
import { toastError } from "@util";
import { Helmet } from "react-helmet";
import { IUser, IFloorLog } from "@interfaces";
import styled from "styled-components";

export type TDailyReportStatus = "co2_good_1" | "co2_good_2" | "co2_good_3" | "voc_bad" | "pm25_bad" | "pm25_good";
export type TDailyReportAreaProps = {
  userId: string;
  floorId: string;
  status: TDailyReportStatus;
  dDayNum?: string;
};

function DailyReportArea({ userId, floorId, status, dDayNum }: TDailyReportAreaProps) {
  const [addr, SetAddr] = useState("");

  const { loading, data } = useQuery<{ user: IUser; latestDailyFloorLog: IFloorLog }, { userId: string; floorId: string }>(INIT_DAILY_REPORT_PAGE, {
    variables: { userId: userId, floorId: floorId },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (!data.latestDailyFloorLog || !data.user) return;
      const addr = data.user?.floors?.find((floor) => floor._id === floorId)?.name;
      addr && SetAddr(addr);
    },
    onError(error) {
      console.error(error);
      toastError("조회 실패", error.message);
    },
  });

  const getBgUrl = (status: TDailyReportStatus) => {
    return `/dailyReport/${status}.png`;
  };

  const getTreeNum = (summary: any, maxVentLevel: any): string => {
    if (!summary || !summary.avgVentLevel || !summary.eqTreeNum || !maxVentLevel) return "";
    return ((maxVentLevel / summary.avgVentLevel) * summary.eqTreeNum).toFixed(1);
  };

  if (!data) return null;
  if (!data.latestDailyFloorLog) return <>latestDailyFloorLog 데이터 없음</>;

  return (
    <>
      <Helmet>
        <title>DailyReport</title>
      </Helmet>
      {/* <Desc>
        {data.user.name}({addr}) 님의 데일리 리포트
        <br />
        (데이터 수집기간 : {moment(data.latestDailyFloorLog.from).format("YYYY-MM-DD, a h:mm")} ~{" "}
        {moment(data.latestDailyFloorLog.to).format("YYYY-MM-DD, a h:mm")} )
      </Desc> */}
      {status.indexOf("co2_good") !== -1 && (
        <Co2GoodPage
          dDayNum={dDayNum}
          userName={data.user.name}
          bgUrl={getBgUrl(status)}
          treeNum={getTreeNum(data.latestDailyFloorLog?.summary, data.latestDailyFloorLog?.highlight?.co2Sleep?.maxVentLevel)}
          // treeNum={data.latestDailyFloorLog?.summary?.eqTreeNum?.toFixed(2) || "-"}
          maxCo2Val={data.latestDailyFloorLog?.highlight?.co2Sleep?.value?.toFixed(2) || "-"}
          maxVentLevel={data.latestDailyFloorLog?.highlight?.co2Sleep?.maxVentLevel?.toFixed(0) || "-"}
        />
      )}
      {status === "voc_bad" && <VocBadPage dDayNum={dDayNum} userName={data.user.name} bgUrl={getBgUrl(status)} />}
      {status === "pm25_bad" && (
        <Pm25BadPage
          dDayNum={dDayNum}
          userName={data.user.name}
          bgUrl={getBgUrl(status)}
          forecastPm25={data.latestDailyFloorLog?.forecast?.pm25?.toFixed(0) || "-"}
        />
      )}
      {status === "pm25_good" && (
        <Pm25GoodPage
          dDayNum={dDayNum}
          userName={data.user.name}
          bgUrl={getBgUrl(status)}
          forecastPm25={data.latestDailyFloorLog?.forecast?.pm25?.toFixed(0) || "-"}
        />
      )}
    </>
  );
}

export default DailyReportArea;

function PageHeader() {
  return (
    <Header>
      <h1>TODAY</h1>
      <p>{moment().format("M월 D일")}</p>
    </Header>
  );
}

function Co2GoodPage({
  dDayNum,
  userName,
  bgUrl,
  treeNum,
  maxCo2Val,
  maxVentLevel,
}: {
  dDayNum?: string;
  userName: string;
  bgUrl: string;
  treeNum: string;
  maxCo2Val: string;
  maxVentLevel: string;
}) {
  if ((!dDayNum && (!treeNum || !maxCo2Val || !maxVentLevel)) || (dDayNum && !maxCo2Val)) return null;

  return (
    <Page bgUrl={bgUrl} id="page">
      <PageHeader />
      <PageBody>
        <span className="small">취침 시간 동안</span>
        <br />
        이산화탄소가
        <br />
        <span className="color-orange">{maxCo2Val}ppm</span> 까지 쌓였네요.
        <br />
        <br />
        <PaidUserArea>
          <div className={dDayNum ? "blur-area" : ""}>
            지난 밤,
            <br />
            퍼핀이 환기량을 <span className="color-green">{maxVentLevel}%</span>로 증가시켰어요.
            <br />
            <span className="color-green">소나무 {treeNum}그루</span> 심은 것과
            <br />
            동일한 효과예요!
          </div>
          {dDayNum && (
            <ConsultingMsg>
              자율환기 시작까지
              <br />
              <b>D-{dDayNum}</b>
              <p>
                * 공기질을 학습해 {userName}님만의
                <br />
                인공지능을 만들고 있어요!
              </p>
            </ConsultingMsg>
          )}
        </PaidUserArea>
      </PageBody>
    </Page>
  );
}

function Pm25BadPage({ dDayNum, userName, bgUrl, forecastPm25 }: { dDayNum?: string; userName: string; bgUrl: string; forecastPm25: string }) {
  if (!forecastPm25) return null;

  return (
    <Page bgUrl={bgUrl} id="page">
      <PageHeader />
      <PageBody>
        오늘의 초미세먼지는
        <br />
        <span className="color-orange">매우 나쁨 ({forecastPm25}µg/m³)</span>
        <br />
        <br />
        <PaidUserArea>
          <div className={dDayNum ? "blur-area" : ""}>
            집 안의 공기는 퍼핀이 지키고 있을게요.
            <br />
            외출 시 마스크를 꼭 챙겨주세요. 😷
          </div>
          {dDayNum && (
            <ConsultingMsg className="pm25Msg">
              자율환기 시작까지
              <br />
              <b>D-{dDayNum}</b>
              <p>
                * 공기질을 학습해 {userName}님만의
                <br />
                인공지능을 만들고 있어요!
              </p>
            </ConsultingMsg>
          )}
        </PaidUserArea>
      </PageBody>
    </Page>
  );
}

function Pm25GoodPage({ dDayNum, userName, bgUrl, forecastPm25 }: { dDayNum?: string; userName: string; bgUrl: string; forecastPm25: string }) {
  if (!forecastPm25) return null;

  return (
    <Page bgUrl={bgUrl} id="page">
      <PageHeader />
      <PageBody>
        오늘의 초미세먼지는
        <br />
        <span className="color-green">매우 좋음 ({forecastPm25}µg/m³)</span>
        <br />
        <br />
        <PaidUserArea>
          <div className={dDayNum ? "blur-area" : ""}>
            창문을 열고 환기하면
            <br />
            공기질 관리 효과가 극대화됩니다.
            <br />
            퍼핀은 에너지 효율을 위해
            <br />
            잠시 쉬고 있을게요! 😇
          </div>
          {dDayNum && (
            <ConsultingMsg>
              자율환기 시작까지
              <br />
              <b>D-{dDayNum}</b>
              <p>
                * 공기질을 학습해 {userName}님만의
                <br />
                인공지능을 만들고 있어요!
              </p>
            </ConsultingMsg>
          )}
        </PaidUserArea>
      </PageBody>
    </Page>
  );
}

function VocBadPage({ dDayNum, userName, bgUrl }: { dDayNum?: string; userName: string; bgUrl: string }) {
  return (
    <Page bgUrl={bgUrl} id="page">
      <PageHeader />
      <PageBody>
        <span className="small">어제 하루 동안</span>
        <br />
        <span className="color-orange">화학물질</span>이 급격히 쌓였어요.
        <br />
        <br />
        <PaidUserArea>
          <div className={dDayNum ? "blur-area" : ""}>
            새 가구를 들이셨거나
            <br />
            화학 용품을 과다하게 사용하셨나요?
            <br />
            <br />
            퍼핀이 당분간 풀가동하며
            <br />
            빠르게 내보낼게요!
          </div>
          {dDayNum && (
            <ConsultingMsg>
              자율환기 시작까지
              <br />
              <b>D-{dDayNum}</b>
              <p>
                * 공기질을 학습해 {userName}님만의
                <br />
                인공지능을 만들고 있어요!
              </p>
            </ConsultingMsg>
          )}
        </PaidUserArea>
      </PageBody>
    </Page>
  );
}

const Desc = styled.div`
  font-size: 10px;
  line-height: 1.2em;
  margin-bottom: 20px;
`;

const Page = styled.div<{ bgUrl: any }>`
  background-image: url(${(props) => props.bgUrl});
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  padding: 14px 13px;
  /* max-width: 600px; */
  width: 270px;
  height: 400px;
  margin: 0 auto;
  border: 1px solid #aeaeae;
  border-radius: 13px;
  transform-origin: top center;
  letter-spacing: -1px;
  transform: scale(1.8);
`;

const Header = styled.div`
  border-bottom: 0.1px solid #959595;
  overflow: hidden;
  h1 {
    color: #03c4c3;
    font-size: 18px;
    line-height: 26.06px;
    font-weight: 900;
    float: left;
    margin-left: 4px;
    margin-bottom: 0;
  }
  p {
    font-weight: 300;
    color: #737373;
    float: right;
    font-size: 9px;
    margin-top: 10px;
  }
`;

const PageBody = styled.div`
  margin: 32px 3px 6px 3px;
  color: #6f6f6f;
  font-size: 14px;

  .color-green {
    color: #03c4c3;
  }

  .color-orange {
    color: #ff9950;
  }

  .biggest {
    font-size: 18px;
  }

  .bigger {
    font-size: 16px;
  }

  .small {
    font-size: 10px;
  }
`;

const PaidUserArea = styled.div`
  position: relative;
  .blur-area {
    filter: blur(6px);
    -webkit-filter: blur(6px);
  }
`;

const ConsultingMsg = styled.div`
  position: absolute;
  top: 80%;
  left: 50%;
  min-width: 200px;
  transform: translate(-50%, -50%);
  text-align: center;
  b {
    line-height: 1.4;
    font-size: 24px;
  }
  p {
    margin-top: 20px;
    font-size: 12px;
  }
  &.pm25Msg {
    top: 100%;
    p {
      margin-top: 6px;
    }
  }
`;
