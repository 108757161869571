import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { Grid, TextArea, Form, Button, Segment, Input, Dropdown } from "semantic-ui-react";
import { useReactToPrint } from "react-to-print";
import { useInput } from "@util";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";
import ConsultingPrintArea from "./ConsultingPrintArea";
import { toastError } from "@util";

function ConsultingItem({ section, startDate, endDate, handleStartDateChange, handleEndDateChange, name }) {
  const [graphData, setGraphData] = useState(null);
  const [averageData, setAverageData] = useState(null);
  const [minMaxHourData, setMinMaxHourData] = useState(null);
  const [minMaxData, setMinMaxData] = useState(null);
  const [dateList, setDateList] = useState(null);
  const [generalComment, onChenageGeneralComment] = useInput(
    "주요한 오염물질은 이산화탄소로서 특히 수면시간에 상당량 증가합니다. 높은 농도의 이산화탄소는 깊은 수면에 방해가 되니 적절한 환기가 필요해 보입니다. 그리고 해당 지역에 고속도로와 재개발 상황으로부터 미세먼지 유입이 우려됩니다. 가정 내에 설치되어 있는 환기장치(전열교환기)를 활용하시는 것을 추천 드립니다."
  );
  const [generalTag1, onChenageGeneralTag1] = useInput("수면 방해");
  const [generalTag2, onChenageGeneralTag2] = useInput("이산화탄소 농도");
  const [generalTag3, onChenageGeneralTag3] = useInput("고속도로 인근");
  const [generalTag4, onChenageGeneralTag4] = useInput("재개발 공사");
  const [minDesc, onChenageMinDesc] = useInput("공기질 점수 최저점을 기록하였습니다. 구체적으로 어떤 위협요소가 있었는지 살펴보겠습니다.");
  const [focusedStart, setFocusedStart] = useState(false);
  const [focusedEnd, setFocusedEnd] = useState(false);
  const [reviewerKey, setReviewerKey] = useState(0);

  const reportAreaRef = useRef();
  const pieChartLabels = ["좋음", "미흡", "나쁨", "미측정"];
  const reviewerNames = ["선강민", "김초은", "고석현", "김범준"];

  useEffect(() => {
    try {
      const awairName = section.name;
      const aqiDatas = section.indoorAtmLogsByDate.measurements;

      let awairScores = [];

      // 시간별 평균
      const hourlyChartDatas = Array.from(
        { length: 24 },
        () =>
          new Object({
            sumAwairScore: 0,
            sumTemp: 0,
            sumHumid: 0,
            sumCo2: 0,
            sumVoc: 0,
            sumPm25: 0,
            countAwairScore: 0,
            countTemp: 0,
            countHumid: 0,
            countCo2: 0,
            countVoc: 0,
            countPm25: 0,
          })
      );

      // 전체기간중 요소별 평균
      const totalChartData = {
        sumAwairScore: 0,
        sumTemp: 0,
        sumHumid: 0,
        sumCo2: 0,
        sumVoc: 0,
        sumPm25: 0,
        countAwairScore: 0,
        countTemp: 0,
        countHumid: 0,
        countCo2: 0,
        countVoc: 0,
        countPm25: 0,
      };

      // 시간대별 평균중 최대, 최소값
      const hourlyMinMaxData = {
        ico2: {
          hour: 0,
          maxValue: 0,
          minValue: Number.MAX_SAFE_INTEGER,
        },
        ivoc: {
          hour: 0,
          maxValue: 0,
          minValue: Number.MAX_SAFE_INTEGER,
        },
        ipm25: {
          hour: 0,
          maxValue: 0,
          minValue: Number.MAX_SAFE_INTEGER,
        },
      };

      const hourlyChartScoreDatas = [];
      const hourlyChartTempDatas = [];
      const hourlyChartHumidDatas = [];
      const hourlyChartCo2Datas = [];
      const hourlyChartVocDatas = [];
      const hourlyChartPm25Datas = [];

      const hourlyAwairScoreObj = {};
      const dailyDatasObj = {};
      let dailyDateArr = [];

      aqiDatas.forEach((item, i) => {
        const ts = moment(item.logtime).valueOf();

        awairScores.push([ts, item.puffinScore && item.puffinScore.indoorPollutant]);

        const logDateStr = moment(item.logtime).format("YYYY. MM. DD");

        if (dailyDateArr[dailyDateArr.length - 1] !== logDateStr) {
          dailyDateArr.push(logDateStr);
        }

        if (!dailyDatasObj[logDateStr]) {
          dailyDatasObj[logDateStr] = {
            sumAwairScore: 0,
            sumCo2: 0,
            sumVoc: 0,
            countAwairScore: 0,
            countCo2: 0,
            countVoc: 0,
          };
        }

        // 시간대별 평균 계산을 위한 데이터 가공
        const hourVal = Number(moment(item.logtime).format("HH"));

        if (item.puffinScore && item.puffinScore.indoorPollutant) {
          hourlyChartDatas[hourVal].sumAwairScore += item.puffinScore.indoorPollutant;
          hourlyChartDatas[hourVal].countAwairScore += 1;
          // 원형그래프용 데이터 가공
          if (!hourlyAwairScoreObj[moment(item.logtime).diff(startDate, "hours")]) {
            hourlyAwairScoreObj[moment(item.logtime).diff(startDate, "hours")] = {
              sum: 0,
              count: 0,
            };
          }
          hourlyAwairScoreObj[moment(item.logtime).diff(startDate, "hours")]["sum"] += item.puffinScore.indoorPollutant;
          hourlyAwairScoreObj[moment(item.logtime).diff(startDate, "hours")]["count"] += 1;

          // 일자 별 공기 상태용 데이터
          dailyDatasObj[logDateStr].sumAwairScore += item.puffinScore.indoorPollutant;
          dailyDatasObj[logDateStr].countAwairScore += 1;
        }
        if (item.temp) {
          hourlyChartDatas[hourVal].sumTemp += item.temp;
          hourlyChartDatas[hourVal].countTemp += 1;
        }
        if (item.humid) {
          hourlyChartDatas[hourVal].sumHumid += item.humid;
          hourlyChartDatas[hourVal].countHumid += 1;
        }
        if (item.co2) {
          hourlyChartDatas[hourVal].sumCo2 += item.co2;
          hourlyChartDatas[hourVal].countCo2 += 1;
          // 일자 별 공기 상태용 데이터
          dailyDatasObj[logDateStr].sumCo2 += item.co2;
          dailyDatasObj[logDateStr].countCo2 += 1;
        }
        if (item.voc) {
          hourlyChartDatas[hourVal].sumVoc += item.voc;
          hourlyChartDatas[hourVal].countVoc += 1;
          // 일자 별 공기 상태용 데이터
          dailyDatasObj[logDateStr].sumVoc += item.voc;
          dailyDatasObj[logDateStr].countVoc += 1;
        }
        if (item.pm25) {
          hourlyChartDatas[hourVal].sumPm25 += item.pm25;
          hourlyChartDatas[hourVal].countPm25 += 1;
        }
      });

      setDateList(dailyDateArr);
      const pieChartDatas = [
        [pieChartLabels[0], 0],
        [pieChartLabels[1], 0],
        [pieChartLabels[2], 0],
        [pieChartLabels[3], 0],
      ]; // "좋음", "미흡", "나쁨", "미측정"

      // 원형 그래프를 위한 데이터 가공
      // for (let i = 0; i < 336; i++) {
      for (let i = 0; i <= moment(endDate).diff(startDate, "hours"); i++) {
        if (!hourlyAwairScoreObj[i]) {
          pieChartDatas[3][1]++;
          continue;
        }
        const average = hourlyAwairScoreObj[i]["sum"] / hourlyAwairScoreObj[i]["count"];
        let index;
        if (average >= 80) {
          index = 0;
        } else if (average >= 60) {
          index = 1;
        } else {
          index = 2;
        }
        pieChartDatas[index][1]++;
      }

      const dailyChartDatas = [];
      const minMaxDatas = { min: { date: null, awairScore: 100, ico2: 0, ivoc: 0 }, max: { date: null, awairScore: 0, ico2: 0, ivoc: 0 } };

      for (const [key, value] of Object.entries(dailyDatasObj)) {
        const averageScore = value.sumAwairScore / value.countAwairScore;
        dailyChartDatas.push([key, averageScore]);
        if (minMaxDatas.min.awairScore > averageScore) {
          minMaxDatas.min.awairScore = averageScore;
          minMaxDatas.min.ico2 = value.sumCo2 / value.countCo2;
          minMaxDatas.min.ivoc = value.sumVoc / value.countVoc;
          minMaxDatas.min.date = key;
        }
        if (minMaxDatas.max.awairScore < averageScore) {
          minMaxDatas.max.awairScore = averageScore;
          minMaxDatas.max.ico2 = value.sumCo2 / value.countCo2;
          minMaxDatas.max.ivoc = value.sumVoc / value.countVoc;
          minMaxDatas.max.date = key;
        }
      }

      setMinMaxData(minMaxDatas);

      hourlyChartDatas.forEach((item, index) => {
        // 그래프용 데이터 가공
        hourlyChartScoreDatas.push([`${index}h~${index + 1}h`, item.countAwairScore ? item.sumAwairScore / item.countAwairScore : 0]);
        hourlyChartTempDatas.push([`${index}h~${index + 1}h`, item.countTemp ? item.sumTemp / item.countTemp : 0]);
        hourlyChartHumidDatas.push([`${index}h~${index + 1}h`, item.countHumid ? item.sumHumid / item.countHumid : 0]);
        hourlyChartCo2Datas.push([`${index}h~${index + 1}h`, item.countCo2 ? item.sumCo2 / item.countCo2 : 0]);
        hourlyChartVocDatas.push([`${index}h~${index + 1}h`, item.countVoc ? item.sumVoc / item.countVoc : 0]);
        hourlyChartPm25Datas.push([`${index}h~${index + 1}h`, item.countPm25 ? item.sumPm25 / item.countPm25 : 0]);
        // 전체기간중 요소별 평균 계산을 위한 데이터 가공
        totalChartData.sumAwairScore += item.sumAwairScore;
        totalChartData.sumTemp += item.sumTemp;
        totalChartData.sumHumid += item.sumHumid;
        totalChartData.sumCo2 += item.sumCo2;
        totalChartData.sumVoc += item.sumVoc;
        totalChartData.sumPm25 += item.sumPm25;
        totalChartData.countAwairScore += item.countAwairScore;
        totalChartData.countTemp += item.countTemp;
        totalChartData.countHumid += item.countHumid;
        totalChartData.countCo2 += item.countCo2;
        totalChartData.countVoc += item.countVoc;
        totalChartData.countPm25 += item.countPm25;
        // 최대값 시간
        if (hourlyMinMaxData.ico2.maxValue < item.sumCo2 / item.countCo2) {
          hourlyMinMaxData.ico2.maxValue = item.sumCo2 / item.countCo2;
          hourlyMinMaxData.ico2.hour = index;
        }
        if (hourlyMinMaxData.ivoc.maxValue < item.sumVoc / item.countVoc) {
          hourlyMinMaxData.ivoc.maxValue = item.sumVoc / item.countVoc;
          hourlyMinMaxData.ivoc.hour = index;
        }
        if (hourlyMinMaxData.ipm25.maxValue < item.sumPm25 / item.countPm25) {
          hourlyMinMaxData.ipm25.maxValue = item.sumPm25 / item.countPm25;
          hourlyMinMaxData.ipm25.hour = index;
        }
        // 최소값 시간
        if (hourlyMinMaxData.ico2.minValue > item.sumCo2 / item.countCo2) {
          hourlyMinMaxData.ico2.minValue = item.sumCo2 / item.countCo2;
        }
        if (hourlyMinMaxData.ivoc.minValue > item.sumVoc / item.countVoc) {
          hourlyMinMaxData.ivoc.minValue = item.sumVoc / item.countVoc;
        }
        if (hourlyMinMaxData.ipm25.minValue > item.sumPm25 / item.countPm25) {
          hourlyMinMaxData.ipm25.minValue = item.sumPm25 / item.countPm25;
        }
      });
      setMinMaxHourData(hourlyMinMaxData);

      setAverageData({
        awairScore: totalChartData.sumAwairScore / totalChartData.countAwairScore,
        itemp: totalChartData.sumTemp / totalChartData.countTemp,
        ihumid: totalChartData.sumHumid / totalChartData.countHumid,
        ico2: totalChartData.sumCo2 / totalChartData.countCo2,
        ivoc: totalChartData.sumVoc / totalChartData.countVoc,
        ipm25: totalChartData.sumPm25 / totalChartData.countPm25,
      });

      // 그래프용 데이터
      const datas = {
        awairScore: [{ name: awairName, data: awairScores }],

        hourlyAwairScore: [{ name: "시간별 평균 어웨어점수", data: hourlyChartScoreDatas }],
        hourlyTempAndHumid: [
          { name: "시간별 평균 온도", data: hourlyChartTempDatas, yAxis: 0 },
          { name: "시간별 평균 습도", data: hourlyChartHumidDatas, yAxis: 1 },
        ],
        hourlyCo2: [{ name: "시간별 평균 이산화탄소", data: hourlyChartCo2Datas }],
        hourlyVoc: [{ name: "시간별 평균 화학물질", data: hourlyChartVocDatas }],
        hourlyPm25: [{ name: "시간별 평균 미세먼지", data: hourlyChartPm25Datas }],
        allHourlyAwairScore: [{ name: "기간 내 공기 상태 평가", data: pieChartDatas }],
        dailyAwairScore: [{ name: "일자 별 공기 상태", data: dailyChartDatas }],
      };

      setGraphData(datas);
    } catch (e) {
      console.error(e);
      toastError("조회 실패", e.message);
    }
  }, []);

  const handlePrint = useReactToPrint({
    content: () => reportAreaRef.current,
  });

  if (!graphData || !averageData || !startDate) return null;

  return (
    <>
      <Segment>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Grid>
            <Grid.Column width={8}>
              <SingleDatePicker
                displayFormat="YYYY. MM. DD"
                date={startDate}
                focused={focusedStart}
                onFocusChange={({ focused }) => setFocusedStart(focused)}
                // isOutsideRange={(date) => {
                //   return date > moment().subtract(15, "days").endOf("day") ? true : false;
                // }}
                isOutsideRange={(date) => {
                  return date >= moment() || date >= moment(endDate) ? true : false;
                }}
                onDateChange={handleStartDateChange}
              />{" "}
              ~{" "}
              <SingleDatePicker
                displayFormat="YYYY. MM. DD"
                date={endDate}
                focused={focusedEnd}
                onFocusChange={({ focused }) => setFocusedEnd(focused)}
                // isOutsideRange={(date) => {
                //   return date > moment().subtract(1, "days").endOf("day") ? true : false;
                // }}
                isOutsideRange={(date) => {
                  return date >= moment() ? true : false;
                }}
                onDateChange={handleEndDateChange}
              />
              <div style={{ marginTop: 40 }}>
                <Button onClick={handlePrint}>Print</Button>
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <Form.Field>
                <label>종합 의견</label>
                <TextArea style={{ height: 100 }} value={generalComment} onChange={onChenageGeneralComment} />
              </Form.Field>
              <Form.Field inline>
                <label>종합 의견 - 내부요인 태그1</label>
                <Input type="text" value={generalTag1} onChange={onChenageGeneralTag1} />
              </Form.Field>
              <Form.Field inline>
                <label>종합 의견 - 내부요인 태그2</label>
                <Input type="text" value={generalTag2} onChange={onChenageGeneralTag2} />
              </Form.Field>
              <Form.Field inline>
                <label>종합 의견 - 내부요인 태그3</label>
                <Input type="text" value={generalTag3} onChange={onChenageGeneralTag3} />
              </Form.Field>
              <Form.Field inline>
                <label>종합 의견 - 내부요인 태그4</label>
                <Input type="text" value={generalTag4} onChange={onChenageGeneralTag4} />
              </Form.Field>
              <Form.Field>
                <label>최저 점수</label>
                <TextArea style={{ height: 100 }} value={minDesc} onChange={onChenageMinDesc} />
              </Form.Field>
              <Form.Field>
                <label>모니터링 담당자</label>
                <Dropdown
                  selection
                  search
                  value={reviewerKey}
                  options={reviewerNames.map((cur, index) => {
                    return { key: index, text: reviewerNames[index], value: index };
                  })}
                  onChange={(e, data) => setReviewerKey(data.value)}
                />
              </Form.Field>
            </Grid.Column>
          </Grid>
        </Form>
      </Segment>
      <ConsultingPrintArea
        name={name}
        reportAreaRef={reportAreaRef}
        startDate={startDate}
        endDate={endDate}
        averageData={averageData}
        generalTag1={generalTag1}
        generalTag2={generalTag2}
        generalTag3={generalTag3}
        generalTag4={generalTag4}
        graphData={graphData}
        minMaxData={minMaxData}
        generalComment={generalComment}
        dateList={dateList}
        minDesc={minDesc}
        minMaxHourData={minMaxHourData}
        reviewerNames={reviewerNames}
        reviewerKey={reviewerKey}
      />
    </>
  );
}
export default ConsultingItem;
