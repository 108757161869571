// import { css } from "styled-components";

// const sizes= {
//   desktop: 1230,
//   tablet: 992,
//   phone: 768,
// };

// // Iterate through the sizes and create a media template
// const media = Object.keys(sizes).reduce((acc, label) => {
//   acc[label] = (...args) => css`
//     @media (max-width: ${sizes[label] / 16}em) {
//       ${css(...args)}
//     }
//   `;
//   return acc;
// }, {});

// export default media;

// export const breakpoints = {
//   sm: 20,
//   md: 30,
//   lg: 45,
//   xl: 60,
// };

// const mediaQueries = (key: keyof typeof breakpoints) => {
//   return (style: TemplateStringsArray | String) => `@media (min-width: ${breakpoints[key]}em) { ${style} }`;
// };

export const breakpoints = {
  sm: 540,
  md: 720,
  lg: 960,
  xl: 1140,
};

const mediaQueries = (key: keyof typeof breakpoints) => {
  return (style: TemplateStringsArray | String) => `@media (max-width: ${breakpoints[key] / 16}em) { ${style} }`;
};

export default mediaQueries;
