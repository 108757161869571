import React, { useState } from "react";
import { Confirm, Dropdown } from "semantic-ui-react";
import styled from "styled-components";
import {useHvacItemContext} from "../useHvacItemContext";

function Option() {
  const  { ervCtrl, handleRemove, handleUnallocate, openHvacModal, handleReset } : any = useHvacItemContext();
 
  const [confirmOpenRemove, setConfirmOpenRemove] = useState(false);
  const [confirmOpenUnallocate, setConfirmOpenUnallocate] = useState(false);
  const [confirmOpenReset, setConfirmOpenReset] = useState(false);

  return(
    <>
      <StyledDropdown icon="setting" direction="left">
        <Dropdown.Menu>
          <Dropdown.Item text="HVAC 수정" icon="edit" onClick={openHvacModal} />
          <Dropdown.Item text="공장 초기화" icon="edit" onClick={() => setConfirmOpenReset(true)} />
          <Dropdown.Item text="Erv Ctrl 해제" icon="trash" disabled={!ervCtrl} onClick={() => setConfirmOpenUnallocate(true)} />
          <Dropdown.Item text="HVAC 삭제" icon="trash" onClick={() => setConfirmOpenRemove(true)} />
        </Dropdown.Menu>
      </StyledDropdown>
      <Confirm
        open={confirmOpenReset}
        header={`공장 초기화`}
        content={`정말로 초기화하시겠습니까?`}
        cancelButton="취소"
        confirmButton={{
          content: "초기화",
          negative: true,
        }}
        onCancel={() => setConfirmOpenReset(false)}
        onConfirm={() => {
          handleReset();
          setConfirmOpenReset(false);
        }}
      />
      <Confirm
        open={confirmOpenRemove}
        header={`삭제`}
        content={`정말로 삭제하시겠습니까?`}
        cancelButton="취소"
        confirmButton={{
          content: "삭제",
          negative: true,
        }}
        onCancel={() => setConfirmOpenRemove(false)}
        onConfirm={() => {
          handleRemove();
          setConfirmOpenRemove(false);
        }}
      />
      <Confirm
        open={confirmOpenUnallocate}
        header={`할당해제`}
        content={`선택된 Erv Ctrl를 해제하시겠습니까?`}
        cancelButton="취소"
        confirmButton={{
          content: "해제",
          negative: true,
        }}
        onCancel={() => setConfirmOpenUnallocate(false)}
        onConfirm={() => {
          handleUnallocate();
          setConfirmOpenUnallocate(false);
        }}
      />
    </>
  )
}
export { Option };

const StyledDropdown = styled(Dropdown)`
  position: absolute !important;
  top: 4px;
  right: 4px;
  color: rgba(0, 0, 0, 0.68);
`;
