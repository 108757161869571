import React, { useContext } from "react";
import { Segment, Header, Tab, Loader } from "semantic-ui-react";
import SelfContext from "@selfContext";
import DefaultLayout from "@Layouts/DefaultLayout";
import FilterTable from "@Components/Filters/FilterTable";
import { IFilter } from "@interfaces";

// import FilterPurchaseItem from "@Components/Filters/FilterPurchaseItem";

import { INIT_FEATHERS_PAGE } from "@graphql/query";
import { useQuery } from "@apollo/react-hooks";

function Filters() {
  const { self } = useContext(SelfContext);
  // const [filters, setFilters] = useState([]);
  // const [filterPurchases, setFilterPurchases] = useState([]);
  // const [filterCart, setFilterCart] = useState([]);
  // const [locations, setLocations] = useState([]);

  const { loading, data } = useQuery<{ filters: IFilter[] }, null>(INIT_FEATHERS_PAGE);

  // useEffect(() => {
  //   fetchAll();
  // }, []);
  // async function fetchAll() {
  //   fetchFilterPurchases();
  //   fetchFilters();
  // }
  // async function fetchFilters() {
  //   try {
  //     const filterList = await a pi.getFilters();
  //     const locations = await a pi.getLocations();
  //     setLocations(locations.map((location) => new Location(location)));
  //     await filterList.map(async (filter) => {
  //       return Object.assign(filter, {
  //         locations: await locations.filter((location) => (location.ervType ? Object.values(location.ervType.filters).includes(filter._id) : false)),
  //       });
  //     });
  //     setFilters(filterList.map((filter) => new Filter(filter)));
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }
  // async function fetchFilterPurchases() {
  //   try {
  //     const filterPurchases = await a pi.getFilterPurchases();
  //     setFilterPurchases(filterPurchases.map((filterPurchase) => new FilterPurchase(filterPurchase)));
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // function handleFilterCart(filter) {
  //   const matchingItemIndex = filterCart.findIndex((item) => item._id === filter._id);
  //   if (matchingItemIndex !== -1) {
  //     const newFilterCart = filterCart;
  //     newFilterCart[matchingItemIndex].stock += filter.stock;
  //     setFilterCart([...newFilterCart]);
  //   } else {
  //     setFilterCart([...filterCart, filter]);
  //   }
  // }
  // function handleFilterRemove(filter) {
  //   let cartItems = filterCart.filter((item) => {
  //     return item._id !== filter._id;
  //   });
  //   setFilterCart(cartItems);
  // }
  // function handleFilterChange(filter, stock) {
  //   let cartItems = filterCart.map((item) => {
  //     if (item._id === filter._id) item.stock = stock;
  //     return item;
  //   });
  //   setFilterCart(cartItems);
  // }
  // async function handlePurchase() {
  //   if (filterCart.length === 0) return;
  //   const purchase = {
  //     filters: filterCart,
  //     status: "ready",
  //   };
  //   await a pi.addFilterPurchase(purchase);
  //   setFilterCart([]);
  //   fetchFilterPurchases();
  //   toastSuccess("주문서가 작성되었습니다.");
  // }

  const filterPanes = data && [
    {
      menuItem: "부직포",
      render: () => (
        <Tab.Pane attached={false}>
          {/* <FilterTable medium="non-woven-fabric" filters={data.filters} handleFilterCart={handleFilterCart} /> */}
          <FilterTable medium="non-woven-fabric" filters={data.filters} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "헤파",
      render: () => (
        <Tab.Pane attached={false}>
          {/* <FilterTable medium="H13-hepa" filters={data.filters} handleFilterCart={handleFilterCart} /> */}
          <FilterTable medium="H13-hepa" filters={data.filters} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "카본",
      render: () => (
        <Tab.Pane attached={false}>
          {/* <FilterTable medium="non-woven-carbon" filters={data.filters} handleFilterCart={handleFilterCart} /> */}
          <FilterTable medium="non-woven-carbon" filters={data.filters} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "열교환소자",
      render: () => (
        <Tab.Pane attached={false}>
          {/* <FilterTable medium="heat-exchange-element" filters={data.filters} handleFilterCart={handleFilterCart} /> */}
          <FilterTable medium="heat-exchange-element" filters={data.filters} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "미디움",
      render: () => (
        <Tab.Pane attached={false}>
          {/* <FilterTable medium="E11-medium" filters={data.filters} handleFilterCart={handleFilterCart} /> */}
          <FilterTable medium="E11-medium" filters={data.filters} />
        </Tab.Pane>
      ),
    },
  ];

  if (loading)
    return (
      <DefaultLayout>
        <Loader active />
      </DefaultLayout>
    );

  if (!self || (self && self.role !== "superAdmin")) {
    return null;
  }

  return (
    <DefaultLayout>
      <div>
        {/* <Segment>
            <Header as="h3">Orders</Header>
            <Card.Group style={{ minHeight: 150 }}>
              {filterPurchases.map((filterPurchase) =>
                filterPurchase.status === "ready" ? <FilterPurchaseItem key={filterPurchase._id} filterPurchase={filterPurchase} onUpdate={fetchAll} /> : null
              )},                 
            </Card.Group>
          </Segment> 

   <Segment>
            <Header as="h3">Purchase</Header>
            <FilterCart medium="any" filters={filterCart} handleFilterRemove={handleFilterRemove} handleFilterChange={handleFilterChange} />
            <div style={{ overflow: "hidden" }}>
              <Button animated floated="right" color="blue" onClick={handlePurchase}>
                <Button.Content visible>Purchase</Button.Content>
                <Button.Content hidden>
                  <Icon name="arrow right" />
                </Button.Content>
              </Button>
            </div>
              </Segment> */}

        <Segment>
          <Header as="h3">Filters</Header>
          <Tab menu={{ pointing: true }} panes={filterPanes} />
        </Segment>
      </div>
    </DefaultLayout>
  );
}

export default Filters;

// function FilterCart({ medium, filters, handleFilterRemove, handleFilterChange }) {
//   return (
//     <>
//       <Table compact="very" celled>
//         <Table.Header>
//           <Table.Row>
//             <Table.HeaderCell>index</Table.HeaderCell>
//             <Table.HeaderCell>specification</Table.HeaderCell>
//             <Table.HeaderCell>medium</Table.HeaderCell>
//             <Table.HeaderCell>stock</Table.HeaderCell>
//             <Table.HeaderCell>cancel</Table.HeaderCell>
//           </Table.Row>
//         </Table.Header>
//         <Table.Body>
//           {filters && filters.length > 0 ? (
//             filters.map((filter, index) =>
//               filter.medium === medium || medium === "any" ? (
//                 <FilterItem key={filter._id} index={index} filter={filter} handleRemove={handleFilterRemove} handleChange={handleFilterChange} />
//               ) : null
//             )
//           ) : (
//             <Table.Row>
//               <Table.Cell colSpan="4">
//                 <EmptyMessage>Empty</EmptyMessage>
//               </Table.Cell>
//             </Table.Row>
//           )}
//         </Table.Body>
//       </Table>
//     </>
//   );
// }
