import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/ko";
import "@styles/weekltReportStyle.css";
import WeeklyReportCharts from "@Components/Common/Charts/WeeklyReportCharts";
import { INIT_WEEKLY_REPORT_PAGE } from "@graphql/query";
import { useQuery } from "@apollo/react-hooks";
import { toastError } from "@util";
import { Helmet } from "react-helmet";
import { IFloorLogDataHeader, IFloor, IUser, IFloorLog } from "@interfaces";

function WeeklyReportArea({ userId, floorId }: { userId: string; floorId: string }) {
  const [fanspeedData, setFanspeedData] = useState<any[]>();
  const [highlightType, setHighlightType] = useState<"co2" | "voc">("co2");
  const [vocOrCo2Data, setVocOrCo2Data] = useState<any[]>();
  const [highlightMsg, setHighlightMsg] = useState("");
  const [isShowHighlight, setIsShowHighlight] = useState(true);
  const [kaptAddr, setKaptAddr] = useState("");

  const { loading, data } = useQuery<{ user: IUser; latestWeeklyFloorLog: IFloorLog }, { userId: string; floorId: string }>(INIT_WEEKLY_REPORT_PAGE, {
    variables: { userId: userId, floorId: floorId },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (!data.latestWeeklyFloorLog || !data.user) return;
      const bodyIndex: any = {};

      data.latestWeeklyFloorLog.header.forEach((cur: IFloorLogDataHeader, index: number) => {
        if (!bodyIndex[cur.nodeModel]) bodyIndex[cur.nodeModel] = {};
        bodyIndex[cur.nodeModel][cur.dataType] = index;
      });

      // 하이라이트
      if (!data.latestWeeklyFloorLog.highlight.co2.startAt || !data.latestWeeklyFloorLog.highlight.voc.startAt) {
        setIsShowHighlight(false);
      } else {
        // 하이라이트 대상지정
        let highlightTypeValue: "co2" | "voc" = "co2";
        if (data.latestWeeklyFloorLog.highlight.co2.weight < data.latestWeeklyFloorLog.highlight.voc.weight) {
          highlightTypeValue = "voc";
        }
        setHighlightType(highlightTypeValue);

        // 하이라이트 그래프
        const fanspeedData: any[] = [];
        const vocOrCo2Data: any[] = [];

        data.latestWeeklyFloorLog.body.forEach((cur: any[][]) => {
          if (
            moment(cur[bodyIndex["logtime"]["logtime"]]).isBefore(moment(data.latestWeeklyFloorLog.highlight[highlightTypeValue].startAt)) ||
            moment(cur[bodyIndex["logtime"]["logtime"]]).isAfter(moment(data.latestWeeklyFloorLog.highlight[highlightTypeValue].endAt))
          ) {
            return true;
          }
          fanspeedData.push([moment(cur[bodyIndex["logtime"]["logtime"]]).valueOf(), cur[bodyIndex["erv"]["ventLevel"]]]);
          vocOrCo2Data.push([moment(cur[bodyIndex["logtime"]["logtime"]]).valueOf(), cur[bodyIndex["section"][highlightTypeValue]]]);
        });

        setFanspeedData(fanspeedData);
        setVocOrCo2Data(vocOrCo2Data);

        // 하이라이트 영역 지정
        const highlightTime = data.latestWeeklyFloorLog.highlight[highlightTypeValue].peakAt;
        const highlightIndex = getHighlightIndex(data.latestWeeklyFloorLog.highlight[highlightTypeValue].startAt, highlightTime);
        const highlightItem = document.querySelector(`.highlight__deco>.item:nth-child(${highlightIndex + 1})`);
        highlightItem && highlightItem.classList.add("active");

        // 하이라이트 문구
        const highlightMsg = makeHighlightMsg(highlightTypeValue, highlightTime, data.latestWeeklyFloorLog.highlight[highlightTypeValue].maxVentLevel);
        setHighlightMsg(highlightMsg);
      }

      // 종합 점수 그래프
      const totalGraph: HTMLElement | null = document.querySelector("#totalgraph__graph");
      if (totalGraph) {
        const deg = (-180 * (100 - data.latestWeeklyFloorLog.summary.currentScoreAvg)) / 100;
        totalGraph.style.transform = "rotate(" + deg + "deg)";
      }

      // 평범한 가정집 공기질 비교
      if (data.latestWeeklyFloorLog.summary.compToNorm && data.latestWeeklyFloorLog.summary.compToNorm > 0 && !!data.latestWeeklyFloorLog.summary.ventCalNum) {
        const totalArrow: HTMLElement | null = document.querySelector("#totalgraph__arrow");
        if (totalArrow) {
          const deg2 = (-180 * (100 - data.latestWeeklyFloorLog.summary.currentScoreAvg + data.latestWeeklyFloorLog.summary.compToNorm)) / 100;
          totalArrow.style.transform = "rotate(" + deg2 + "deg)";
        }
      }

      // kaptAddr 지정
      if (data.user.floors) {
        const currentFloor: IFloor | undefined = data.user.floors.find((floor: IFloor) => floor._id === floorId);
        // if (currentFloor && currentFloor.location) {
        if (currentFloor?.location?.kaptInfo?.kaptAddr) {
          setKaptAddr(currentFloor.location.kaptInfo.kaptAddr);
        }
      }
    },
    onError(error) {
      console.error(error);
      toastError("조회 실패", error.message);
    },
  });

  useEffect(() => {
    if (!loading) return;
    reset();
  }, [loading]);

  const reset = () => {
    setFanspeedData([]);
    setVocOrCo2Data([]);
    setIsShowHighlight(true);
    setHighlightMsg("");
    setKaptAddr("");
    const highlightItems = document.querySelectorAll(`.highlight__deco>.item`);
    for (let i = 0; i < highlightItems.length; i++) {
      highlightItems[i].classList.remove("active");
    }
  };

  if (!data) return null;
  if (!data.latestWeeklyFloorLog) return <>latestWeeklyFloorLog 데이터 없음</>;

  return (
    <>
      <Helmet>
        <title>{data.user.name}(weeklyReport)</title>
      </Helmet>

      <div id="page" className="weekly-page">
        <div id="header">
          <img className="logo" src="/weeklyReport/logo.png" alt="logo" />
          <h3>
            {data.user.name}님 댁의
            <br /> {moment(data.latestWeeklyFloorLog.from).format("MM/DD")} - {moment(data.latestWeeklyFloorLog.to).format("MM/DD")} 주간 리포트입니다!
            <br />
            {kaptAddr && <span>{kaptAddr}</span>}
          </h3>
        </div>
        <div className="segment segment--total">
          <h3>종합 점수</h3>
          <div id="totalgraph">
            <img id="totalgraph__bg" src="/weeklyReport/total_bg.png" alt="total_bg" />
            <img id="totalgraph__graph" src="/weeklyReport/total_graph.png" alt="total_graph" />
            <p id="totalgraph__number"> {data.latestWeeklyFloorLog.summary.currentScoreAvg && data.latestWeeklyFloorLog.summary.currentScoreAvg.toFixed(1)} </p>
            {data.latestWeeklyFloorLog.summary.compToNorm &&
              data.latestWeeklyFloorLog.summary.compToNorm > 0 &&
              !!data.latestWeeklyFloorLog.summary.ventCalNum && (
                <>
                  <img id="totalgraph__arrow" src="/weeklyReport/total_arrow.png" alt="total_arrow" />
                  <p className="msg msg--total">
                    평범한 가정집보다 <b>{data.latestWeeklyFloorLog.summary.compToNorm.toFixed(1)}%</b> 좋아요!
                  </p>
                </>
              )}
          </div>
        </div>
        {isShowHighlight && (
          <div className="segment">
            <h3>하이라이트</h3>
            <div className="legend">
              <p>
                {highlightType}
                <span />
              </p>
              <p>
                환기량
                <span />
              </p>
            </div>
            <div className="highlight">
              <div className="highlight__deco">
                <div className="item">
                  <span className="date">{moment(data.latestWeeklyFloorLog.highlight[highlightType].startAt).format("MM/DD")}</span>
                  <br />
                  {moment(data.latestWeeklyFloorLog.highlight[highlightType].startAt).hour()}
                </div>
                <div className="item">
                  <span className="date">{showNextDate(data.latestWeeklyFloorLog.highlight[highlightType].startAt, 4)}</span>
                  <br />
                  {moment(data.latestWeeklyFloorLog.highlight[highlightType].startAt).add(4, "hour").hour()}
                </div>
                <div className="item">
                  <span className="date">{showNextDate(data.latestWeeklyFloorLog.highlight[highlightType].startAt, 8)}</span>
                  <br />
                  {moment(data.latestWeeklyFloorLog.highlight[highlightType].startAt).add(8, "hour").hour()}
                </div>
                <div className="item">
                  <span className="date">{showNextDate(data.latestWeeklyFloorLog.highlight[highlightType].startAt, 12)}</span>
                  <br />
                  {moment(data.latestWeeklyFloorLog.highlight[highlightType].startAt).add(12, "hour").hour()}
                </div>
              </div>
              {/*  */}
              {fanspeedData && <WeeklyReportCharts fanspeedData={fanspeedData} highlightType={highlightType} vocOrCo2Data={vocOrCo2Data} />}
            </div>
            <div className="highlight-msg msg" dangerouslySetInnerHTML={{ __html: highlightMsg }}></div>
          </div>
        )}
        <div className="segment">
          <h3>
            PM2.5 <span>(초미세먼지)</span>
          </h3>
          <div className="descs">
            <div className="desc--1">
              {data.latestWeeklyFloorLog.summary.avgPm25 ? data.latestWeeklyFloorLog.summary.avgPm25.toFixed(1) : "-"}
              <span>
                μg/m<sup>3</sup>
              </span>
            </div>
            <div className="desc--2">
              <span dangerouslySetInnerHTML={{ __html: getIconPathnMsg("PM", data.latestWeeklyFloorLog.summary.avgPm25)[1] }}></span>
              <img className="icon" src={getIconPathnMsg("PM", data.latestWeeklyFloorLog.summary.avgPm25)[0]} alt="icon" />
            </div>
          </div>
          <div className="pm25graphs">
            <div>
              <div className="pm25graph">
                <div className="pm25graph__date">{data.latestWeeklyFloorLog.highlight.dailyPm25.shortDate}</div>
                <div className="pm25graph__bar">
                  <div className="pm25graph__out" style={{ width: "100%" }}>
                    <span className="pm25graph__value">
                      {data.latestWeeklyFloorLog.highlight.dailyPm25.avgOutdoorValue
                        ? data.latestWeeklyFloorLog.highlight.dailyPm25.avgOutdoorValue.toFixed(1)
                        : "-"}
                      μg/m<sup>3</sup>
                    </span>
                  </div>
                  <div
                    className="pm25graph__in"
                    style={{
                      width: `${
                        (data.latestWeeklyFloorLog.highlight.dailyPm25.avgIndoorValue / data.latestWeeklyFloorLog.highlight.dailyPm25.avgOutdoorValue) * 100
                      }%`,
                    }}
                  >
                    <span className="pm25graph__value">
                      {data.latestWeeklyFloorLog.highlight.dailyPm25.avgIndoorValue
                        ? data.latestWeeklyFloorLog.highlight.dailyPm25.avgIndoorValue.toFixed(1)
                        : "-"}
                      μg/m<sup>3</sup>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p>
                야외
                <span />
              </p>
              <p>
                실내
                <span />
              </p>
            </div>
          </div>
          {/* <div className="msg"></div> */}
        </div>
        <div className="segment">
          <h3>
            CO<span>2 (이산화탄소)</span>
          </h3>
          <div className="descs">
            <div className="desc--1">
              {data.latestWeeklyFloorLog.summary.avgCo2 ? data.latestWeeklyFloorLog.summary.avgCo2.toFixed(1) : "-"}
              <span>ppm</span>{" "}
            </div>
            <div className="desc--2">
              {getIconPathnMsg("CO2", data.latestWeeklyFloorLog.summary.avgCo2)[1]}
              <img className="icon" src={getIconPathnMsg("CO2", data.latestWeeklyFloorLog.summary.avgCo2)[0]} alt="icon" />
            </div>
          </div>
        </div>
        <div className="segment">
          <h3>
            VOC<span>s (휘발성 유기화합물)</span>
          </h3>
          <div className="descs">
            <div className="desc--1">
              {data.latestWeeklyFloorLog.summary.avgVoc ? data.latestWeeklyFloorLog.summary.avgVoc.toFixed(1) : "-"}
              <span>ppb</span>{" "}
            </div>
            <div className="desc--2">
              {getIconPathnMsg("VOC", data.latestWeeklyFloorLog.summary.avgVoc)[1]}
              <img className="icon" src={getIconPathnMsg("VOC", data.latestWeeklyFloorLog.summary.avgVoc)[0]} alt="icon" />
            </div>
          </div>
        </div>
        {!!data.latestWeeklyFloorLog.summary.ventCalNum && (
          <div className="segment">
            <h3>
              환기 계산 횟수 <span>(하루 평균)</span>
            </h3>
            <div className="descs">
              <div className="desc--1">
                {(data.latestWeeklyFloorLog.summary.ventCalNum / 7).toFixed(0)}
                <span>번</span>{" "}
              </div>
              <div className="desc--2">
                딱 필요할 때 환기하는 똑똑한 퍼핀플레이스!
                <img className="icon" src="/weeklyReport/wind.svg" alt="icon" />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default WeeklyReportArea;

const getIconPathnMsg = (mode: "CO2" | "VOC" | "PM", value: number) => {
  let name = "";
  let msg = "";
  if (mode === "CO2") {
    if (value >= 0 && value <= 600) {
      name = "1.svg";
      msg = "좋음 기준 600ppm 이하";
    } else if (value > 600 && value <= 1000) {
      name = "2.svg";
      msg = "괜찮음 기준 1000ppm 이하";
    } else if (value > 1000 && value <= 1500) {
      name = "3.svg";
      msg = "나쁘지 않음 기준 1500ppm 이하";
    } else if (value > 1500 && value <= 2500) {
      name = "4.svg";
      msg = "안좋음 기준 1500ppm 이상";
    } else {
      name = "5.svg";
      msg = "나쁨 기준 2500ppm 이상";
    }
  } else if (mode === "VOC") {
    if (value >= 0 && value <= 400) {
      name = "1.svg";
      msg = "좋음 기준 400ppb 이하";
    } else if (value > 400 && value <= 1000) {
      name = "2.svg";
      msg = "괜찮음 기준 1000ppb 이하";
    } else if (value > 1000 && value <= 3000) {
      name = "3.svg";
      msg = "나쁘지 않음 기준 3000ppb 이하";
    } else if (value > 3000 && value <= 8000) {
      name = "4.svg";
      msg = "안좋음 기준 3000ppb 이상";
    } else {
      name = "5.svg";
      msg = "나쁨 기준 8000ppb 이상";
    }
  } else if (mode === "PM") {
    if (value >= 0 && value <= 15) {
      name = "1.svg";
      msg = "좋음 기준 15μg/m<sup>3</sup> 이하";
    } else if (value > 15 && value <= 35) {
      name = "2.svg";
      msg = "괜찮음 기준 35μg/m<sup>3</sup> 이하";
    } else if (value > 35 && value <= 55) {
      name = "3.svg";
      msg = "나쁘지 않음 기준 55μg/m<sup>3</sup> 이하";
    } else if (value > 55 && value <= 75) {
      name = "4.svg";
      msg = "안좋음 기준 75μg/m<sup>3</sup> 이하";
    } else {
      name = "5.svg";
      msg = "나쁨 기준 75μg/m<sup>3</sup> 이상";
    }
  }
  return [`/weeklyReport/${name}`, msg];
};

const getHighlightIndex = (startTime: Date, highlightTime: Date) => {
  const startHour = moment(startTime).hour();
  let pickHour = moment(highlightTime).hour();
  if (startHour > pickHour) pickHour += 24;
  return Math.floor((pickHour - startHour) / 4);
};

const makeHighlightMsg = (type: "co2" | "voc", highlightTime: Date, ventLevel: number) => {
  let typeStr = "";
  if (type === "co2") {
    typeStr = '<span class="co2">CO<sub>2</sub></span>';
  } else if (type === "voc") {
    typeStr = '<span class="co2">VOC</span>';
  }
  const dateStr = moment(highlightTime).locale("ko").format("M월 D일 dddd a h시");
  return `${dateStr}쯤에 ${typeStr}수치가 급증했었네요.<br />그래서 퍼핀플레이스가 곧바로 작업을 시작했어요.<br /><span class="fan">환기량</span>을 ${Math.floor(
    ventLevel
  )}%로 증가시켜<br />${typeStr}가 빠르게 줄어드는 게 보이시나요? :D`;
};

const showNextDate = (startAt: Date, addHour: number) => {
  if (moment(startAt).date() !== moment(startAt).add(addHour, "hour").date() && moment(startAt).add(addHour, "hour").hour() < 4) {
    return moment(startAt).add(addHour, "hour").format("MM/DD");
  } else {
    return "";
  }
};
