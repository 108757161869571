import React, { useState } from "react";
import { Button, Form, Input, Modal, Select, Rating } from "semantic-ui-react";

type TProps = {
  name?: String;
  profile?: any;
  onSubmit?: any;
};

export const SectionForm = ({ name:prevName, profile, onSubmit}: TProps) => {

  const [name, setName] = useState(prevName || "");
  const [sectionType, setSectionType] = useState(profile?.sectionType || "bedroom");
  const [ervVentInfluenceLevel, setErvVentInfluenceLevel] = useState<any>(profile?.ervVentInfluenceLevel);
  const [ervNoiseInfluenceLevel, setErvNoiseInfluenceLevel] = useState<any>(profile?.ervNoiseInfluenceLevel);
  const ratings = ["very low", "low", "mid", "high", "very high"];
  const sectionTypes = ["bedroom", "livingroom", "office", "kidroom", "kitchen", "etc"];

  return (
    <>
      <Modal.Content>
        <Form
          onSubmit={() => {
            onSubmit({
              name,
              profile: { sectionType, ervVentInfluenceLevel, ervNoiseInfluenceLevel },
            });
          }}
        >
          <Form.Group>
            <Form.Field>
              <label>Name</label>
              <Input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
            </Form.Field>
            <Form.Field>
              <label>공간 타입</label>
              <Select
                value={sectionType}
                options={sectionTypes.map((opt) => {
                  return { key: opt, text: opt, value: opt };
                })}
                onChange={(e, data: any) => setSectionType(data.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>ERV 영향력: {ervVentInfluenceLevel}</label>
              <Rating
                icon="heart"
                rating={ratings.findIndex((val) => val === ervVentInfluenceLevel) + 1}
                maxRating={5}
                onRate={(e, data: any) => {
                  setErvVentInfluenceLevel(ratings[data.rating - 1]);
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>소음 영향력: {ervNoiseInfluenceLevel}</label>
              <Rating
                icon="heart"
                rating={ratings.findIndex((val) => val === ervNoiseInfluenceLevel) + 1}
                maxRating={5}
                onRate={(e, data: any) => {
                  setErvNoiseInfluenceLevel(ratings[data.rating - 1]);
                }}
              />
            </Form.Field>
          </Form.Group>
          <Button type="submit" fluid>
            Submit
          </Button>
        </Form>
      </Modal.Content>
    </>
  );
}

