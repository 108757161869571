import React from "react";
import { useParams } from "react-router-dom";
import WeeklyReportArea from "@Components/Reports/WeeklyReportArea";

function WeeklyReport() {
  const { userId, floorId }: { userId: string; floorId: string } = useParams();
  return <WeeklyReportArea userId={userId} floorId={floorId} />;
}

export default WeeklyReport;
