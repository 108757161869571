import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Table } from "semantic-ui-react";
import styled from "styled-components";

function ContractItem({ contract, orgId, userId, current = "contracts" }) {
  const to = `/orgs/${orgId}/users/${userId}/contracts/${contract._id}/billings`;

  return (
    <StyledTableRow status={contract.status}>
      {current === "billings" ? (
        <Table.Cell>{contract.serviceTier.name}</Table.Cell>
      ) : (
        <Table.Cell>
          <Link to={to}>{contract.serviceTier.name}</Link>
        </Table.Cell>
      )}
      <Table.Cell>{contract.serviceTier.type}</Table.Cell>
      <Table.Cell>
        {contract.startAt && moment(contract.startAt).format("YYYY-MM-DD")} ~ {contract.endAt ? moment(contract.endAt).format("YYYY-MM-DD") : "진행중"}
        {contract.reqCancelAt && <SmallText> (해지 신청일 : {moment(contract.reqCancelAt).format("YYYY-MM-DD")})</SmallText>}
      </Table.Cell>
      <Table.Cell>{contract.status}</Table.Cell>
    </StyledTableRow>
  );
}

export default ContractItem;

const StyledTableRow = styled(Table.Row)`
  ${(props) => props.status === "inactive" && "color: #999;"}
`;

const SmallText = styled.span`
  font-size: 10px;
`;
