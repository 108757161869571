import React from "react";
import type { ReactNode } from "react";
import { SectionCardProvider } from "./useSectionCardContext";
import { Label, Awair, Option, Description, CheckBox, Marker, Detail, DetailBtns } from "./layouts";
import type { IAwair, IFloor, ISection, IUser } from "@interfaces";
import { Card } from "semantic-ui-react";
import { useSectionCard } from "./useSectionCard";
import { SectionForm, AwairSelectorForm } from "@Components/Modals";

type TProps = {
  children: ReactNode;
  awairs?: IAwair[];
  section?: ISection;
  user?: IUser;
  floor?: IFloor;
};

function SectionCard({ children, awairs, section, user, floor }: TProps) {
  const {
    sectionModifySubmit,
    SectionModalPortal,
    openSectionModal,
    handleRemove,
    handleUnallocate,
    AwairSelectorModalPortal,
    openAwairSelectorModal,
    awairSelectorModifySubmit,
    detailType,
    setDetailType,
  } = useSectionCard({ section, user, floor });
  return (
    <>
      <SectionCardProvider
        value={{ awairs, openSectionModal, handleRemove, handleUnallocate, openAwairSelectorModal, floor, section, detailType, setDetailType }}
      >
        <Card>{children}</Card>
      </SectionCardProvider>
      <SectionModalPortal title={`${user?.name} / ${floor?.location?.kaptInfo?.kaptName} / ${floor?.name}`}>
        <SectionForm onSubmit={sectionModifySubmit} name={section?.name} profile={section?.profile} />
      </SectionModalPortal>
      <AwairSelectorModalPortal title="Section에 Awair를 할당">
        <AwairSelectorForm onSubmit={awairSelectorModifySubmit} />
      </AwairSelectorModalPortal>
    </>
  );
}

SectionCard.Label = Label;
SectionCard.Awair = Awair;
SectionCard.Option = Option;
SectionCard.Description = Description;
SectionCard.CheckBox = CheckBox;
SectionCard.Marker = Marker;
SectionCard.Detail = Detail;
SectionCard.DetailBtns = DetailBtns;

export { SectionCard };
