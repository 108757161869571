import React from "react";
import { Link } from "react-router-dom";
import { Segment, Icon, Button } from "semantic-ui-react";
import styled from "styled-components";
import PinPointMap from "@Components/Common/PinPointMap";
import { IFloor } from "@interfaces";

function MapSegment({
  curFloorId,
  orgId,
  userId,
  curFloor,
  userStatus,
}: {
  curFloorId: string;
  orgId: string;
  userId: string;
  curFloor: IFloor;
  userStatus: string;
}) {
  return (
    <Segment>
      <BtnGroup style={{lineHeight: "2.4em"}}>
        {curFloorId && (
          <Link to={`/orgs/${orgId}/users/${userId}/floors/${curFloorId}/position`}>
            <Button size="mini" disabled={userStatus === "filter" ? true : false}>
              <Icon name="map marker" />
              Position
            </Button>
          </Link>
        )}
        {curFloorId && curFloor?.location && (
          <Link to={`/orgs/${orgId}/users/${userId}/locations/${curFloor.location._id}/floors/${curFloorId}`}>
            <Button size="mini" disabled={userStatus === "filter" ? true : false}>
              <Icon name="chart bar" />
              Floor Control
            </Button>
          </Link>
        )}
        {curFloorId && (
          <>
            <Link to={`/orgs/${orgId}/users/${userId}/floors/${curFloorId}/consulting`}>
              <Button size="mini" disabled={userStatus === "filter" ? true : false}>
                <Icon name="file alternate outline" />
                Consulting Report
              </Button>
            </Link>
            <Link to={`/orgs/${orgId}/users/${userId}/floors/${curFloorId}/dashboard`}>
              <Button color="blue" size="mini" disabled={userStatus === "filter" ? true : false}>
                <Icon name="chart bar" />
                Charts
              </Button>
            </Link>
            {/* <Link to={`/orgs/${orgId}/users/${userId}/floors/${curFloorId}/dashboard2`}>
              <Button color="grey" size="mini" disabled={userStatus === "filter" ? true : false}>
                <Icon name="chart bar" />
                Charts (old)
              </Button>
            </Link>  */}
          </>
        )}
      </BtnGroup>
      {curFloor?.location?.coordinates ? (
        <PinPointMap coordinates={curFloor.location.coordinates} name={curFloor?.location?.kaptInfo?.kaptName} />
      ) : (
        <NoFloorMsg>floor 미지정</NoFloorMsg>
      )}
    </Segment>
  );
}

export default MapSegment;

const BtnGroup = styled.div`
  margin-bottom: 20px;
  text-align: right;
  a {
    margin-left: 4px;
  }
`;

const NoFloorMsg = styled.div`
  min-height: 200px;
  color: #ccc;
  text-align: center;
`;
