import React from "react";
import type {ReactNode} from "react"; 

const HvacItemContext = React.createContext(undefined);
type TProps = {
  children: ReactNode,
  value: any
}

function HvacItemProvider({ children, value }: TProps) {
  return (
    <HvacItemContext.Provider value={value}>{children}</HvacItemContext.Provider>
  );
}

function useHvacItemContext() {
  const context = React.useContext(HvacItemContext);
  if (context === undefined) {
    throw new Error("useHvacItemContext must be used within a HvacItemProvider");
  }
  return context;
}

export { HvacItemProvider, useHvacItemContext };
